import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import OutsideClickHandler from "react-outside-click-handler";
import PhoneNumber from "../../../Common/PhoneNumber/PhoneNumber";
import Message from "../EmployerForm/Message";
import API from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import { getCitiesAsync, getCountriesAsync, getSearchSuggestionAsync, getStateAsync, resendVerifyEmailAsync } from "../../../../redux/slices/AuthSlice";
import './CandidateForm.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const CandidateForm = ({
  errorMsg,
  successMsg,
  loader,
  candidateResume,
  errorMsgHandler,
  resumeModelHandler,
  CandidateSignUpHandler,
}) => {
  const [selectedImageName, setSelectedImageName] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);

  const [allJobTitles, setAllTobTitles] = useState([]);
  const [selectJobTitles, setSelectJobTitles] = useState([]);
  const [allJobTitlesHide, setAllJobTitlesHide] = useState(true);
  const [currencyOption, setCurrencyOption] = useState({});
  const [salaryExpectationOption, setSalaryExpectationOption] = useState([]);
  const { user, mode, postJobId } = useSelector((state) => state.auth);
  const [allSkillTags, setAllSkillTags] = useState([]);
  const [allWorkPreference, setAllWorkPreference] = useState([]);
  const [selectSkillTags, setSelectSkillTags] = useState([]);
  const [selectWorkPreferenceTags, setSelectWorkPreferenceTags] = useState([]);
  const [allSkillTagsHide, setAllSkillTagsHide] = useState(false);
  const [allWorkPreferenceTagsHide, setAllWorkPreferenceTagsHide] = useState(true);

  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [maskedPlaceHolder, setMaskedPlaceHolder] = useState("");
  

  const workPreferenceArray = [];
  const dispatch = useDispatch();

  const highlightMatchingText = (option, inputValue) => {
    const lowercaseInputValue = inputValue?.toLowerCase();
    const regex = new RegExp(`(${lowercaseInputValue})`, "gi");
    const parts = option.split(regex);

    return (
      <span>
        {parts.map((part, index) =>
          regex.test(part) ? (
            <strong key={index} style={{ color: "#143975" }}>
              {part}
            </strong>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const onCurrencyClick = async (id, name) => {
    await formik.setFieldValue("currencyId", id);
    await formik.setFieldValue("currency", name);
  };

  const onSalaryExpectationClick = async (name) => {
    console.log(name)
    await formik.setFieldValue("salaryExpectation", name);
  };

  const getJobDropDownData = () => {
    // if (user !== null) {}
    const currencies = [{
      id: 1,
      country
        :
        null,
      flag_link
        :
        "https://demo.workbridgetalent.ca/uploads/flags/macedonia.png",
      symbol
        :
        "Ден",
      title
        :
        "MKD"
    }]
    const salaryExpectation = [
      {
        label
          :
          "Year",
        page
          :
          "",
        value
          :
          "year"
      },
      {
        label
          :
          "Month",
        page
          :
          "",
        value
          :
          "month"
      },
      {
        label
          :
          "Hour",
        page
          :
          "",
        value
          :
          "hour"
      }
    ]



    setSalaryExpectationOption(salaryExpectation);

  }




  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      salaryExpectation: "month",
      confirmPassword: "",
      country: "",
      countryId: null,
      state: "",
      stateId: null,
      city: "",
      cityId: null,
      phoneNo: "",
      currency: "",
      currencyId: null,
      files: null,
      jobTitle: "",
      skillTags: "",
      workPreference: "",
      userWorkPreferences: [],
      uploadR: "",
      linkedinURL: "",
      termServices: false,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z ]+$/, "First name should be an alphabet")
        .required("Please enter first name"),
      lastName: Yup.string()
        .matches(/^[A-Za-z ]+$/, "Last name should be an alphabet")
        .required("Please enter last name"),
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Please enter email"),
      password: Yup.string().test((value) => {
        const errors = [];
        if (!value || value.length < 8) {
          errors.push("Password must be at least 8 characters in length");
        }
        if (!/\d/.test(value)) {
          errors.push("Use a minimum of 1 numeric value");
        }
        if (!/[A-Z]/.test(value)) {
          errors.push("Use at least 1 upper case letter (ABCD)");
        }
        if (!/[a-z]/.test(value)) {
          errors.push("Use at least 1 lower case letter (abcd)");
        }
        if (!/[!#%$*]/.test(value)) {
          errors.push("Use at least one special character (!# % $ *)");
        }
        setPasswordErrors(errors);

        return errors.length === 0;
      }),
      confirmPassword: Yup.string()
        .required("Please re-enter confirm password")
        .oneOf([Yup.ref("password")], "Your passwords do not match"),
      country: Yup.string().required("Please select country"),
      state:  Yup.string(),
      city:  Yup.string(),
      phoneNo: Yup.string().required("Please enter phone number"),
      files: Yup.mixed()
        .required("Upload a resume in a PDF format")
        .test(
          "fileType",
          "Please upload a file in PDF format",
          (value) => !value || ["application/pdf"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 5 MB",
          (value) => !value || value?.size <= 5 * 1024 * 1024
        ),
      termServices: Yup.boolean()
        .oneOf([true], "Please select the terms and conditions")
        .required("Please select the terms and conditions"),
      // jobTitle: Yup.string().required("Enter job titles"),
      // skillTags: Yup.string().test(
      skillTags: Yup.mixed().test(
        "skillTags",
        "Please select a minimum of 5 skill tags",
        (value) =>
          !!(Array.isArray(selectSkillTags) && selectSkillTags?.length >= 5)
      ).test(
        "skillTags",
        "A maximum of 10 technical tags can be selected",
        (value) => (Array.isArray(selectSkillTags) && selectSkillTags?.length <= 10)
      ),
      jobTitle: Yup.mixed().test(
        "jobTitle",
        "Please select a maximum of 5 job titles",
        (value) => !!(Array.isArray(selectJobTitles) && selectJobTitles?.length <= 5)
      ),
    }),
    onSubmit: async (data, { resetForm, setErrors }) => {
      const { email, state, city } = data;
      if (passwordErrors?.length > 0) return;
      if (selectSkillTags?.length < 5 || selectSkillTags?.length > 10 || selectJobTitles?.length > 5) return;
      if(stateOptions?.length > 0 && state === "") {
        formik?.setFieldError("state", "Please select state");
        return;
      }
      if(citiesOptions?.length > 0 && city === "") {
        formik?.setFieldError("city", "Please select city");
        return;
      }
      try {
        await API.post("/auth/check-email-unique", {
          email: email,
        })
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              let res = response?.data;
              if (response?.data?.result === true) {
                const selectJobTitlesId = selectJobTitles?.map(
                  (item) => item?.id
                );
                const selectSkillTagsId = selectSkillTags.map(
                  (item) => item?.id
                );

                const selectWorkPreferenceId = selectWorkPreferenceTags.map((item) => item?.id);
                CandidateSignUpHandler(
                  data,
                  selectJobTitlesId,
                  selectSkillTagsId,
                  selectWorkPreferenceId
                );
              } else {
                let errorMsg = res?.message?.email[0];
                setErrors({ email: errorMsg });
                errorMsgHandler("");
              }
            }
          })
          .catch((error) => {
            if (error) {
              const { response } = error;
              const { data } = response;
              setErrors({ email: data?.message?.error });
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isSkillTagsFieldValid = () =>
    !!(
      formik.touched["skillTags"] &&
      formik.errors["skillTags"] &&
      selectSkillTags?.length < 5 || selectSkillTags?.length > 10
    );

  const isJobTitleFieldValid = () =>
      !!(
        formik.touched["jobTitle"] &&
        formik.errors["jobTitle"] &&
        selectJobTitles?.length > 5
  );

      
  const getSkillTagsErrorMessage = () => {
    return (
      isSkillTagsFieldValid() && (
        <p className="error-msg">{formik.errors["skillTags"]}</p>
      )
    );
  };

  const getJobTitleErrorMessage = () => {
    return (
      isJobTitleFieldValid() && (
        <p className="error-msg">{formik.errors["jobTitle"]}</p>
      )
    );
  };

  const isPasswordFieldValid = (passwordErrors) =>
    !!(formik.touched["password"] && passwordErrors?.length > 0);
  const getPasswordErrorMessage = (passwordErrors) => {
    if (isPasswordFieldValid(passwordErrors)) {
      return (
        <ul className="error-msg">
          {passwordErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const countryHandler = async (item) => {
    const {id, name, currency, masked_phone_number, placeholder, states_count} = item;

    await formik.setFieldValue("country", name);
    await formik.setFieldValue("countryId", id);

    await formik.setFieldValue("state", "");
    await formik.setFieldValue("stateId", null);

    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);

    onCurrencyClick(currency?.id, currency?.title);
    setMaskedPhoneNumber(masked_phone_number);
    setMaskedPlaceHolder(placeholder);
    setStateOptions([]);
    setCitiesOptions([]);

    if(states_count === 0){
      getCitiesData(id, null);
    }
    else {
      getStatusData(id);
    }
  };

  const stateHandler = async (id, name) => {
    await formik.setFieldValue("state", name);
    await formik.setFieldValue("stateId", id);
    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);
    getCitiesData(null, id);
  };

  const cityHandler = async (id, name) => {
    await formik.setFieldValue("city", name);
    await formik.setFieldValue("cityId", id);
  };

  const handleImageChange = async (event) => {
    resumeModelHandler(true);
  };

  const checkJobTitleError =async () => {
    if(selectJobTitles?.length >= 5){
      formik?.setFieldError("jobTitle", "Please select a maximum of 5 job titles");
      getJobTitleErrorMessage()
    }
    else {
      const newErrors = { ...formik.errors };
      delete newErrors?.jobTitle;
      formik.setErrors(newErrors);
      getJobTitleErrorMessage()
    }
  }

  const onJobTitileChange = async (value) => {
    await formik.setFieldValue("jobTitle", value);
    getSearchSuggestion(value, "jobTitle");
  };

  const onJobTitileClick = async (curentJobTitles) => {
    const isIdExists = selectJobTitles?.some(
      (item) => item.id === curentJobTitles?.id
    );
    if (isIdExists) return;
    setSelectJobTitles([...selectJobTitles, curentJobTitles]);
    setAllJobTitlesHide(true);
    await formik.setFieldValue("jobTitle", "");
    await checkJobTitleError();
  };

  const onJobTitileCancel = async (curentJobTitles) => {
    const updatedItems = selectJobTitles.filter(
      (item) => item.id !== curentJobTitles?.id
    );
    setSelectJobTitles(updatedItems);
    await checkJobTitleError();
  };

  const onSkillTagsChange = async (value) => {
    await formik.setFieldValue("skillTags", value);
    getSearchSuggestion(value, "jobTag");
  };

  const checkSkillTagsError =async () => {
    if(selectSkillTags?.length <= 5){
      formik?.setFieldError("skillTags", "Please select a minimum of 5 skill tags");
      getSkillTagsErrorMessage()
    }
    else if(selectSkillTags?.length >= 10){
      formik?.setFieldError("skillTags", "A maximum of 10 technical tags can be selected");
      getSkillTagsErrorMessage()
    }
    else {
      const newErrors = { ...formik.errors };
      delete newErrors?.skillTags;
      formik.setErrors(newErrors);
      getSkillTagsErrorMessage()
    }
  }

  const onSkillTagsClick = async (curentSkillTags) => {
    const isIdExists = selectSkillTags?.some(
      (item) => item.id === curentSkillTags?.id
    );
    if (isIdExists) return;
    setSelectSkillTags([...selectSkillTags, curentSkillTags]);
    setAllSkillTagsHide(true);
    await formik.setFieldValue("skillTags", "");
    await checkSkillTagsError();
  };

  const onWorkPreferenceTagsClick = async (curentSkillTags) => {
    const isIdExists = selectWorkPreferenceTags?.some(
      (item) => item.id === curentSkillTags?.id
    );
    if (isIdExists) return;
    workPreferenceArray.push([...selectWorkPreferenceTags, curentSkillTags])
    console.log(workPreferenceArray)
    setSelectWorkPreferenceTags([...selectWorkPreferenceTags, curentSkillTags]);
    setAllWorkPreferenceTagsHide(true);
    await formik.setFieldValue("workPreference", "");
  };

  const onSkillTagsCancel = async (curentSkillTags) => {
    const updatedItems = selectSkillTags.filter(
      (item) => item.id !== curentSkillTags?.id
    );
    setSelectSkillTags(updatedItems);
    await checkSkillTagsError();
  };

  const onWorkPreferenceTagsCancel = async (curentSkillTags) => {
    const updatedItems = selectWorkPreferenceTags.filter(
      (item) => item.id !== curentSkillTags?.id
    );
    setSelectWorkPreferenceTags(updatedItems);
  };

  const getStatusData = async (id) => {
    dispatch(
      getStateAsync({
        id: id
      })
    )
      .then((response) => {
        const res = response?.payload;
        setStateOptions(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCitiesData = async (id1, id2) => {
    dispatch(
      getCitiesAsync({
        country_id: id1,
        state_id: id2
      })
    )
      .then((response) => {
        const res = response?.payload;
        setCitiesOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearchSuggestion = async (searchValue, searchType) => {
    try {
      dispatch(
        getSearchSuggestionAsync({
          searchValue: searchValue,
          searchType: searchType
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (searchType === "jobTitle") {
            if (res?.job_titles?.length > 0) {
              setAllTobTitles(res?.job_titles);
              setAllJobTitlesHide(false);
            } else {
              setAllTobTitles([]);
              setAllJobTitlesHide(false);
            }
          }

          if (searchType === "jobTag") {
            if (res?.job_tags?.length > 0) {
              setAllSkillTags(res?.job_tags);
              setAllSkillTagsHide(false);
            } else {
              setAllSkillTags([]);
              setAllSkillTagsHide(false);
            }
          }
            
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getWorkPreferences = async () => {
    const workPreferenceList = [{ id: 1, name: "Remote" }, { id: 2, name: "Onsite" }, { id: 3, name: "Hybrid" }, { id: 4, name: "All" }]

    setAllWorkPreference(workPreferenceList)
  }

  const resendVerifyEmailHandler = async () => {
    if (formik?.values?.email === "" || isFormFieldValid("email")) {
      formik.touched["email"] = true;
      isFormFieldValid("email");
      formik?.setFieldError("email", formik?.errors?.email);
      return;
    }
    else {
      dispatch(
        resendVerifyEmailAsync({
          email: formik?.values?.email,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          } else {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getWorkPreferences();
    getJobDropDownData();
    async function getCountries() {
      try {
        dispatch(
          getCountriesAsync({ country_id: null })
        )
          .then((response) => {
            const res = response?.payload;
              setCountryOptions(res);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }

    getCountries();

  }, []);


  useEffect(() => {
    if (candidateResume !== null) {
      formik.setFieldValue("files", candidateResume);
      setSelectedImageName(candidateResume?.name);
    }
  }, [candidateResume]);

  console.log("error", formik.errors);

  return (
    <div className="candidate-signup-form-feild">
      <form onSubmit={formik.handleSubmit}>
        <div className="candidate-username-signup-form f-feild ">
          <div
            className={`first-name ${isFormFieldValid("firstName") && "error-input"
              }`}
          >
            <label for="fname">First name</label>
            <br />
            <input
              className="form-font-f"
              type="text"
              placeholder="First name"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("firstName")}
          </div>

          <div
            className={`last-name ${isFormFieldValid("lastName") && "error-input"
              }`}
          >
            <label for="lname">Last name</label>
            <br />
            <input
              className="form-font-f"
              type="text"
              placeholder="Last name"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("lastName")}
          </div>
        </div>

        <div
          className={`signup-email f-feild ${isFormFieldValid("email") && "error-input"
            }`}
        >
          <label for="femail">E-mail</label>
          <br />
          <input
            className="form-font-f"
            type="email"
            name="email"
            id=""
            placeholder="Enter valid email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {getFormErrorMessage("email")}
        </div>

        <div
          className={`signup-passowrd f-feild ${isPasswordFieldValid(passwordErrors) && "error-input"
            }`}
        >
          <label for="fpassword">Password</label>
          <br />
          <div class="password-icon bg-pass-f">
            <input
              className="form-font-f"
              id="password-field"
              type={passwordShown ? "text" : "password"}
              name="password"
              placeholder="Enter valid password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="icon-hide">
              {isPasswordFieldValid(passwordErrors) ? (
                <img src="/image/warning.png" alt="" />
              ) : (
                <>
                  <img
                    src="/image/hide-password.png"
                    alt=""
                    className={passwordShown && "password-icon-togle"}
                    onClick={() => setPasswordShown(true)}
                  />
                  <img
                    src="/image/password.png"
                    alt=""
                    className={!passwordShown && "password-icon-togle"}
                    onClick={() => setPasswordShown(false)}
                  />
                </>
              )}
            </div>
          </div>
          {getPasswordErrorMessage(passwordErrors)}
        </div>

        <div
          className={`signup-passowrd f-feild ${isFormFieldValid("confirmPassword") && "error-input"
            }`}
        >
          <label for="fpassword">Confirm password</label>
          <br />
          <div class="password-icon bg-pass-f">
            <input
              className="form-font-f"
              id="password-field"
              type={confirmPasswordShown ? "text" : "password"}
              name="confirmPassword"
              placeholder="Re-type password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="icon-hide">
              {isFormFieldValid("confirmPassword") ? (
                <img src="/image/warning.png" alt="" />
              ) : (
                <>
                  <img
                    src="/image/hide-password.png"
                    alt=""
                    className={confirmPasswordShown && "password-icon-togle"}
                    onClick={() => setConfirmPasswordShown(true)}
                  />
                  <img
                    src="/image/password.png"
                    alt=""
                    className={!confirmPasswordShown && "password-icon-togle"}
                    onClick={() => setConfirmPasswordShown(false)}
                  />
                </>
              )}
            </div>
          </div>
          {getFormErrorMessage("confirmPassword")}
        </div>
        <div
          className={`signup-country f-feild ${isFormFieldValid("country") && "error-input"
            }`}
        >
          <label for="fcountry">Country</label>
          <br />
          <div
            className={`industry-feild-frm-country bg-pass-f ${isFormFieldValid("country") && "error-input"
              }`}
          >
            <div
              name="country"
              id="dropdown-boxId-2"
              className="candidate-form-dropdownBox-f"
              data-toggle="dropdown"
              aria-haspopup="true"
            >
            <span 
              className={`${formik?.values?.country === "" && "dropdown-opty"
              }`}>
                {formik?.values?.country === ""
                ? "Choose country"
                : formik?.values?.country}
            </span>
              <div className="icon-hide">
                {isFormFieldValid("country") ? (
                  <img src="/image/warning.png" alt="" />
                ) : (
                  <img src="/image/form-d-f.png" alt="" />
                )}
              </div>
            </div>
            {countryOptions?.length > 0 && (
              <div className="list-search-dropdown-company">
                <ul className="list-job-title-country">
                  {countryOptions.map((j, i) => (
                    <li
                      className="icon-cursor-f"
                      onClick={() => countryHandler(j)}
                    >
                      {/* <img src="/image/US.png" alt="" /> */}
                      {j?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}           
          </div>
          {getFormErrorMessage("country")}
        </div>
        <div className="candidate-username-signup-form f-feild">
        <div
          className={`signup-country first-name ${isFormFieldValid("state") && "error-input" 
          ||
          stateOptions?.length === 0 && "disable-field"
            }`}
        >
          <label for="fcountry">Province/State</label>
          <br />
          <div
            className={`industry-feild-frm-country bg-pass-f ${isFormFieldValid("state") && "error-input" 
              }`}
          >
            <div
              name="state"
              id="dropdown-boxId-2"
              className="candidate-form-dropdownBox-f"
              data-toggle="dropdown"
              aria-haspopup="true"
            >
              {formik?.values?.state === ""
                ? "Province/State"
                : formik?.values?.state}
              <div className="icon-hide-2">
                {isFormFieldValid("state") ? (
                  <img src="/image/warning.png" alt="" />
                ) : (
                  <img src="/image/form-d-f.png" alt="" />
                )}
              </div>
            </div>
            {stateOptions?.length > 0 && (
              <div className="list-search-dropdown-company">
                <ul className="list-job-title-country">
                  {stateOptions?.map((j, i) => (
                    <li
                      className="icon-cursor-f"
                      onClick={() => stateHandler(j?.id, j?.name)}
                    >
                      {j?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {getFormErrorMessage("state")}
        </div>
        <div
          className={`signup-country first-name ${isFormFieldValid("city") && "error-input"      
          ||            
          citiesOptions?.length === 0 && "disable-field"
            }`}
        >
          <label for="fcountry">City</label>
          <br />
          <div
            className={`industry-feild-frm-country bg-pass-f ${isFormFieldValid("city") && "error-input" 
       
              }`}
          >
            <div
              name="city"
              id="dropdown-boxId-2"
              className="candidate-form-dropdownBox-f"
              data-toggle="dropdown"
              aria-haspopup="true"
            >
              {formik?.values?.city === ""
                ? "City"
                : formik?.values?.city}
              <div className="icon-hide-2">
                {isFormFieldValid("city") ? (
                  <img src="/image/warning.png" alt="" />
                ) : (
                  <img src="/image/form-d-f.png" alt="" />
                )}
              </div>
            </div>
            {citiesOptions?.length > 0 && (
              <div className="list-search-dropdown-company">
                <ul className="list-job-title-country">
                  {citiesOptions?.map((j, i) => (
                    <li
                      className="icon-cursor-f"
                      onClick={() => cityHandler(j?.id, j?.name)}
                    >
                      {j?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {getFormErrorMessage("city")}
        </div>
        </div>
    
        <div
          className={`signup-phone f-feild ${isFormFieldValid("phoneNo") && "error-input"
            }`}
        >
          <label for="fphone">Phone</label>
          <br />
          <div
            className={`merge-feild  ${isFormFieldValid("phoneNo") && "error-input"
              }`}
          >
            <PhoneNumber
              id=""
              className="form-font-f"
              type="tel"
              name="phoneNo"
              value={formik.values.phoneNo}
              maskedPlaceHolder={maskedPlaceHolder}
              maskedPhoneNumber={maskedPhoneNumber} 
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}              
            />
          </div>
          {getFormErrorMessage("phoneNo")}
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            setAllJobTitlesHide(true);
          }}
        >
          <div 
              className={`signup-job-titles f-feild tags-tittles ${isJobTitleFieldValid() && "error-input"
              }`}          
          >
            <label for="fjobtitles">Job titles I’m interested in</label>
            <input
              className="form-font-f"
              type="text"
              name="jobTitle"
              value={formik.values.jobTitle}
              onChange={(e) => onJobTitileChange(e.currentTarget.value)}
              placeholder="Job titles I’m interested in"
            />
            {getJobTitleErrorMessage()}
            {allJobTitles?.length > 0 && (
              <div
                className={`job-tags-titles-dropdown ${allJobTitlesHide && "job-dropdown-hide"
                  }`}
              >
                <ul className="dorpdown-list-location">
                  {allJobTitles.map((j, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        onJobTitileClick(j);
                      }}
                    >
                      {formik?.values?.jobTitle?.length > 0 ? (
                        <>
                          {highlightMatchingText(
                            j?.name,
                            formik?.values?.jobTitle
                          )}
                        </>
                      ) : (
                        j?.name
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {selectJobTitles?.length > 0 && (
              <ul class="tags-titles-list">
                {selectJobTitles.map((j, i) => (
                  <li key={i}>
                    {j?.name}
                    <span onClick={() => onJobTitileCancel(j)}>
                      <img src="../image/cross.png" alt="" />
                    </span>
                  </li>
                ))}
              </ul>
            )}
            {/* <p className="error-msg hide-error">Enter job titles </p> */}
          </div>
        </OutsideClickHandler>
        <OutsideClickHandler
          onOutsideClick={() => {
            setAllSkillTagsHide(true);
            setAllWorkPreferenceTagsHide(true);
          }}
        >
          <div
            className={`signup-skill-tags f-feild tags-tittles ${isSkillTagsFieldValid() && "error-input"
              }`}
          >
            <label for="fjobtitles">My top tech skills tags</label>
            <input
              className="form-font-f"
              type="text"
              name="skillTags"
              value={formik.values.skillTags}
              onChange={(e) => {
                onSkillTagsChange(e.currentTarget.value);
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              placeholder="Tech tags"
            />
            {getSkillTagsErrorMessage()}
            {allSkillTags?.length > 0 && (
              <div
                className={`job-tags-titles-dropdown ${allSkillTagsHide && "job-dropdown-hide"
                  }`}
              >
                <ul className="dorpdown-list-location">
                  {allSkillTags.map((j, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        onSkillTagsClick(j);
                      }}
                    >
                      {" "}
                      {j?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {selectSkillTags?.length > 0 && (
              <ul class="tags-titles-list">
                {selectSkillTags.map((j, i) => (
                  <li key={i}>
                    {j?.name}
                    <span onClick={() => onSkillTagsCancel(j)}>
                      <img
                        src="../image/cross.png"
                        alt=""
                        onClick={() => {
                          isSkillTagsFieldValid();
                        }}
                      />
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="need-to-key">
            <div className="signup-salary f-feild cand-form">
              <label for="fsalary">Minimum salary expectation</label>
              <br />
              <div className="salary-range-start-ends position-relative">
                <div className="row">
                  <div className="col-md-9 col-8">
                    <div className={`merge-feild-1 max-w-100 ${isFormFieldValid("salaryStart") && "error-input"}`}>
                      <div className={`salary-f-job ${isFormFieldValid("currency") && "error-input"}`}>
                        <div className="salary-start">
                          <div
                            id="fsalary-country"
                            className="company-form-dropdownBox-f"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {formik?.values?.currency !== ""
                              ? formik?.values?.currency
                              : ""}
                          </div>

                          <div className="list-search-dropdown-country-job">
                            {currencyOption?.length > 0 && (
                              <ul className="list-job-title-country">
                                {currencyOption?.map((prod) => (
                                  <li
                                    onClick={() =>
                                      onCurrencyClick(
                                        prod?.id,
                                        prod?.title
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        prod?.flag_link
                                          ? prod?.flag_link
                                          : "/image/CA.png"
                                      }
                                      alt=""
                                    />{" "}
                                    {`${prod?.title}`}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                      <input
                        className="form-font-f"
                        type="text"
                        name="salaryStart"
                        id="salary-start-range"
                        value={formik?.values?.salaryStart}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-4">
                    <div className="merge-feild-4 max-w-100">
                      <div
                        className={`${isFormFieldValid("salaryExpectation") && "error-input"
                          }`}
                      >
                        <div className="Year">
                          <div
                            id="fsalary-year"
                            className="company-form-dropdownBox-f"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {formik?.values?.salaryExpectation !== ""
                              ? capitalizeFirstLetter(
                                formik?.values?.salaryExpectation
                              )
                              : "Year"}
                          </div>

                          {salaryExpectationOption?.length > 0 && (
                            <div className="candidate-search-dropdown-year-job">
                              <ul className="candidate-job-title-year-job">
                                {salaryExpectationOption.map((prod) => (
                                  <li
                                    onClick={() =>
                                      onSalaryExpectationClick(prod?.value)
                                    }
                                  >
                                    {" "}
                                    {`${capitalizeFirstLetter(
                                      prod?.value
                                    )}`}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {getFormErrorMessage("salaryStart")
                ? getFormErrorMessage("salaryStart")
                : getFormErrorMessage("salaryEnd")}
            </div>

            <div className={`signup-skill-tags f-feild tags-tittles ${isSkillTagsFieldValid() && "error-input"}`}>
              <label for="fjobtitles">Work preference</label>
              <div className="input-withicon" onClick={() => setAllWorkPreferenceTagsHide(false)}>
                <input
                  className="form-font-f"
                  type="text"
                  name="skillTags"
                  value={formik.values.workPreference}
                  //onFocus={() => setAllWorkPreferenceTagsHide(false)}
                  placeholder="Choose work preference"
                  readOnly
                />
                <div className="icon">
                  <img src="/image/form-d-f.png" alt="" />
                </div>
              </div>

              {!allWorkPreferenceTagsHide ? (
                <div
                  className={`job-tags-titles-dropdown`}
                >
                  <ul className="dorpdown-list-location">
                    {allWorkPreference.map((j, i) => (
                      <li
                        key={i}
                        onClick={() => {
                          onWorkPreferenceTagsClick(j);
                        }}
                      >
                        {" "}
                        {j?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : <></>}
              {selectWorkPreferenceTags?.length > 0 && (
                <ul class="tags-titles-list">
                  {selectWorkPreferenceTags.map((j, i) => (
                    <li key={i}>
                      {j?.name}
                      <span onClick={() => onWorkPreferenceTagsCancel(j)}>
                        <img
                          src="../image/cross.png"
                          alt=""
                          onClick={() => {

                          }}
                        />
                      </span>
                    </li>
                  ))}
                </ul>
              )}

              {/* <p className="error-msg hide-error ">Enter skill tags</p> */}
            </div>
          </div>


        </OutsideClickHandler>
        <div
          className={`signup-resume f-feild ${isFormFieldValid("files") && "error-input"
            }`}
        >

          <OverlayTrigger
            placement='top'
            overlay={
              // delay={{ show: 250, hide: 4000000 }}
              <Tooltip id='tooltip-top' className="large-tooltip">
                Upload a PDF resume which is without images, multiple columns, and other patterns. It should be in a simple document format.
              </Tooltip>
            }
          >
            <label for="fresume">Upload resume</label>
          </OverlayTrigger>

          <div
            className="position-relative upload-icon icon-cursor"
            onClick={handleImageChange}
          >
            <div className="resume-choose-label">
              {" "}
              {selectedImageName !== "" ? selectedImageName : "Choose file"}
            </div>
            <input
              type="text"
              name="files"
              className="cursor-pointer bg-pass-f"
              placeholder="Choose file"
            />
          </div>
          {getFormErrorMessage("files")}
        </div>

        <div className={`signup-linkedIn-link f-feild`}>
          <label for="flink">LinkedIn link</label>

          <input
            className="form-font-f"
            type="text"
            name="linkedinURL"
            id=""
            placeholder="Enter your LinkedIn profile here"
            value={formik.values.linkedinURL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="signup-check f-feild">
          {isFormFieldValid("termServices") && (
            <div className="attention-box">
              <div className="attendtion-heading">
                <div className="icon-atten">
                  <img src="/image/attention.png" alt="" />
                </div>
                <div className="attention-text">
                  <p className="text-atten-box">
                    {formik?.errors?.termServices}
                  </p>
                </div>
              </div>
            </div>
          )}
          <input
            type="checkbox"
            name="termServices"
            checked={formik?.values?.termServices}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />{" "}
          &nbsp;Yes, I understand and agree to the terms of service
        </div>
        <div className="candidate-from-signup-create">
          <button type="submit" className="form-submit">
            {loader ? (
              <>
                {/* {"Your account is being created "} */}
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              "Get started"
            )}
          </button>
          <div className="signup-resendmail">
            <p>
              Haven't received the confirmation email yet?{" "}
              <Link href="#" onClick={() => resendVerifyEmailHandler()}>
                <span>Resend Email</span>
              </Link>
            </p>
          </div>
          <br />
          <Message errorMsg={successMsg} />
        </div>
      </form>
    </div>
  );
};

export default CandidateForm;
