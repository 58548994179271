import { Fragment } from "react";
import { useSelector } from "react-redux";
import getCurrentFormattedDate from "../../../../utils/getCurrentFormattedDate";
import { USER_TYPE } from "../../../../constants";

const AllPromotionTable = ({
  index,
  item,
  selectedListingId,
  selectedListingIdHandler,
}) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <tr>
      {user?.user_type === USER_TYPE.SUPER_ADMIN && (
        <td className="">
          {" "}
          <input
            className="icon-cursor"
            type="checkbox"
            name=""
            id="company-promotion"
            checked={selectedListingId.includes(item?.id) ? true : false}
            onClick={() => selectedListingIdHandler(item?.id)}
          />
          {item?.company?.name}
        </td>
      )}

      <td>{`Position ${item?.position_number}`}</td>
      {/* <td className=""> {item?.type?.name}</td> */}
      <td
        className={` ${
          item?.status?.id === 5 ||
          (item?.status?.id === 4 && "clr-chnage-text")
        }`}
      >
        {item?.status?.name}
      </td>
      <td>{item?.time_frame?.name}</td>
      <td>{getCurrentFormattedDate(item?.start_date)}</td>
      <td>{getCurrentFormattedDate(item?.end_date)}</td>
      <td>
      <td>
        {" "}
        {item?.promotable_type.includes("Company") ? (
          <div className="dash-td-por" />
        ) : (
          item?.promotable?.name
        )}
      </td>
      </td>
      <td>{item?.country?.currency?.title}{" "}{item?.total_amount}</td>
      {/* <td>
        {item?.status_changed_at !== null &&
        (item?.status?.id === 2 || item?.status?.id === 3) ? (
          getCurrentFormattedDate(item?.status_changed_at)
        ) : (
          <div className="dash-td-por"></div>
        )}
      </td> */}
      <td>{item?.created_by?.first_name}</td>
      {user?.user_type === USER_TYPE.SUPER_ADMIN ? (
        <td>
          <img src="/image/action-promotion.png" alt="" className="icon" />
        </td>
      ) : (
        <td>{getCurrentFormattedDate(item?.created_at)}</td>
      )}
    </tr>
  );
};

export default AllPromotionTable;
