import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setSelectJobId } from "../../../redux/slices/JobDetailSlice";
import {
  setWithdrawJobId,
  withdrawJobAsync,
} from "../../../redux/slices/candidateDashboardSlice";
import getCurrentFormattedDate from "../../../utils/getCurrentFormattedDate";
import "./JobRecord.css";

const JobAppliedRight = ({ selectedJobId, date }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const onDetailJobHandler = () => {
    navigate(`/job-view-detail/${selectedJobId}`);
  };

  const onWithdrawJobHandler = () => {
    if (user !== null) {
      dispatch(withdrawJobAsync({ userId: user?.id, jobId: selectedJobId }))
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            dispatch(setWithdrawJobId(selectedJobId));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="job-announcements">
      <div className="job-announcements-col-candidate">
        <div className="job-time-wishlist job-app p-0">
          <a className="btn btn-outline-black w-100" onClick={() => onDetailJobHandler()}>
            <img src="/image/view-candidate.png" alt="apply" className="mr-1" />
            View detail
          </a>

          <a className="btn btn-black" onClick={() => { navigate(`/job-quesation/${selectedJobId}`) }}>
            <img src="/image/canidate-q.png" alt="apply" className="mr-1" />
            Sample questions
          </a>
          <div className="job-date-candidate">
            <p>Date Applied: {getCurrentFormattedDate(date)}</p>
          </div>
          {/* <div className="job-opstion-status">
            <img src="/image/job-postion.png" alt="apply" />
            <p>Position Open</p>
          </div>
          <div className="job-applied-text-candidate">
            <img src="/image/job-apply-can.png" alt="favorite" />
            <p>Job Applied</p>
          </div> 
          <div
            className="job-application-status"
            onClick={() => onWithdrawJobHandler()}
          >
            <p>Withdraw Job Application</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default JobAppliedRight;
