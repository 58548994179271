import React from 'react'
import Button from 'react-bootstrap/Button';

const GoogleAuth = ({ isLogin = false }) => {
  const authGoogleHandler = () => {
    if (isLogin) {

    } else {
      fetch('https://demo.workbridgetalent.ca/backend-files/public/api/v1/auth', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {

          const url = data.url;
          console.log(url)

          window.open(url, '_self');
        })
        .catch((error) => console.error(error));
    }
  }
  return (
    <>
      <Button variant="outline-google-signup w-100" onClick={authGoogleHandler}>
        <img src='/image/google.png' alt='google' />
        Continue with Google
      </Button>
    </>
  )
}

export default GoogleAuth