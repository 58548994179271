export const jobTitle = ["React Developer", "Angular Developer"];
export const activeJob = ["Yes", "No"];
export const location = ["Cities"];
export const datePosted = [
  "Today",
  "Last 7 Days",
  "Last 14 Days",
  "This Month",
];

export const datePostedValue = [
  {
    id:1,
    name:"Today"
  },
  {
    id:2,
    name:"Last 7 Days"
  },
  {
    id:3,
    name:"Last 14 Days"
  },
  {
    id:4,
    name:"This Month"
  }
  
];

export const jobTags = [
  {
    id: 1,
    query: "Software engineer",
    count: 9,
    type: "search",
    type_string: "Search",
  },
  {
    id: 2,
    query: "Work From Home",
    count: 1,
    type: "search",
    type_string: "Search",
  },
  {
    id: 3,
    query: "Artificial Intelligence",
    count: 1,
    type: "search",
    type_string: "Search",
  },
  {
    id: 4,
    query: "Hybrid",
    count: 1,
    type: "search",
    type_string: "Search",
  },
  {
    id: 5,
    query: "Mobile App Developer",
    count: 1,
    type: "search",
    type_string: "Search",
  },
  {
    id: 6,
    query: "UI/UX Designer",
    count: 1,
    type: "search",
    type_string: "Search",
  },
  {
    id: 7,
    query: "Manager Jobs",
    count: 1,
    type: "search",
    type_string: "Search",
  },
  {
    id: 8,
    query: "Health jobs",
    count: 1,
    type: "search",
    type_string: "Search",
  },
  {
    id: 9,
    query: "Freshers",
    count: 1,
    type: "search",
    type_string: "Search",
  },
  {
    id: 10,
    query: "Data Entry",
    count: 1,
    type: "search",
    type_string: "Search",
  },
];

export const JobDescriptionData = [
  {
    name: "Senior Full Stack Developer(Java & Angular)",
    description:
      "As a React.js developer, you will implement new features and maintain existing code using React and other standard tools in the React ecosystem, such as Node.js and Yarn. Your duties will include designing software solutions to meet project requirements, maintaining and refactoring existing code, writing tests, and fixing bugs.",
    responsibilities:
      "Design and develop new features and upgrades supporting a range of Identity and Access Management applications for deployment.Translate software requirements into programming code through to usable applications.Design and select appropriate solutions. Write and implement efficient code, identifying any risks and issues escalating when necessary to leadership on a timely basis.Evaluate impact and support the testing, productization, and release processes.Investigate defects, logging details, and following defects through their resolution lifecycle.Track development tasks in the application lifecycle management tool and provide relevant reporting when needed.Perform code reviews and walkthroughs of system design and developed code.",

    skills:
      "Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model. Thorough understanding of React.js and its core principles. Experience with popular React.js workflows (such as Flux or Redux). Familiarity with newer specifications of EcmaScript.Experience with data structure libraries (e.g., Immutable.js). Knowledge of isomorphic React is a plus. Familiarity with RESTful APIs.Knowledge of modern authorization mechanisms, such as JSON Web Token. Familiarity with modern front-end build pipelines and tools. Experience with common front-end development tools such as Babel, Webpack, NPM, etc. Ability to understand business requirements and translate them into technical requirements. A knack for benchmarking and optimization. Familiarity with code versioning tools (such as Git, SVN, and Mercurial).",

    must_skills:
      "Experience with Identity and Access Management technologies5-8 years of experience with Java, knowledge of Angular (front end)Strong Programming and Software Development SkillsProficiency in Angular",

    nice_to_have:
      "Experience with Oracle. Understanding cloud platforms such as Amazon Web Services (AWS), Microsoft Azure, or Google Cloud Platform. Experience with Microservices Architecture. Expertise in Angular, familiarity with other front-end frameworks.",

    working_hrs:
      "Mon - Friday 9 AM - 7 PM EST. Should be flexible to work on Saturday twice a month.",
    interview_process:
      "1st TEAM screening with Poly2nd TEAM panel interview with ClientSend TestJob Offer",

    benefits:
      "Competitive salary and comprehensive benefits package. Opportunities for career growth and advancement. Collaborative and dynamic work environment. Work from anywhere around the world. Professional development and training opportunities.",

    recruiter_notes:
      "Great role if you’re looking for something stable, and remote. Projects are interesting, team is positive and supportive, a manager who cares about you and your success.Please get in touch with me as soon as possible as we’re looking to hire immediately",
    reportingPerson: [
      {
        id: 1,
        name: "Team Lead",
      },
    ],
    workMethodology: [
      {
        id: 1,
        name: "Waterfall & Agile",
      },
    ],
    companySize: [
      {
        id: 1,
        title: "1,001 - 5,000",
      },
    ],
    industry: [
      {
        id: 1,
        name: "Government",
      },
    ],
    currency: [],
    image: null,
    salary_from: "40,000.00",
    salary_to: "80,000.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Amazon Web Service",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "C Sharp",
      },
      {
        id: 3,
        name: "React Js",
      },
      {
        id: 4,
        name: "C++",
      },
      {
        id: 5,
        name: "Java Developer",
      },
      {
        id: 6,
        name: "C++",
      },
      {
        id: 7,
        name: "My SQL",
      },
      {
        id: 8,
        name: "JSON",
      },
      {
        id: 9,
        name: "AngularJS",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
      },
      {
        id: 2,
        name: "Prilep",
      },
      {
        id: 3,
        name: "Bitola",
      },
    ],
  },
];

export const JobDetailData = [
  {
    name: "React Java Developer",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Amazon Web Service",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "London",
        status: "published",
      },
      {
        id: 2,
        name: "Copenhagen",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Customer Support Analyst",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "1000.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "International Consolidated Airlines Group SA",
      logo: "/image/logo-2 4.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - On Site",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Senior Angular Developer",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Groupe Bruxelles Lambert SA",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Part Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Talent Acquisition Specialist",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Bank of Montreal",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "React Java Developer",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Amazon Web Service",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "London",
        status: "published",
      },
      {
        id: 2,
        name: "Copenhagen",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Customer Support Analyst",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "1000.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "International Consolidated Airlines Group SA",
      logo: "/image/logo-2 4.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - On Site",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Senior Angular Developer",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Groupe Bruxelles Lambert SA",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Part Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Talent Acquisition Specialist",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Bank of Montreal",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "React Java Developer",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Amazon Web Service",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "London",
        status: "published",
      },
      {
        id: 2,
        name: "Copenhagen",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Customer Support Analyst",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "1000.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "International Consolidated Airlines Group SA",
      logo: "/image/logo-2 4.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - On Site",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
];

export const TopMatchLeftData = [
  {
    name: "React Java Developer",
    is_promoted: 1,
    company: {
      id: 1,
      name: "Amazon Web Service",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
  },
  {
    name: "React Java Developer",
    is_promoted: 1,
    company: {
      id: 1,
      name: "Amazon Web Service",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
  },
  {
    name: "React Java Developer",
    is_promoted: 1,
    company: {
      id: 1,
      name: "Group Bruxelles Lambert SA",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
  },
  {
    name: "React Java Developer",
    is_promoted: 1,
    company: {
      id: 1,
      name: "International Consolidated Airlines Group SA ",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
  },
  {
    name: "React Java Developer",
    is_promoted: 1,
    company: {
      id: 1,
      name: "Amazon Web Service",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
  },
  {
    name: "React Java Developer",
    is_promoted: 0,
    company: {
      id: 1,
      name: "Amazon Web Service",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
  },
  {
    name: "React Java Developer",
    is_promoted: 0,
    company: {
      id: 1,
      name: "Amazon Web Service",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
  },
  {
    name: "React Java Developer",
    is_promoted: 0,
    company: {
      id: 1,
      name: "Group Bruxelles Lambert SA",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
  },
  {
    name: "React Java Developer",
    is_promoted: 0,
    company: {
      id: 1,
      name: "International Consolidated Airlines Group SA ",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
  },
  {
    name: "React Java Developer",
    is_promoted: 0,
    company: {
      id: 1,
      name: "Amazon Web Service",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
  },
];

export const TopMatchRightData = [
  {
    name: "React Java Developer",
    is_promoted: 1,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Amazon Web Service",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "London",
        status: "published",
      },
      {
        id: 2,
        name: "Copenhagen",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Customer Support Analyst",
    is_promoted: 1,
    salary_from: "500.00",
    salary_to: "1000.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "International Consolidated Airlines Group SA",
      logo: "/image/logo-2 4.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - On Site",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Senior Angular Developer",
    is_promoted: 1,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Groupe Bruxelles Lambert SA",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Part Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Talent Acquisition Specialist",
    is_promoted: 1,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Bank of Montreal",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "React Java Developer",
    is_promoted: 1,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Amazon Web Service",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "London",
        status: "published",
      },
      {
        id: 2,
        name: "Copenhagen",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Customer Support Analyst",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "1000.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "International Consolidated Airlines Group SA",
      logo: "/image/logo-2 4.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - On Site",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Senior Angular Developer",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Groupe Bruxelles Lambert SA",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Part Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Talent Acquisition Specialist",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Bank of Montreal",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "React Java Developer",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "500.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "Amazon Web Service",
      logo: "/image/fitness.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - Remote",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "London",
        status: "published",
      },
      {
        id: 2,
        name: "Copenhagen",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Customer Support Analyst",
    is_promoted: 0,
    salary_from: "500.00",
    salary_to: "1000.00",
    date: "2023-06-13T20:15:49.000000Z",
    company: {
      id: 1,
      name: "International Consolidated Airlines Group SA",
      logo: "/image/logo-2 4.png",
      website: "https://squareup.com/",
    },
    tags: [
      {
        id: 1,
        name: "C++",
      },
      {
        id: 2,
        name: "Java",
      },
      {
        id: 3,
        name: "Python",
      },
      {
        id: 4,
        name: "Micro Services",
      },
      {
        id: 5,
        name: "Angular",
      },
    ],
    jobType: [
      {
        id: 1,
        name: "Full Time - On Site",
        status: "published",
      },
    ],
    jobCities: [
      {
        id: 1,
        name: "Skopje",
        status: "published",
      },
    ],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
];
