import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import getCurrentFormattedDate from "../../../../utils/getCurrentFormattedDate";
import TablePagination from "../../../../components/Common/Admin/TablePagination/TablePagination";
import ShowRows from "../../../../components/Common/Admin/ShowRows/ShowRows";
import TableError from "../../../../components/Common/Admin/TabelError/TableError";
import {
  setActiveSideBar,
  setFormMode,
} from "../../../../redux/slices/AdminDashboardSlice";
import {
  getBulkResumeListingDataAsync,
  setBulkResumeProcessId,
} from "../../../../redux/slices/bulkResumeCanDetailSlice";
import {
  getProgressBarColorClass,
  getProgressBarWidth,
} from "../../../../utils/progressbarFn";
import "./index.css";

const BulkResumeAnalysis = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const [msg, setMsg] = useState("");
  const [bulkResumeData, setBulkResumeData] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState({
    label: "",
    value: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const navHandler = () => {
    dispatch(setFormMode("add"));

    dispatch(
      setActiveSideBar({
        id: "4",
        name: "Bulk resume analysis",
        value: "Bulk analysis",
      })
    );

    navigate("/bulk-resume-upload");
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onStatusClick = (label, value) => {
    setSelectedStatus({
      label: label,
      value: value,
    });
  };

  const onStatusClean = () => {
    setSelectedStatus({
      label: "",
      value: null,
    });
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onChangePerPageHandler = (value) => {
    setPerPage(value);
  };

  /*****************************/

  const getBulkResumeListingData = async () => {
    try {
      setMsg("");
      dispatch(
        getBulkResumeListingDataAsync({
          userId: user?.id,
          search: search,
          status: selectedStatus?.value,
          currentPage: currentPage,
          perPage: perPage,
        })
      )
        .then(async (response) => {
          const res = response?.payload;

          if (res?.status === true) {
            setBulkResumeData(res?.data);
            setStatus(res?.dropdowns?.status_data);
            setTotalPages(res?.last_page);
            if (
              res?.data?.length === 0 &&
              search === "" &&
              selectedStatus?.value === null
            )
              setMsg("Please upload a bulk resume to view data.");
            else if (
              res?.data?.length === 0 &&
              (search !== "" || selectedStatus?.value !== null)
            )
              setMsg("Please make another selection. No record found.");
            else {
              if (res?.data?.length !== 0) setMsg("");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBulkResumeListingData();
  }, [selectedStatus, search, currentPage, perPage]);

  return (
    <div className="page-space bg-clr-admin">
      <div className="page-title-heading">
        <h2>Bulk resume analysis</h2>

        <button
          className="create-department"
          type="text"
          onClick={() => navHandler()}
        >
          Bulk resume upload +
        </button>
      </div>{" "}
      <div className="filter-search-bar">
        <div className="two-drop-role">
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Status</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedStatus?.label !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedStatus?.label === ""
                      ? "Status"
                      : selectedStatus?.label}
                  </p>
                  {selectedStatus?.label === "" ? (
                    <img src="/image/admin-d.png" alt="" />
                  ) : (
                    <div
                      id="dropdown-boxId2"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/image/cross-port.png"
                        alt=""
                        onClick={() => onStatusClean()}
                      />
                    </div>
                  )}
                </div>
              </div>

              {status?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {status?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onStatusClick(item?.label, item?.value);
                        }}
                      >
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        

        <div className="col-right-searcgadmin">
          <div className="search-admin">
            <img src="/image/job-search.png" alt="" />
            <input
              type="text"
              name="search"
              id="jon-search-box"
              placeholder="Search"
              className="form-font-f"
              onChange={(e) => {
                onSearchChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      <ShowRows
        perPage={perPage}
        onChangePerPageHandler={onChangePerPageHandler}
      />
      <div className="department-table-imge">
        <div className="col-user-table">
          <div className="table-responsive">
            <table className="depart-table-left">
              <thead className="tb-heading-user">
                <tr>
                  <th scope="col">
                    <div className="th-admin-heading">
                      Job title{" "}
                      <img src="/image/bars-arrow-up.png" alt="" />{" "}
                    </div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">Zip file name </div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">
                      Total files{" "}
                      <img src="/image/bars-arrow-up.png" alt="" />
                    </div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">
                      Files parsed{" "}
                      <img src="/image/bars-arrow-up.png" alt="" />
                    </div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">
                      Files in error{" "}
                      <img src="/image/bars-arrow-up.png" alt="" />
                    </div>
                  </th>
                  <th scope="col" className="th-status-res">
                    <div className="th-admin-heading">Status</div>
                  </th>
                  <th scope="col" className="th-size-six">
                    <div className="th-admin-heading">
                      Est time{" "}
                      <img src="/image/bars-arrow-up.png" alt="" />
                    </div>
                  </th>
                  <th scope="col" className="th-size-six">
                    <div className="th-admin-heading">
                      Date submitted{" "}
                      <img src="/image/bars-arrow-up.png" alt="" />
                    </div>
                  </th>
                  <th scope="col" className="th-size-seven">
                    <div className="th-admin-heading">Actions</div>
                  </th>
                </tr>
              </thead>
              <tbody className="tb-user">
                {bulkResumeData?.length > 0 &&
                  bulkResumeData?.map((item, index) => (
                    <tr>
                      <td className="">{item?.job_title}</td>
                      <td className="">{item?.zip_name}</td>
                      <td>{item?.total_resumes}</td>
                      <td>{item?.resume_parsed}</td>
                      <td>{item?.resume_in_error}</td>
                      <td>
                        {item?.status}
                        <div className="progress">
                          <div
                            className={`progress-bar progress-bar-color ${getProgressBarColorClass(
                              item?.status?.toLowerCase()
                            )}`}
                            role="progressbar"
                            style={{
                              width: getProgressBarWidth(
                                item?.status?.toLowerCase()
                              ),
                            }}
                            aria-valuenow={parseInt(
                              getProgressBarWidth(item?.status?.toLowerCase())
                            )}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>{item?.estimated_process_time}</td>
                      <td>{getCurrentFormattedDate(item?.date_submitted)}</td>
                      <td>
                        {" "}
                        <div className="four-resume-img">
                          <div className="img-text-tooltip">
                            <img
                              src="/image/jd-des-res.png"
                              alt=""
                              className="show-tooltip icon-cursor"
                              onClick={() => {
                                dispatch(
                                  setBulkResumeProcessId(item?.process_id)
                                );
                                navigate("/bulk-resume-view-job");
                              }}
                            />
                            <div className="tooltip-jd-resume">
                              View job descritpion
                            </div>
                          </div>
                          <div className="img-text-tooltip">
                            <img
                              src="/image/request-ana-res.png"
                              alt=""
                              className="show-tooltip3 icon-cursor"
                              onClick={() => {
                                dispatch(
                                  setBulkResumeProcessId(item?.process_id)
                                );
                                navigate("/bulk-resume-req-detail");
                              }}
                            />
                            <div className="tooltip-request-detail-res">
                              Request detail
                            </div>
                          </div>
                          <div className="img-text-tooltip">
                            <img
                              src="/image/bulk-ana-res.png"
                              alt=""
                              className="show-tooltip2 icon-cursor"                                    
                              onClick={() => {
                                dispatch(
                                  setBulkResumeProcessId(item?.process_id)
                                );
                                navigate("/bulk-analysis");
                              }}
                            />
                            <div className="tooltip-request-analysis-res">
                              Bulk analysis
                            </div>
                          </div>                        
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {bulkResumeData?.length > 0 && (
            <TablePagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          )}
          <TableError msg={msg} />
        </div>
      </div>
    </div>
  );
};

export default BulkResumeAnalysis;
