import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { regenerateResumeAIAsync } from "../../../../redux/slices/verifyResumeSlice";
import { RESUME_ITEM } from "../../../../constants";
import "./CanResumeProForm.css";

const CanResumeProForm = ({
  prod,
  index,
  onProChangeHandler,
  onProSaveHandler,
  onProDeleteHandler,
  proModelHandler,
  selectedProHandler,
  openDeleteItemHandler
}) => {
  const uniqueId = Math.random();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [showAISuggestion, setShowAISuggestion] = useState(false);
  const [loadingShowAISuggestion, setLoadingShowAISuggestion] = useState(false);
  const [suggestedDes, setSuggestedDes] =
    useState("");
  const [checkedDes, setCheckedDes] = useState([]);
  const [toggleAllSuggestion, setToggleAllSuggestion] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      url: "",
      description: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Project Name"),
      description: Yup.string().required("Please Enter Project Description"),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      setLoader(true);
      onProSaveHandler();

      const timer = setTimeout(() => {
        setLoader(false);
        setSuggestedDes("");
        setShowAISuggestion(false);
      }, 2000);

      return () => clearTimeout(timer);
    },
  });

  const regenerateResumeAI = async (selectedPro) => {
    const { name, description } = selectedPro;
    if (user?.id !== null) {
      setLoadingShowAISuggestion(true);
      setSuggestedDes("");
      dispatch(
        regenerateResumeAIAsync({
          userId: user?.id,
          jobTitle: name,
          sectionName: "projects",
          sectionValue: description,
          promptInput: "",
          aiGen: suggestedDes,
        })
      )
        .then(async (response) => {
          setLoadingShowAISuggestion(false);
          const res = response?.payload;
          const { value } = res?.response;

          if (res?.result === true) {
            const updateDes = typeof value === "string" ? value : "";
            setSuggestedDes(updateDes);
          } else {
            setLoadingShowAISuggestion(false);
            toast.error(res?.message?.error, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          setLoadingShowAISuggestion(false);
          console.log(error);
        });
    }
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };


  const onCustomChangeHandler = async (e) => {
    const { name, value } = e?.target;
    await formik.setFieldValue(name, value);
    const newFormData = {
      ...formik?.values,
      [name]: value,
    };
    onProChangeHandler(newFormData, index);
  };

  const getProData = async () => {
    await formik.setFieldValue("name", prod?.name || "");
    await formik.setFieldValue("url", prod?.url || "");
    await formik.setFieldValue("description", prod?.description || "");
  };

  useEffect(() => {
    formik?.resetForm();
    getProData();
  }, [prod]);

  return (
    <div
      id={`collapseOne-${uniqueId}`}
      className="accordion-collapse collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body educ-res-right">
        <div className="accordion" id={`sub-accordionExample-${uniqueId}`}>
          <div className="accordion-item experince-res-ver-right">
            <h2
              className="accordion-header sub-header"
              id={`sub-heading-${uniqueId}`}
            >
              <button
                className="accordion-button data-tab-res-right"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#sub-collapseanswer-pro-${uniqueId}`}
                aria-expanded="false"
                aria-controls={`collapse-${uniqueId}`}
              >
                <div className="job-title-resume-form">
                  <div className="compay-name-job-resume-right">
                    <p className="right-resume-cmp">
                      {" "}
                      {formik?.values?.name !== ""
                        ? formik?.values?.name
                        : "Project Name"}
                    </p>
                    <p className="job-pro-url-res-right">
                      {formik?.values?.url !== "" ? (
                        <span className="job-pro-url-res-right-inner">
                          {formik?.values?.url}
                        </span>
                      ) : (
                        "Project URL"
                      )}
                    </p>
                  </div>
                  <div className="date-job-res m-0 gap-2">
                    <img
                      src="/image/edit-tab.png"
                      className="edit-res"
                      alt=""
                    />
                    <img
                      className="del-res"
                      src="/image/delet-res.png"
                      alt=""
                      onClick={(e) => {
                        e.stopPropagation();
                        openDeleteItemHandler(index, RESUME_ITEM.PROJECT, true);
                      }}
                    />
                  </div>
                </div>
              </button>
            </h2>
            <div
              id={`sub-collapseanswer-pro-${uniqueId}`}
              className="accordion-collapse collapse"
              aria-labelledby={`sub-heading-${uniqueId}`}
              data-bs-parent={`#sub-accordionExample-${uniqueId}`}
            >
              <div className="accordion-body side-space">
                <h2
                  className="accordion-header sub-header"
                  id={`sub-heading-${uniqueId}`}
                >
                  <button
                    className="accordion-button resume-exper-right-tb"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#sub-collapseOne"
                    aria-expanded="true"
                    aria-controls={`collapseOne-${uniqueId}`}
                  >
                    <form onSubmit={formik.handleSubmit}>
                      <div
                        className={`address-res f-feild-res ${isFormFieldValid("name") && "error-input"
                          }`}
                      >
                        <label for="lname">Project Name</label>
                        <br />
                        <input
                          type="text"
                          placeholder="Project Name"
                          className="feild-stl-res form-font-f"
                          name="name"
                          value={formik?.values?.name}
                          onChange={onCustomChangeHandler}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("name")}
                      </div>
                      <div
                        className={`address-res f-feild-res ${isFormFieldValid("url") && "error-input"
                          }`}
                      >
                        <label for="lname">Project URL</label>
                        <br />
                        <input
                          type="text"
                          placeholder="Project URL"
                          className="feild-stl-res form-font-f"
                          name="url"
                          value={formik?.values?.url}
                          onChange={onCustomChangeHandler}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("url")}
                      </div>
                      <div
                        className={`address-res f-feild-res ${isFormFieldValid("description") && "error-input"
                          }`}
                      >
                        <div className="lbl-ai-mdl-btn">
                          <label for="lname"> Description</label>
                          <br />
                          <a
                            className="btn btn-black"
                            onClick={() => {                                               
                              const isDisabled =
                                suggestedDes?.length > 0 ||
                                loadingShowAISuggestion;
                              if (!isDisabled) {
                                setShowAISuggestion(true);
                                setLoadingShowAISuggestion(true);
                                regenerateResumeAI(formik?.values);
                              }    
                            }}
                          >
                            AI Suggestions{" "}
                            <img src="/image/ai-suggestion-res.png" alt="" />
                          </a>
                        </div>

                        <br />
                        <textarea
                          className="feild-stl-res form-font-f"
                          cols="30"
                          rows="8"
                          name="description"
                          placeholder="Type here"
                          value={formik?.values?.description}
                          onChange={onCustomChangeHandler}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("description")}
                      </div>

                      {showAISuggestion && (
                          <>                            
                            <div className="address-res f-feild-res">
                            <div className="btn-resume-magic">
                            <div></div>     
                                <a
                                  className="btn btn-black"
                                  onClick={() => {
                                    setShowAISuggestion(true);
                                    setLoadingShowAISuggestion(true);
                                    regenerateResumeAI(formik?.values);
                                    setToggleAllSuggestion(false);
                                  }}
                                >
                                  {loadingShowAISuggestion ? (
                                    <div
                                      class="spinner-border"
                                      style={{ width: 24, height: 24 }}
                                      role="status"
                                    >
                                      <span class="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    <img
                                      src="/image/reg-white.png"
                                      width={24}
                                      height={24}
                                    />
                                  )}
                                    </a>
                            </div>

                          

                            {suggestedDes !== "" && (
                                <div className="check-box-resumme">
                                <div className="check-box-f-res">                              
                                    <label className="checkbox-label-resume project-des"  style={{ userSelect: 'text' }}>
                                      {suggestedDes}
                                    </label> 
                                    <br/>
                                  </div>                                  
                                </div>                                         
                              )}                                                                                                                                                                                                           
                          </div>                   
                      <div className="address-res f-feild-res">
                        <div className="two-can-sav-btn">
                          <button
                            type="bttton"
                            className="can-res-btn icon-cursor"                           
                            onClick={() => {
                              setShowAISuggestion(false);
                              setSuggestedDes("");
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="save-res-btn icon-cursor"
                          >
                            {loader ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>                        
                         </>
                        )}

                      {/* <div className="address-res f-feild-res">
                        <div className="two-can-sav-btn">
                          <button
                            type="bttton"
                            className="can-res-btn icon-cursor"
                            data-bs-toggle="collapse"
                            data-bs-target={`#sub-collapseanswer-pro-${uniqueId}`}
                            aria-expanded="false"
                            aria-controls={`collapse-${uniqueId}`}
                            onClick={(e) => e.preventDefault()}
                          >
                            Cancel
                          </button>
                          <button
                            className="save-res-btn icon-cursor"
                            type="submit"
                          >
                            {loader ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      </div> */}
                    </form>
                  </button>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanResumeProForm;
