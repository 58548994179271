import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import API from "../../../api";
import PhoneNumber from "../../Common/PhoneNumber/PhoneNumber";
import { getCitiesAsync, getEmpCountriesAsync, getStateAsync } from "../../../redux/slices/AuthSlice";

const EmpProfileForm = ({
  loader,
  baseInfo,
  companyInfo,
  companyLogo,
  maskedPlaceHolderP,
  maskedPhoneNumberP,
  statesCount,
  editHandler,
  companyLogoModelHandler,
  submitHandler,
}) => {
  const dispatch = useDispatch();
  const [selectedImageName, setSelectedImageName] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [locationHide, setLocationHide] = useState(true);

  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [maskedPlaceHolder, setMaskedPlaceHolder] = useState("");

  const formik = useFormik({
    initialValues: {
      files: null,
      companyName: "",
      country: "",
      countryId: null,
      websiteUrl: "",
      email: "",
      phoneNo: "",
      linkedinURL: "",
      companyInfo: "",
      firstName: "",
      lastName: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z ]+$/, "First name should be an alphabet")
        .required("Please enter first name"),
      lastName: Yup.string()
        .matches(/^[A-Za-z ]+$/, "Last name should be an alphabet")
        .required("Please enter last name"),
      country: Yup.string().required("Please select the country"),
      websiteUrl: Yup.string().required("Please enter valid website URL"),
      phoneNo: Yup.string().required("Please enter phone number"),
      companyInfo: Yup.string(),
      files: Yup.mixed()
        .test(
          "fileType",
          "File can be a JPEG or PNG document",
          (value) => !value || ["image/png", "image/jpeg"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 10 MB",
          (value) => !value || value?.size <= 10 * 1024 * 1024
        ),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      submitHandler(data);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isFileFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFileErrorMessage = (name) => {
    return (
      isFileFieldValid(name) && (
        <p className="file-error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const handleImageChange = async (event) => {
    companyLogoModelHandler(true);
  };

  const countryHandler = async (item) => {
    const {id, name, masked_phone_number, placeholder, states_count} = item;

    await formik.setFieldValue("country", name);
    await formik.setFieldValue("countryId", id);

    await formik.setFieldValue("state", "");
    await formik.setFieldValue("stateId", null);

    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);


    setMaskedPhoneNumber(masked_phone_number);
    setMaskedPlaceHolder(placeholder);
    setLocationHide(true);
    setCitiesOptions([]);
    setStateOptions([]);

    if(states_count === 0){
      getCitiesData(id, null);
    }
    else {
      getStatusData(id);
    }
  };

  const stateHandler = async (id, name) => {
    await formik.setFieldValue("state", name);
    await formik.setFieldValue("stateId", id);
    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);
    getCitiesData(null, id);
  };

  const cityHandler = async (id, name) => {
    await formik.setFieldValue("city", name);
    await formik.setFieldValue("cityId", id);
  };


  const getStatusData = async (id) => {
    dispatch(
      getStateAsync({
        id: id
      })
    )
      .then((response) => {
        const res = response?.payload;
        setStateOptions(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCitiesData = async (id1, id2) => {
    dispatch(
      getCitiesAsync({
        country_id: id1,
        state_id: id2
      })
    )
      .then((response) => {
        const res = response?.payload;
        setCitiesOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    async function getCountries() {
      try {
        dispatch(
          getEmpCountriesAsync({ country_id: null })
        )
          .then((response) => {
            const res = response?.payload;
              setCountryOptions(res);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
    getCountries();
  }, [baseInfo]);

  useEffect(() => {
    if (companyLogo !== null) {
      formik.setFieldValue("files", companyLogo);
      setSelectedImageName(companyLogo?.name);
    }
  }, [companyLogo]);

  useEffect(() => {

    if(baseInfo?.countryId){

      if(statesCount === 0)
      getCitiesData(baseInfo?.countryId, null);
    else {
      getStatusData(baseInfo?.countryId);
      getCitiesData(null, baseInfo?.countryId);
    }
  }
    setMaskedPlaceHolder(maskedPlaceHolderP);
    setMaskedPhoneNumber(maskedPhoneNumberP);

    formik.setValues({
      companyName: companyInfo?.name || "",
      countryId: baseInfo?.countryId || null,
      country: baseInfo?.countryName || "",
      cityId: baseInfo?.cityId || null,
      city: baseInfo?.city || "",
      stateId: baseInfo?.stateId || null,
      state: baseInfo?.state || "",
      websiteUrl: companyInfo?.website || "",
      email: baseInfo?.email || "",
      phoneNo: baseInfo?.phone || "",
      linkedinURL: companyInfo?.linkedin || "",
      companyInfo: companyInfo?.description || "",
      firstName: baseInfo?.firstName,
      lastName: baseInfo?.lastName,
    });
  }, []);

  return (
    <div className="my-profile-row">
      <div className="candidtae-profile-image new-profile-image-st profile-pic-desktop">
        <img
          src={
            companyLogo !== null
              ? URL?.createObjectURL(companyLogo)
              : companyInfo?.logo === null || companyInfo?.logo === undefined
              ? "/image/avtar-company.png"
              : companyInfo?.logo
          }
          alt=""
          className="profile-opac"
        />
        <div className="addProfile-icon icon-cursor">
          <img src="/image/addProfile.png" alt="" onClick={handleImageChange} />
        </div>
      </div>
      <span className="profile-img-mbl mt-4">
        <div className="candidtae-profile-image new-profile-image-st">
          <img
            src={
              companyLogo !== null
                ? URL?.createObjectURL(companyLogo)
                : companyInfo?.logo === null || companyInfo?.logo === undefined
                ? "/image/avtar-company.png"
                : companyInfo?.logo
            }
            alt=""
            className="profile-opac"
          />
          <div className="addProfile-icon icon-cursor">
            <img
              src="/image/addProfile.png"
              alt=""
              onClick={handleImageChange}
            />
          </div>
        </div>
      </span>
      <div className="divider-left-profile"> </div>
      <div className="candidate-information max-mob-100">
        <div className="candidate-about-me">
          <div className="candidate-profile-form">
            <h5 className="text-primary">About me</h5>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-block-width">
                <div className="candidate-name-field-profile-f profile-field-f">
                  <div className="employr-f-profile password-icon bg-pass-f">
                    <input
                      className="form-font-f"
                      type="text"
                      name=""
                      id="Company-name-employe-profile"
                      placeholder="Company Name"
                      value={formik.values.companyName}
                    />
                    <div className="icon-hide">
                      <img src="/image/locked-field.png" alt="" />
                      <span className="tooltiptext-profile">
                        Contact admin support
                      </span>
                    </div>
                  </div>
                </div>

                <div className={`candidate-name-field-profile profile-field-f`}>
                  <div
                    class=""
                    className={`first-name-profile ${
                      isFormFieldValid("firstName") && "error-input"
                    }`}
                  >
                    <input
                      className="form-font-f"
                      type="text"
                      id="candidate-name-field"
                      placeholder="First name"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("firstName")}
                  </div>
                  <div
                    className={`first-name-profile ${
                      isFormFieldValid("lastName") && "error-input"
                    }`}
                  >
                    <input
                      className="form-font-f"
                      type="text"
                      id="candidate-name-field"
                      placeholder="Last name"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("lastName")}
                  </div>
                </div>

                <div
                  className={`profile-field-f signup-passowrd-job location-profile-list ${
                    isFormFieldValid("country") && "error-input"
                  }`}
                >
                  <div className="industry-feild-frm bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-location.png"
                        className="location-icon"
                        alt=""
                      />
                    </div>
                    <div
                      id="dropdown-boxId-2"
                      className="company-form-dropdownBox-profile"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => setLocationHide(!locationHide)}
                    >
                      {" "}
                      {formik?.values?.country === ""
                        ? "location"
                        : formik?.values?.country}
                    </div>
                    <div className="icon-hide">
                      {isFormFieldValid("country") ? (
                        <img src="/image/warning.png" alt="" />
                      ) : (
                        <img src="/image/form-d-f.png" alt="" />
                      )}
                    </div>
                    {countryOptions?.length > 0 && (
                      <div
                        id="company-dropList-t"
                        className={`dropdown-form-f company-drop-list-t ${
                          locationHide && "job-dropdown-hide"
                        }`}
                      >
                        <ul>
                          {countryOptions.map((j, i) => (
                            <li
                              className="icon-cursor-f"
                              key={i}
                              onClick={() => {
                                countryHandler(j);
                              }}
                            >
                              <img
                                src="/image/profile-location.png"
                                className="location-icon-drop"
                                alt=""
                              />{" "}
                              {j?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  {getFormErrorMessage("country")}
                </div>

                <div className="candidate-username-signup-form">
                <div
                  className={`profile-field-f signup-passowrd-job location-profile-list first-name ${isFormFieldValid("state") && "error-input"
                  ||
                  stateOptions?.length === 0 && "disable-field"
                    }`}
                >
                  <div className="industry-feild-frm bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-location.png"
                        className="location-icon"
                        alt=""
                      />
                    </div>
                    <div
                      id="dropdown-boxId-2"
                      className="company-form-dropdownBox-profile"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {formik?.values?.state === ""
                        ? "Province/State"
                        : formik?.values?.state}
                    </div>
                    <div className="icon-hide-2">
                      {isFormFieldValid("state") ? (
                        <img src="/image/warning.png" alt="" />
                      ) : (
                        <img src="/image/form-d-f.png" alt="" />
                      )}
                    </div>
                    {stateOptions?.length > 0 && (
                      <div
                        id="company-dropList-t"
                        className={`dropdown-form-f company-drop-list-t 
                          }`}
                      >
                        <ul>
                          {stateOptions?.map((j, i) => (
                            <li
                              className="icon-cursor-f"
                              key={i}
                              onClick={() => stateHandler(j?.id, j?.name)}
                            >
                              <img
                                src="/image/profile-location.png"
                                className="location-icon-drop"
                                alt=""
                              />{" "}
                              {j?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  {getFormErrorMessage("state")}
                </div>

                <div
                  className={`profile-field-f signup-passowrd-job location-profile-list first-name ${isFormFieldValid("city") && "error-input"
                  ||
                  citiesOptions?.length === 0 && "disable-field"
                    }`}
                >
                  <div className="industry-feild-frm bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-location.png"
                        className="location-icon"
                        alt=""
                      />
                    </div>
                    <div
                      id="dropdown-boxId-2"
                      className="company-form-dropdownBox-profile"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {formik?.values?.city === ""
                        ? "City"
                        : formik?.values?.city}
                    </div>
                    <div className="icon-hide-2">
                      {isFormFieldValid("city") ? (
                        <img src="/image/warning.png" alt="" />
                      ) : (
                        <img src="/image/form-d-f.png" alt="" />
                      )}
                    </div>
                    {countryOptions?.length > 0 && (
                      <div
                        id="company-dropList-t"
                        className={`dropdown-form-f company-drop-list-t`}
                      >
                        <ul>
                          {citiesOptions?.map((j, i) => (
                            <li
                              className="icon-cursor-f"
                              key={i}
                              onClick={() => cityHandler(j?.id, j?.name)}
                            >
                              <img
                                src="/image/profile-location.png"
                                className="location-icon-drop"
                                alt=""
                              />{" "}
                              {j?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  {getFormErrorMessage("city")}
                </div>

                </div>

                <div
                  className={`profile-field-f ${
                    isFormFieldValid("phoneNo") && "error-input"
                  }`}
                >
                  <div
                    className={`country-code-profile phone-f-profile  ${
                      isFormFieldValid("phoneNo") && "error-input"
                    }`}
                  >
                    <div className="location-f-icon bg-pass-f">
                      <div className="password-icon-logo">
                        <img
                          src="/image/profile-phone.png"
                          className="profile-icon"
                          alt=""
                        />
                      </div>
                      <PhoneNumber
                        className="form-font-f"
                        id="profile-call"
                        type="tel"
                        name="phoneNo"
                        value={formik.values.phoneNo}
                        maskedPlaceHolder={maskedPlaceHolder}
                        maskedPhoneNumber={maskedPhoneNumber} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="icon-hide">
                        {isFormFieldValid("phoneNo") && (
                          <img src="/image/warning.png" alt="" />
                        )}
                      </div>
                    </div>
                  </div>
                  {getFormErrorMessage("phoneNo")}
                </div>

                <div
                  className={`url-employee-profile-f profile-field-f ${
                    isFormFieldValid("websiteUrl") && "error-input"
                  }`}
                >
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-website.png"
                        className="profile-icon"
                        alt=""
                      />
                    </div>
                    <input
                      className="form-font-f"
                      id="profile-url-employee"
                      placeholder="Company website link"
                      name="websiteUrl"
                      value={formik.values.websiteUrl}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {isFormFieldValid("websiteUrl") && (
                      <img src="/image/warning.png" alt="" />
                    )}
                  </div>

                  {getFormErrorMessage("websiteUrl")}
                </div>
                <div className={`email-candidate-profile-f profile-field-f`}>
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-email.png"
                        alt=""
                      />
                    </div>
                    <input
                      className="form-font-f"
                      id="profile-email-candidate"
                      placeholder="E-mail"
                      name="email"
                      value={formik.values.email}
                    />
                    <div className="icon-hide">
                      <img src="/image/locked-field.png" alt="" />
                      <span className="tooltiptext-profile">
                        Contact admin support
                      </span>
                    </div>
                  </div>
                </div>              
                <div className="candidate-link-profile profile-field-f">
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-linked.png"
                        className="profile-icon"
                        alt=""
                      />
                    </div>
                    <input
                      className="form-font-f"
                      type="text"
                      id="social-link"
                      placeholder="Enter your linkedIn profile here"
                      name="linkedinURL"
                      value={formik.values.linkedinURL}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="form-block-width">              
                <div className="row">
                  <div className="col-md-6 order-md-0 order-1">
                    <button
                      className="cancel-btn-profile w-100"
                      onClick={() => editHandler(false)}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-md-6 order-md-1 order-0">
                    <button
                      className="save-btn-profilefill w-100 mb-md-0 mb-3"
                      type="submit"
                    >
                      {loader ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmpProfileForm;
