import axios from "axios";
import { BASE_API_ADMIN, BASE_API_ADMIN_DYNAMIC } from "./config";

// Check if the project is running on localhost
const isLocalhost = window.location.hostname === 'localhost';

const baseURL = isLocalhost ? BASE_API_ADMIN : BASE_API_ADMIN_DYNAMIC;

const getAccessToken = () => {
  return localStorage.getItem("token");
};

const APIPvt = axios.create({
  baseURL: baseURL,
});

APIPvt.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default APIPvt;

// const APIPvt = axios.create();

// APIPvt.interceptors.request.use(
//   async (config) => {
//     try {
//       // Get the accessToken from the Redux state using useSelector
//       const accessToken  = localStorage.getItem("token");

//       const response = await axios.get(
//         "https://workbridge.unitedsoftlabs.com/base_url.php"
//       );

//       if (response?.status === 200) {
//         const { base_url } = response.data;

//         config.baseURL = base_url + "/public/api/v1/admin/v1";
//       } else {
//         config.baseURL = BASE_API;
//       }

//       // Set the Bearer token in the request headers
//       config.headers.Authorization = `Bearer ${accessToken}`;
//     } catch (error) {
//       // Handle errors
//       console.error("Error fetching base URL:", error);
//     }

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// export default APIPvt;
