import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import WorldConnect from "../../../../components/Common/WorldConnect/WorldConnect";
import { setResumeFormMode, setResumeRetryCount } from "../../../../redux/slices/verifyResumeSlice";
import { RESUME_MODE } from "../../../../constants";
import "./index.css";

const CanResumeDecision = () => {
  const dispatch = useDispatch();
  return (
    <>
      <section className="journey-sec pt-0">
        <div className="decision-banner px-md-0 px-3">
          <div className="container container-site">
            <div className="row align-items-center">
              <div className="col-md-8 col-12">
                <div className="d-ban-content pe-md-5">
                  <p className="text-pacific fw-700 mb-3">Poly AI Powered Resume Tools</p>
                  <h2 className="mb-2">Build a professional resume for free.</h2>
                  <p>Let our AI Powered Resume Tools refine your existing resume or tailor it to any job description effortlessly. Bid farewell to generic resumes and welcome personalized success - all at no cost.</p>

                  <div className="d-flex flex-wrap gap-3 mt-md-5 mt-3 py-md-0 py-5">
                    <Link to="/candidate-resume"
                      onClick={() => {
                        dispatch(setResumeRetryCount(0));
                        dispatch(setResumeFormMode(RESUME_MODE.UPDATE));
                      }} className="btn btn-lg btn-outline-black">Enhance my resume</Link>
                    <Link to="/candidate-resume-job-form" className="btn btn-lg btn-black">Customize my resume</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-md-block d-none">
                <div className="d-ban-img">
                  <img src='/image/decision-banner.png' alt='decision-banner' className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WorldConnect />
    </>
  );
};

export default CanResumeDecision;
