import { useEffect } from 'react'
import OutsideClickHandler from "react-outside-click-handler";

const ContentModal = ({ closeModal, children, size = 'md' }) => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => (document.body.style.overflowY = 'auto');
  })
  return (
    <section className="common-modal" data-size={size}>
      <OutsideClickHandler
        onOutsideClick={() => {
          closeModal();
        }}
      >
        <div className="modal-content-info">
          <div className="modal-header">
            <div className="modal-logo">
              <img src="/image/polycareers-north-macedonia.png" alt="" className="img-fluid" />
            </div>
            <button className="btn btn-close" onClick={() => closeModal()}></button>
          </div>
          {children}
        </div>
      </OutsideClickHandler>
    </section>
  )
}

export default ContentModal;