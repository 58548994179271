import React, { useState } from 'react'
import { Button, Form } from "react-bootstrap";
import APIPvtDB from "../../../../apiPvtD";

const NotesSec = ({ commentDet, loggedInID, getCommentsDetail }) => {
  // edited
  const [edit, setEdit] = useState(false);
  const [inputValue, setInputValue] = useState(false);



  const editComment = (data) => {
    setEdit(true)
    setInputValue(data.comments)
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const updateComment = async (data) => {
    try {
      const formData = {
        "id": data.id,
        "comments": inputValue
      }
      await APIPvtDB.post(`/update-note`, formData)
        .then((response) => {
          if (response.data.success) {
            setInputValue('')
            setEdit(false)
            getCommentsDetail()
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  const deleteComment = async (data) => {
    try {
      await APIPvtDB.delete(`/delete-note/${data}`)
        .then((response) => {
          if (response.data.success) {
            getCommentsDetail()
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  const dateTime = (data) => {
    const date = new Date(data);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  }

  const dayTime = (data) => {
    const date = new Date(data);

    // Options for formatting the date
    const options = {
      weekday: 'long', // Display full weekday name
      month: 'long', // Display full month name
      day: 'numeric', // Display day of the month as a number
      year: 'numeric', // Display full year
    };
    return date.toLocaleDateString('en-US', options)
  }
  return (
    <div className="single-note-item">
      <div className="note-item bg-white">
        <div className="d-flex justify-content-between align-items-start">
          {!edit ? (
            <div className="d-flex flex-column-reverse flex-sm-row justify-content-between align-items-start w-100">
              <div className='flex-auto w-100'>
                {commentDet?.first_name && (
                  <h4>{commentDet?.first_name} {commentDet?.lasst_name}</h4>
                )}
                <div className="note-item-text">{commentDet?.comments}</div>
              </div>
              <div className="d-inline-flex align-items-center ms-auto ms-sm-0 mb-2 mb-sm-0">
                <div className="me-3 pointer" onClick={() => editComment(commentDet)}>
                  <img src="image/pen.png" width={18} height={18} />
                </div>
                <div className='pointer' onClick={() => deleteComment(commentDet.id)}>
                  <img src="image/bin-black.png" width={16} height={18} />
                </div>
              </div>
            </div>
          ) : (
            <>
              <Form
                className="d-flex justify-content-between w-100 flex-column"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Form.Group
                  className="w-100"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    className="w-100 note-edit-input"
                    placeholder="Type your note here..."
                    size="lg"
                    value={inputValue}
                    onChange={handleInputChange}
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>
                <div className="d-flex mt-3 justify-content-end">
                  <Button
                    className="me-3 px-3 py-2 edit-btn"
                    variant="dark"
                    onClick={() => setEdit(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="border-muted px-3 py-2 edit-btn"
                    variant="outline-dark"
                    onClick={() => updateComment(commentDet)}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </>
          )}
        </div>
        {(commentDet.is_edited && !edit) ?
          <div className="text-end mt-3 text-edited">
            Edited: {dayTime(commentDet?.updated_at)}
          </div> : ''}
      </div>
      <div className="text-end note-item-time mt-2">
        {dateTime(commentDet?.created_at)}
      </div>
    </div>
  );
};

export default NotesSec