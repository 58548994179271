import "./PostJobDecisionModel.css";
import OutsideClickHandler from "react-outside-click-handler";
import {useDispatch} from "react-redux";
import {
  changeJobDecisionToCopy,
  changeJobDecisionToZoe,
  clearJobDecision, closeJobDecisionModal
} from "../../../redux/slices/JobDecisionSlice";

const PostJobDecisionModel = () => {

  const dispatch = useDispatch();

  return (
    <section className="model-popup-post-job">
      <div className="popup-post-job">
        <div className="popup-content-post-job">
          <div className="logo-site">
            <a>
              <img src="/image/WORKBRIDGE.png" alt="" />
            </a>  
            <img src="/image/chat-cut.png" alt="" className="close-m-gen"/>     
          </div>
          <OutsideClickHandler
            onOutsideClick={() => {
              dispatch(closeJobDecisionModal());          
            }}
          >
            <div className="three-row">
              <div
                className="col-job-cardone icon-cursor"
                onClick={() => {
                  dispatch(changeJobDecisionToCopy());
                  dispatch(closeJobDecisionModal());
                }}
              >
                <div className="line-one"></div>
                <div className="line-two"></div>
                <div className="line-white"></div>
                <div className="line-white"></div>
                <div className="line-white"></div>
                <p className="copy-j-description-content">Copy/Paste your </p>
                <p className="j-card-text-job">Job Description 🗒️</p>
              </div>
              <div className="col-job-cardtwo">
                <p>OR</p>
              </div>
              <div
                className="col-job-cardthree icon-cursor"
                onClick={() => {
                  dispatch(changeJobDecisionToZoe());
                  dispatch(closeJobDecisionModal());
                }}
              >
                <img src="/image/zoe-ai.png" alt="" />
                <p className="text-zoeai">
                  Let <span>Zoe</span>👩🏼‍💻 our (AI Recruiter) create it for you.
                </p>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </section>
  );
};

export default PostJobDecisionModel;
