import React from "react";
import ForgotForm from "../../../components/Auth/ForgotPassword/ForgotForm/ForgotForm";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";

const ForgotPassword = () => {
  return (
    <>
      <ForgotForm />
      <WorldConnect />
    </>
  );
};

export default ForgotPassword;
