import React from "react";
import PasswordForm from "../../../components/Auth/ForgotPassword/PasswordForm/PasswordForm";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";

const ChangePassword = () => {
  return (
    <>
      <PasswordForm />
      <WorldConnect />
    </>
  );
};

export default ChangePassword;
