import { Link } from "react-router-dom";
import "./Pagination.css";

const Pagination = ({ currentPage, totalPages, onCurrentPageHandler }) => {
  const maxPageNumbers = 4; // Number of page numbers to display

  // Ensure currentPage is within valid range
  currentPage = Math.max(1, Math.min(currentPage, totalPages));

  // Calculate the range of page numbers to display
  let startPage = Math.max(currentPage - Math.floor(maxPageNumbers / 2), 1);
  let endPage = startPage + maxPageNumbers - 1;

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(endPage - maxPageNumbers + 1, 1);
  }

  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  const prePageHandler = () => {
    if (currentPage > startPage) onCurrentPageHandler(currentPage - 1);
  };

  const nextPageHandler = () => {
    if (currentPage < endPage) onCurrentPageHandler(currentPage + 1);
  };

  return (
    <section className="pageniation-sec">
      <div className="container-site-job-match">
        <div className="pagination-job">
          <Link
            className="fill-box light-bg"
            onClick={() => onCurrentPageHandler(1)}
            disabled={currentPage === 1}
          >
            &laquo;
          </Link>
          <Link
            className="fill-box"
            onClick={() => prePageHandler()}
            disabled={currentPage >= 1}
          >
            &lt;
          </Link>
          {pageNumbers?.map((pageNumber) => (
            <Link
              key={pageNumber}
              className={pageNumber === currentPage ? "active" : ""}
              onClick={() => onCurrentPageHandler(pageNumber)}
            >
              {pageNumber}
            </Link>
          ))}

          <Link
            className="fill-box"
            onClick={() => nextPageHandler()}
            disabled={currentPage <= totalPages}
          >
            &gt;
          </Link>
          <Link
            className="fill-box light-bg"
            onClick={() => onCurrentPageHandler(totalPages)}
            disabled={currentPage === totalPages}
          >
            &raquo;
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Pagination;
