// AdminSidebar.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  setActiveSideBar,
  setdefaultPermissions,
} from "../../../redux/slices/AdminDashboardSlice";
import {
  clearLoginUser,
  setResumeDetail,
  setResumeVerified,
} from "../../../redux/slices/AuthSlice";
import { setResumeRetryCount } from "../../../redux/slices/verifyResumeSlice";
import { ADMIN_SIDE_BAR, USER_TYPE } from "../../../constants";
import "./AdminSidebar.css";

const AdminSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const [isExpanded, setIsExpanded] = useState(true);

  const { activeSideBar, defaultPermissions } = useSelector(
    (state) => state.adminDashboard
  );

  const [adminSideBarOption, setAdminSideBarOption] = useState([
    {
      id: "2",
      name: "User setup",
      value: "User setup",
      path: "/admin-department",
      subMenuOpen: false,
      icon: "/image/system-nav.png",
      iconActive: "/image/system-nav-b.png",
      key: "system_setup_view",
      subMenuOption: [
        {
          id: "23",
          name: "Department",
          value: "Department",
          path: "/admin-department",
          subMenuOpen: false,
          icon: "/image/deprt-nav.png",
          iconActive: "/image/deprt-nav-b.png",
          key: "department_view",
        },
        {
          id: "24",
          name: "Roles",
          value: "Roles",
          path: "/admin-role",
          subMenuOpen: false,
          icon: "/image/role-nav.png",
          iconActive: "/image/role-nav-b.png",
          key: "roles_view",
        },
        {
          id: "25",
          name: "Users",
          value: "Users",
          path: "/admin-user",
          subMenuOpen: false,
          icon: "/image/user-nav.png",
          iconActive: "/image/user-nav-b.png",
          key: "users_view",
        },
      ],
    },
    {
      id: "3",
      name: "Promotions",
      value: "Promotions",
      path: "/admin-all-promotion",
      subMenuOpen: false,
      icon: "/image/promotion-icon.png",
      iconActive: "/image/promotion-icon-b.png",
      key: "promotions_view",
      subMenuOption: [
        {
          id: "32",
          name: "All promotions",
          value: "All promotions",
          path: "/admin-all-promotion",
          subMenuOpen: false,
          icon: "/image/promotion-icon.png",
          iconActive: "/image/promotion-icon-b.png",
          key: "promotions_view",
        },
        {
          id: "34",
          name: "Promotions requests",
          value: "Promotions requests",
          path: "/admin-promotion-req",
          subMenuOpen: false,
          icon: "/image/promotion-icon.png",
          iconActive: "/image/promotion-icon-b.png",
          key: "promotions_view",
        },
      ],
    },
    {
      id: "4",
      name: "Bulk resume analysis",
      value: "Bulk analysis",
      path: "/bulk-resume-analysis",
      subMenuOpen: false,
      icon: "/image/bulk-resume-m.png",
      iconActive: "/image/bulk-resume-m.png",
      key: "bulk_resume_view",
      subMenuOption: [],
    },
  ]);

  /***********Side Bar Event**************/

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleSubMenu = (index) => {
    const updatedAdminSideBarOption = [...adminSideBarOption];
    updatedAdminSideBarOption[index].subMenuOpen =
      !updatedAdminSideBarOption[index]?.subMenuOpen;
    setAdminSideBarOption(updatedAdminSideBarOption);
    dispatch(
      setActiveSideBar({
        id: updatedAdminSideBarOption[index]?.id,
        name: updatedAdminSideBarOption[index]?.name,
        value: updatedAdminSideBarOption[index]?.value,
      })
    );
  };

  const toggleSubSubMenu = (index, subIndex) => {
    const updatedAdminSideBarOption = [...adminSideBarOption];
    updatedAdminSideBarOption[index].subMenuOption[subIndex].subMenuOpen =
      !updatedAdminSideBarOption[index]?.subMenuOption[subIndex]?.subMenuOpen;
    setAdminSideBarOption(updatedAdminSideBarOption);

    dispatch(
      setActiveSideBar({
        id: updatedAdminSideBarOption[index]?.id,
        name: updatedAdminSideBarOption[index]?.subMenuOption[subIndex]?.name,
        value: updatedAdminSideBarOption[index]?.subMenuOption[subIndex]?.value,
      })
    );
  };

  const filterSubMenuOptions = (menuItems, permission) => {
    return menuItems
      .map((item) => {
        if (item?.subMenuOption && item?.subMenuOption?.length > 0) {
          item.subMenuOption = filterSubMenuOptions(
            item?.subMenuOption,
            permission
          );
        }

        if (
          permission?.hasOwnProperty(item?.key) &&
          permission[item?.key] === 0
        ) {
          return null;
        }

        return item;
      })
      .filter((item) => item !== null);
  };

  /*************************/

  const adminLogoClick = () => {
    if (user?.user_type !== USER_TYPE.SUPER_ADMIN) {
      navigate("/");
    } else {
      dispatch(
        setActiveSideBar({
          id: "2",
          name: "Department",
          value: "Department",
        })
      );
      navigate("/admin-department");
    }
  };

  const onLogOutHandler = () => {
    dispatch(clearLoginUser());
    dispatch(setResumeVerified(0));
    dispatch(setResumeRetryCount(0));
    dispatch(setResumeDetail({ resumeId: null, resumeType: 1 }));
    dispatch(setdefaultPermissions({}));
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Department",
        value: "Department",
      })
    );
    localStorage.removeItem("token");
  };

  useEffect(() => {
    const updatedSidebar = adminSideBarOption?.map((topLevelItem) => {
      if (topLevelItem?.subMenuOption) {
        topLevelItem.subMenuOption = filterSubMenuOptions(
          topLevelItem?.subMenuOption,
          defaultPermissions
        );
      }
      return topLevelItem;
    });
    setAdminSideBarOption(updatedSidebar);
  }, [defaultPermissions]);

  return (
    <>
      <aside id="sidebar" className={isExpanded ? "expand" : ""}>
        <div className="d-flex">
          {isExpanded ? (
            <div
              className="siebar-logo icon-cursor"
              onClick={() => adminLogoClick()}
            >
              <img
                src="/image/WORKBRIDGE-white.png"
                alt=""
                onClick={toggleSidebar}
              />
            </div>
          ) : (
            <button
              className="toggle-btn"
              type="button"
              onClick={toggleSidebar}
            >
              <img src="/image/pc-logo-m.png" alt="" />
            </button>
          )}
        </div>
        <div className="sidebar-clos-icon">
          <img src="/image/left-sidebar.png" alt="" onClick={toggleSidebar} />
        </div>
        <ul className="sidebar-nav">
          {adminSideBarOption?.map((option, index) =>
            option?.subMenuOption?.length === 0 ? (
              <li
                key={index}
                className={`sidebar-item ${
                  option.id === activeSideBar?.id ? "active" : ""
                }`}
              >
                <Link
                  to={option?.path}
                  className="sidebar-link"
                  onClick={() => toggleSubMenu(index)}
                >
                  <img
                    src={option?.icon}
                    // src={
                    //   option?.id === activeSideBar?.id
                    //     ? option?.iconActive
                    //     : option?.icon
                    // }
                    alt=""
                  />
                  <span>{option?.name}</span>
                </Link>
              </li>
            ) : (
              <>
                <li
                  key={index}
                  className={`sidebar-item ${
                    option?.id === activeSideBar?.id ||
                    option?.name === activeSideBar?.name ||
                    option?.value === activeSideBar?.value
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to={option?.path}
                    className="menu-btn nav-link"
                    onClick={() => toggleSubMenu(index)}
                  >
                    <div
                      className="sidebar-link collapsed has-dropdown"
                      data-bs-toggle="collapse"
                      data-bs-target={`#sub-menu-${index}`}
                      aria-expanded="false"
                      aria-controls={`sub-menu-${index}`}
                    >
                      <img
                        src={option?.icon}
                        // src={
                        //   option?.id === activeSideBar?.id
                        //     ? option?.iconActive
                        //     : option?.icon
                        // }
                        alt=""
                      />
                      <span>{option?.name}</span>
                    </div>
                  </Link>
                </li>
                <ul
                  id={`sub-menu-${index}`}
                  className="sidebar-dropdown list-unstyled collapse"
                  data-bs-parent="#sidebar"
                >
                  {option?.subMenuOption.map((subOption, subIndex) => (
                    <>
                      <li
                        className={`sidebar-item ${
                          subOption?.name === activeSideBar?.name ||
                          subOption?.value === activeSideBar?.value
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          to={subOption?.path}
                          className="sidebar-link pl-2rem"
                          onClick={() => toggleSubSubMenu(index, subIndex)}
                        >
                          <img
                            src={subOption?.icon}
                            // src={
                            //   subOption?.name === activeSideBar?.name ||
                            //   subOption?.value === activeSideBar?.value
                            //     ? subOption?.iconActive
                            //     : subOption?.icon
                            // }
                            alt=""
                          />
                          <span>{subOption?.name}</span>
                        </Link>
                      </li>
                    </>
                  ))}
                </ul>
              </>
            )
          )}
        </ul>
        <div className="sidebar-footer">
          <Link
            to="/"
            className="sidebar-link"
            onClick={() => onLogOutHandler()}
          >
            <img src="/image/logout-admin.png" alt="" />
            <span>Logout</span>
          </Link>
        </div>
      </aside>

      {/* <div className="col-md-2 sidebar-bg">
      <Link
        to={
          user?.user_type !== USER_TYPE.SUPER_ADMIN ? "/" : "/admin-department"
        }
      >
        <div className="siebar-logo icon-cursor">
          <img src="/image/WORKBRIDGE-white.png" alt="" />
        </div>
      </Link>
      <div className="navbar-sidemenu">
        <ul className="navbar-nav">
          {adminSideBarOption?.map((option, index) => (
            <li
              key={index}
              className={`sub-children nav-item icon-crv ${
                option.id === activeSideBar?.id ? "active" : ""
              }`}
            >
              <Link
                to={option?.path}
                className="menu-btn nav-link"
                onClick={() => toggleSubMenu(index)}
              >
                <img
                  src={
                    option?.id === activeSideBar?.id
                      ? option?.iconActive
                      : option?.icon
                  }
                  alt=""
                />{" "}
                {option?.name}
                {option?.subMenuOption?.length > 0 && (
                  <img
                    src={
                      option?.id === activeSideBar?.id
                        ? "/image/down-menu-admin-b.png"
                        : "/image/down-menu-admin.png"
                    }
                    alt=""
                    // className="cart-menu"
                  />
                )}
              </Link>
              {option?.subMenuOpen ||
                (option.id === activeSideBar.id && (
                  <ul
                    className={`inner-dropdown ${
                      option.id === activeSideBar.id ? "d-block" : "d-none"
                    }`}
                  >
                    {option?.subMenuOption.map((subOption, subIndex) => (
                      <li
                        key={subIndex}
                        className={`sub-children nav-item icon-crv ${
                          subOption?.name === activeSideBar?.name ||
                          subOption?.value === activeSideBar?.value
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          className="menu-btn"
                          to={subOption?.path}
                          onClick={() => toggleSubSubMenu(index, subIndex)}
                        >
                          <img
                            src={
                              subOption?.name === activeSideBar?.name ||
                              subOption?.value === activeSideBar?.value
                                ? subOption?.iconActive
                                : subOption?.icon
                            }
                            alt=""
                          />{" "}
                          {subOption?.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ))}
            </li>
          ))}
        </ul>
      </div>
    </div> */}
    </>
  );
};

export default AdminSidebar;
