import React from 'react'
import "./NotFound.css";
import WorldConnect from '../../components/Common/WorldConnect/WorldConnect';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='container main-container pt-md-5'>
        <div className='row align-items-center'>
          <div className='col-md-3'></div>
          <div className='col-md-3'>
            <div className='page-nf'>
              <h1>Ooops...</h1>
              <h3>Page not found</h3>
              <p>We are sorry, page you requested cannot be found.</p>
              <button className='btn btn-black w-100' onClick={() => navigate(-1)}>Go back
                <img src='./image/arrow-right-btn.png' alt='arrow' className='ms-2' />
              </button>
            </div>
          </div>
          <div className='col-md-5 text-center'>
            <img src='./image/page-404.png' className='img-fluid' />
          </div>
        </div>
      </div>
      <WorldConnect />
    </>
  )
}

export default NotFound