import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFooterDataAsync } from "../../../redux/slices/homeSlice";
import { COUNTRY_ISO_CODE } from "../../../constants";
import "./Footer.css";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { countryISOCode } = useSelector((state) => state.home);
  const [openServices, setOpenServices] = useState(false);
  const [openCountries, setOpenCountries] = useState(false);
  const [countries, setCountries] = useState([]);

  const getFooterData = async () => {
    dispatch(getFooterDataAsync())
      .then(async (response) => {
        const res = response?.payload;
        const { countries } = res?.data;

        if (Array?.isArray(countries) && countries?.length > 0) {
          setCountries(countries);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFooterData();
  }, []);

  return (
    <section className="footer-sec ">
      <div className="container container-site">
        <div className="space-inner-row footer-desktop">
          <div className="footer-row ">
            {/* <div className="footer-address ">
              <img src="/image/footer-logo.png " alt="footer-logo " />
              <p className="head-office ">HEAD OFFICE</p>
              <p className="office-address ">
                9131 Keele Street, Unit A4, Vaughan, ON L4K 0G7, Canada
              </p>
              <p className="office-mail ">
                <a href="mailto:info@polycareers.com.mk">
                  info@polycareers.com.mk
                </a>
              </p>
            </div> */}
            {/* <div className="footer-services footer-list ">
              <ul>
                <li className="service-heading ">Services</li>
                <li>Employer of Record</li>
                <li>Background and Reference Check</li>
                <li>Asset Management</li>
                <li>Recruitment Process Outsourcing</li>
                <li>Global Consulting</li>
                <li>Global Talent Hub</li>
              </ul>
            </div> */}

            <div className="footer-countries footer-list ">
              <ul>
                <p className="Countries-heading ">Countries</p>
                {countries?.length > 0 &&
                  countries?.map((prod, index) => (
                    <li key={index}>{prod?.name}</li>
                  ))}
              </ul>
            </div>
            <div className="footer-services footer-list ">
              <ul>
                <li>About Poly Tech Talent</li>
                <li>Contact us</li>
              </ul>
            </div>
            <div className="footer-privacy footer-list ">
              <ul>
                <li
                  className="icon-cursor"
                  onClick={() => navigate("/privacy-policy")}
                >
                  Privacy Statement
                </li>
                <li
                  className="icon-cursor"
                  onClick={() => navigate("/term-condition")}
                >
                  Terms and Conditions
                </li>
                <li>GDPR</li>
              </ul>
            </div>
            <div className="footer-address ">
              <img src="/image/footer-logo.png " alt="footer-logo " />
              <p className="head-office ">Head Office</p>
              <p className="office-address ">
                {countryISOCode === COUNTRY_ISO_CODE.CA ? (
                  <>1920 Yonge Street, Toronto, ON M5E 1S7</>
                ) : (
                  <>202/1 Orce Nikolov, First Floor Skopje, Macedonia</>
                )}
              </p>
              <p className="office-mail ">
                <a
                  href={
                    countryISOCode === COUNTRY_ISO_CODE.CA
                      ? "mailto:info@polycareers.com"
                      : "mailto:info@polycareers.com.mk"
                  }
                >
                  {countryISOCode === COUNTRY_ISO_CODE.CA
                    ? "info@polycareers.com"
                    : "info@polycareers.com.mk"}
                </a>
              </p>
            </div>
          </div>
          {/* <div className="footer-divider "></div>
          <div className="footer-rights ">
            <div className="footer-site-development ">
              <p>
                2024 © <strong>WORKBRIDGE.</strong> All rights reserved{" "}
              </p>
            </div>
            <div className="site-map ">
              <ul className="site-list ">
                <li
                  className="icon-cursor"
                  onClick={() => navigate("/privacy-policy")}
                >
                  Privacy Policy
                </li>
                <li>Site Map</li>
                <li>Accessibility</li>
              </ul>
            </div>
          </div> */}
        </div>
        {/* --------------------------------Mobile footer--------------------------- */}
        <div className="space-inner-row footer-mbl">
          <div className="footer-row ">
            <div className="footer-address ">
              <img src="/image/footer-logo.png " alt="footer-logo " />
              <p className="head-office ">Head Office</p>
              {countryISOCode === COUNTRY_ISO_CODE.CA ? (
                <p className="office-address ">
                  1920 Yonge Street, <br /> Toronto, ON M5E 1S7
                </p>
              ) : (
                <p className="office-address ">
                  202/1 Orce Nikolov, First Floor <br /> Skopje, North Macedonia
                </p>
              )}
              <p className="office-mail ">
                <a
                  href={
                    countryISOCode === COUNTRY_ISO_CODE.CA
                      ? "mailto:info@polycareers.com"
                      : "mailto:info@polycareers.com.mk"
                  }
                >
                  {countryISOCode === COUNTRY_ISO_CODE.CA
                    ? "info@polycareers.com"
                    : "info@polycareers.com.mk"}
                </a>
              </p>
            </div>
            {/* <div className="footer-services footer-list ">
              <ul>
                <li
                  className="service-heading icon-cursor"
                  onClick={() => {
                    setOpenServices(!openServices);
                  }}
                >
                  SERVICES{" "}
                  <img
                    className="dropdown-arrow"
                    src="image/footer-arrow.png"
                    alt="footer-arrow"
                  />
                </li>
                {openServices && (
                  <>
                    {" "}
                    <li>Employer of Record</li>
                    <li>Background and Reference Check</li>
                    <li>Asset Management</li>
                    <li>Recruitment Process Outsourcing</li>
                    <li>Global Consulting</li>
                    <li>Global Talent Hub</li>{" "}
                  </>
                )}
              </ul>
            </div> */}
            <div className="footer-countries footer-list ">
              <ul>
                <p
                  className="Countries-heading"
                  onClick={() => {
                    setOpenCountries(!openCountries);
                  }}
                >
                  COUNTRIES{" "}
                  <img
                    className="dropdown-arrow"
                    src="image/footer-arrow.png"
                    alt="footer-arrow"
                  />
                </p>
                {openCountries && (
                  <>
                    {" "}
                    {countries?.length > 0 &&
                      countries?.map((prod, index) => (
                        <li key={index}>{prod?.name}</li>
                      ))}
                  </>
                )}
              </ul>
            </div>

            <div className="footer-privacy footer-list ">
              <ul>
                <li className="icon-cursor">About Poly Tech Talent</li>

                <li>Contact us</li>
              </ul>
            </div>

            <div className="footer-privacy footer-list ">
              <ul>
                <li
                  className="icon-cursor"
                  onClick={() => navigate("/privacy-policy")}
                >
                  Privacy Statement
                </li>
                <li
                  className="icon-cursor"
                  onClick={() => navigate("/term-condition")}
                >
                  Terms and Conditions
                </li>
                <li>GDPR</li>
              </ul>
            </div>
          </div>
          {/* <div className="footer-divider "></div>
          <div className="footer-rights ">
            <div className="footer-site-development ">
              <p>
                2024 © <strong>WORKBRIDGE.</strong> All rights reserved{" "}
              </p>
            </div>
            <div className="site-map ">
              <ul className="site-list ">
                <li
                  className="icon-cursor"
                  onClick={() => navigate("/privacy-policy")}
                >
                  Privacy Policy
                </li>
                <li>Site Map</li>
                <li>Accessibility</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Footer;
