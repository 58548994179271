import api from "../api";

export const handlePreviewResume = (resumeURL) => {
  if (resumeURL !== null) {
    const pdfUrl = resumeURL;
    window.open(pdfUrl, "_blank");
  }
};

export const handlePDFResume = async (resumeId, userID) => {
  try {
    const response = await api.get(
      `/job-board/download-resume-pdf?resume_id=${resumeId}&candidate_id=${userID}`,
      {
        responseType: "blob", // Set responseType to blob
      }
    );

    // Create a URL for the blob
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Create a link element and click it to initiate download
    const a = document.createElement("a");
    a.href = url;
    a.download = "resume.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error downloading PDF:", error);
  }
};

export const handleWordResume = async (resumeId, userID) => {
  try {
    const response = await api.get(
      `/job-board/download-resume-word?resume_id=${resumeId}&candidate_id=${userID}`,
      {
        responseType: "blob", // Set responseType to blob
      }
    );

    // Create a URL for the blob
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Create a link element and click it to initiate download
    const a = document.createElement("a");
    a.href = url;
    a.download = "resume.docx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error downloading Word:", error);
  }
};
