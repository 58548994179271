const getCurrentFormattedDate = (dateString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let date;

  if (dateString === true) {
    date = new Date();
  } else {
    date = new Date(dateString);
  }

  const day = ("0" + date.getDate()).slice(-2); // Ensure two digits for day
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  if (dateString === true) {
    return `( ${day} ${month} )`;
  } else {
    return `${month}-${day}-${year}`;
  }
};

export default getCurrentFormattedDate;
