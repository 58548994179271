const PolicyContent = () => {
  return (
    <>
      <section className="term-condition">
        <div className="main-heading-term">Privacy policy</div>
      </section>
      <section>
        <div className="container container-site">
          <div className="first-para-term">
            At Poly Tech Talent, we are committed to protecting the privacy of
            our users. This Privacy Policy explains how we collect, use, and
            share information about you when you use our website, , and our
            products and services (collectively, the "Services").By using our
            Services, you agree to the collection, use, and sharing of your
            information as described in this Privacy Policy. If you do not agree
            with our policies and practices, do not use our Services.We may
            change our Privacy Policy from time to time. We encourage you to
            review the Privacy Policy whenever you access our Services to stay
            informed about our information practices and the choices available
            to you.
          </div>
          <div className="sub-heading-term">Changes to the Terms</div>
          <div className="first-sub-para-term">
            <div className="bold-text-policy">
              We collect information about you when you use our Services,
              including the following:
            </div>
            <div className="lis-privcay-policy">
              <ul>
                <li>
                  <span>Personal Information:</span> This includes information
                  that can be used to identify you, such as your name, email
                  address, phone number, and physical address. We collect this
                  information when you create an account with us, make a
                  purchase, or otherwise interact with us.
                </li>
                <li>
                  <span>Transaction Information:</span> We collect information
                  about your transactions with us, including the products or
                  services you purchase, and the date, time, and location of
                  your transactions.
                </li>
                <li>
                  <span>Location Information:</span> We may collect information
                  about your location when you use our Services, including
                  through the use of GPS, Bluetooth, or other location-based
                  technologies.
                </li>
                <li>
                  <span>Device Information</span> We collect information about
                  the devices you use to access our Services, including the type
                  of device, device identifier, IP address, and operating
                  system.
                </li>
                <li>
                  <span>Usage Information</span>We collect information about how
                  you use our Services, including the features you access, the
                  time and duration of your use, and the websites and links you
                  click on.
                </li>
                <li>
                  <span>Log Information:</span> We collect log information when
                  you use our Services, including your IP address, browser type,
                  and the pages you visited.
                </li>
              </ul>
            </div>
          </div>
          <div className="sub-heading-term">How We Use Your Information</div>
          <div className="first-sub-para-term">
            <div className="bold-text-policy">
              We use the information we collect about you to provide, maintain,
              and improve our Services, and to personalize your experience.
              Specifically, we use your information for the following purposes:
            </div>
            <div className="lis-privcay-policy-icon">
              <ul>
                <li>To process and fulfill your orders and requests.</li>
                <li>
                  To communicate with you about your account, orders, and
                  requests.
                </li>
                <li>To provide customer support and resolve disputes.</li>
                <li>
                  To personalize your experience and recommend products or
                  services that may be of interest to you.
                </li>
                <li>
                  To measure and analyze the effectiveness of our Services.
                </li>
                <li>To protect the security and integrity of our Services.</li>
              </ul>
            </div>
          </div>
          <div className="sub-heading-term">Sharing Your Information</div>
          <div className="first-sub-para-term">
            <div className="bold-text-policy">
              We do not share your personal information with third parties for
              their own marketing purposes. However, we may share your
              information with third parties in the following circumstances:
            </div>
            <div className="lis-privcay-policy-icon">
              <ul>
                <li>
                  With vendors, service providers, and other partners who
                  perform services on our behalf.
                </li>
                <li>
                  In connection with a merger, sale, or other transfer of
                  assets.
                </li>
                <li>
                  To comply with legal obligations, such as responding to a
                  subpoena or court order.
                </li>
                <li>
                  To protect the rights, property, or safety of Poly Tech
                  Talent, our users, or the public.
                </li>
              </ul>
            </div>
          </div>
          <div className="sub-heading-term">Your Choices</div>
          <div className="first-sub-para-term">
            <div className="bold-text-policy">
              You have the following choices regarding the collection, use, and
              sharing of your information:
            </div>
            <div className="lis-privcay-policy-space-icon">
              <ul>
                <li>
                  <span>Opt-out of marketing communications:</span> You can
                  opt-out of receiving marketing communications from us by
                  following the unsubscribe instructions in the communication or
                  by contacting us at support@polytech.com.mk.
                </li>
                <li>
                  <span>Review and update your personal information:</span> You
                  can review and update your personal information by logging
                  into your account or contacting us at support@polytech.com.mk.
                </li>
              </ul>
            </div>
          </div>
          <div className="sub-heading-term">Data Security and Protection</div>
          <div className="first-sub-para-term">
            We take reasonable measures to protect your personal information
            from unauthorized access, use, or disclosure.
          </div>
          <div className="sub-heading-term">Data Security</div>
          <div className="first-sub-para-term">
            Poly Tech Talent is committed to protecting the privacy and security
            of our users' personal information. We have implemented appropriate
            technical and organizational measures to ensure the security of our
            systems and to protect the personal information we collect from
            unauthorized access, use, or disclosure. However, it is important to
            note that no system can be completely secure and we cannot guarantee
            the absolute security of your personal information. We encourage you
            to use caution when sharing personal information online and to
            regularly review and update your privacy settings on our website.In
            the event of a data breach, we will notify affected users as soon as
            possible and take appropriate steps to protect their personal
            information. If you have any questions or concerns about the
            security of your personal information on our website, please contact
            us at [insert email or contact information.
          </div>
          <div className="sub-heading-term">Cookies</div>
          <div className="first-sub-para-term">
            Poly Tech Talent uses cookies on our website to enhance your user
            experience and to gather information about how our site is being
            used. A cookie is a small file that is stored on your computer or
            mobile device when you visit a website. It allows the website to
            remember your actions and preferences over a period of time, so you
            don't have to keep re-entering them whenever you come back to the
            site or browse from one page to another. We use both session cookies
            and persistent cookies. Session cookies are temporary and are
            deleted when you close your browser. Persistent cookies remain on
            your device until they expire or are deleted by you. You can control
            the use of cookies at the individual browser level. If you choose to
            disable cookies, some features of our website may not function
            properly. We may also use third-party cookies from service providers
            to collect and analyze data about our website traffic and usage.
            This information is used to improve our website and to personalize
            your experience on our site. We do not share this information with
            third parties for their own marketing purposes.
          </div>
          <div className="sub-heading-term">Conclusion</div>
          <div className="first-sub-para-term">
            We are committed to protecting the privacy and security of your
            personal information and to being transparent about our data
            collection and use practices. We encourage you to review this
            Privacy Policy regularly and to contact us with any questions or
            concerns. If you have any questions about our privacy practices or
            would like to make a complaint, please contact us at [insert email
            or contact information]. We will do our best to resolve any issues
            you may have.This Privacy Policy was last updated on Jan 15, 2024.
            We reserve the right to make changes to this policy at any time, so
            please check back periodically for updates. By continuing to use our
            website, you agree to the terms of this Privacy Policy.
          </div>
        </div>
      </section>
    </>
  );
};

export default PolicyContent;
