import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Field } from "formik";
import OutsideClickHandler from "react-outside-click-handler";
import * as Yup from "yup";
import API from "../../../../api";
import PhoneNumber from "../../../Common/PhoneNumber/PhoneNumber";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import APIPvt from "../../../../apiPvt";
import { getCitiesAsync, getCountriesAsync, getStateAsync } from "../../../../redux/slices/AuthSlice";

const CanProfileForm = ({
  loader,
  baseInfo,
  jobTitles,
  tags,
  companyLogo,
  maskedPlaceHolderP,
  maskedPhoneNumberP,
  statesCount,
  editHandler,
  companyLogoModelHandler,
  submitHandler,
}) => {
  const dispatch = useDispatch();
  const { user, resumeVerified } = useSelector((state) => state.auth);


  const [allJobTitles, setAllTobTitles] = useState([]);
  const [selectJobTitles, setSelectJobTitles] = useState([]);
  const [allJobTitlesHide, setAllJobTitlesHide] = useState(true);

  const [allSkillTags, setAllSkillTags] = useState([]);
  const [selectSkillTags, setSelectSkillTags] = useState([]);
  const [allSkillTagsHide, setAllSkillTagsHide] = useState(true);
  const [currencyOption, setCurrencyOption] = useState([]);

  const [selectedImageName, setSelectedImageName] = useState("");
  const [selectedVideoName, setSelectedVideoName] = useState("");

  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [locationHide, setLocationHide] = useState(true);

  const [phoneNumberValue, setPhoneNumberValue] = useState(null);
  const [allWorkPreference, setAllWorkPreference] = useState([]);
  const [selectWorkPreferenceTags, setSelectWorkPreferenceTags] = useState([]);
  const [allWorkPreferenceTagsHide, setAllWorkPreferenceTagsHide] = useState(true);
  const workPreferenceArray = [];
  const [salaryRangeOption, setSalaryRangeOption] = useState([]);

  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [maskedPlaceHolder, setMaskedPlaceHolder] = useState("");

  const onChange = (event) => {
    setPhoneNumberValue(event.target.value);
  };

  const highlightMatchingText = (option, inputValue) => {
    const lowercaseInputValue = inputValue?.toLowerCase();
    const regex = new RegExp(`(${lowercaseInputValue})`, "gi");
    const parts = option.split(regex);

    return (
      <span>
        {parts.map((part, index) =>
          regex.test(part) ? (
            <strong key={index} style={{ color: "#143975" }}>
              {part}
            </strong>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const formik = useFormik({
    initialValues: {
      file: null,
      firstName: "",
      lastName: "",
      country: "",
      countryId: null,
      state: "",
      stateId: null,
      city: "",
      cityId: null,
      email: "",
      phoneNo: "",
      linkedinURL: "",
      aboutSelf: "",
      interested: "",
      resume: "",
      introVideo: null,
      jobTitle: "",
      skillTags: "",
      salaryRange: "year",
      salaryExpectation: "",
      userWorkPreferences: [],
      currency: "",
      currencyId: null,
      workPreference: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z ]+$/, "First name should be an alphabet")
        .required("Please enter first name"),
      lastName: Yup.string()
        .matches(/^[A-Za-z ]+$/, "Last name should be an alphabet")
        .required("Please enter last name"),
      country: Yup.string().required("Please select the country"),
      state:  Yup.string(),
      city:  Yup.string(),
      phoneNo: Yup.string().required("Please enter phone number"),
      files: Yup.mixed()
        .test(
          "fileType",
          "File can be a JPEG or PNG document",
          (value) => !value || ["image/png", "image/jpeg"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 10 MB",
          (value) => !value || value?.size <= 10 * 1024 * 1024
        ),

      introVideo: Yup.mixed()
        .test(
          "fileType",
          "Unsupported file format",
          (value) =>
            !value ||
            [
              "video/mp4",
              "video/webm",
              "video/x-matroska",
              "video/3gpp",
            ].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 25 MB",
          (value) => !value || value?.size <= 25 * 1024 * 1024
        ),
      /*skillTags: Yup.mixed().test(
        "skillTags",
        "Please select a minimum of 5 Skill Tags",
        (value) =>
          !!(Array.isArray(selectSkillTags) && selectSkillTags?.length > 5),
      ),*/
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {

      const { state, city } = data;

      if(stateOptions?.length > 0 && state === "") {
        formik?.setFieldError("state", "Please select state");
        return;
      }
      if(citiesOptions?.length > 0 && city === "") {
        formik?.setFieldError("city", "Please select city");
        return;
      }

      const selectJobTitlesNames = selectJobTitles?.map((item) => item.id);
      const selectSkillTagsNames = selectSkillTags.map((item) => item.id);
      const selectWorkPreferenceNames = selectWorkPreferenceTags.map((item) => item.id);


      // user["currency_id"] = data.currencyId
      submitHandler(data, user, selectJobTitlesNames, selectSkillTagsNames, selectWorkPreferenceNames);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isFileFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFileErrorMessage = (name) => {
    return (
      isFileFieldValid(name) && (
        <p className="file-error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const onSalaryRangeClick = async (name) => {
    console.log(name)
    await formik.setFieldValue("salaryRange", name);
  };
  /*const isSkillTagsFieldValid = () => {
    if ( selectSkillTags?.length < 5){
      formik.errors["skillTags"] = "Please select a minimum of 5 Skill Tags";
    }
    return !!((formik.touched["skillTags"] && formik.errors["skillTags"] && selectSkillTags?.length < 5) || (formik.errors["skillTags"] && selectSkillTags?.length < 5) || (selectSkillTags?.length < 5))
  }

  const getSkillTagsErrorMessage = () => {
    return (
        isSkillTagsFieldValid() && (
            <p className="error-msg">{formik.errors["skillTags"]}</p>
        )
    )
  }*/

  const handleImageChange = async (event) => {
    companyLogoModelHandler(true);
    // const file = event.target.files[0];

    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setSelectedImageName(file.name);
    //     formik.setFieldValue("files", file);
    //   };
    //   reader.readAsDataURL(file);
    // } else {
    //   setSelectedImageName("");
    //   formik.setFieldValue("files", null);
    // }
  };

  const countryHandler = async (item) => {
    const {id, name, currency, masked_phone_number, placeholder, states_count} = item;

    await formik.setFieldValue("country", name);
    await formik.setFieldValue("countryId", id);

    await formik.setFieldValue("state", "");
    await formik.setFieldValue("stateId", null);

    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);

    onCurrencyClick(currency?.id, currency?.title);

    setMaskedPhoneNumber(masked_phone_number);
    setMaskedPlaceHolder(placeholder);
    setLocationHide(true);

    setStateOptions([]);
    setCitiesOptions([]);
    
    if(states_count === 0){
      getCitiesData(id, null);
    }
    else {
      getStatusData(id);
    }
  };

  
  const stateHandler = async (id, name) => {
    await formik.setFieldValue("state", name);
    await formik.setFieldValue("stateId", id);
    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);
    getCitiesData(null, id);
  };

  const cityHandler = async (id, name) => {
    await formik.setFieldValue("city", name);
    await formik.setFieldValue("cityId", id);
  };

  const handleVideoChange = async (event) => {
    console.log("event", event);
    console.log("handleVideoChange");
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedVideoName(file.name);
        console.log(file)
        formik.setFieldValue("introVideo", file);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedVideoName("");
      formik.setFieldValue("introVideo", null);
    }
  };

  const onJobTitileChange = async (value) => {
    await formik.setFieldValue("jobTitle", value);
    getSearchSuggestion(value, "jobTitle");
  };

  const onWorkPreferenceTagsCancel = async (curentSkillTags) => {
    const updatedItems = selectWorkPreferenceTags.filter(
      (item) => item.id !== curentSkillTags?.id
    );
    setSelectWorkPreferenceTags(updatedItems);
  };
  const onJobTitileClick = async (curentJobTitles) => {
    const isIdExists = selectJobTitles?.some(
      (item) => item.id === curentJobTitles?.id
    );
    if (isIdExists) return;
    setSelectJobTitles([...selectJobTitles, curentJobTitles]);
    setAllJobTitlesHide(true);
    await formik.setFieldValue("jobTitle", "");
  };

  const onJobTitileCancel = async (curentJobTitles) => {
    const updatedItems = selectJobTitles.filter(
      (item) => item.id !== curentJobTitles?.id
    );
    setSelectJobTitles(updatedItems);
  };

  const onSkillTagsChange = async (value) => {
    await formik.setFieldValue("skillTags", value);
    getSearchSuggestion(value, "jobTag");
  };

  const onSkillTagsClick = async (curentSkillTags) => {
    const isIdExists = selectSkillTags?.some(
      (item) => item.id === curentSkillTags?.id
    );
    if (isIdExists) return;
    setSelectSkillTags([...selectSkillTags, curentSkillTags]);
    setAllSkillTagsHide(true);
    await formik.setFieldValue("skillTags", "");
  };

  const getJobDropDownData = () => {
    // if (user !== null) {}
    const currencies = [{
      id: 1,
      country
        :
        null,
      flag_link
        :
        "https://demo.workbridgetalent.ca/uploads/flags/macedonia.png",
      symbol
        :
        "Ден",
      title
        :
        "MKD"
    }]
    const salaryRange = [
      {
        label
          :
          "Year",
        page
          :
          "",
        value
          :
          "year"
      },
      {
        label
          :
          "Month",
        page
          :
          "",
        value
          :
          "month"
      },
      {
        label
          :
          "Hour",
        page
          :
          "",
        value
          :
          "hour"
      }
    ]



    setSalaryRangeOption(salaryRange);

  }

  const onWorkPreferenceTagsClick = async (curentSkillTags) => {
    const isIdExists = selectWorkPreferenceTags?.some(
      (item) => item.id === curentSkillTags?.id
    );
    if (isIdExists) return;
    workPreferenceArray.push([...selectWorkPreferenceTags, curentSkillTags])
    console.log(workPreferenceArray)
    setSelectWorkPreferenceTags([...selectWorkPreferenceTags, curentSkillTags]);
    setAllWorkPreferenceTagsHide(true);
    await formik.setFieldValue("userWorkPreferences", "");
  };

  const onSkillTagsCancel = async (curentSkillTags) => {
    const updatedItems = selectSkillTags.filter(
      (item) => item.id !== curentSkillTags?.id
    );
    setSelectSkillTags(updatedItems);
  };

  const onCurrencyClick = async (id, name) => {
    await formik.setFieldValue("currencyId", id);
    await formik.setFieldValue("currency", name);
  };

  const getWorkPreferences = async () => {
    const workPreferenceList = [{ id: 1, name: "Remote" }, { id: 2, name: "Onsite" }, { id: 3, name: "Hybrid" }, { id: 4, name: "All" }]

    setAllWorkPreference(workPreferenceList)
  }

  const getStatusData = async (id) => {
    dispatch(
      getStateAsync({
        id: id
      })
    )
      .then((response) => {
        const res = response?.payload;
        setStateOptions(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCitiesData = async (id1, id2) => {
    dispatch(
      getCitiesAsync({
        country_id: id1,
        state_id: id2
      })
    )
      .then((response) => {
        const res = response?.payload;
        setCitiesOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearchSuggestion = async (searchValue, searchType) => {
    try {
      let params = ``;
      if (searchValue !== "") {
        params = `?query_key=${searchValue}&type=${searchType}`;
      }
      await API.get(`/job-board/get-search-suggestions${params}`)
        .then((response) => {
          if (response?.status === 200) {
            let res = response?.data;
            if (searchType === "jobTitle") {
              if (res?.job_titles?.length > 0) {
                setAllTobTitles(res?.job_titles);
                setAllJobTitlesHide(false);
              } else {
                setAllTobTitles([]);
                setAllJobTitlesHide(false);
              }
            }

            if (searchType === "jobTag") {
              if (res?.job_tags?.length > 0) {
                setAllSkillTags(res?.job_tags);
                setAllSkillTagsHide(false);
              } else {
                setAllSkillTags([]);
                setAllSkillTagsHide(false);
              }
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateCandidateProfile = (data) => {
    console.log(data)
    // APIPvt.post("/auth/update-candidate-profile",)
  }

  useEffect(() => {
    async function getCountries() {
      try {
        dispatch(
          getCountriesAsync({ country_id: null })
        )
          .then((response) => {
            const res = response?.payload;
              setCountryOptions(res);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
    getCountries();
  }, [baseInfo]);

  useEffect(() => {
    if (companyLogo !== null) {
      formik.setFieldValue("files", companyLogo);
      setSelectedImageName(companyLogo?.name);
    }
  }, [companyLogo]);

  useEffect(() => {


    if (baseInfo?.workPreferences?.length > 0) {
      setSelectWorkPreferenceTags(baseInfo?.workPreferences)
    }

    if(baseInfo?.countryId){

      if(statesCount === 0)
      getCitiesData(baseInfo?.countryId, null);
    else {
      getStatusData(baseInfo?.countryId);
      getCitiesData(null, baseInfo?.countryId);
    }
    }
    setSelectJobTitles(jobTitles);
    setSelectSkillTags(tags);
    getWorkPreferences();
    getJobDropDownData();

    setMaskedPlaceHolder(maskedPlaceHolderP);
    setMaskedPhoneNumber(maskedPhoneNumberP);

    formik.setValues({
      firstName: baseInfo?.first_name || "",
      lastName: baseInfo?.last_name || "",
      countryId: baseInfo?.countryId || null,
      country: baseInfo?.country || "",
      cityId: baseInfo?.cityId || null,
      city: baseInfo?.city || "",
      stateId: baseInfo?.stateId || null,
      state: baseInfo?.state || "",
      email: baseInfo?.email || "",
      phoneNo: baseInfo?.phone || "",
      linkedinURL: baseInfo?.linkedin || "",
      aboutSelf: baseInfo?.bio || "",
      interested: baseInfo?.my_interests || "",
      salaryExpectation: baseInfo?.expected_salary || 0,
      userWorkPreferences: baseInfo?.workPreferences,
      currency: baseInfo?.currency || "",
      currencyId: baseInfo?.currencyId || null,
      resume: baseInfo?.file,
      salaryRange: baseInfo?.salary_range,
    });
  }, []);

  // console.log("formik errors =======>", formik.errors);

  return (
    <div className="my-profile-row">
      <div className="candidtae-profile-image new-profile-image-st profile-pic-desktop">
        <img
          src={
            companyLogo !== null
              ? URL?.createObjectURL(companyLogo)
              : baseInfo?.avatar === null || baseInfo?.avatar === undefined
                ? "/image/avtar.jpg"
                : baseInfo?.avatar
          }
          alt=""
          className="profile-opac"
        />
        <div className="addProfile-icon icon-cursor">
          <img src="/image/addProfile.png" alt="" onClick={handleImageChange} />
        </div>
      </div>
      <span className="profile-img-mbl">
        <div className="candidtae-profile-image new-profile-image-st">
          <img
            src={
              companyLogo !== null
                ? URL?.createObjectURL(companyLogo)
                : baseInfo?.avatar === null || baseInfo?.avatar === undefined
                  ? "/image/avtar.jpg"
                  : baseInfo?.avatar
            }
            alt=""
            className="profile-opac"
          />
          <div className="addProfile-icon icon-cursor">
            <img
              src="/image/addProfile.png"
              alt=""
              onClick={handleImageChange}
            />
          </div>
        </div>
      </span>
      <div className="candidate-information with-accordion">
        <div className="candidate-about-me">
          <div className="candidate-profile-form">
            <h5 className="text-primary">About Me</h5>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-block-width">
                <div className={`candidate-name-field-profile profile-field-f`}>
                  <div
                    class=""
                    className={`first-name-profile ${isFormFieldValid("firstName") && "error-input"
                      }`}
                  >
                    <input
                      className="form-font-f"
                      type="text"
                      id="candidate-name-field"
                      placeholder="First Name"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("firstName")}
                  </div>
                  <div
                    className={`first-name-profile ${isFormFieldValid("lastName") && "error-input"
                      }`}
                  >
                    <input
                      className="form-font-f"
                      type="text"
                      id="candidate-name-field"
                      placeholder="Last Name"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("lastName")}
                  </div>
                </div>

                <div
                  className={`profile-field-f signup-passowrd-job location-profile-list ${isFormFieldValid("country") && "error-input"
                    }`}
                >
                  <div className="industry-feild-frm bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-location.png"
                        className="location-icon"
                        alt=""
                      />
                    </div>
                    <div
                      id="dropdown-boxId-2"
                      className="company-form-dropdownBox-profile"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => setLocationHide(!locationHide)}
                    >
                      {" "}
                      {formik?.values?.country === ""
                        ? "location"
                        : formik?.values?.country}
                    </div>
                    <div className="icon-hide">
                      {isFormFieldValid("country") ? (
                        <img src="/image/warning.png" alt="" />
                      ) : (
                        <img src="/image/form-d-f.png" alt="" />
                      )}
                    </div>
                    {countryOptions?.length > 0 && (
                      <div
                        id="company-dropList-t"
                        className={`dropdown-form-f company-drop-list-t ${locationHide && "job-dropdown-hide"
                          }`}
                      >
                        <ul>
                          {countryOptions?.map((j, i) => (
                            <li
                              className="icon-cursor-f"
                              key={i}
                              onClick={() => {
                                countryHandler(j);
                              }}
                            >
                              <img
                                src="/image/profile-location.png"
                                className="location-icon-drop"
                                alt=""
                              />{" "}
                              {j?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  {getFormErrorMessage("country")}
                </div>

                <div className="candidate-username-signup-form">
                <div
                  className={`profile-field-f signup-passowrd-job location-profile-list first-name ${isFormFieldValid("state") && "error-input"
                  ||
                  stateOptions?.length === 0 && "disable-field"
                    }`}
                >
                  <div className="industry-feild-frm bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-location.png"
                        className="location-icon"
                        alt=""
                      />
                    </div>
                    <div
                      id="dropdown-boxId-2"
                      className="company-form-dropdownBox-profile"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {formik?.values?.state === ""
                        ? "Province/State"
                        : formik?.values?.state}
                    </div>
                    <div className="icon-hide-2">
                      {isFormFieldValid("state") ? (
                        <img src="/image/warning.png" alt="" />
                      ) : (
                        <img src="/image/form-d-f.png" alt="" />
                      )}
                    </div>
                    {stateOptions?.length > 0 && (
                      <div
                        id="company-dropList-t"
                        className={`dropdown-form-f company-drop-list-t 
                          }`}
                      >
                        <ul>
                          {stateOptions?.map((j, i) => (
                            <li
                              className="icon-cursor-f"
                              key={i}
                              onClick={() => stateHandler(j?.id, j?.name)}
                            >
                              <img
                                src="/image/profile-location.png"
                                className="location-icon-drop"
                                alt=""
                              />{" "}
                              {j?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  {getFormErrorMessage("state")}
                </div>

                <div
                  className={`profile-field-f signup-passowrd-job location-profile-list first-name ${isFormFieldValid("city") && "error-input"
                  ||
                  citiesOptions?.length === 0 && "disable-field"
                    }`}
                >
                  <div className="industry-feild-frm bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-location.png"
                        className="location-icon"
                        alt=""
                      />
                    </div>
                    <div
                      id="dropdown-boxId-2"
                      className="company-form-dropdownBox-profile"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {formik?.values?.city === ""
                        ? "City"
                        : formik?.values?.city}
                    </div>
                    <div className="icon-hide-2">
                      {isFormFieldValid("city") ? (
                        <img src="/image/warning.png" alt="" />
                      ) : (
                        <img src="/image/form-d-f.png" alt="" />
                      )}
                    </div>
                    {countryOptions?.length > 0 && (
                      <div
                        id="company-dropList-t"
                        className={`dropdown-form-f company-drop-list-t`}
                      >
                        <ul>
                          {citiesOptions?.map((j, i) => (
                            <li
                              className="icon-cursor-f"
                              key={i}
                              onClick={() => cityHandler(j?.id, j?.name)}
                            >
                              <img
                                src="/image/profile-location.png"
                                className="location-icon-drop"
                                alt=""
                              />{" "}
                              {j?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  {getFormErrorMessage("city")}
                </div>

                </div>
                <div className={`email-candidate-profile-f profile-field-f`}>
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img src="/image/profile-email.png" alt="" />
                    </div>
                    <input
                      className="form-font-f"
                      id="profile-email-candidate"
                      placeholder="E-Mail"
                      name="email"
                      value={formik.values.email}
                    />
                    <div className="icon-hide">
                      <img src="/image/locked-field.png" alt="" />
                      <span className="tooltiptext-profile">
                        Contact Admin Support
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className={`profile-field-f ${isFormFieldValid("phoneNo") && "error-input"
                    }`}
                >
                  <div
                    className={`country-code-profile phone-f-profile  ${isFormFieldValid("phoneNo") && "error-input"
                      }`}
                  >
                    <div className="location-f-icon bg-pass-f">
                      <div className="password-icon-logo">
                        <img src="/image/profile-phone.png" alt="" className="profile-icon" />
                      </div>
                      <PhoneNumber
                        className="form-font-f"
                        id="profile-call"
                        name="phoneNo"
                        value={formik.values.phoneNo}
                        maskedPlaceHolder={maskedPlaceHolder}
                        maskedPhoneNumber={maskedPhoneNumber} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="icon-hide">
                        {isFormFieldValid("phoneNo") && (
                          <img src="/image/warning.png" alt="" />
                        )}
                      </div>
                    </div>
                  </div>
                  {getFormErrorMessage("phoneNo")}
                </div>
                <div className="candidate-link-profile profile-field-f">
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img src="/image/profile-linked.png" alt="" className="profile-icon" />
                    </div>
                    <input
                      className="form-font-f"
                      type="text"
                      id="social-link"
                      placeholder="Enter Your LinkedIn Profile Here"
                      name="linkedinURL"
                      value={formik.values.linkedinURL}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="form-block-width">
                <div
                  className={`accordion-candidate-prfile`}
                  id="myAccordionCandidateCandidate"
                >
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOne">
                      <button
                        type="button"
                        className="accordion-button collapsed p-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Tell us about yourself
                        <i className="plus-icon">
                          <img src='/image/plus.png' alt='plus-icon' />
                        </i>
                      </button>
                    </div>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#myAccordionCandidate"
                    >
                      <div className="card-body p-0">
                        <textarea
                          className="form-font-f"
                          id="candidate-profile-tabs"
                          cols="30"
                          rows="10"
                          name="aboutSelf"
                          value={formik.values.aboutSelf}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <p className="candidate-profile-tabs-msg">
                          100 words limit
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        className="accordion-button collapsed p-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Things I like to do.
                        <i className="plus-icon">
                          <img src='/image/plus.png' alt='plus-icon' />
                        </i>
                      </button>
                    </div>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#myAccordionCandidate"
                    >
                      <div className="card-body p-0">
                        <textarea
                          className="form-font-f"
                          id="candidate-profile-tabs"
                          cols="30"
                          rows="10"
                          name="interested"
                          value={formik.values.interested}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <p className="candidate-profile-tabs-msg">
                          100 words limit
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingThree">
                      <button
                        type="button"
                        className="accordion-button collapsed p-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                      >
                        Short Intro video
                        <i className="plus-icon">
                          <img src='/image/plus.png' alt='plus-icon' />
                        </i>
                      </button>
                    </div>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#myAccordionCandidate"
                    >
                      <div className="card-body p-0">
                        <div className="upload-intro-vedio">
                          <label id="candidate-img">
                            <img src="/image/upload-1.png" alt="pic" />
                            <input
                              id="file-input-2"
                              type="file"
                              name="introVideo"
                              onClick={(e) => handleVideoChange(e)}
                              onChange={(e) => handleVideoChange(e)}
                            />
                            <p className="candidate-logo-file">Upload here</p>
                            <p className="file-size-candidate">Max: 25MB</p>
                            <p className="file-size-candidate">
                              Format: .mp4, .webm, .mkv, .3gp{" "}
                            </p>
                            {selectedVideoName}
                            {getFileErrorMessage("introVideo")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="form-block-width">
                {/* <div className="preferences-row">
                  <h5>Preferences</h5>
                </div>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setAllSkillTagsHide(true);
                    setAllWorkPreferenceTagsHide(true);
                  }}
                >
                  <div className="need-to-key">
                    <div className="signup-salary f-feild cand-form">
                      <label for="fsalary">Minimum Salary Expectation</label>
                      <br />
                      <div className="salary-range-start-ends position-relative">
                        <div className="row">
                          <div className="col-md-9 col-8">
                            <div className={`merge-feild-1 max-w-100 ${isFormFieldValid("salaryStart") && "error-input"}`}>
                              <div className={`salary-f-job ${isFormFieldValid("currency") && "error-input"}`}>
                                <div className="salary-start">
                                  <div
                                    id="fsalary-country"
                                    className="company-form-dropdownBox-f"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {formik?.values?.currencyId
                                      ? currencyOption.filter((val) => val?.id === formik?.values?.currencyId)[0]?.title
                                      : "MKD"}
                                  </div>

                                  <div className="list-search-dropdown-country-job">

                                    <ul className="list-job-title-country">
                                      {currencyOption.map((prod) => (
                                        <li
                                          onClick={() =>
                                            onCurrencyClick(
                                              prod?.id,
                                              prod?.title
                                            )
                                          }
                                        >
                                          <img
                                            src={
                                              prod?.flag_link
                                                ? prod?.flag_link
                                                : "/image/CA.png"
                                            }
                                            alt=""
                                          />{" "}
                                          {`${prod?.title}`}


                                        </li>
                                      ))}
                                    </ul>

                                  </div>
                                </div>
                              </div>
                              <input
                                className="form-font-f"
                                type="text"
                                name="salaryExpectation"
                                id="salary-start-range"
                                value={formik?.values?.salaryExpectation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 col-4">
                            <div className="merge-feild-4 max-w-100">
                              <div
                                className={`${isFormFieldValid("salaryRange") && "error-input"
                                  }`}
                              >
                                <div className="Year">
                                  <div
                                    id="fsalary-year"
                                    className="company-form-dropdownBox-f"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {formik?.values?.salaryRange
                                      ? capitalizeFirstLetter(
                                        formik?.values?.salaryRange
                                      )
                                      : "Year"}
                                  </div>

                                  {salaryRangeOption?.length > 0 && (
                                    <div className="candidate-search-dropdown-year-job">
                                      <ul className="candidate-job-title-year-job">
                                        {salaryRangeOption.map((prod) => (
                                          <li
                                            onClick={() =>
                                              onSalaryRangeClick(prod?.value)
                                            }
                                          >
                                            {" "}
                                            {`${capitalizeFirstLetter(
                                              prod?.value
                                            )}`}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {getFormErrorMessage("salaryStart")
                        ? getFormErrorMessage("salaryStart")
                        : getFormErrorMessage("salaryEnd")}
                    </div>

                    <div className={`signup-skill-tags f-feild tags-tittles`}>
                      <label for="fjobtitles">Work Preference</label>
                      <input
                        className="form-font-f"
                        type="text"
                        name="skillTags"
                        value={formik.values.workPreference}
                        onFocus={() => setAllWorkPreferenceTagsHide(false)}
                        placeholder="Choose Work Preference"
                      />

                      {!allWorkPreferenceTagsHide ? (
                        <div
                          className={`job-tags-titles-dropdown`}
                        >
                          <ul className="dorpdown-list-location">
                            {allWorkPreference.map((j, i) => (
                              <li
                                key={i}
                                onClick={() => {
                                  onWorkPreferenceTagsClick(j);
                                }}
                              >
                                {" "}
                                {j?.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : <></>}
                      {selectWorkPreferenceTags?.length > 0 && (
                        <ul class="tags-titles-list">
                          {selectWorkPreferenceTags.map((j, i) => (
                            <li key={i}>
                              {j?.name}
                              <span onClick={() => onWorkPreferenceTagsCancel(j)}>
                                <img
                                  src="../image/cross.png"
                                  alt=""
                                  onClick={() => {

                                  }}
                                />
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </OutsideClickHandler>
                <div className="preferences-fields-profile">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setAllJobTitlesHide(true);
                    }}
                  >
                    <div className="signup-job-titles f-feild tags-tittles">
                      <label for="fjobtitles">Job Titles I’m interested in</label>
                      <input
                        className="form-font-f"
                        type="text"
                        name="jobTitle"
                        value={formik.values.jobTitle}
                        onChange={(e) => onJobTitileChange(e.currentTarget.value)}
                        placeholder="Job Titles I’m interested in"
                      />
                      {allJobTitles?.length > 0 && (
                        <div
                          className={`job-tags-titles-dropdown ${allJobTitlesHide && "job-dropdown-hide"
                            }`}
                        >
                          <ul className="dorpdown-list-location">
                            {allJobTitles.map((j, i) => (
                              <li
                                key={i}
                                onClick={() => {
                                  onJobTitileClick(j);
                                }}
                              >
                                {formik?.values?.jobTitle?.length > 0 ? (
                                  <>
                                    {highlightMatchingText(
                                      j?.name,
                                      formik?.values?.jobTitle
                                    )}
                                  </>
                                ) : (
                                  j?.name
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {selectJobTitles?.length > 0 && (
                        <ul class="tags-titles-list">
                          {selectJobTitles.map((j, i) => (
                            <li key={i}>
                              {j?.name}
                              <span onClick={() => onJobTitileCancel(j)}>
                                <img src="../image/cross.png" alt="" />
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </OutsideClickHandler>
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setAllSkillTagsHide(true);
                    }}
                  >
                    <div className={`signup-skill-tags f-feild tags-tittles`}>
                      <label for="fjobtitles">Tech Tags</label>
                      <input
                        className="form-font-f"
                        type="text"
                        name="skillTags"
                        value={formik.values.skillTags}
                        onChange={(e) => {
                          onSkillTagsChange(e.currentTarget.value);
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        placeholder="Tech Tags"
                      />

                      {allSkillTags?.length > 0 && (
                        <div
                          className={`job-tags-titles-dropdown ${allSkillTagsHide && "job-dropdown-hide"
                            }`}
                        >
                          <ul className="dorpdown-list-location">
                            {allSkillTags.map((j, i) => (
                              <li
                                key={i}
                                onClick={() => {
                                  onSkillTagsClick(j);
                                }}
                              >
                                {" "}
                                {j?.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {selectSkillTags?.length > 0 && (
                        <ul class="tags-titles-list">
                          {selectSkillTags.map((j, i) => (
                            <li key={i}>
                              {j?.name}
                              <span onClick={() => onSkillTagsCancel(j)}>
                                <img src="../image/cross.png" alt="" />
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </OutsideClickHandler>
                </div> */}

                <div className="row m-0">
                  <div className="col-md-6 order-md-0 order-1">
                    <button
                      className="btn btn-black w-100 btn-lg"
                      onClick={() => editHandler(false)}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-md-6 order-md-1 order-0">
                    <button type="submit" className="btn btn-outline-black w-100 btn-lg mb-md-0 mb-3">
                      {loader ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanProfileForm;
