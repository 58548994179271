import { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import OutsideClickHandler from "react-outside-click-handler";
import "./FeedBackModal.css";
import APIPvtDB from "../../../apiPvtD";
import { toast } from "react-toastify";

const FeedBackModal = ({ canDetail, modelHandler, defaultValues = "Don’t like the UI" }) => {
  const [checked, setChecked] = useState({});
  const [feedbackText, setFeedbackText] = useState("");
  const handleCheckboxChange = (event, checkboxValue, id) => {

    if (!event.target.checked) {
      delete checked[checkboxValue];

      const textArray = feedbackText.split(", ")

      textArray.splice(textArray.findIndex((value) => value === checkboxValue), 1)

      setFeedbackText(textArray.join(", "));

    }
    else {
      setFeedbackText((state) => {
        if (Object.keys(checked).length >= 1 || Object.keys(checked).length === 0) {
          return `${state ? `${state},` : ''} ${checkboxValue}`
        }
      })
      setChecked({ ...checked, [checkboxValue]: event.target.checked });
    }
  }

  console.log(canDetail);

  const handleSubmit = (e) => {
    e.preventDefault();
    const feedbackForm = new FormData();
    feedbackForm.append("application_id", canDetail?.application_id);
    feedbackForm.append("message", feedbackText);
    APIPvtDB.post(`/candidate-feedback/thumbs-down`, feedbackForm).then(val => {
      modelHandler(false)
      if (val?.data.success === true) {
        toast.success(val?.data.message, {
          autoClose: 2000,
          closeOnClick: false,
        });
      }
      else {
        toast.error(val?.data.message, {
          autoClose: 2000,
          closeOnClick: false,
        })
      }

    })

    // feedbackText.length === 0 ? console.log("Pehle kuch likh tou chutiyaa!") : console.log("Mein submit hogayuab hoooonnnnnnnnnnnnnnnn" + e.target.value);

  }


  return (
    <section className="model-popup model-popup-2">
      <div className="upload-resume-model-popup-bg">
        <OutsideClickHandler
          onOutsideClick={() => {
            modelHandler(false);
          }}
        >
          <div className="popup-resume-content fd-content p-3">
            <div
              className="close-resmodle-file"
              onClick={() => modelHandler(false)}
            >
              <img src="/image/cut-sm.png " alt=" " />
            </div>
            <div className="send-correct-resume mb-2">
              <h3>Feedback</h3>
            </div>
            <p className="tt">
              Please provide detail to help us improve our analysis
            </p>
            <Form onSubmit={handleSubmit}>
              <div className="fd-options mb-3">
                {
                  defaultValues?.map((checkbox) =>
                    <Form.Check key={checkbox.id}
                      inline
                      label={checkbox.value}
                      name="group1"
                      type='checkbox'
                      id={`checkbox-${checkbox.id}`}
                      onChange={(event) => handleCheckboxChange(event, checkbox.value, checkbox.id)} />
                  )
                }

              </div>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control required onChange={(e) => setFeedbackText(e.target.value)} value={feedbackText} as="textarea" rows={4} placeholder="If you have any feedback, please type it in here..." />
              </Form.Group>
              <button type="submit" className="btn btn-black w-100">Submit Feedback</button>
            </Form>
          </div>
        </OutsideClickHandler>
      </div>
    </section>
  );
};

export default FeedBackModal;
