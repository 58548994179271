import React from "react";
import SignUpMain from "../../../components/Auth/SignUp";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";

const SignUp = () => {
  return (
    <>
      <SignUpMain />
      <WorldConnect />
    </>
  );
};

export default SignUp;
