import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";
import APIPvtDB from "../../apiPvtD";

const initialState = {
  candidateId: null,
  jobId: null,
  comments: [],
};

const commentModalSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    setJobId: (state, action) => {
      state.jobId = action.payload;
    },
    setCandidateId: (state, action) => {
      state.candidateId = action.payload;
    },
    addComment: (state, action) => {
      state.comments.push(action.payload);
    },
    emptyCommentsModalData: (state, action) => {
      state.comments = [];
      state.candidateId = null;
      state.jobId = null;
    },
  },
});

export const {
  setComments,
  setJobId,
  setCandidateId,
  addComment,
  emptyCommentsModalData,
} = commentModalSlice.actions;

export default commentModalSlice.reducer;

export const fetchJobCommentsAsync = createAsyncThunk(
  "comment/fetchJobComments",
  async ({ jobId, candidateId, objectType = "job" }) => {
    const formData = new FormData();
    formData.append("candidate_id", candidateId);
    formData.append("object_id", jobId);
    formData.append("object_type", objectType);

    try {
      const response = await API.post("/get-candidate-comments", formData);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      }
      throw new Error("Unexpected response status");
    } catch (error) {
      throw error;
    }
  }
);

export const addJobCommentAsync = createAsyncThunk(
  "comment/addJobComment",
  async ({ userId, jobId, candidateId, commentText, objectType = "job" }) => {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("candidate_id", candidateId);
    formData.append("object_id", jobId);
    formData.append("object_type", objectType);
    formData.append("job_id", jobId);
    formData.append("comments", commentText);

    try {
      const response = await APIPvtDB.post("/add-candidate-comment", formData);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      }
      throw new Error("Unexpected response status");
    } catch (error) {
      throw error;
    }
  }
);

export const removeJobCommentAsync = createAsyncThunk(
  "comment/removeJobComment",
  async ({ commentId, candidateId, jobId, objectType = "job" }) => {
    const formData = new FormData();
    formData.append("comment_id", commentId);
    formData.append("candidate_id", candidateId);
    formData.append("object_id", jobId);
    formData.append("object_type", objectType);
    try {
      const response = await APIPvtDB.post("/remove-comment", formData);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      }
      throw new Error("Unexpected response status");
    } catch (error) {
      throw error;
    }
  }
);

export const editJobCommentsAsync = createAsyncThunk(
  "comment/editJobComments",
  async ({ editComment, candidateId }) => {
    const formData = new FormData();
    formData.append("comment_id", candidateId);
    formData.append("comments", editComment);

    try {
      const response = await APIPvtDB.post("/update-candidate-comment", formData);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      }
      throw new Error("Unexpected response status");
    } catch (error) {
      throw error;
    }
  }
);
