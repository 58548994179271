import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EMP_CAND_LIST } from "../../constants";
import API from "../../api";

const initialState = {
  listingDropDown: { id: null, name: "", value: EMP_CAND_LIST.ALL },
};
const candidateListingSlice = createSlice({
  name: "candidateListing",
  initialState,
  reducers: {
    setCandidateListingDropdown: (state, action) => {
      state.listingDropDown = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCandidateListingDropdown } = candidateListingSlice.actions;

export default candidateListingSlice.reducer;

// asynchronous function with createAsyncThunk

export const getCandidateMyListAsync = createAsyncThunk(
  "public/getCandidateMyList",
  async ({ userId, jobId }) => {
    try {
      const response = await API.get(`/job-board/get-my-lists/${userId}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteCandidateFromListAsync = createAsyncThunk(
  "candidate/deleteCandidateFromList",
  async ({ listId, candidateId }) => {
    const formData = new FormData();
    formData.append("list_id", listId);
    formData.append("candidate_id", candidateId);

    try {
      const response = await API.post(`/job-board/remove-candidate-from-list`, 
        formData,
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);


export const deleteCandidateFromAllListAsync = createAsyncThunk(
  "candidate/deleteCandidateFromAllList",
  async ({ userId, candidateId }) => {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("candidate_id", candidateId);

    try {
      const response = await API.post(`/job-board/remove-candidate-from-all-list`, 
        formData,
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);