import { RESUME_STATUS } from "../constants";

export const getProgressBarWidth = (status) => {
  switch (status) {
    case RESUME_STATUS.PENDING:
      return "60%";
    case RESUME_STATUS.COMPLETED:
      return "100%";
    case RESUME_STATUS.FAILED:
      return "0%";
    default:
      return "0%";
  }
};

export const getProgressBarColorClass = (status) => {
  switch (status) {
    case RESUME_STATUS.PENDING:
      return "bg-inter";
    case RESUME_STATUS.COMPLETED:
      return "bg-ad";
    case RESUME_STATUS.FAILED:
      return "bg-lower";
    default:
      return "bg-lower";
  }
};

export const getProgressTxtColor = (status) => {
  switch (status) {
    case RESUME_STATUS.PENDING:
      return "color-inter";
    case RESUME_STATUS.COMPLETED:
      return "color-ad";
    case RESUME_STATUS.FAILED:
      return "color-lower";
    default:
      return "color-lower";
  }
};
