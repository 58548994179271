import React, { useEffect } from "react";
import { JOB_POST_DECISION, JOB_POST_FIELDS_AI } from "../../../../constants";
import { useSelector } from "react-redux";
import { async } from "q";

const RecommendedSection = ({
  data,
  field,
  promptText,
  fieldNewValue,
  onRadioButtonChange,
  radioFieldName,
  regenerateBtnClickHandler,
  updateBtnHandler,
  generateJobSectionAI,
  setFieldPrompt,
  clearRecommendArray,
}) => {
  const { jobDecision } = useSelector((state) => state.jobDecision);
  const getFieldNameAI = (field) => {
    switch (field) {
      case "JobDes":
        return JOB_POST_FIELDS_AI.JOB_SUMMARY;
      case "rules":
        return JOB_POST_FIELDS_AI.ROLES_AND_RESPONSIBILITIES;
      case "skill":
        return JOB_POST_FIELDS_AI.SKILLS;
      case "mustSkill":
        return JOB_POST_FIELDS_AI.MUST_HAVE;
      case "niceToHave":
        return JOB_POST_FIELDS_AI.NICE_TO_HAVE;
      case "benefits":
        return JOB_POST_FIELDS_AI.BENEFITS;
      case "workingHours":
        return JOB_POST_FIELDS_AI.WORKING_HOURS;
      case "interviewPro":
        return JOB_POST_FIELDS_AI.INTERVIEW_PROCESS;
      case "recNotes":
        return JOB_POST_FIELDS_AI.RECRUITER_NOTES;

      default:
        break;
    }
  };

  if (jobDecision !== JOB_POST_DECISION.ZOE) {
    return;
  }

  return (
    <div className="recommended-feild">
      {/* <label htmlFor="flink">Recommended</label> */}
      <br />
      {data?.length > 0 &&
        data?.map((record) => (
          <div className="list-box-post">
            <div className="list-radio-post">
              <div className="radio-button-container">
                <input
                  type="radio"
                  name={radioFieldName}
                  value={record?.data}
                  id="clr-btn"
                  onChange={(e) => onRadioButtonChange(e, field)}
                />
              </div>
              <div className="count-span">
                <span>
                  {record?.upper} / {record?.lower}
                </span>
              </div>
              <div
                className="post-line-content"
                dangerouslySetInnerHTML={{ __html: record?.data }}
              ></div>
            </div>
          </div>
        ))}
      {/* Recommended Section End */}
      <div className="list-box-post-form">
        {data?.length > 0 && data.length < 3 && (
          <>
            <div className="re-gen-btn">
              <button
                className="clr-reg-btn"
                disabled={
                  promptText === undefined ||
                  promptText === null ||
                  promptText === ""
                }
                onClick={async () =>
                  await generateJobSectionAI(
                    getFieldNameAI(field),
                    field,
                    promptText
                  )
                }
              >
                Regenerate
                <img src="/image/regen-img.png" alt="" />
              </button>
            </div>
          </>
        )}
        <div className="user-add-data">
          {data?.length < 3 && (
            <div className="type-user-f">
              <input
                type="text"
                id="chat-cv-job"
                value={promptText}
                onChange={(e) => setFieldPrompt(field, e.target.value)}
              />
              <button
                type="submit"
                disabled={
                  promptText === undefined ||
                  promptText === null ||
                  promptText === ""
                }
                className="send-chat-j"
                onClick={async () =>
                  await generateJobSectionAI(
                    getFieldNameAI(field),
                    field,
                    promptText
                  )
                }
              >
                {promptText !== "" ? (
                  <img src="/image/send-icon.png" alt="" />
                ) : (
                  <img src="/image/chat-post-job.png" alt="" />
                )}
              </button>
            </div>
          )}
          <div
            className="post-type-content-btn"
            onClick={async () => {
              await clearRecommendArray(field);
              await generateJobSectionAI(
                getFieldNameAI(field),
                field,
                promptText
              );
            }}
          >
            {data?.length === 3 && (
              <button className="clr-j-btn">Clear & Regenerate</button>
            )}
            {fieldNewValue !== "" && (
              <button
                className="update-j-btn"
                onClick={async () => {
                  await updateBtnHandler(field, fieldNewValue);
                }}
              >
                Update
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendedSection;
