import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveSideBar } from "../../../redux/slices/AdminDashboardSlice";
import AdminCreateDepartment from "./CreateDepartment";
import AdminCreateRole from "./CreateRole";
import AdminCreateUser from "./CreateUser";

const AdminSystemUserMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeSideBar, formMode } = useSelector(
    (state) => state.adminDashboard
  );

  const navHandler = () => {
    if (activeSideBar?.value === "Create Department") {
      dispatch(
        setActiveSideBar({
          id: "23",
          name: "Department",
          value: "Department",
        })
      );
      navigate("/admin-department");
    } else if (activeSideBar?.value === "Create Role") {

      if (formMode === "add") {
        dispatch(
          setActiveSideBar({
            id: "2",
            name: "Department",
            value: "Create Department",
          })
        );
      } else if (formMode === "edit") {
        dispatch(
          setActiveSideBar({
            id: "2",
            name: "Roles",
            value: "Roles",
          })
        );
        navigate("/admin-role");
      }

    } else if (activeSideBar?.value === "Create User") {
      if (formMode === "add") {
        dispatch(
          setActiveSideBar({
            id: "2",
            name: "Roles",
            value: "Create Role",
          })
        );
      } else if (formMode === "edit") {
        dispatch(
          setActiveSideBar({
            id: "2",
            name: "Users",
            value: "Users",
          })
        );
        navigate("/admin-user");
      }
    }
  };

  return (
    <>
      <div className="form-heading-prom">
        <img
          src="/image/form-back-arrow.png"
          alt=""
          onClick={() => navHandler()}
        />
      </div>

      {formMode === "add" ? (
        <div className="step-progressbar-admin">
          <ul className="progressbar-admin">
            <li className={`step-circle active active-circle `}>
              Create department
            </li>
            <li
              className={`step-circle ${
                (activeSideBar?.value === "Create Role" ||
                  activeSideBar?.value === "Create User") &&
                "active active-circle"
              }`}
            >
              Create role
            </li>
            <li
              className={`step-circle ${
                activeSideBar?.value === "Create User" && "active active-circle"
              }`}
            >
              Create user
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}

      {activeSideBar?.value === "Create Department" ? (
        <AdminCreateDepartment />
      ) : activeSideBar?.value === "Create Role" ? (
        <AdminCreateRole />
      ) : activeSideBar?.value === "Create User" ? (
        <AdminCreateUser />
      ) : (
        <></>
      )}
    </>
  );
};

export default AdminSystemUserMain;
