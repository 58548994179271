import React from "react";
import { Link } from "react-router-dom";
import "./TablePagination.css"

const TablePagination = ({ totalPages, currentPage, onPageChange }) => {
  const maxPageNumbers = 3; // Number of page numbers to display

  // Ensure currentPage is within valid range
  currentPage = Math.max(1, Math.min(currentPage, totalPages));

  // Calculate the range of page numbers to display
  let startPage = Math.max(currentPage - Math.floor(maxPageNumbers / 2), 1);
  let endPage = startPage + maxPageNumbers - 1;

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(endPage - maxPageNumbers + 1, 1);
  }

  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  return (
    <div className="pagination-admin-tb">
      <Link
        className="fill-box"
        onClick={() => onPageChange(1)}
        disabled={currentPage === 1}
      >
        &laquo;
      </Link>
      <Link
        className="fill-box"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage >= 1}
      >
        &lt;
      </Link>
      {pageNumbers?.map((pageNumber) => (
        <Link
          key={pageNumber}
          className={pageNumber === currentPage ? "active" : ""}
          onClick={() => onPageChange(pageNumber)}
        >
          {pageNumber}
        </Link>
      ))}
      <Link
        className="fill-box"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage <= totalPages}
      >
        &gt;
      </Link>
      <Link
        className="fill-box"
        onClick={() => onPageChange(totalPages)}
        disabled={currentPage === totalPages}
      >
        &raquo;
      </Link>
    </div>
  );
};

export default TablePagination;
