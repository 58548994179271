import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import {
  setActiveSubTab,
  setActiveTab,
  setSignUpEmp,
  clearLoginUser,
  setPostJobMode,
  setPostJobId,
  setSignUpBarHide,
  setLoginModel,
  setResumeVerified,
  setResumeDetail,
} from "../../../redux/slices/AuthSlice";
import { setClearFilter } from "../../../redux/slices/JobSearchSlice";
import {
  setActiveSideBar,
  setdefaultPermissions,
} from "../../../redux/slices/AdminDashboardSlice";
import { setResumeRetryCount } from "../../../redux/slices/verifyResumeSlice";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import "./TopNavBar.css";
import { ADMIN, CANDIDATE, POST_JOB_HIDE } from "../../../constants";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

const TopNavBar = ({
  findJobHide,
  loginHide,
  signUpHide,
  toggleAuthHeader,
  isLogged,
  setContactModal,
}) => {
  const {
    isEmailVerify,
    resumeVerified,
    user,
    userName,
    activeSubTab,
    favoriteJobCount,
  } = useSelector((state) => state.auth);

  const { countryISOCode } = useSelector(
    (state) => state.home
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const scroll = "search";
  const [openBurger, setOpenBurger] = useState(false);

  const onSignUpHandler = () => {
    dispatch(clearLoginUser());
    dispatch(setActiveTab("candidate"));
    dispatch(setActiveSubTab("signUp"));
    dispatch(setSignUpEmp({}));
    dispatch(setPostJobId(null));
  };

  const onLogOutHandler = () => {
    dispatch(clearLoginUser());
    dispatch(setResumeVerified(0));
    dispatch(setResumeRetryCount(0));
    dispatch(setResumeDetail({ resumeId: null, resumeType: 1 }));
    dispatch(setdefaultPermissions({}));
    dispatch(
      setActiveSideBar({
        id: "1",
        name: "Modules",
        value: "Modules",
      })
    );
    localStorage.removeItem("token");
  };

  const onCustomResumeHandler = () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else if (resumeVerified === 0) {
      toast.warning(
        "Please verify your Resume for our AI analysis and job application process.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
    } else {
      navigate("/candidate-resume-decision");
    }
  };

  const pricingHandler = () => {
    navigate("/pricing-content");
  };

  const postJobClickHanlder = async () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else {
      dispatch(setActiveTab("employer"));
      dispatch(setActiveSubTab("postJob"));
      dispatch(setPostJobMode("add"));
      dispatch(setPostJobId(null));
      dispatch(setSignUpBarHide(true));
      navigate("/post-job");
    }
  };

  const howItWorksHandler = () => {
    navigate("/");
    setTimeout(() => {
      const section = document.getElementById("how_it_work");
      section.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  const MainMenuBar = ({ mobileMenu, mobileMenuMethod, handleActiveOption }) => {
    const [activePage, setActivePage] = useState("");
    useEffect(() => {
      const baseUrl = window.location.origin;

      // Get the part of the URL after the base URL
      const urlPartAfterBaseUrl = window.location.href.substring(baseUrl.length);
      setActivePage(urlPartAfterBaseUrl);
      // Log or use the result as needed
    }, []);
    return (
      <div className={`menu-items-poly ${mobileMenu ? 'openNavbar' : ''}`}>
        <button className="btn close-menu" onClick={mobileMenuMethod}>
          <img src="/image/cross-btn.png" alt="" />
        </button>
        <div className="profile-section">
          <div className="profile-avatar">
            <img
              src={user?.avatar ? user?.avatar : "/image/default-avatar.png"}
              className="loggedout-prof"
              alt=""
            />
          </div>
          {user ?
            <h3 className="signIn-btns">{user?.first_name} {user?.last_name}</h3> :
            <h3 className="signIn-btns">
              <Link to="/login">
                Login/Signup
              </Link>
            </h3>
          }

        </div>
        <ul className="menu-list">
          <div className="d-md-none d-block w-100">
            {ADMIN.includes(user?.user_type) ? (
              <>
                <div
                  className={`menu-option-div ${(activePage === "/candidate-dashboard" ||
                    activePage === "/employer-dashboard") &&
                    "active-option"
                    }`}
                  onClick={() => {
                    handleActiveOption("dashboard");
                  }}
                >
                  <img
                    src="/image/dash-m.png"
                    alt="dashboard-icon"
                    className="dashboard-icon"
                  />
                  <h3 className="option-name">Dashboard</h3>
                </div>

                <div
                  className={`menu-option-div ${(activePage === "/candidate-profile" ||
                    activePage === "/employer-profile") &&
                    "active-option"
                    }`}
                  onClick={() => {
                    handleActiveOption("profile");
                  }}
                >
                  <img
                    src="/image/profile-m.png"
                    alt="dashboard-icon"
                    className="dashboard-icon"
                  />
                  <h3 className="option-name">My Profile</h3>
                </div>
              </>
            ) : (
              resumeVerified !== 0 && (
                <>
                  <div
                    className={`menu-option-div ${(activePage === "/candidate-dashboard" ||
                      activePage === "/employer-dashboard") &&
                      "active-option"
                      }`}
                    onClick={() => {
                      handleActiveOption("dashboard");
                    }}
                  >
                    <img
                      src="/image/dash-m.png"
                      alt="dashboard-icon"
                      className="dashboard-icon"
                    />
                    <h3 className="option-name">Dashboard</h3>
                  </div>
                  <div
                    className={`menu-option-div ${(activePage === "/candidate-profile" ||
                      activePage === "/employer-profile") &&
                      "active-option"
                      }`}
                    onClick={() => {
                      handleActiveOption("profile");
                    }}
                  >
                    <img
                      src="/image/profile-m.png"
                      alt="dashboard-icon"
                      className="dashboard-icon"
                    />
                    <h3 className="option-name">My Profile</h3>
                  </div>
                </>
              )
            )}

            {ADMIN.includes(user?.user_type) ? (
              <></>
            ) : (
              resumeVerified !== 0 && (
                <div
                  className={`menu-option-div ${activePage === "/candidate-wishlist" && "active-option"
                    }`}
                  onClick={() => {
                    handleActiveOption("wishlist");
                  }}
                >
                  <img
                    src="/image/wishlist-m.png"
                    alt="dashboard-icon"
                    className="dashboard-icon wish-icon"
                  />
                  <h3 className="option-name">Wishlist</h3>
                </div>
              )
            )}
          </div>
          {!CANDIDATE.includes(user?.user_type) && (
            <li>
              <div className="menu-dropdown-head">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-menu-icon">
                    <p>
                      <img src="/image/menuIcon/employers.png" alt="" />
                      Employers
                    </p>
                    <img src="/image/menu-drop-poly.png" alt="" />
                  </div>
                </div>
                <div className="list-men-dropdown-head">
                  <ul className="menu-item-drop-poly">
                    <li onClick={() => postJobClickHanlder()}>Post a job</li>
                    <li onClick={() => howItWorksHandler()}>How it works</li>
                    {user?.user_type !== "company_user" && (
                      <li onClick={() => pricingHandler()}>Pricing</li>
                    )}
                  </ul>
                </div>
              </div>
            </li>
          )}
          {!ADMIN.includes(user?.user_type) && (
            <>
              <li>
                <div className="menu-dropdown-head">
                  <div
                    id="dropdown-boxId2"
                    className="company-form-dropdownBox1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-menu-icon">
                      <p><img src="/image/menuIcon/jobSeeker.png" alt="" />Job seekers</p>
                      <img src="/image/menu-drop-poly.png" alt="" />
                    </div>
                  </div>
                  <div className="list-men-dropdown-head">
                    <ul className="menu-item-drop-poly">
                      <li
                        onClick={() => {
                          dispatch(setSignUpBarHide(true));
                          navigate(`/job-search/${scroll}`);
                        }}
                        className="icon-cursor"
                      >
                        Find a job
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li
                className="icon-cursor resume-active-menu"
                onClick={() => onCustomResumeHandler()}
              >
                <a><img src="/image/menuIcon/resume.png" alt="" />Resume tools</a>
              </li>
            </>
          )}
          <li className="icon-cursor" onClick={() => setContactModal(true)}>
            <a href="#"><img src="/image/menuIcon/contactUs.png" alt="" />Contact us</a>
          </li>
          {
            (user) && (
              <li className="icon-cursor d-md-none d-block" onClick={() => handleActiveOption("logout")}>
                <a href="#"><img src="/image/menuIcon/logout.png" alt="" />Logout</a>
              </li>
            )
          }
        </ul>
        {isEmailVerify === true && ADMIN.includes(user?.user_type) && (
          <div
            className="find-job-btn-candidate-dashbord"
            onClick={() => postJobClickHanlder()}
          >
            <Link>
              <button>Post a job</button>
            </Link>
          </div>
        )}
      </div>
    );
  };

  const PublicHeader = () => {
    const [mobileMenu, setMobileMenu] = useState(false)
    const handleToggleBtn = () => {
      setMobileMenu(!mobileMenu)
      // if (isEmailVerify === false) {
      //   dispatch(setLoginModel(true));
      // }
    };
    return (
      <div className="container container-site">
        <div className="row-log">
          <div className="logo-col icon-cursor log-div">
            <Link to="/">
              <img
                src="/image/polycareers-north-macedonia.png"
                className="logo-mbl"
                alt="logo"
              />
            </Link>
            <p className="country-name-logo">{countryISOCode}</p>
          </div>
          <MainMenuBar
            mobileMenu={mobileMenu}
            mobileMenuMethod={handleToggleBtn}
          />
          <div className="mbl-logo">
            <Link to="/">
              <img
                src="/image/polycareers-north-macedonia.png"
                className="logo-mbl"
                alt="logo"
              />
            </Link>
            <p className="country-name-logo">{countryISOCode}</p>
          </div>
          {/* <div className="find-job-btn-mbl">
            <Link
              to={`/job-search/${scroll}`}
              onClick={() => dispatch(setClearFilter())}
            >
              <button className="find-j-nav-mbl ">Find a Job</button>
            </Link>
          </div> */}
          <div className="col-two-button">
            {/* {!findJobHide && (
              <div className="find-job-btn">
                <Link
                  to={`/job-search/${scroll}`}
                  onClick={() => dispatch(setClearFilter())}
                >
                  <button className="find-j-nav ">Find a Job</button>
                </Link>
              </div>
            )} */}
            <div className="menu-items">
              {!loginHide && (
                <p>
                  <Link to="/login">
                    <button className="login-header-poly">Log in</button>
                  </Link>
                </p>
              )}

              {!signUpHide && (
                <Link to="/signup" onClick={() => onSignUpHandler()}>
                  <button className="signup-poly">Sign up</button>
                </Link>
              )}
            </div>
            <div
              className="toggleBtn"
              onClick={() => {
                handleToggleBtn();
              }}
            >
              <img src="/image/toogle-btn.png" alt="toggleBtn" />
            </div>
          </div>
        </div>
        {/* {openBurger && <BurgerMenu from='public' openBurger={openBurger} setOpenBurger={setOpenBurger}/> } */}
      </div>
    );
  };

  const DashboardHeader = () => {
    const [hideMenu, setHideMenu] = useState(true);
    const [activeOption, setActiveOption] = useState("");
    const navigate = useNavigate();
    const [mobileMenu, setMobileMenu] = useState(false)
    const handleToggleBtn = () => {
      setMobileMenu(!mobileMenu)
    };

    const postJobClickHanlder = async () => {
      dispatch(setActiveTab("employer"));
      dispatch(setActiveSubTab("postJob"));
      dispatch(setPostJobMode("add"));
      dispatch(setPostJobId(null));
      dispatch(setSignUpBarHide(true));
      navigate("/post-job");
    };

    const handleActiveOption = async (option) => {
      setActiveOption(option);
      setOpenBurger(false);
      if (isEmailVerify === true && ADMIN.includes(user?.user_type)) {
        if (option === "dashboard") {
          navigate("/employer-dashboard");
        } else if (option === "profile") {
          navigate("/employer-profile");
        } else if (option === "settings") {
          navigate("/admin-department");
        } else if (option === "logout") {
          onLogOutHandler();
          navigate("/");
        } else if (option === "password") {
          navigate("/change-password");
        } else if (option === "postJob") {
          await postJobClickHanlder();
        }
      } else {
        if (option === "dashboard") {
          navigate("/candidate-dashboard");
        } else if (option === "profile") {
          navigate("/candidate-profile");
        } else if (option === "wishlist") {
          navigate("/candidate-wishlist");
        } else if (option === "logout") {
          onLogOutHandler();
          navigate("/");
        } else if (option === "findJob") {
          navigate(`/job-search/${scroll}`);
        }
      }
    };

    return (
      <div className="container container-site-profile">
        {isEmailVerify === true && ADMIN.includes(user?.user_type) ? (
          <>
            <div className="row-log">
              <div className="logo-col icon-cursor">
                <Link to="/">
                  <img
                    src="/image/polycareers-north-macedonia.png"
                    alt="logo"
                  />
                </Link>
                <p className="country-name-logo">{countryISOCode}</p>
                {/* {!POST_JOB_HIDE.includes(location?.pathname) && (
                  <div
                    className="find-job-btn-candidate-dashbord"
                    onClick={() => postJobClickHanlder()}
                  >
                    <Link                  
                    >
                      <button>Post a Job</button>
                    </Link>
                  </div>
                )} */}
              </div>
              <MainMenuBar mobileMenu={mobileMenu}
                mobileMenuMethod={handleToggleBtn} handleActiveOption={handleActiveOption} />
              <div className="col-two-button">
                <div className="hide-wishlist-style">
                  <div className="candidate-name-img icon-cursor">
                    <img src="/image/profile.png" alt="" />
                    <p className="candidate-name">
                      Hi,{" "}
                      {userName !== ""
                        ? capitalizeFirstLetter(userName)
                        : "John"}
                    </p>
                  </div>
                  <div className="toggle-icon-profile icon-cursor toggle-btn-res">
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setHideMenu(true);
                      }}
                    >
                      <img
                        src="/image/toogle-btn.png"
                        alt=""
                        onClick={() => setHideMenu(!hideMenu)}
                      />
                    </OutsideClickHandler>
                  </div>
                  <div className="toggle-icon-profile icon-cursor toggle-btn-mbl">
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setHideMenu(true);
                      }}
                    >
                      <img
                        src="/image/toogle-btn.png"
                        alt=""
                        onClick={() => {
                          handleToggleBtn(true);
                        }}
                      />
                    </OutsideClickHandler>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`dropdown-dashbord ${hideMenu && "hide-show-click"}`}
              onClick={() => {
                setHideMenu(true);
                dispatch(setSignUpBarHide(true));
              }}
            >
              <ul className="dropdown-menu-dashbord">
                <Link to="/employer-dashboard">
                  <li className="menu-dropdown">
                    <img src="/image/dash-m.png" alt="" />
                    Dashboard
                  </li>
                </Link>
                <Link to="/employer-profile">
                  <li className="menu-dropdown">
                    <img src="/image/profile-m.png" alt="" />
                    My Profile
                  </li>
                </Link>
                <Link
                  to="/admin-department"
                  onClick={() =>
                    dispatch(
                      setActiveSideBar({
                        id: "2",
                        name: "Department",
                        value: "Department",
                      })
                    )
                  }
                >
                  <li className="menu-dropdown">
                    <img src="/image/setting-m.png" alt="" />
                    System Settings
                  </li>
                </Link>
                <Link to="/" onClick={() => onLogOutHandler()}>
                  <li className="menu-dropdown">
                    <img src="/image/log-m.png" alt="" />
                    Logout
                  </li>
                </Link>
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className="row-log">
              <div className="logo-col icon-cursor">
                <Link to="/">
                  <img
                    src="/image/polycareers-north-macedonia.png"
                    alt="logo"
                  />
                </Link>
                <p className="country-name-logo">{countryISOCode}</p>
                {/* {!findJobHide && (
                  <div className="find-job-btn-candidate-dashbord">
                    <Link
                      to={`/job-search/${scroll}`}
                      onClick={() => dispatch(setClearFilter())}
                    >
                      <button className="find-j-nav ">Find a Job</button>
                    </Link>
                  </div>
                )} */}
              </div>
              <MainMenuBar mobileMenu={mobileMenu}
                mobileMenuMethod={handleToggleBtn} handleActiveOption={handleActiveOption} />
              <div className="profile-right-col icon-cursor">
                <div className="candidate-name-img">
                  <img src="/image/profile.png" alt="" />
                  <p className="candidate-name">
                    Hi,{" "}
                    {userName !== "" ? capitalizeFirstLetter(userName) : "John"}
                  </p>
                </div>
                {/* {resumeVerified !== 0 && (
                  <div className="candidate-wishlist-block icon-cursor">
                    <Link to="/candidate-wishlist">
                      <img src="/image/wishlist.png" alt="" />
                      <p className="candidate-wishlist">Wishlist</p>
                      <div className="whislist-notofication">
                        <p className="conter-text-wiishlist">
                          {favoriteJobCount}
                        </p>
                      </div>
                    </Link>
                  </div>
                )} */}

                <div className="toggle-icon-profile icon-cursor toggle-btn-res">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setHideMenu(true);
                    }}
                  >
                    <img
                      src="/image/toogle-btn.png"
                      alt=""
                      onClick={() => setHideMenu(!hideMenu)}
                    />
                  </OutsideClickHandler>
                </div>
                <div className="toggle-icon-profile icon-cursor toggle-btn-mbl">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setHideMenu(true);
                    }}
                  >
                    <img
                      src="/image/toogle-btn.png"
                      alt=""
                      onClick={() => {
                        handleToggleBtn(true);
                      }}
                    />
                  </OutsideClickHandler>
                </div>
              </div>
            </div>
            <div
              className={`dropdown-dashbord ${hideMenu && "hide-show-click"}`}
              onClick={() => setHideMenu(true)}
            >
              <ul className="dropdown-menu-dashbord">
                {resumeVerified !== 0 && (
                  <Link to="/candidate-dashboard">
                    <li className="menu-dropdown">
                      <img src="/image/dash-m.png" alt="" />
                      Dashboard
                    </li>
                  </Link>
                )}
                <Link to="/candidate-profile">
                  <li className="menu-dropdown">
                    <img src="/image/profile-m.png" alt="" />
                    My Profile
                  </li>
                </Link>
                {resumeVerified !== 0 && (
                  <Link to="/candidate-wishlist">
                    <li className="menu-dropdown">
                      <img src="/image/wishlist-m.png" alt="" />
                      Wishlist
                    </li>
                  </Link>
                )}
                <Link to="/" onClick={() => onLogOutHandler()}>
                  <li className="menu-dropdown">
                    <img src="/image/log-m.png" alt="" />
                    Logout
                  </li>
                </Link>
              </ul>
            </div>
          </>
        )}
        {/* {openBurger && (
          <BurgerMenu
            activeOption={activeOption}
            setActiveOption={setActiveOption}
            handleActiveOption={handleActiveOption}
            from="private"
            openBurger={openBurger}
            setOpenBurger={setOpenBurger}
          />
        )} */}
      </div>
    );
  };
  return (
    <header>
      {isEmailVerify === false ? <PublicHeader /> : <DashboardHeader />}
    </header>
  );
};

export default TopNavBar;
