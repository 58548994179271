import React from "react";
import NewPasswordForm from "../../../components/Auth/ForgotPassword/NewPasswordForm/NewPasswordForm";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";

const NewPassword = () => {
  return (
    <>
      <NewPasswordForm />
      <WorldConnect />
    </>
  );
};

export default NewPassword;
