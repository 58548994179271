import AdminSystemUserMain from "../../../components/Admin/SystemUser";
import "./index.css";

const AdminSystemUser = () => {
  return (
    <>
    <div className="department-role-space bg-clr-admin">
      <AdminSystemUserMain />
    </div>
    </>
  );
};

export default AdminSystemUser;
