import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { EmployerRoutes } from "./EmployerRoutes";
import { CandidateRoutes } from "./CandidateRoutes";
import { AdminRoutes } from "./AdminRoutes";
import { PublicRoutes } from "./PublicRoutes";
import ScrollToTop from "../utils/ScrollToTop";
import Home from "../pages/Home";
import JobSearch from "../pages/Job/JobSearch";
import ViewDetail from "../pages/Job/ViewDetail";
import CompanyDetail from "../pages/Job/CompanyDetail";
import JobQuesation from "../pages/Job/JobQuesation";
import Login from "../pages/Auth/Login";
import SignUp from "../pages/Auth/SignUp";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ChangePassword from "../pages/Auth/ChangePassword";
import NewPassword from "../pages/Auth/NewPassword";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermCondition from "../pages/TermCondition";
import Pricing from "../pages/Pricing/PricingTable";
import NotFound from "../pages/NotFound/NotFound"

/*candidate*/
import CanDashboard from "../pages/Candidate/Dashboard";
import CanProfile from "../pages/Candidate/Profile";
import CanWishlist from "../pages/Candidate/Wishlist";
import CanResume from "../pages/Candidate/Resume/CanResume";
import CanResumeDecision from "../pages/Candidate/Resume/CanResumeDecision";
import CanResumeJobForm from "../pages/Candidate/Resume/CanResumeJobForm";

/*Employer*/
import PostJob from "../pages/Auth/PostJob";
import EmpDashboard from "../pages/Employer/Dashboard";
import EmpProfile from "../pages/Employer/Profile";
import EmpCandidateList from "../pages/Employer/CandidateList/EmpCandidateList";
import EmpFavouritesList from "../pages/Employer/FavouritesList/EmpFavouritesList";
import EmpList from "../pages/Employer/List/EmpList";
import EmpCandidateDetail from "../pages/Employer/CandidateDetail/EmpCandidateDetail";

/*Admin*/
import AdminDashboard from "../pages/Admin/Dashboard";
import AdminDepartment from "../pages/Admin/Department";
import AdminRole from "../pages/Admin/Role";
import AdminUser from "../pages/Admin/User";
import AdminSystemUser from "../pages/Admin/SystemUser";
import AdminViewRole from "../pages/Admin/ViewRole";
import AdminViewUser from "../pages/Admin/ViewUser";
import AdminDetailUser from "../pages/Admin/DetailUser";
import AdminAllPromotion from "../pages/Admin/Promotion/AllPromotion";
import AdminCreatePromotionForm from "../pages/Admin/Promotion/CreatePromotion";
import AdminCreatePromotionSlot from "../pages/Admin/Promotion/CreatePromotionSlot";
import AdminPromotionReq from "../pages/Admin/Promotion/PromotionReq";
import BulkResumeAnalysis from "../pages/Admin/BulkResume/BulkResumeAnalysis";
import BulkResumeUpload from "../pages/Admin/BulkResume/BulkResumeUpload";
import BulkResumeViewJob from "../pages/Admin/BulkResume/BulkResumeViewJob";
import BulkResumeReqDetail from "../pages/Admin/BulkResume/BulkResumeReqDetail";
import BulkAnalysis from "../pages/Admin/BulkResume/BulkAnalysis";
import BulkResumeAIAnalysis from "../pages/Admin/BulkResume/BulkResumeAIAnalysis";
import { ADMIN } from "../constants";
import GoogleCallback from "../components/Auth/GoogleAuth/GoodgleCallback";
import { MyResumes } from "../pages/Candidate/Resume/MyResumes";


const CustomRouter = () => {
  const { isEmailVerify, user } = useSelector((state) => state.auth);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<Home />} />
          <Route path="/company-detail/:id" element={<CompanyDetail />} />
          <Route path="/job-view-detail/:id" element={<ViewDetail />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term-condition" element={<TermCondition />} />
          <Route path="/pricing-content" element={<Pricing />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>



        <Route element={<PublicRoutes findJobHide={true} />}>
          <Route path="/job-search" element={<JobSearch />} />
          <Route path="/job-search/:scroll" element={<JobSearch />} />
          <Route path="/job-quesation/:id" element={<JobQuesation />} />
        </Route>

        <Route element={<PublicRoutes findJobHide={true} loginHide={true} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>

        <Route
          element={
            <PublicRoutes
              findJobHide={true}
              loginHide={false}
              signUpHide={true}
            />
          }
        >
          <Route path="/signup" element={<SignUp />} />
          <Route path="/auth/callback" element={<GoogleCallback />} />
        </Route>

        <Route
          element={
            isEmailVerify === true && ADMIN.includes(user?.user_type) ? (
              <EmployerRoutes toggleAuthHeader={true} />
            ) : (
              <CandidateRoutes findJobHide={true} />
            )
          }
        >
          <Route path="/new-password" element={<NewPassword />} />
        </Route>

        <Route element={<CandidateRoutes />}>
          <Route path="/candidate-dashboard" element={<CanDashboard />} />
          <Route path="/candidate-wishlist" element={<CanWishlist />} />
        </Route>

        <Route element={<CandidateRoutes findJobHide={true} />}>
          <Route path="/candidate-profile" element={<CanProfile />} />
          <Route path="/my-resumes" element={<MyResumes />} />
          <Route path="/candidate-resume" element={<CanResume />} />
          <Route
            path="/candidate-resume-decision"
            element={<CanResumeDecision />}
          />
          <Route
            path="/candidate-resume-job-form"
            element={<CanResumeJobForm />}
          />
        </Route>

        <Route element={<EmployerRoutes toggleAuthHeader={true} />}>
          <Route path="/post-job" element={<PostJob />} />
          <Route path="/employer-dashboard" element={<EmpDashboard />} />
          <Route path="/employer-profile" element={<EmpProfile />} />
          <Route
            path="/employer-Favourit-list"
            element={<EmpFavouritesList />}
          />
          <Route
            path="/employer-candidate-list"
            element={<EmpCandidateList />}
          />
          <Route path="/employer-list" element={<EmpList />} />
          <Route
            path="/employer-candidate-detail"
            element={<EmpCandidateDetail />}
          />
        </Route>

        <Route element={<AdminRoutes />}>
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin-department" element={<AdminDepartment />} />
          <Route path="/admin-role" element={<AdminRole />} />
          <Route path="/admin-user" element={<AdminUser />} />
          <Route path="/admin-system-user" element={<AdminSystemUser />} />
          <Route path="/admin-view-role" element={<AdminViewRole />} />
          <Route path="/admin-view-user" element={<AdminViewUser />} />
          <Route path="/admin-detail-user" element={<AdminDetailUser />} />
          <Route path="/admin-all-promotion" element={<AdminAllPromotion />} />
          <Route
            path="/admin-promotion"
            element={<AdminCreatePromotionForm />}
          />
          <Route
            path="/admin-promotion-slot"
            element={<AdminCreatePromotionSlot />}
          />
          <Route path="/admin-promotion-req" element={<AdminPromotionReq />} />
          <Route
            path="/bulk-resume-analysis"
            element={<BulkResumeAnalysis />}
          />
          <Route path="/bulk-resume-upload" element={<BulkResumeUpload />} />
          <Route path="/bulk-resume-view-job" element={<BulkResumeViewJob />} />
          <Route
            path="/bulk-resume-req-detail"
            element={<BulkResumeReqDetail />}
          />
           <Route
            path="/bulk-analysis"
            element={<BulkAnalysis />}
          />
          <Route
            path="/bulk-resume-ai-analysis"
            element={<BulkResumeAIAnalysis />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default CustomRouter;
