import React from "react";
import InputMask from "react-input-mask";

const PhoneNumber = ({
  className,
  id,
  name,
  type,
  value,
  maskedPlaceHolder = "+(___) __-___-___",
  maskedPhoneNumber = "+(999)99-999-999",
  onChange,
  onBlur,
}) => {
  return (
    <InputMask
      id={id}
      mask={maskedPhoneNumber}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {(inputProps) => (
        <input
          className={className}
          id={id}
          type={type}
          placeholder={maskedPlaceHolder}
          {...inputProps}
        />
      )}
    </InputMask>
  );
};

export default PhoneNumber;
