import React from "react";
import PostJobContainer from "../../../components/Auth/SignUp/PostJobContainer";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";

const PostJob = () => {
  return (
    <>
      <PostJobContainer />
      <WorldConnect />
    </>
  );
};

export default PostJob;
