import React, { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import "../PostJobProgressModel/PostJobProgressModel.css";

const ResumeProgressModel = ({ heading, content, modelHandler }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress((prevProgress) => prevProgress + 1);
      } else {
        clearInterval(interval);

        modelHandler(false);
      }
    }, 400); // Update every 300 milliseconds for a total duration of 30 seconds

    return () => {
      clearInterval(interval);
    };
  }, [progress]);

  return (
    <div className="modal-body">
      <div className="generating-decs text-start px-3 pb-md-4 pb-3">
        <p className="mb-2">PolyCareersAI feature</p>
        <p className="job-decs-sub-heading">{heading}</p>
        <div className="text-center">
          <img className="my-md-5 my-2 zeo-img-size" src="/image/job-decs-img.gif" alt="" />
        </div>
        <p>{content}</p>
      </div>
      <section class="model-popup-post-job d-none">
        <div class="popup-post-job">
          <div class="popup-content-zoe-job-gen">
            <div class="logo-site">
              <a href="#">
                <img src="/image/WORKBRIDGE.png" alt="" />
              </a>
              <img
                src="/image/chat-cut.png"
                alt=""
                class="close-m-gen"
                onClick={() => modelHandler(false)}
              />
            </div>
            <OutsideClickHandler
              onOutsideClick={() => {
                modelHandler(false);
              }}
            >
              <div class="col-job-gencard">
                <p class="zeo-gen-text">
                  Customizing <span> Resume</span>
                </p>
                <img
                  src="/image/Text_Animation.gif"
                  alt=""
                  class="animation-img-m"
                />

                <p class="progressbar-text">Please wait Zoe is Writing...</p>
                <div class="progress">
                  <div
                    class="progress-bar progress-bar-striped bg-success"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow="60"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <img src="/image/zeo-eye.png" alt="" class="eye-img-m" />
              </div>
            </OutsideClickHandler>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResumeProgressModel;
