import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import "./EmpProfileView.css";
import { useEffect, useRef, useState } from "react";
import APIPvt from "../../../apiPvt";
import API from "../../../api";
import { toast } from "react-toastify";
import FileUploadModel from "../../Common/FileUploadModel/FileUploadModel";

const EmpProfileView = ({ baseInfo, companyInfo, getUserProfile }) => {
  const navigate = useNavigate();

  const [legelDocumentAvailable, setLegalDocumentAvailable] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const fileRef = useRef();
  const changePasswordHandler = () => {
    navigate("/new-password");
  };

  useEffect(() => {
    if (companyInfo?.company?.length > 0) {
      if (companyInfo?.company[0].legal_document) {
        console.log(companyInfo?.company[0].legal_document);
        setLegalDocumentAvailable(true);
      } else {
        console.log(companyInfo?.company[0].legal_document);
        setLegalDocumentAvailable(false);
      }
    }
  }, [companyInfo]);

  const updateLegalDocument = async (file, companyInfo) => {
    const formData = new FormData();
    formData.append("legal_document", file);
    formData.append("company_detail", companyInfo?.description);
    formData.append("first_name", baseInfo?.firstName?.trim());
    formData.append("last_name", baseInfo?.lastName?.trim());
    formData.append("website_url", companyInfo?.website);
    formData.append("id", baseInfo?.id ? baseInfo?.id : "");
    formData.append("phone", baseInfo?.phone ? baseInfo?.phone : "");
    formData.append(
      "linkedin",
      companyInfo?.linkedin ? companyInfo?.linkedin : ""
    );
    formData.append(
      "city_id",
      companyInfo?.city_id ? companyInfo?.city_id : ""
    );
    formData.append(
      "country_id",
      baseInfo?.countryId ? baseInfo.countryId : ""
    );
    formData.append("company_logo", companyInfo?.company[0]?.logo);

    API.post("/auth/update-employer-profile", formData)
      .then((res) => {
        if (res.data.result === true) {
          toast.success("Document Updated Successfully!", {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
      })
      .then(() => {
        setLegalDocumentAvailable(true);
        getUserProfile();
      })
      .catch((error) => {
        toast.error(error?.message, {
          autoClose: 2000,
          closeOnClick: false,
        });
      });
  };
  const handleFileChange = async (event) => {
    const file = event;

    if (file) {
      if (file.size / 1000 <= 2048 && file.type === "application/pdf") {
        updateLegalDocument(file, companyInfo);
      } else {
        if (file.size / 1000 > 2048) {
          toast.error("File size must be less 2048 KB", {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
        if (file.type !== "application/pdf") {
          toast.error("Only PDF file type is supported", {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
      }
    }
  };

  const resumeFileHandler = async (file, selectedImageName) => {
    handleFileChange(file);
  };

  const handlePreviewDocument = (documentURL) => {
    const pdfUrl = documentURL;
    window.open(pdfUrl, "_blank");
  };

  const modelHandler = (toggleModel) => {
    setToggleModal(toggleModel);
  };

  return (
    <>
      <div className="profile-sec-view">
        <div className="profile-row">
          {companyInfo?.logo === null || companyInfo?.logo === undefined ? (
            <div className="candidtae-profile-image-avatar candidate-viewP-mbl">
              <img src="/image/avtar-company.png" alt="" />
              <div className="addProfile-icon-view-mbl d-none">
                <img src="/image/addProfile.png" alt="" />
              </div>
            </div>
          ) : (
            <div className="candidtae-profile-image-avatar candidate-viewP-mbl">
              <img src={companyInfo?.logo} alt="" />
              <div className="addProfile-icon-view-mbl d-none">
                <img src="/image/addProfile.png" alt="" />
              </div>
            </div>
          )}
          <div className="candidate-information">
            <div className="candidate-profile-date-after">
              <h5 className="p-0">
                {capitalizeFirstLetter(companyInfo?.name)}
              </h5>

              {baseInfo?.firstName !== "" && baseInfo?.lastName !== "" && (
                <div className="candiate-info-heading">
                  <Link href="#">
                    <p className="candidate-info-text userName-mbl">
                      {baseInfo?.username}
                    </p>
                  </Link>
                </div>
              )}

              {baseInfo?.countryName && (
                <div className="candiate-info-heading">
                  <Link href="#">
                    <img
                      src="/image/profile-location.png"
                      className="location-icon"
                      alt=""
                    />
                    <p className="candidate-info-text location-name-local userName-mb ml-0">
                      {baseInfo?.city !== "" ? baseInfo?.city + ", " : ""}
                      {baseInfo?.state !== "" ? baseInfo?.state + ", " : ""}                  
                      {baseInfo?.countryName !== ""
                        ? baseInfo?.countryName
                        : ""}
                    </p>
                  </Link>
                </div>
              )}

              {baseInfo?.email && (
                <div className="candiate-info-heading">
                  <a href="mailto:teklavasadze@gmail.com">
                    <img
                      src="/image/profile-email.png"
                      className="email-icon"
                      alt=""
                    />
                    <p className="candidate-info-text">{baseInfo?.email}</p>
                  </a>
                </div>
              )}

              {baseInfo?.phone && (
                <div className="candiate-info-heading">
                  <a href="tel:+995599132862">
                    <img
                      src="../image/profile-phone.png"
                      className="profile-icon"
                      alt=""
                    />
                    <p className="candidate-info-text">{baseInfo?.phone}</p>
                  </a>
                </div>
              )}

              {companyInfo?.website && (
                <div className="candiate-info-heading">
                  <a href={companyInfo?.website}>
                    <img
                      src="/image/profile-website.png"
                      className="profile-icon"
                      alt=""
                    />
                    <p className="candidate-info-text">
                      {companyInfo?.website}
                    </p>
                  </a>
                </div>
              )}

              {companyInfo?.linkedin && (
                <div className="candiate-info-heading">
                  <a href={companyInfo?.linkedin} target="_blank">
                    <img
                      src="/image/profile-linked.png"
                      className="profile-icon"
                      alt=""
                    />
                    <p className="candidate-info-text">
                      {companyInfo?.linkedin}
                    </p>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="my-profile-row pb-md-5 pb-3"> */}
      {/* <div className="mt-md-0 mt-4 candidtae-profile-image-avatar emp-profile-mob">
          <div className="candidate-viewP-mbl">
            <img
              src={
                companyInfo?.logo === null || companyInfo?.logo === undefined
                  ? "/image/avtar-company.png"
                  : companyInfo?.logo
              }
              alt=""
              className="company-logo-mbl"
            />
            <div className="addProfile-icon-view-mbl d-none">
              <img src="/image/addProfile.png" alt="" />
            </div>
          </div>
          <div className="candidate-info-mbl">
            <div className="candidate-profile-date-after ">
              <h5 className="companyName-mbl">
                {capitalizeFirstLetter(companyInfo?.name)}
              </h5>
              {baseInfo?.firstName !== "" && baseInfo?.lastName !== "" && (
                <div className="candiate-info-heading candiate-info-heading-mbl">
                  <Link href="#">
                    <img
                      src="/image/profile-name.jpg"
                      className="profile-icon"
                      alt=""
                    />
                    <p className="candidate-info-text userName-mbl">
                      {baseInfo?.username}
                    </p>
                  </Link>
                </div>
              )}
              {baseInfo?.countryName !== "" && (
                <div className="candiate-info-heading">
                  <Link href="#">
                    <img
                      src="/image/profile-location.png"
                      className="location-icon"
                      alt=""
                    />
                    <p className="candidate-info-text location-name-local userName-mbl">
                      {baseInfo?.countryName}
                    </p>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="candidate-indo-sub-mbl">
          {companyInfo?.website && (
            <div className="candiate-info-heading candiate-info-heading-mbl-sub">
              <a href={companyInfo?.website}>
                <img
                  src="/image/profile-website.png"
                  className="profile-icon"
                  alt=""
                />
                <p className="candidate-info-text">{companyInfo?.website}</p>
              </a>
            </div>
          )}
          {baseInfo?.email && (
            <div className="candiate-info-heading">
              <a href="mailto:teklavasadze@gmail.com">
                <img
                  src="/image/profile-email.png"
                  className="email-icon"
                  alt=""
                />
                <p className="candidate-info-text">{baseInfo?.email}</p>
              </a>
            </div>
          )}
          {baseInfo?.phone && (
            <div className="candiate-info-heading">
              <a href="tel:+995599132862">
                <img
                  src="../image/profile-phone.png"
                  className="profile-icon"
                  alt=""
                />
                <p className="candidate-info-text">{baseInfo?.phone}</p>
              </a>
            </div>
          )}

          {companyInfo?.website && (
            <div className="candiate-info-heading">
              <a href={companyInfo?.website}>
                <img
                  src="/image/profile-linked.png"
                  className="profile-icon"
                  alt=""
                />
                <p className="candidate-info-text">{companyInfo?.website}</p>
              </a>
            </div>
          )}

          {companyInfo?.linkedin && (
            <div className="candiate-info-heading">
              <a href={companyInfo?.linkedin}>
                <img
                  src="/image/profile-linked.png"
                  className="profile-icon"
                  alt=""
                />
                <p className="candidate-info-text">{companyInfo?.linkedin}</p>
              </a>
            </div>
          )}
        </div>
        {toggleModal && (
          <FileUploadModel
            modelHandler={modelHandler}
            resumeFileHandler={resumeFileHandler}
            title="Upload Document"
          />
        )} */}
      {/* 
        <div className="candidate-information with-stack-btns">
          <div className="candidate-profile-date-after pb-0 candidate-info-desktop">
            <h5 className="p-0">{capitalizeFirstLetter(companyInfo?.name)}</h5>
            {baseInfo?.username && (
              <div className="candiate-info-heading">
                <Link href="#">
                  <img
                    src="/image/profile-name.jpg"
                    className="profile-icon"
                    alt=""
                  />
                  <p className="candidate-info-text">{baseInfo?.username}</p>
                </Link>
              </div>
            )}
            {baseInfo?.countryName !== "" && (
              <div className="candiate-info-heading">
                <Link href="#">
                  <img
                    src="/image/profile-location.png"
                    className="location-icon"
                    alt=""
                  />
                  <p className="candidate-info-text location-name-local">
                    {baseInfo?.countryName}
                  </p>
                </Link>
              </div>
            )}

            {companyInfo?.website && (
              <div className="candiate-info-heading">
                <Link href="#">
                  <img
                    src="/image/profile-website.png"
                    className="profile-icon"
                    alt=""
                  />
                  <p className="candidate-info-text">{companyInfo?.website}</p>
                </Link>
              </div>
            )}
            {baseInfo?.email && (
              <div className="candiate-info-heading">
                <a href="mailto:teklavasadze@gmail.com">
                  <img
                    src="/image/profile-email.png"
                    className="email-icon"
                    alt=""
                  />
                  <p className="candidate-info-text">{baseInfo?.email}</p>
                </a>
              </div>
            )}
            {baseInfo?.phone && (
              <div className="candiate-info-heading">
                <a href="tel:+995599132862">
                  <img
                    src="../image/profile-phone.png"
                    className="profile-icon"
                    alt=""
                  />
                  <p className="candidate-info-text">{baseInfo?.phone}</p>
                </a>
              </div>
            )}
            {companyInfo?.linkedin && (
              <div className="candiate-info-heading">
                <Link href="companyInfo?.linkedin">
                  <img
                    src="/image/profile-linked.png"
                    className="profile-icon"
                    alt=""
                  />
                  <p className="candidate-info-text">{companyInfo?.linkedin}</p>
                </Link>
              </div>
            )}
          </div>
        </div> */}

      {/* <div className="candidate-profile-btn d-md-block d-none">
          <div className="three-stack-btn emp-profile-btns">
            {!legelDocumentAvailable ? (
              <>
                <button onClick={() => modelHandler(true)}>
                  Upload Status Document
                  <img src="/image/toast-icon.png" alt="toast-icon" />
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
          
          <div className="three-stack-btn">
            {legelDocumentAvailable ? (
              <>
                <button
                  onClick={() =>
                    handlePreviewDocument(
                      companyInfo?.company[0].legal_document
                    )
                  }
                >
                  View Document
                </button>
                <button onClick={() => modelHandler(true)}>
                  Update Document
                </button>
              </>
            ) : (
              <></>
            )}
            <button onClick={() => changePasswordHandler()}>
              Change Password
            </button>
          </div>
        </div> */}
      {/* </div> */}
      {/* <hr className="m-0 theme-line" />
      <div className="my-profile-row pb-md-5 pb-3 justify-content-end">
        <div className="candidate-information max-mob-100">
          {companyInfo?.description && (
            <div className="employee-profile-compnay-data company-desc-mbl">
              <p className="company-name-profile-date">Company Information</p>
              <p className="employee-profile-company-content-date comapny-desc-mbl-p">
                {companyInfo?.description}
              </p>
            </div>
          )}
        </div>
      </div> */}
      {/* <hr className="m-0 d-md-none d-block theme-line" />
      <div className="candidate-profile-btn d-md-none d-block mt-5">
        <div className="three-stack-btn emp-profile-btns">
          {!legelDocumentAvailable ? (
            <button onClick={() => modelHandler(true)}>
              Upload Status Document
              <img src="/image/toast-icon.png" alt="toast-icon" />
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="three-stack-btn">
          {legelDocumentAvailable ? (
            <>
              <button
                onClick={() =>
                  handlePreviewDocument(companyInfo?.company[0].legal_document)
                }
              >
                View Document
              </button>
              <button onClick={() => modelHandler(true)}>
                Update Document
              </button>
            </>
          ) : (
            <></>
          )}
          <button onClick={() => changePasswordHandler()}>
            Change Password
          </button>
        </div>
      </div> */}
    </>
  );
};

export default EmpProfileView;
