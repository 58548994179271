import React, { useEffect } from "react";
import "./BurgerMenu.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  setActiveSubTab,
  setActiveTab,
  setLoginModel,
  setSignUpEmp,
} from "../../../redux/slices/AuthSlice";
import { ADMIN, POST_JOB_HIDE } from "../../../constants";

const BurgerMenu = ({
  activeOption,
  setActiveOption,
  handleActiveOption,
  setOpenBurger,
  openBurger,
  from,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, resumeVerified } = useSelector((state) => state.auth);
  const [activePage, setActivePage] = useState("");
  useEffect(() => {
    const baseUrl = window.location.origin;

    // Get the part of the URL after the base URL
    const urlPartAfterBaseUrl = window.location.href.substring(baseUrl.length);
    setActivePage(urlPartAfterBaseUrl);
    // Log or use the result as needed
    console.log("URL Part After Base URL:", urlPartAfterBaseUrl);
  }, []);
  const handleLogin = () => {
    setOpenBurger(false);
    dispatch(setLoginModel(true));
  };
  const onSignUpHandler = () => {
    dispatch(setActiveTab("candidate"));
    dispatch(setActiveSubTab("signUp"));
    dispatch(setSignUpEmp({}));
    setOpenBurger(false);
    navigate("/signup");
  };

  return (
    <section className={`model-comment-popup burger-bg `}>
      <div className={`burger-root`}>
        <div className="close-img-resume" onClick={() => setOpenBurger(false)}>
          <img src="/image/cross-btn.png" className="cross-btn" alt="" />
        </div>
        <div className="profile-section">
          <img
            src={`${from === "public"
                ? "/image/loggedout-prof.png"
                : user?.avatar
                  ? user?.avatar
                  : "/image/loggedIn-prof.png"
              }`}
            className="loggedout-prof"
            alt=""
          />
          {from === "public" ? (
            <h3 className="signIn-btns">
              <span className="signIn-btns" onClick={handleLogin}>
                Login
              </span>{" "}
              /{" "}
              <span className="signIn-btns" onClick={() => onSignUpHandler()}>
                Sign Up
              </span>
            </h3>
          ) : (
            <h3 className="signIn-btns">
              {user?.first_name} {user?.last_name}
            </h3>
          )}
          {from === "private" && <p className="user-email">{user?.email}</p>}
        </div>
        <div className={`${from === "private" ? "menu-list" : "hide-menu"}`}>
          {ADMIN.includes(user?.user_type) ? (
            <div
              className={`menu-option-div ${(activePage === "/candidate-dashboard" ||
                  activePage === "/employer-dashboard") &&
                "active-option"
                }`}
              onClick={() => {
                handleActiveOption("dashboard");
              }}
            >
              <img
                src="/image/dash-m.png"
                alt="dashboard-icon"
                className="dashboard-icon"
              />
              <h3 className="option-name">Dashboard</h3>
            </div>
          ) : (
            resumeVerified !== 0 && (
              <div
                className={`menu-option-div ${(activePage === "/candidate-dashboard" ||
                    activePage === "/employer-dashboard") &&
                  "active-option"
                  }`}
                onClick={() => {
                  handleActiveOption("dashboard");
                }}
              >
                <img
                  src="/image/dash-m.png"
                  alt="dashboard-icon"
                  className="dashboard-icon"
                />
                <h3 className="option-name">Dashboard</h3>
              </div>
            )
          )}
          {ADMIN.includes(user?.user_type) ? (
            !POST_JOB_HIDE.includes(location?.pathname) && (
              <div
                className={`menu-option-div ${activePage === "/signup" && "active-option"
                  }`}
                onClick={() => {
                  handleActiveOption("postJob");
                }}
              >
                <img
                  src="/image/postJob.png"
                  alt="dashboard-icon"
                  className="dashboard-icon"
                />
                <h3 className="option-name ">Post a Job</h3>
              </div>
            )
          ) : (
            <div
              className={`menu-option-div ${activePage === "/job-search/search" && "active-option"
                }`}
              onClick={() => {
                handleActiveOption("findJob");
              }}
            >
              <img
                src="/image/findJob.png"
                alt="dashboard-icon"
                className="dashboard-icon"
              />
              <h3 className="option-name ">Find a Job</h3>
            </div>
          )}
          <div
            className={`menu-option-div ${(activePage === "/candidate-profile" ||
                activePage === "/employer-profile") &&
              "active-option"
              }`}
            onClick={() => {
              handleActiveOption("profile");
            }}
          >
            <img
              src="/image/profile-m.png"
              alt="dashboard-icon"
              className="dashboard-icon"
            />
            <h3 className="option-name">My Profile</h3>
          </div>

          {ADMIN.includes(user?.user_type) ? (
            <></>
          ) : (
            resumeVerified !== 0 && (
              <div
                className={`menu-option-div ${activePage === "/candidate-wishlist" && "active-option"
                  }`}
                onClick={() => {
                  handleActiveOption("wishlist");
                }}
              >
                <img
                  src="/image/wishlist-m.png"
                  alt="dashboard-icon"
                  className="dashboard-icon wish-icon"
                />
                <h3 className="option-name">Wishlist</h3>
              </div>
            )
          )}

          {/* {ADMIN.includes(user?.user_type) && <div className={`menu-option-div ${activePage === '/admin-department' && 'active-option'}`} onClick={()=>{handleActiveOption('settings')}}>
                <img src='/image/system-icon.png' alt='dashboard-icon' className='setting-icon'/>
                <h3 className='option-name system-name'>System Settings</h3>
            </div> } */}

          <div
            className={`menu-option-div ${activeOption === "logout" && "active-option"
              }`}
            onClick={() => {
              handleActiveOption("logout");
            }}
          >
            <img
              src="/image/log-m.png"
              alt="dashboard-icon"
              className="setting-icon"
            />
            <h3 className="option-name ">Logout</h3>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BurgerMenu;
