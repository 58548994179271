import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EMP_LIST } from "../../constants";
import API from "../../api";

const initialState = {
  activeListingTab: null,
};
const listingSlice = createSlice({
  name: "listing",
  initialState,
  reducers: {
    setActiveListingTab: (state, action) => {
      state.activeListingTab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setActiveListingTab } = listingSlice.actions;

export default listingSlice.reducer;

// asynchronous function with createAsyncT

export const getEmpListAsync = createAsyncThunk(
  "public/getEmpList",
  async ({ userId, search, activeListingTab }) => {
    let Url = "";
    if (
      activeListingTab === EMP_LIST.CREATED_BY_ME ||
      activeListingTab === null
    )
      Url = "/job-board/get-my-lists/";
    else if (activeListingTab === EMP_LIST.SHARED_BY_ME)
      Url = "/job-board/lists-shared-by-me/";
    else if (activeListingTab === EMP_LIST.SHARED_WITH_ME)
      Url = "/job-board/lists-shared-with-me/";

    let params = `?`;
    if (search !== null) {
      params += `search=${search}`;
    }

    try {
      const response = await API.get(`${Url}${userId}${params}`);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const createListAsync = createAsyncThunk(
  "candidate/createList",
  async ({ userId, candidateListId, listName, description }) => {
    const formData = new FormData();
    userId !== null && formData.append("user_id", userId);
    candidateListId != null && formData.append("job_id", candidateListId);
    formData.append("list_name", listName);
    formData.append("description", description);

    try {
      const response = await API.post(`/job-board/create-list`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteListAsync = createAsyncThunk(
  "candidate/deleteCandidateFromList",
  async ({ ListingId }) => {
    try {
      const response = await API.get(`/job-board/remove-list/${ListingId}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const editListAsync = createAsyncThunk(
  "candidate/editList",
  async ({ listId, listName, description }) => {
    const formData = new FormData();
    listId !== null && formData.append("list_id", listId);
    formData.append("list_name", listName);
    formData.append("description", description);

    try {
      const response = await API.post(`/job-board/update-list`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const saveAsListAsync = createAsyncThunk(
  "candidate/saveAsList",
  async ({ userId, listId, listName, description }) => {
    const formData = new FormData();
    userId !== null && formData.append("user_id", userId);
    listId !== null && formData.append("list_id", listId);
    formData.append("list_name", listName);
    formData.append("description", description);

    try {
      const response = await API.post(`/job-board/list-save-as`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const shareListAsync = createAsyncThunk(
  "candidate/shareList",
  async ({ userId, selectedShareListId, selectCompanyUsersId }) => {
    const formData = new FormData();
    userId !== null && formData.append("shared_by", userId);
    selectedShareListId !== null &&
      formData.append("list_id", selectedShareListId);

    selectCompanyUsersId !== null &&
      Object.keys(selectCompanyUsersId).forEach((key) => {
        formData.append("shared_with[]", selectCompanyUsersId[key]);
      });

    try {
      const response = await API.post(`/job-board/share-list`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
