import React, { useEffect } from 'react'
import ConfettiExplosion from "react-confetti-explosion";

const ConfettiModal = ({ title, closeModal }) => {
  const largeProps = {
    force: 0.8,
    duration: 3000,
    particleCount: 150,
    width: 1600,
    colors: ['#009FB5', '#2B2B2B'],
    zIndex: 9999,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (closeModal) {
        closeModal()
      }
    }, 10000)
    return () => clearTimeout(timer)
  }, [])
  return (
    <div className="modal-body text-center">
      <ConfettiExplosion {...largeProps} />
      <div className="mb-4">
        <img src="/image/success-modal.png" alt="Icon" />
      </div>
      <h3 className="mt-0">Congratulations!</h3>
      {title}
    </div>
  )
}

export default ConfettiModal