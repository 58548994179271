import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";
import JobRecordRight from "../../Common/JobRecord/JobRecordRight";
import JobCardMbl from "../../Common/JobCardMbl/JobCardMbl";
import JobNoRecordFound from "../../Common/JobNoRecordFound/JobNoRecordFound";
import API from "../../../api";
import "./JobSection.css";

const JobSection = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [count, setCount] = useState(10);
  const [openSearch, setOpenSearch] = useState(false);
  const [searchingInput, setSearchingInput] = useState("");
  const [showMsg, setShowMSg] = useState(false);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const countHandler = () => {
    if (count < 30) setCount((prevCount) => prevCount + 10);
    else navigate("/job-search");
  };
  useEffect(() => {
    async function getLatestJob() {
      try {
        let params = `?`;
        if (user !== null) {
          params += `user_id=${user?.id}`;
        }
        await API.get(`/job-board/new_jobs${params}`)
          .then((response) => {
            if (response?.status === 200) {
              let res = response?.data;
              setData(res);
              setSearchData(res);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
    getLatestJob();
  }, []);
  if (data?.length === 0) return null;

  const handleFilterJobs = (e) => {
    if (e.target.value === "") {
      setShowMSg(false);
    }
    setSearchingInput(e.target.value);
    let searchInput = e.target.value;
    if (searchInput !== "") {
      const searchedData = searchData.filter(
        (data) =>
          data?.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          data?.company?.name.toLowerCase().includes(searchInput.toLowerCase())
      );
      if (searchedData.length > 0) {
        setData(searchedData);
        setShowMSg(false);
      } else {
        setShowMSg(true);
      }
    } else {
      setData(searchData);
    }
  };

  const closeToast = () => {
    setSearchingInput("");
    setShowMSg(false);
  };

  return (
    <>
      <section className="job-heading">
        <div className="container container-site">
          <div className="job-sec-wrape">
            <div
              className={`job-heading-home job-heading-local w-100 ${openSearch ? "job-heading-local-mbl" : ""
                }`}
            >
              <h2>Explore more jobs here</h2>
              <div
                className={`job-search-div m-0 ${openSearch ? "job-searchBtn-open" : "job-search-div-mbl"
                  }`}
                onClick={() => {
                  setOpenSearch(true);
                }}
              >
                <img
                  className="job-searchIcon job-searchIcon-mbl"
                  src="/image/job-searchIcon.png"
                  alt="job-searchBar"
                />
              </div>
              <div
                className={`job-search-div m-0 ${openSearch ? "job-search-div-abs" : "job-search-open"
                  }`}
              >
                <img
                  className="job-searchIcon"
                  src="/image/job-searchIcon.png"
                  alt="job-searchBar"
                  onClick={() => {
                    setOpenSearch(false);
                  }}
                />
                <input
                  placeholder="Search jobs"
                  className="job-search-input"
                  value={searchingInput}
                  onChange={handleFilterJobs}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="new-job">
        <div className="container container-site">
          <div className="job-sec-wrape">
            {data?.length > 0 && showMsg === false ? (
              data?.slice(0, count)?.map((prod, index) => (
                <>
                  <span className="jobs-section-lg desktop-jobs">
                    <div className="Job-Opportunities-row">
                      <JobRecordLeft JobData={prod} index={index} />
                      <JobRecordRight
                        date={prod?.date}
                        selectedJobId={prod?.id}
                        jobCountry={prod?.country}
                        isApplied={prod?.is_applied}
                        wishList={prod?.whishlist}
                      />
                    </div>
                    <div className="divider divide-desk"></div>
                    <div className="divider divide-mbl"></div>
                  </span>
                  <span className="mbl-jobs">
                    <JobCardMbl
                      JobData={prod}
                      index={index}
                      date={prod?.date}
                      jobCountry={prod?.country}
                      selectedJobId={prod?.id}
                      isApplied={prod?.is_applied}
                      wishList={prod?.whishlist}
                    />
                  </span>
                </>
              ))
            ) : (
              <JobNoRecordFound />
            )}
          </div>
          <div className="divider"></div>
        </div>
        {data?.length > 10 && (
          <div className="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>
                More jobs
                {/* <span className="toggleSeeMoreText">See more</span> */}
              </button>
            </Link>
          </div>
        )}
      </section>
    </>
  );
};
export default JobSection;
