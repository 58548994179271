export const formatSalary = (salary, currency, range, salaryEndRange) => {

  if (currency === 'MKD') {
    let newValue = `${salary}`;
    newValue = newValue.replace(/[^\d.]/g, "");
    // newValue = parseFloat(newValue).toFixed(2);
    newValue = `${parseInt(newValue)}`
    if (newValue.indexOf(".") === 0) {
      newValue = "0" + newValue;
    }
    newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return newValue + ` ${salaryEndRange ? 'per' : ""} ${range}`
  } else {
    let newValue = `${salary}`;
    newValue = newValue.replace(/[^\d.]/g, "");
    // newValue = parseFloat(newValue).toFixed(2);
    newValue = `${parseInt(newValue)}`
    if (newValue.indexOf(".") === 0) {
      newValue = "0" + newValue;
    }
    newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    console.log("new value", newValue)

    return newValue + ` ${salaryEndRange ? 'per' : ""} ${range}`
  }
}