export const FormDropdown = ({
  label,
  placeholder,
  required,
  value,
  isInvalid,
  error,
  options,
  onSelect,
}) => {
  const loading = false;
  return (
    <div
      className={`f-admin feild-f-admin ${isInvalid && "error-input-admin"}`}
    >
      <label for="">
        {label} {required && "*"}
      </label>
      <div className={`admin-status-filter-form`}>
        <div
          id="dropdown-boxId2"
          className="company-form-dropdownBox1"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="text-filter-admin">
            <p className={` ${value !== "" && "text-filter-select"}`}>
              {value === "" ? placeholder : value}
            </p>

            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : isInvalid ? (
              <img src="/image/warning.png" alt="" />
            ) : (
              <img src="/image/admin-d.png" alt="" />
            )}
          </div>
        </div>
        {options?.length > 0 && (
          <div className="admin-status-dropdown-frm">
            <ul className="list-job-title-company">
              {options?.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    onSelect(item?.id, item?.name, item);
                  }}
                >
                  {item?.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {error}
    </div>
  );
};
