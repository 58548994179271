import { useEffect, useState, Fragment } from "react";
import { formatTimestamp } from "../../../utils/formatDate";
import { editJobCommentsAsync } from "../../../redux/slices/commentModalSlice";
import { useSelector, useDispatch } from "react-redux";

const CommentRightCard = ({ comment, removeCommentClickHandler, handleSubmit }) => {
  const dispatch = useDispatch();

  //Edit Comment
  const [commentMode, setCommentMode] = useState(false);
  const [editComment, setEditComment] = useState("");

  const editCommentHandler = (e) => {
    const value = e.target.value;
    setEditComment(value);
  };

  const editCommentModeHandler = (value) => {
    setCommentMode(value);
  };

  const EditComments = async (candidateId) => {
    dispatch(editJobCommentsAsync({ editComment, candidateId }))
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          editCommentModeHandler(false);
          handleSubmit()
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setEditComment(comment?.comments);
  }, [comment]);

  return (
    <>
      <div className="comment-chat-model-right info-border">
        <div className="chat-comment-box-right">
          <div className="chat-f-icon">
            <div className="comment-add-box-right">
              {commentMode ? (
                <div className="edit-icon-model">
                  <div>
                    <img
                      src="/image/chat-right.png "
                      alt="test "
                      onClick={() => EditComments(comment?.id)}
                    />
                  </div>
                  <div onClick={() => {
                    setEditComment(comment?.comments);
                    editCommentModeHandler(false);
                  }}>
                    <p className="m-0">
                      <img src="/image/chat-cut.png" alt="test" />
                    </p>
                  </div>

                </div>
              ) : (
                <div className="edit-icon-model">
                  <div onClick={() => {
                    setEditComment(comment?.comments);
                    editCommentModeHandler(true);
                  }}>
                    <p className="m-0">
                      <img src="/image/pen.png" alt="test" />
                    </p>
                  </div>
                  <div onClick={() => removeCommentClickHandler(comment?.id)}>
                    <img src="/image/bin.png " alt="test " />
                  </div>
                </div>
              )}
            </div>

            {commentMode ? (
              <div className="chat-info-box-f" contenteditable="true">
                <input
                  type="text"
                  name="editComment"
                  id=" "
                  autoFocus={commentMode}
                  className="chat-user-in-f"
                  onChange={(e) => editCommentHandler(e)}
                  value={editComment}
                  disabled={!commentMode}
                />
              </div>
            ) : (
              <div className="chat-info-box-f">
                <p className="left-chat-c ">{editComment} </p>
              </div>
            )}
          </div>
        </div>
        <p className="time-chat-left ">
          {" "}
          {formatTimestamp(comment?.created_at)}
        </p>
      </div>
    </>
  );
};

export default CommentRightCard;
