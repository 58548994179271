import { useSelector } from "react-redux";
import AdminHeader from "./AdminHeader/AdminHeader";
import AdminSidebar from "./AdminSidebar/AdminSidebar";
import PermissionModel from "../Common/Admin/PermissionModel/PermissionModel";
import ComfrimModel from "../Common/Admin/ComfrimModel/ComfrimModel";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminLayout = ({ children }) => {
  const { permissionModel, deleteAction } = useSelector(
    (state) => state.adminDashboard
  );
  return (
    <>
      <div className="wrapper">
        {/* <!-- Sidebar --> */}
        <AdminSidebar />
        {/* <!-- Main Content --> */}
        {/* <!-- header start here --> */}
        <div class="main-admin">
          <AdminHeader />
          <ToastContainer />
          <div className="bg-clr-admin">{children}</div>
        </div>
      </div>
      {permissionModel && <PermissionModel />}
      {deleteAction?.open && <ComfrimModel />}
    </>
  );
};

export default AdminLayout;
