import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FindJob from "../../../components/Common/FindJob/FindJob";
import MostSearchJob from "../../../components/Common/MostSearchJob/MostSearchJob";
import JobHead from "../../../components/Job/JobHead/JobHead";
import JobDescription from "../../../components/Job/ViewDetail/JobDescription";
import SimilarJobs from "../../../components/Job/ViewDetail/SimilarJobs";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import LoginModel from "../../../components/Common/LoginModel/LoginModel";
import { jobTags, JobDescriptionData, JobDetailData } from "../../../data/job";
import {
  setMostSearchJob,
  setSearchWord,
} from "../../../redux/slices/JobSearchSlice";
import API from "../../../api";
import { ADMIN } from "../../../constants";
import "./index.css";


const ViewDetail = () => {
  const UrlParams = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isEmailVerify, user } = useSelector((state) => state.auth);
  const { mostSearchJob } = useSelector((state) => state.jobSearch);

  const [search, setSearch] = useState("");
  const [showMsg, setShowMSg] = useState(false);

  const [qaStatus, setQAStatus] = useState(false);
  const [qrCodeData, setQRCodeData] = useState(null);
  const [jobDetail, setJobDetail] = useState([]);
  const [similarJobs, setSimilarJobs] = useState([]);
  const headRef = useRef(null);

  const findJobHandler = () => {
    navigate("/job-search");
  };

  const onMostSearchjobClick = (value) => {
    dispatch(setSearchWord(value));
    dispatch(setMostSearchJob(!mostSearchJob));
    navigate("/job-search");
  };

  const scrollToHead = () => {
    if (headRef.current) {
      headRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };
  useEffect(() => {
    async function getJobDetail() {
      if (UrlParams?.id !== null) {
        let params = `?`;
        if (user !== null) {
          params += `user_id=${user?.id}&`;
        }
        if (UrlParams?.id !== null) {
          params += `jobId=${UrlParams?.id}&`;
        }
        if (search !== "") {
          params += `search=${search}&`;
        }
        try {
          await API.get(`/job-board/job-detail${params}`)
            .then((response) => {
              if (response?.status === 200) {
                let res = response?.data;
                if (search !== "") {
                  setSimilarJobs(res?.similarJobs);
                  setShowMSg(true);
                } else {   
                  setJobDetail(res?.jobDetail);
                  setSimilarJobs(res?.similarJobs);
                  setQAStatus(res?.qa_status);
                  setQRCodeData(res?.qr_code_data);
                  setShowMSg(false);
                }
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("else");
        setJobDetail(JobDescriptionData);
        setSimilarJobs(JobDetailData);
      }
    }
    console.log("urlParams are: ", UrlParams);
    getJobDetail();
  }, [UrlParams, search]);

  useEffect(() => {
    if (jobDetail) {
      scrollToHead();
    }
  }, [UrlParams, jobDetail]);

  return (
    <>
      {jobDetail?.length > 0 ? (
        <>          
          <span ref={headRef}>
            <JobHead hideQuesationBtn={false} jobDetail={jobDetail} qaStatus={qaStatus} />
          </span>
          <JobDescription jobDetail={jobDetail} qrCodeData={qrCodeData}/>
          <SimilarJobs
            showMsg={showMsg}
            similarJobs={similarJobs}
            onSearchChange={onSearchChange}
          />
          <WorldConnect />
          <LoginModel />
        </>
      ) : (
        <div className="loader-div">
          <span
            className="spinner-border spinner-border"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
    </>
  );
};

export default ViewDetail;
