const getDaysUntilExpiration = (expireDateString) => {
  const expireDate = new Date(expireDateString); // Convert the expire_date string to a Date object
  const currentDate = new Date(); // Get current date

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = expireDate.getTime() - currentDate.getTime();

  // Convert milliseconds to days
  const differenceInDays = Math.ceil(
    differenceInMilliseconds / (1000 * 3600 * 24)
  );

  return differenceInDays;
};

export default getDaysUntilExpiration;
