import { useDispatch } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { setPermissionModel } from "../../../../redux/slices/AdminDashboardSlice";
import "./PermissionModel.css";
import ActionModal from "../../ActionModal/ActionModal";

const PermissionModel = () => {
  const dispatch = useDispatch();
  return (
    <>
      <ActionModal
        heading="Double-Check"
        closeModal={() => dispatch(setPermissionModel(false))}
        modalArt="/image/warning-wishlist.png"
        content="Please see permissions tab for module access"
      ></ActionModal>

      {/* <section className="model-popup-post-job">
        <div className="popup-admin-portal">
          <OutsideClickHandler
            onOutsideClick={() => {
              dispatch(setPermissionModel(false));
            }}
          >
            <div className="popup-content-admin">
              <div
                className="close-img-admin"
                onClick={() => {
                  dispatch(setPermissionModel(false));
                }}
              >
                <img src="/image/chat-cut.png" alt=" " />
              </div>
              <div className="uper-part-admin">
                <div className="admin-logo-site">
                  <img src="/image/polycareers-north-macedonia.png" alt=" " />
                </div>
                <div className="admin-logo-model">
                  <img src="/image/model-admin.png" alt=" " />
                </div>
              </div>
              <div className="lower-part-admin">
                <p className="text-admin-two">
                  Please see permissions tab for module access
                </p>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </section> */}
    </>
  );
};

export default PermissionModel;
