import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {};
const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
});

// Action creators are generated for each case reducer function
export const { setCandidateListId, setCandidateStatues } = ProfileSlice.actions;

export default ProfileSlice.reducer;

// asynchronous function with createAsyncThunk
export const getPDFResumeDownloadAsync = createAsyncThunk(
  "profile/getPDFResumeDownload",
  async ({ UserId, resumeId }) => {
    let params = `?`;
    if (resumeId !== null) {
      params += `candidate_id=${resumeId}`;
    }
    try {
      const response = await API.get(`/job-board/download-resume-pdf${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getWordResumeDownloadAsync = createAsyncThunk(
  "profile/getWordResumeDownload",
  async ({ UserId, resumeId }) => {
    let params = `?`;
    if (UserId !== null) {
      params += `candidate_id=${UserId}`;
    }
    if (resumeId !== null) {
      params += `&resume_id=${resumeId}`;
    }

    try {
      const response = await API.get(
        `/job-board/download-resume-word${params}`
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
