import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  jobAppliedId: null,
  withdrawJobId: null,
  resumeUrl: null,
};
const candidateDashboardSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    setJobAppliedId: (state, action) => {
      state.jobAppliedId = action.payload;
    },
    setWithdrawJobId: (state, action) => {
      state.withdrawJobId = action.payload;
    },
    setResumeUrl: (state, action) => {
      state.resumeUrl = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setJobAppliedId, setWithdrawJobId, setResumeUrl } =
  candidateDashboardSlice.actions;

export default candidateDashboardSlice.reducer;

// asynchronous function with createAsyncThunk
export const jobAppliedAsync = createAsyncThunk(
  "candidate/jobApplied",
  async ({
    userId,
    jobId,
    resume_id,
    include_profile,
    show_expected_salary,
  }) => {
    let params = `?`;
    if (userId !== null) {
      params += `user_id=${userId}&`;
    }
    if (jobId !== null) {
      params += `job_id=${jobId}&`;
    }
    if (resume_id !== null) {
      params += `resume_id=${resume_id}&`;
    }
    if (jobId !== null) {
      params += `include_profile=${include_profile}&`;
    }
    if (jobId !== null) {
      params += `show_expected_salary=${show_expected_salary}`;
    }

    try {
      const response = await API.post(`/job-board/apply-job${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const withdrawJobAsync = createAsyncThunk(
  "candidate/withdrawJob",
  async ({ userId, jobId }) => {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("job_id", jobId);

    try {
      const response = await API.post(
        `/job-board/withdraw-application`,
        formData
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
