import { Outlet } from "react-router-dom";
import Layout from "../components/Layout/Layout";
export const PublicRoutes = ({ findJobHide, loginHide, signUpHide }) => {
  return (
    <Layout
      findJobHide={findJobHide}
      loginHide={loginHide}
      signUpHide={signUpHide}
    >
      <Outlet />
    </Layout>
  );
};
