import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PricingCard from './pricingCard'
import './PrincingTable.css';
import Button from 'react-bootstrap/Button';
import { setLoginModel } from "../../redux/slices/AuthSlice";
import LoginModel from "../../components/Common/LoginModel/LoginModel";
import API from "../../api";
import { toast } from "react-toastify";



const PricingTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pricingPlans, setPricingPlans] = useState([]);
  const [planSubscribed, setPlanSubscribed] = useState([]);
  const [customerExists, setCustomerExists] = useState([]);
  const { isEmailVerify, user, resumeVerified } = useSelector(
    (state) => state.auth
  );

  const getSubscription = async (stripeId) => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else {
      // initializeCheckout(stripeId)
      checkSubscription(stripeId)
      // navigate("/post-job");
    }
  };

  const checkSubscription = (data) => {
    //Stripe Customer_ID === ? yes or no
    // if (planSubscribed?.plan_id) {
    //   upgradeJobPlan(data)

    // } else {
    //   initializeCheckout(data)
    // }
    if (customerExists == 0) {
      initializeCheckout(data)
    } else {
      upgradeJobPlan(data)
    }
  }

  async function upgradeJobPlan(id) {

    try {
      const formData = {
        price_id: id,
        user_id: user.id
      }
      await API.post(`/job-board/upgrade-plan`, formData)
        .then((response) => {
          if (response) {
            toast.success(response?.data?.message.success, {
              autoClose: 2000,
              closeOnClick: false,
            });
            setTimeout(() => {
              window.location.reload();
            }, 3000)
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  async function initializeCheckout(id) {
    try {
      const formData = {
        price_id: id,
        user_email: user.email
      }
      await API.post(`/job-board/plan-checkout`, formData)
        .then((response) => {
          // navigate(response.data.checkout_url)
          const url = response.data.checkout_url;
          const newWindow = window.open(url, '_self', 'noopener,noreferrer');
          if (newWindow) {
            newWindow.opener = null; // Prevent new tab from having access to current tab
            newWindow.location.href = url; // Navigate the new tab to the specified URL
          } else {
            // Fallback if opening in new tab fails
            navigate(url); // Navigate within the current tab if new tab failed to open
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function pricingPlan() {
    try {
      await API.get(`/job-board/get-subscription-plans?user_id=${user?.id}`)
        .then((response) => {
          if (response?.status === 200) {
            let res = response?.data.PricingPlan;
            setPricingPlans(res);
            setPlanSubscribed(response?.data?.planSubscribed)
            setCustomerExists(response?.data?.customer_exists)
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    pricingPlan()
  }, []);
  return (
    <div>
      <div className="container container-site py-2">
        <Button variant="link" className="back-to-home add top-0 position-relative" onClick={() => navigate(-1)}>
          <img src="/image/form-back-arrow.png" alt="" />
        </Button>
      </div>
      <div className="job-heading">
        <div className="container-site">
          <h2>Pricing</h2>
        </div>
      </div>
      <div className='container container-site'>
        <div className="pricing-tabs">
          <ul className="nav nav-tabs">
            <li className='nav-item active'>
              <Link className='nav-link' to="#1a" data-toggle="tab">Monthly</Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to="#2a" data-toggle="tab">Yearly (save 20%)</Link>
            </li>
          </ul>

          <div className="tab-content clearfix">
            <div className="tab-pane active" id="1a">
              {pricingPlans && (
                <div className='price-card-sec'>
                  {pricingPlans.map((val) => {
                    return (
                      planSubscribed?.interval === "month" ? (
                        <PricingCard
                          getSubscription={getSubscription}
                          planSubscribed={planSubscribed}
                          val={val}
                          tabType='monthly'
                        />) : (
                        <PricingCard
                          getSubscription={getSubscription}
                          val={val}
                          tabType='monthly'
                        />)
                    )
                  })}
                </div>
              )}
            </div>
            <div className="tab-pane" id="2a">
              <div className='price-card-sec'>
                {pricingPlans.slice(1).map((val) => {
                  return (
                    planSubscribed?.interval === "year" ? (
                      <PricingCard
                        getSubscription={getSubscription}
                        planSubscribed={planSubscribed}
                        val={val}
                        tabType='yearly'
                      />) : (
                      <PricingCard
                        getSubscription={getSubscription}
                        val={val}
                        tabType='yearly'
                      />)
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginModel />
      {/* <stripe-pricing-table
        pricing-table-id="prctbl_1OvtquKlPKTZ8WKL4452xQ12"
        publishable-key="pk_test_51Ovt1FKlPKTZ8WKLj2oLk6dYL3qdyzD80EaUKGZY6N8YYGxx3z9HdstU3k3Vr0dxsJdkQfRS2KeMqf4DtdZUKtRp00sE9Bt68y" /> */}
    </div>
  )
}

export default PricingTable