const FormError = ({ errorMsg }) => {
  if (errorMsg === "") return;

  return (
    <div className="email-error--admin">
      <p>{errorMsg}</p>
    </div>
  );
};

export default FormError;
