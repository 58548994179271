import { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";
import JobDraftRight from "../../Common/JobRecord/JobDraftRight";
import JobNoRecordFound from "../../Common/JobNoRecordFound/JobNoRecordFound";
import { JobDraftData } from "../../../data/employer";

const JobDrafts = ({
  draftJobsCount,
  draftJobs,
  showJobDraft,
  onSearchChange,
  onChangeJobStatus,
  openDeleteItemHandler,
}) => {
  const [count, setCount] = useState(10);
  const [openSearch, setOpenSearch] = useState(false);

  const countHandler = () => {
    if (count < draftJobsCount) setCount((prevCount) => prevCount + 10);
  };

  if (showJobDraft === false && draftJobs?.length === 0) return null;

  return (
    <>
      <section className="job-heading" id="draft_jobs">
        <div className="container container-site">
          <div className="job-search-box-row">
            <div className="job-heading-home smilar-job-heading job-heading-local w-100">
              <h2>Draft jobs</h2>
              <div
                className={`job-search-div m-0 ${
                  openSearch ? "job-searchBtn-open" : "job-search-div-mbl"
                }`}
                onClick={() => {
                  setOpenSearch(true);
                }}
              >
                <img
                  className="job-searchIcon job-searchIcon-mbl"
                  src="/image/job-searchIcon.png"
                  alt="job-searchBar"
                />
              </div>
              <div
                className={`job-search-div m-0 ${
                  openSearch ? "job-search-div-abs" : "job-search-open"
                }`}
              >
                <img
                  className="job-searchIcon"
                  src="/image/job-searchIcon.png"
                  alt="job-searchBar"
                  onClick={() => {
                    setOpenSearch(false);
                  }}
                />
                <input
                  placeholder="Search jobs"
                  className="job-search-input"
                  onChange={(e) => {
                    onSearchChange(e?.target?.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="company-job-sec open-postion">
        <div className="space-inner-row">
          <div className="container container-site">
            {draftJobs?.length > 0 ? (
              draftJobs?.slice(0, count)?.map((prod, index) => (
                <Fragment key={prod?.id}>
                  <div className="Job-Opportunities-row">
                    <JobRecordLeft
                      hideCompanyName={true}
                      activeSwitch={false}
                      radioBtn={false}
                      JobData={prod}
                      onChangeJobStatus={onChangeJobStatus}
                    />
                    <JobDraftRight
                      date={prod?.date}
                      selectedJobId={prod?.id}
                      onChangeJobStatus={onChangeJobStatus}
                      openDeleteItemHandler={openDeleteItemHandler}
                    />
                  </div>
                  <div className="divider"></div>
                </Fragment>
              ))
            ) : (
              <JobNoRecordFound />
            )}
          </div>
        </div>

        {draftJobs?.length > 10 && (
          <div className="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>See more</button>
            </Link>
          </div>
        )}
      </section>
    </>
  );
};

export default JobDrafts;
