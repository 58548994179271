import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIPvt from "../../apiPvt";

const initialState = {
  defaultPermissions: {},
  activeSideBar: { id: "2", name: "Department", value: "Department" },
  selectedDep: { id: null, name: "" },
  selectedRoleR: { id: null, name: "" },
  selectedCompanyUser: null,
  permissionModel: false,
  deleteAction: { open: false, msg: "" },
  formMode: "add",
};
const AdminDashboardSlice = createSlice({
  name: "adminDashboard",
  initialState,
  reducers: {
    setdefaultPermissions: (state, action) => {
      state.defaultPermissions = action.payload;
    },
    setActiveSideBar: (state, action) => {
      state.activeSideBar = action.payload;
    },
    setSelectedDep: (state, action) => {
      state.selectedDep = action.payload;
    },
    setSelectedRoleR: (state, action) => {
      state.selectedRoleR = action.payload;
    },
    setSelectedCompanyUser: (state, action) => {
      state.selectedCompanyUser = action.payload;
    },
    setPermissionModel: (state, action) => {
      state.permissionModel = action.payload;
    },
    setDeleteAction: (state, action) => {
      state.deleteAction = action.payload;
    },
    setFormMode: (state, action) => {
      state.formMode = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setdefaultPermissions,
  setActiveSideBar,
  setSelectedDep,
  setSelectedRoleR,
  setSelectedCompanyUser,
  setPermissionModel,
  setDeleteAction,
  setFormMode
} = AdminDashboardSlice.actions;

export default AdminDashboardSlice.reducer;

// asynchronous function with createAsyncThunk
export const depDropdownAsync = createAsyncThunk(
  "admin/depDropdown",
  async () => {
    try {
      const response = await APIPvt.get(`/departments-status-dropdown`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const roleDropdownAsync = createAsyncThunk(
  "admin/roleDropdown",
  async () => {
    try {
      const response = await APIPvt.get(`/get-data-for-create-role`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
