import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import {
  setClearFilter,
  setSearchWord,
  setSelectDate,
  setSelectJobLocation,
  setSelectJobLocationId,
  setSelectJobType,
} from "../../../redux/slices/JobSearchSlice";
import { datePostedValue } from "../../../data/job";
import API from "../../../api";
import "./FindJob.css";
import Button from 'react-bootstrap/Button';



const FindJob = ({ findJobHandler, findLoader }) => {
  const dispatch = useDispatch();
  const {
    searchWord,
    jobTypeId,
    selectJobType,
    jobLocationId,
    selectJobLocation,
    selectDate,
  } = useSelector((state) => state.jobSearch);

  const { countryISOCode } = useSelector(
    (state) => state.home
  );

  const [jobTitles, setTobTitles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [tags, setTags] = useState([]);

  const [searchDropDownHide, setSearchDropDownHide] = useState(true);

  const [jobTypes, setJobTypes] = useState([]);
  const [jobTypeHide, setJobTypeHide] = useState(true);
  const [locationList, setLocationList] = useState(true);

  const [jobLocation, setJobLocation] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  // const [jobLocationDropDownHide, setJobLocationDropDownHide] = useState(true);

  const [dateValue, setDateValue] = useState([]);
  const [dateDropDownHide, setDateDropDownHide] = useState(true);

  const onSearchWordChangeHandler = (searchValue) => {
    dispatch(setSearchWord(searchValue));
    getSearchSuggestion(searchValue, "keyword");
  };

  const onSearchWordClickHandler = async (value) => {
    setTobTitles([]);
    setCompanies([]);
    setTags([]);

    dispatch(setSearchWord(value));
    setSearchDropDownHide(true);
  };

  const onsearchDropDownHideHandler = (v) => {
    setSearchDropDownHide(v);
  };

  const onLocationDropDownHideHandler = (v) => {
    setLocationList(v);
  };

  const onselectJobTypeHandler = (id, name) => {
    dispatch(setSelectJobType({ id, name }));
    setJobTypeHide(true);
  };

  // const onJobLocationChangeHandler = (searchValue) => {
  //   dispatch(setSelectJobLocation(searchValue));

  //   getSearchSuggestion(searchValue, "location");
  // };

  const onJobLocationClickHandler = (id, name) => {
    setSearchTerm(name)
    dispatch(setSelectJobLocation(name));
    dispatch(setSelectJobLocationId(id));
    toggleLocation()

    // setJobLocationDropDownHide(true);
  };

  // const setJobLocationDropDownHideHandler = (v) => {
  //   setJobLocationDropDownHide(v);
  // };

  const onselectDateHandler = (id, name) => {
    dispatch(setSelectDate({ id, name }));
    setDateDropDownHide(true);
  };

  const clearFilterHandler = () => {
    dispatch(setClearFilter());
  };

  const getSearchSuggestion = async (searchValue, searchType) => {
    try {
      let params = ``;
      if (searchWord !== "" || selectJobLocation !== "") {
        params = `?query_key=${searchValue}&type=${searchType}`;
      }
      await API.get(`/job-board/get-search-suggestions${params}`)
        .then((response) => {
          if (response?.status === 200) {
            let res = response?.data;
            if (searchType === "keyword") {
              if (
                res?.job_titles?.length > 0 ||
                res?.companies?.length > 0 ||
                res?.tags?.length > 0
              ) {
                setTobTitles(res?.job_titles);
                setCompanies(res?.companies);
                setTags(res?.tags);
                setSearchDropDownHide(false);
              } else setSearchDropDownHide(true);
            }

            // if (searchType === "location") {
            //   if (res?.locations?.length > 0) {
            //     setJobLocation(res?.locations);
            //     setJobLocationDropDownHide(false);
            //   } else setJobLocationDropDownHide(true);
            // }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  function sortCitiesArray(array) {
    array.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return array;
  }

  const getCityValue = async () => {
    let countryVar = 1;
    console.log(countryISOCode);
    if (countryISOCode !== 'North Macedonia') {
      countryVar = 2
    }
    try {
      await API.get(`/get-cities-new?country_id=${countryVar}`)
        .then((response) => {
          if (response?.status === 200) {
            let res = response?.data;
            let sortedArr = sortCitiesArray(res);
            setJobLocation(sortedArr);
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {

    async function getjobTypes() {
      try {
        await API.get(`/job-board/job-types`)
          .then((response) => {
            if (response?.status === 200) {
              let res = response?.data;
              setJobTypes(res);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
    getjobTypes();
  }, []);

  useEffect(() => {
    getCityValue();
  }, []);


  useEffect(() => {
    async function getDatePosted() {
      try {
        await API.get(`/get-system-field-values?key=date_posted`)
          .then((response) => {
            if (response?.status === 200) {
              let res = response?.data;
              setDateValue(res?.data);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
    getDatePosted();
  }, []);

  const searchJobLocation = (e) => {
    setSearchTerm(e.target.value);
  }

  const toggleLocation = () => {
    setLocationList(!locationList)
    // setJobLocationResult([])
  }

  const filteredItem = jobLocation.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))

  return (
    <>
      <section className="f-dream job">
        <div className="container container-site">
          <div className="f-job-heaading d-none">
            <h2>Find Your Dream Job</h2>
          </div>
          <div className="filter-serach-btn position-relative">
            {/* <Button variant="link" className="back-to-home add">
              <img src="/image/form-back-arrow.png" alt="" />
            </Button> */}
            <div className="job-fillter-f">
              <div className="three-filter-inline" tabindex="0">
                <div className="job-keywords">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      onsearchDropDownHideHandler("true");
                    }}
                    className="d-flex"
                  >
                    <>
                      <img src="/image/cogg.png" alt="" className="job-filter-icon" />
                      <input
                        type="text"
                        name="searchWord"
                        value={searchWord}
                        onChange={(e) =>
                          onSearchWordChangeHandler(e.target.value)
                        }
                        id="job-search-field"
                        placeholder="Keywords, company, skills..."
                      />
                      <div
                        className={`job-search-dropdown ${searchDropDownHide && "job-dropdown-hide"
                          }`}
                      >
                        {jobTitles?.length > 0 && (
                          <>
                            <h3 className="job-suggestion">
                              Suggested Job Titles
                            </h3>
                            <ul className="dorpdown-list-search">
                              {jobTitles.map((j, i) => (
                                <li
                                  className="icon-cursor"
                                  onClick={() =>
                                    onSearchWordClickHandler(j?.name)
                                  }
                                >
                                  <img src="/image/job-search.png" alt="" />
                                  {j?.name}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                        {tags?.length > 0 && (
                          <>
                            <h3 className="job-suggestion">
                              Suggested Technical Tags
                            </h3>
                            <ul className="dorpdown-list-search">
                              {tags.map((j, i) => (
                                <li
                                  className="icon-cursor"
                                  onClick={() =>
                                    onSearchWordClickHandler(j?.name)
                                  }
                                >
                                  <img src="/image/job-search.png" alt="" />
                                  {j?.name}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}

                        {companies?.length > 0 && (
                          <>
                            <h3 className="job-suggestion">
                              Suggested Companies
                            </h3>
                            <ul className="dorpdown-list-search">
                              {companies.map((j, i) => (
                                <li
                                  className="icon-cursor"
                                  onClick={() =>
                                    onSearchWordClickHandler(j?.name)
                                  }
                                >
                                  <img src="/image/job-search.png" alt="" />{" "}
                                  {j?.name}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    </>
                  </OutsideClickHandler>
                </div>

                <div class="job-type-f">
                  <div
                    id="dropdown-boxId1"
                    class="company-form-dropdownBox"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => setJobTypeHide(false)}
                  >
                    <img src="/image/briefcase.png" alt="" className="job-filter-icon" />
                    <p>{selectJobType === "" ? "Choose Job Type" : selectJobType}</p>
                  </div>
                  <div
                    className={`job-search-dropdown-job-type jobType-dropdown-mbl ${jobTypeHide && "job-dropdown-hide"
                      }`}
                  >
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setJobTypeHide(true);
                      }}
                    >
                      <ul class="dorpdown-list-search-j-type">
                        {jobTypes?.length > 0 &&
                          jobTypes.map((j, i) => (
                            <li
                              className="icon-cursor"
                              onClick={() => {
                                onselectJobTypeHandler(j?.id, j?.name);
                              }}
                            >
                              <img src="/image/job-search.png" alt="" />{" "}
                              {j?.name}{" "}
                            </li>
                          ))}
                      </ul>
                    </OutsideClickHandler>
                  </div>
                </div>

                <div className="job-location-f pe-0">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      onLocationDropDownHideHandler("true");
                    }}
                    className="d-flex"
                  >
                    <>
                      <img src="/image/job-location.png" alt="" className="job-filter-icon" />
                      <input
                        type="text"
                        name="searchLocation"
                        value={searchTerm}
                        onChange={(e) =>
                          searchJobLocation(e)
                        }
                        id="job-search-field"
                        placeholder="Job Location"
                        onFocus={toggleLocation}
                        autoComplete="off"
                      />
                      <div
                        className={`job-location-dropdown ${locationList ? "job-dropdown-showhide" : 'open-dd'
                          }`}
                      >
                        {(jobLocation?.length > 0 && !filteredItem.length) && (
                          <>
                            <ul className="dorpdown-list-location">
                              {jobLocation.map((j, i) => (
                                <li
                                  className="icon-cursor"
                                  onClick={() =>
                                    onJobLocationClickHandler(j?.id, j?.name)
                                  }
                                >
                                  <img src="/image/location-j-place.png" alt="" />
                                  {j?.name}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                        {(filteredItem?.length > 0) && (
                          <>
                            <ul className="dorpdown-list-location">
                              {filteredItem.map((j, i) => (
                                <li
                                  className="icon-cursor"
                                  onClick={() =>
                                    onJobLocationClickHandler(j?.id, j?.name)
                                  }
                                >
                                  <img src="/image/location-j-place.png" alt="" />
                                  {j?.name}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    </>
                  </OutsideClickHandler>
                  <div className="location-input-f d-none">
                    <div
                      id="dropdown-boxId1"
                      className="company-form-dropdownBox"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src="/image/location-alt.png" alt="" className="job-filter-icon" />
                      <p>{selectJobLocation === "" ? "Job Location" : selectJobLocation}</p>
                    </div>
                    <div className="job-location-dropdown">
                      <ul className="dorpdown-list-location">

                        {jobLocation.map((j, i) => (
                          <li
                            className="icon-cursor"
                            onClick={() =>
                              onJobLocationClickHandler(j?.id, j?.name)
                            }
                          >
                            <img src="/image/location-j-place.png" alt="" />
                            {j?.name}
                          </li>
                        ))}


                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="job-post-date">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => setDateDropDownHide(false)}
                >
                  <img src="/image/calender.png" alt="" className="job-filter-icon" />
                  {selectDate === "" ? "Date Posted" : selectDate}
                </div>
                <div
                  className={`job-date-dropdown ${dateDropDownHide && "job-dropdown-hide"
                    }`}
                >
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setDateDropDownHide(true);
                    }}
                  >
                    <ul className="dorpdown-list-date">
                      {dateValue?.length > 0 &&
                        dateValue?.map((j, i) => (
                          <li
                            className="icon-cursor"
                            onClick={() => {
                              onselectDateHandler(j?.value, j?.label);
                            }}
                          >
                            <img src="/image/calander-d.png" alt="" />{" "}
                            {j?.label}{" "}
                          </li>
                        ))}
                    </ul>
                  </OutsideClickHandler>
                </div>
              </div>
            </div>

            <div className="find-job-btn job-filter-btn find-job-page">
              <a onClick={() => findJobHandler()}>
                <button>{findLoader ? (
                  <div className='findLoader'> <span
                    className="spinner-border spinner-border-sm loader-white"
                    role="status"
                    aria-hidden="true"
                  /></div>
                ) : (
                  <>
                    <img src="/image/search-white.png" alt="" className="mr-1" />
                    <p className="">Find jobs</p>
                  </>
                )}</button>
              </a>
              <p
                className="clear-filter icon-cursor"
                onClick={() => clearFilterHandler()}
              >
                Clear Filter
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FindJob;
