import { ModuleData } from "../../../data/admin/module";
import "./index.css";

const AdminDashboard = () => {
  return (
    <div className="admin-cards">
      <div className="page-title-heading">
        <h2>Modules</h2>
      </div>
      <div className="all-admin-cards-rows">
        {ModuleData?.length > 0 &&
          ModuleData?.map((item, index) => (
            <div
              className={`admin-card-one card-bg-${index} 
            }`}
            >
              <p className="admin-main-title">{item?.name}</p>
              <ul className="crad-admin-list">
                <li>Lorem Ipsum is simply dummy text</li>
                <li>Lorem Ipsum is simply dummy text</li>
                <li>Lorem Ipsum is simply dummy text</li>
              </ul>
              <button type="submit" className="admin-continue">
                Continue
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
