import React from "react";
import LoginForm from "../../../components/Auth/Login/LoginForm/LoginForm";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";

const Login = () => {
  return (
    <>
      <LoginForm />
      <WorldConnect />
    </>
  );
};

export default Login;
