import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSideBar,
  setDeleteAction,
  setFormMode,
  setSelectedDep,
  setSelectedRoleR,
} from "../../../redux/slices/AdminDashboardSlice";
import TablePagination from "../../../components/Common/Admin/TablePagination/TablePagination";
import TableError from "../../../components/Common/Admin/TabelError/TableError";
import APIPvt from "../../../apiPvt";
import "../Dashboard/index.css";

const AdminViewUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedRoleR } = useSelector((state) => state.adminDashboard);
  const [msg, setMsg] = useState("");
  const [userListingData, setUserListingData] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [dep, setDep] = useState([]);
  const [role, setRole] = useState([]);

  const [selectedDepDropDown, setSelectedDepDropDown] = useState({
    id: null,
    name: "",
  });

  const [selectedRole, setSelectedRole] = useState({
    id: null,
    title: "",
  });

  const [selectedStatus, setSelectedStatus] = useState({
    label: "",
    value: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onStatusClick = (label, value) => {
    setSelectedStatus({
      label: label,
      value: value,
    });
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onDepClick = (id, name) => {
    setSelectedDepDropDown({ id: id, name: name });
  };

  const onRoleClick = (id, title) => {
    setSelectedRole({
      id: id,
      title: title,
    });
  };

  const getUserListingData = async () => {
    if (selectedRoleR?.id !== null) {
      try {
        let params = `?`;
        if (search !== "") {
          params += `search=${search}&`;
        }
        if (selectedDepDropDown?.id !== null) {
          params += `department_id=${selectedDepDropDown?.id}&`;
        }
        if (selectedStatus?.value !== null) {
          params += `status=${selectedStatus?.value}&`;
        }
        if (currentPage !== null) {
          params += `page=${currentPage}&`;
        }
        if (selectedRole?.id === null) {
          params += `role_id=${selectedRoleR?.id}&`;
        } else if (selectedRole?.id !== null) {
          params += `role_id=${selectedRole?.id}&`;
        }
        setMsg("");
        await APIPvt.get(`/get-users-listing-admin${params}`)
          .then((response) => {
            if (response?.status === 200 || response?.success === true) {
              let res = response?.data;
              setUserListingData(res?.data);
              setDep(res?.departments);
              setRole(res?.roles);
              setStatus(res?.status);
              setTotalPages(res?.last_page);
              if (
                res?.data?.length === 0 &&
                (search === "" ||
                  selectedDepDropDown?.id === null ||
                  selectedStatus?.value === null)
              )
                setMsg("Please Create a User for this Role.");
              else if (
                res?.data?.length === 0 &&
                (search !== "" ||
                  selectedDepDropDown?.id !== null ||
                  selectedStatus?.value !== null)
              )
                setMsg("Please make another selection. No record found.");
              else {
                if (res?.data?.length !== 0) setMsg("");
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getUserListingData();
  }, [
    selectedRoleR,
    selectedDepDropDown,
    selectedRole,
    selectedStatus,
    search,
    currentPage,
  ]);

  return (
    <div className="page-space bg-clr-admin">
      <div className="page-title-heading">
        <h2>Users</h2>
      </div>
      <div className="filter-search-bar">
        <div className="two-drop-role">
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Department</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedDepDropDown?.name !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedDepDropDown?.name === ""
                      ? "Department"
                      : selectedDepDropDown?.name}
                  </p>
                  <img src="/image/admin-d.png" alt="" />
                </div>
              </div>

              {dep?.length > 0 && (
                <div className="admin-status-dropdown">
                  <ul className="list-job-title-company">
                    {dep?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onDepClick(item?.id, item?.name);
                        }}
                      >
                        {item?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Roles</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedRole?.title !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedRole?.title === "" ? "Roles" : selectedRole?.title}
                  </p>
                  <img src="/image/admin-d.png" alt="" />
                </div>
              </div>

              {role?.length > 0 && (
                <div className="admin-status-dropdown">
                  <ul className="list-job-title-company">
                    {role?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onRoleClick(item?.id, item?.title);
                        }}
                      >
                        {item?.title}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Status</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedStatus?.label !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedStatus?.label === ""
                      ? "Status"
                      : selectedStatus?.label}
                  </p>
                  <img src="/image/admin-d.png" alt="" />
                </div>
              </div>

              {status?.length > 0 && (
                <div className="admin-status-dropdown">
                  <ul className="list-job-title-company">
                    {status?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onStatusClick(item?.label, item?.value);
                        }}
                      >
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-right-searcgadmin">
          <div className="search-admin">
            <img src="/image/job-search.png" alt="" />
            <input
              type="text"
              name="search"
              id="jon-search-box"
              placeholder="Search"
              className="form-font-f"
              onChange={(e) => {
                onSearchChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="department-table-imge">
        <div className="col-user-table">
          <div className="table-responsive">
            <table className="depart-table-left">
              <thead className="tb-heading-user">
                <tr>
                  <th scope="col">
                    <div className="th-admin-heading">Name</div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">Department</div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">Role name</div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">User name</div>
                  </th>
                  <th scope="col" className="size-th-user">
                    <div className="th-admin-heading">Email</div>
                  </th>
                  <th scope="col" className="size-th-user-status">
                    <div class="th-admin-heading">Status</div>
                  </th>
                </tr>
              </thead>
              <tbody className="tb-user">
                {userListingData?.length > 0 &&
                  userListingData?.map((item, index) => (
                    <tr>
                      <td className="">
                        <div className="name-sr-circle">
                          {`${item?.first_name}  ${item?.last_name}`}
                        </div>
                      </td>
                      <td>{item?.roles[0]?.department?.name}</td>
                      <td>{item?.roles[0]?.title}</td>
                      <td>{item?.username}</td>
                      <td>{item?.email}</td>
                      <td>
                        <div
                          className={
                            item?.roles[0]?.status === 1
                              ? "active-tb-data"
                              : "inactive-tb-data"
                          }
                        >
                          {" "}
                          {item?.roles[0]?.status === 1 ? "Active" : "Inactive"}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {userListingData?.length > 0 && (
            <TablePagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          )}
          <TableError msg={msg} />
        </div>
      </div>
    </div>
  );
};

export default AdminViewUser;
