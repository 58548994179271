import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCandidateDetailTab } from "../../../../redux/slices/candidateDetailSlice";
import "./CanDetailTab.css";

const CanDetailTab = ({ list, detailTab, onTabClick }) => {
  const dispatch = useDispatch();

  return (
    <ul className="nav nav-tabs mb-3 tabs-summary" id="ex1" role="tablist">

      {list?.length > 0 &&
        list?.map((item, index) => (
          <li
            className="nav nav-tabs nav-fill"
            role="presentation"
            onClick={() => {
              onTabClick(item?.value);
            }}
          >
            <Link
              className={`nav-link tab-size-jour ${detailTab === item?.value && "active"
                }`}
              id="ex1-tab-1"
              data-mdb-toggle="tab"
              role="tab"
              aria-controls="ex1-tabs-1"
              aria-selected="true"
            >
              {item?.name}
            </Link>
          </li>
        ))}
    </ul>
  );
};

export default CanDetailTab;
