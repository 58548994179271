import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setEmailVerify,
  setLoginUser,
  setLoginUserName,
  setResumeVerified,
  setResumeDetail,
  setSignUpBarHide,
  setUserResumes,
} from "../../../redux/slices/AuthSlice";

import { setdefaultPermissions } from "../../../redux/slices/AdminDashboardSlice";

import { ADMIN, CANDIDATE, RESUME_MODE } from "../../../constants";
import { setResumeFormMode, setResumeRetryCount } from "../../../redux/slices/verifyResumeSlice";
import { getResumeForApplyingOnJob } from "../../../redux/slices/candidateDetailSlice";

function GoogleCallback() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [user, setUser] = useState(null);
  const location = useLocation();

  const called = useRef(false);

  // On page load, we take "search" parameters
  // and proxy them to /api/auth/callback on our Laravel API

  useEffect(() => {
    if (!called.current) {
      FetchData();

      called.current = true;
    }
  }, []);

  const getResumes = async (userId) => {
    dispatch(
      getResumeForApplyingOnJob({
        userId,
      })
    )
      .then(async (response) => {
        const res = response?.payload?.data;
        dispatch(setUserResumes(res));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const FetchData = () => {
    fetch(
      `https://demo.workbridgetalent.ca/backend-files/public/api/v1/auth/callback${location.search}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then(async (data) => {
        setData(data);
        setLoading(false);

        const user = data.user;

        const access_token = data.access_token;
        dispatch(setLoginUser({ user: user, access_token: access_token }));
        dispatch(setLoginUserName(user?.first_name));
        dispatch(setEmailVerify(true));

        await getResumes(user?.id);
        dispatch(setResumeVerified(user?.resumeVerified));
        dispatch(
          setResumeDetail({
            resumeId: user?.resume_id,
            resumeType: user?.resume_type,
          })
        );
        // dispatch(setFavoriteJobsCount(res?.favorite_job_count));
        dispatch(setSignUpBarHide(true));
        if (user?.permissions)
          dispatch(setdefaultPermissions(user?.permissions));
        localStorage.setItem("token", data?.access_token);
        if (user?.user_type && CANDIDATE.includes(user?.user_type)) {
          if (user?.resumeVerified === 0) {
            dispatch(setResumeFormMode(RESUME_MODE.VERIFICATION));
            dispatch(setResumeRetryCount(0));
            navigate("/candidate-resume");
          } else if (user?.resumeVerified !== 0) {
            dispatch(setResumeFormMode(RESUME_MODE.UPDATE));
            navigate("/candidate-dashboard");
          }
        }
        // setTimeout(() => {
        //   navigate("/candidate-dashboard");

        // }, 500)
      });
  };

  // Helper method to fetch User data for authenticated user
  // Watch out for "Authorization" header that is added to this call
  function fetchUserData() {
    // fetch(`http://largoo.test/api/user`, {
    fetch(`https://demo.workbridgetalent.ca/backend-files/public/api/user`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + data.access_token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUser(data);
      });
  }

  if (loading) {
    return <DisplayLoading />;
  } else {
    if (user != null) {
      return <DisplayData data={user} />;
    } else {
      return (
        <div>
          <DisplayData data={data} />
          <div style={{ marginTop: 10 }}>
            <button onClick={fetchUserData}>Fetch User</button>
          </div>
        </div>
      );
    }
  }
}

function DisplayLoading() {
  return (
    <div className="fullscreen-spinner">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

function DisplayData(data) {
  return (
    <div>
      <samp>{JSON.stringify(data, null, 2)}</samp>
    </div>
  );
}
export default GoogleCallback;
