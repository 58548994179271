import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ListingTable from "../../../components/Common/ListingTable/ListingTable";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import Button from "react-bootstrap/Button";
import ActionModal from "../../../components/Common/ActionModal/ActionModal";

// Redux  Slice
import {
  createListAsync,
  deleteListAsync,
  editListAsync,
  getEmpListAsync,
  saveAsListAsync,
  setActiveListingTab,
  shareListAsync,
} from "../../../redux/slices/listingSlice";
import { setCandidateListingDropdown } from "../../../redux/slices/candidateListingSlice";

// Import Model
import CreateListModel from "../../../components/Common/CreateListModel/CreateListModel";
import EditListModel from "../../../components/Common/EditListModel/EditListModel";
import ShareListModel from "../../../components/Common/ShareListModel/ShareListModel";
import SaveAsListModel from "../../../components/Common/SaveAsListModel/SaveAsListModel";
import ContentModal from "../../../components/Common/ContentModal/ContentModal";

import { EMP_CAND_LIST, EMP_LIST } from "../../../constants";
import API from "../../../api";


const EmpList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { candidateListId } = useSelector((state) => state.employerDashboard);
  const { activeListingTab } = useSelector((state) => state.listing);

  const [count, setCount] = useState(10);
  const [loader, setLoader] = useState(false);

  /*Toggle Model*/
  const [toggleCreateListModel, setToggleCreateListModel] = useState(false);
  const [toggleDeleteListModel, settoggleDeleteListModel] = useState(false);
  const [toggleEditListModel, settoggleEditListModel] = useState(false);
  const [toggleShareListModel, settoggleShareListModel] = useState(false);
  const [toggleSaveAsListModel, settoggleSaveAsListModel] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const [AllList, setAllList] = useState([]);

  /*Listing Dropdown Value*/
  const [searchListing, setSearchListing] = useState(null);

  const [listingDropDown, setListingDropDown] = useState([
    {
      label: "Created by me",
      value: EMP_LIST.CREATED_BY_ME,
    },

    {
      label: "Lists shared by me",
      value: EMP_LIST.SHARED_BY_ME,
    },
    {
      label: "Lists shared with me",
      value: EMP_LIST.SHARED_WITH_ME,
    },
  ]);

  const [selectedLsting, setselectedLsting] = useState({
    label: null,
    value: null,
  });

  /*Listing Delete */

  const [selectedDeleteListId, setselectedDeleteListId] = useState(null);

  /*Listing Edit */

  const [selectedEditListing, setselectedEditListing] = useState({
    id: null,
    name: "",
    des: "",
  });

  /*Listing Share */

  const [selectedShareListId, setselectedShareListId] = useState(null);

  /*Listing Save As */

  const [selectedSaveAsListing, setselectedSaveAsListing] = useState({
    id: null,
    name: "",
    des: "",
  });

  const countHandler = () => {
    if (count < AllList?.length) setCount((prevCount) => prevCount + 10);
  };

  /* Listing filter Event */

  const onSearchListingChange = (value) => {
    setSearchListing(value);
  };

  const onListingClick = (label, value) => {
    dispatch(setActiveListingTab(value));
    setselectedLsting({
      label: label,
      value: value,
    });
  };

  const onListingClean = () => {
    dispatch(setActiveListingTab(EMP_LIST.CREATED_BY_ME));
    setselectedLsting({
      label: null,
      value: null,
    });
  };

  /*Model Event Handler*/

  const createListModelHandler = (toggleModel, callAPi) => {
    setToggleCreateListModel(toggleModel);
    if (callAPi) {
      getEmpListData();
    }
  };

  const deleteListModelHandler = (toggleModel, listId) => {
    setselectedDeleteListId(listId);
    settoggleDeleteListModel(toggleModel);
  };

  const editListModelHandler = (toggleModel) => {
    settoggleEditListModel(toggleModel);
  };
  const selectedEditListModelHandler = (toggleModel, id, name, des) => {
    settoggleEditListModel(toggleModel);
    setselectedEditListing({
      id: id,
      name: name,
      des: des,
    });
  };

  const shareListModelHandler = (toggleModel, id) => {
    settoggleShareListModel(toggleModel);
    setselectedShareListId(id);
  };

  const saveAsListModelHandler = (toggleModel) => {
    settoggleSaveAsListModel(toggleModel);
  };

  const selectedSaveAsListModelHandler = (toggleModel, id, name, des) => {
    settoggleSaveAsListModel(toggleModel);
    setselectedSaveAsListing({
      id: id,
      name: name,
      des: des,
    });
  };

  /*CRUD Event Handler*/

  const createListHandler = async ({ listName, description }) => {
    setLoader(true);
    dispatch(
      createListAsync({
        userId: user?.id,
        candidateListId: candidateListId,
        listName: listName,
        description: description,
      })
    )
      .then((response) => {
        const res = response?.payload;

        if (res?.result === true) {
          createListModelHandler(false, true);
          setLoader(false);
          toast.success(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          getEmpListData();
        } else {
          createListModelHandler(false, true);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const deleteListHandler = async () => {
    setLoader(true);
    dispatch(
      deleteListAsync({
        ListingId: selectedDeleteListId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          setLoader(false);
          settoggleDeleteListModel(false);
          toast.error(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          getEmpListData();
        } else {
          getEmpListData(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const editListHandler = async ({ listName, description }) => {
    setLoader(true);
    dispatch(
      editListAsync({
        listId: selectedEditListing?.id,
        listName: listName,
        description: description,
      })
    )
      .then((response) => {
        const res = response?.payload;

        if (res?.result === true) {
          editListModelHandler(false);
          setLoader(false);
          toast.success(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          getEmpListData();
        } else {
          editListModelHandler(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const shareListHandler = async (selectCompanyUsersId) => {
    setLoader(true);
    dispatch(
      shareListAsync({
        userId: user?.id,
        selectedShareListId: selectedShareListId,
        selectCompanyUsersId: selectCompanyUsersId,
      })
    )
      .then((response) => {
        const res = response?.payload;

        if (res?.result === true) {
          settoggleShareListModel(false);
          setLoader(false);
          toast.success(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          getEmpListData();
        } else {
          settoggleShareListModel(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const saveAsListHandler = async ({ listName, description }) => {
    setLoader(true);
    dispatch(
      saveAsListAsync({
        userId: user?.id,
        listId: selectedSaveAsListing?.id,
        listName: listName,
        description: description,
      })
    )
      .then((response) => {
        const res = response?.payload;

        if (res?.result === true) {
          saveAsListModelHandler(false);
          setLoader(false);
          toast.success(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          getEmpListData();
        } else {
          saveAsListModelHandler(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const getEmpListData = async () => {
    dispatch(
      getEmpListAsync({
        userId: user?.id,
        search: searchListing,
        activeListingTab: activeListingTab,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          if (res?.myLists) setAllList(res?.myLists);
          else if (res?.lists_shared_by_me) setAllList(res?.lists_shared_by_me);
          else if (res?.lists_shared_with_me)
            setAllList(res?.lists_shared_with_me);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getEmpListData();
  }, [activeListingTab, searchListing]);

  useEffect(() => {
    if (activeListingTab === EMP_LIST.CREATED_BY_ME) {
      setselectedLsting({
        label: "Created by me",
        value: EMP_LIST.CREATED_BY_ME,
      });
    } else if (activeListingTab === EMP_LIST.SHARED_BY_ME) {
      setselectedLsting({
        label: "Lists Shared by me",
        value: EMP_LIST.SHARED_BY_ME,
      });
    } else if (activeListingTab === EMP_LIST.SHARED_WITH_ME) {
      setselectedLsting({
        label: "Lists Shared with me",
        value: EMP_LIST.SHARED_WITH_ME,
      });
    }
  }, []);

  return (
    <>
      <div className="position-relative mt-3">
        <div className="container container-site">
          <Button
            variant="link"
            className="back-to-home with-text p-0 add"
            onClick={() => {
              dispatch(
                setCandidateListingDropdown({
                  id: null,
                  name: "",
                  value: EMP_CAND_LIST.ALL,
                })
              );
              navigate("/employer-candidate-list");
            }}
          >
            <img src="/image/form-back-arrow.png" alt="" className="mr-1" />
          </Button>
        </div>
      </div>

      <section className="job-heading mt-5">
        <div className="container container-site">
          <div className="space-inner-row">
            <div className="job-search-box-row position-relative">
              <div className="job-heading-home smilar-job-heading job-heading-local w-100">
                <h2>
                  {" "}
                  {selectedLsting.value === null
                    ? `All lists`
                    : selectedLsting.label}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 mt-md-0">
        <div className="container container-site">
          <div className="row mx-0">
            <div className="employee-searchbox-btn">
              <div className="employee-list-fllter-container">
                <div className="two-btn-list create-listing-btns">
                  <div class="employee-list-btn fix-width-btn">
                    <button onClick={() => createListModelHandler(true)}>
                      Create list +
                    </button>
                  </div>
                </div>
              </div>

              <div className="filter-search-box">
                <img src="../image/job-search.png" alt="" />
                <input
                  type="text"
                  name=""
                  id="jon-search-box"
                  placeholder="Search lists"
                  className="form-font-f"
                  onChange={(e) => {
                    onSearchListingChange(e?.target?.value);
                  }}
                />
              </div>
            </div>

            <div className="divider-opcity mt-5 mt-md-0"></div>
            <div className="j-fillter-only-table">
              <div className="j-company-fillter-table">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1-table"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon-table">
                    <p>
                      {" "}
                      {selectedLsting?.label === null
                        ? "Lists"
                        : selectedLsting?.label?.length >= 22
                          ? selectedLsting?.label?.substring(0, 22) + " ..."
                          : selectedLsting?.label}
                    </p>
                    {selectedLsting?.label === null ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onListingClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="list-search-dropdown-company">
                  <ul className="list-job-title-company">
                    {listingDropDown?.length > 0 &&
                      listingDropDown?.map((item) => (
                        <li
                          key={item?.id}
                          onClick={() =>
                            onListingClick(item?.label, item?.value)
                          }
                        >
                          {item?.label}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="divider-opcity hor-line-hide"></div>
          </div>
        </div>
      </section>

      <section>
        <div className="container container-site">
          <div className="job-listing">
            <div className="job-listing-header">
              <div className="lr list-name">List name</div>
              <div className="lr l-desc">List description</div>
              <div className="lr c-date">Created date</div>
              <div className="lr no-cand"># of candidates</div>
              <div className="lr action">Action</div>
            </div>
            <div className="job-listing-mb">
              {AllList?.length > 0 &&
                AllList?.slice(0, count)?.map((item, index) => (
                  <div className="job-listing-body">
                    <ListingTable
                      item={item}
                      deleteListModelHandler={deleteListModelHandler}
                      selectedEditListModelHandler={
                        selectedEditListModelHandler
                      }
                      shareListModelHandler={shareListModelHandler}
                      selectedSaveAsListModelHandler={
                        selectedSaveAsListModelHandler
                      }
                    />
                  </div>
                ))}
            </div>
            <div className="divider"></div>
          </div>
        </div>

        {AllList?.length > 10 && (
          <div class="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>See more</button>
            </Link>
          </div>
        )}
      </section>
      <WorldConnect />

      {toggleCreateListModel && (
        <ContentModal
          closeModal={createListModelHandler}>
          <CreateListModel
            loader={loader}
            modelHandler={createListModelHandler}
            createListHandler={createListHandler}
          />
        </ContentModal>
      )}

      {toggleDeleteListModel && (
        <ActionModal
          closeModal={() => deleteListModelHandler(false, null)}
          modalArt='/image/warning-wishlist.png'
          heading='Double-Check'
          content='Are you sure you want to delete this list?'>
          <button className="btn btn-black" onClick={() => deleteListModelHandler(false, null)}>No</button>
          <button className="btn btn-outline-black" onClick={deleteListHandler}>Yes</button>
        </ActionModal>
        // <DeleteCanListModel
        //   text="Are you sure you want to delete this list?"
        //   loader={loader}
        //   modelHandler={deleteListModelHandler}
        //   onDeleteCanListHandler={deleteListHandler}
        // />
      )}

      {toggleEditListModel && (
        <ContentModal
          closeModal={editListModelHandler}>
          <EditListModel
            loader={loader}
            selectedEditListing={selectedEditListing}
            modelHandler={editListModelHandler}
            editListHandler={editListHandler}
          />
        </ContentModal>

      )}

      {toggleShareListModel && (
        <ContentModal
          closeModal={shareListModelHandler}>
          <ShareListModel
            loader={loader}
            modelHandler={shareListModelHandler}
            shareListHandler={shareListHandler}
          />
        </ContentModal>

      )}

      {toggleSaveAsListModel && (
        <SaveAsListModel
          loader={loader}
          selectedSaveAsListing={selectedSaveAsListing}
          modelHandler={saveAsListModelHandler}
          saveAsListHandler={saveAsListHandler}
        />
      )}
    </>
  );
};

export default EmpList;
