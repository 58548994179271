import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSideBar,
  setFormMode,
} from "../../../../redux/slices/AdminDashboardSlice";
import BulkResumeUploadForm from "../../../../components/Admin/BulkResume/BulkResumeUpload";
import ZipFileUploadModel from "../../../../components/Common/ZipFileUploadModel/ZipFileUploadModel";
import "./index.css";
import { uploadBulkResumeFileAsync } from "../../../../redux/slices/bulkResumeCanDetailSlice";
import ActionModal from "../../../../components/Common/ActionModal/ActionModal";

const BulkResumeUpload = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [toggleResumeModel, setToggleResumeModel] = useState(false);
  const [toggleStatusModel, setToggleStatusModel] = useState(false);

  const [reqStatus, setReqStatus] = useState("");
  const [zipResume, setZipResume] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");

  const navHandler = () => {
    dispatch(setFormMode("add"));

    dispatch(
      setActiveSideBar({
        id: "4",
        name: "Bulk resume analysis",
        value: "Bulk analysis",
      })
    );

    navigate("/bulk-resume-analysis");
  };

  const uploadBulkResumeFileHandler = (data) => {
    if (user !== null) {
      const { jobTitle, jobDes, files } = data;
      setLoader(true);
      dispatch(
        uploadBulkResumeFileAsync({
          userId: user?.id,
          jobTitle: jobTitle,
          jobDes: jobDes,
          local_zip_file: files,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            dispatch(
              setActiveSideBar({
                id: "4",
                name: "Bulk resume analysis",
                value: "Bulk analysis",
              })
            );
            setReqStatus("success");
          } else {
            setReqStatus("failed");
          }
          setToggleStatusModel(true);
          setLoader(false);

          setTimeout(() => {
            navigate("/bulk-resume-analysis");
          }, 5000);

        })
        .catch((error) => {
          setReqStatus("failed");
          setLoader(false);
          setToggleStatusModel(false);
          console.log(error);
        });
    }
  };

  const resumeModelHandler = (value) => {
    setToggleResumeModel(value);
  };

  const zipResumeHandler = (file, selectedFileName) => {
    setZipResume(file);
    setSelectedFileName(selectedFileName);
  };

  return (
    <>
      <div className="department-role-space bg-clr-admin">
        <div className="form-heading-prom">
          <img
            src="/image/form-back-arrow.png"
            alt=""
            onClick={() => navHandler()}
          />
          <h2>Bulk resume upload</h2>
        </div>
        <BulkResumeUploadForm
          zipResume={zipResume}
          selectedFileName={selectedFileName}
          loader={loader}
          resumeModelHandler={resumeModelHandler}
          uploadBulkResumeFileHandler={uploadBulkResumeFileHandler}
        />
      </div>

      {toggleResumeModel && (
        <ZipFileUploadModel
          title="Upload zip file"          
          modelHandler={resumeModelHandler}
          resumeFileHandler={zipResumeHandler}
        />
      )}

      {toggleStatusModel && (
        <ActionModal
          heading={reqStatus === "success" ? "Success" : "Sorry"}
          closeModal={() => setToggleStatusModel(false)}
          modalArt={
            reqStatus === "success"
              ? "/image/success-modal.png"
              : "/image/failed-modal.png"
          }
          content={
            reqStatus === "success"
              ? "PolyCareerAI is analyzing your resumes. We'll email you once everything's ready!"
              : "We are unable to unzip the file. Please upload a valid zip file."
          }
        ></ActionModal>
      )}
    </>
  );
};

export default BulkResumeUpload;
