import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  incrementFavoriteJobsCount,
  setLoginModel,
} from "../../../redux/slices/AuthSlice";
import API from "../../../api";
import { USER_TYPES } from "../../../constants";

function AddToWishlist({
  jobId,
  wishlist,
  withParentButton = false,
  withoutTxt = false,
}) {
  const dispatch = useDispatch();
  const { user, isEmailVerify, resumeVerified } = useSelector((state) => state.auth);
  const [jobAddedToWishlist, setJobAddedToWishlist] = useState(0);
  const [textValue, setTextValue] = useState(null);
  const [imagePath, setImagePath] = useState(null);

  useEffect(() => {
    setJobAddedToWishlist(wishlist);
  }, []);

  const wishListHandler = (jobId) => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
      return;
    }
    else if (resumeVerified === 0) {
      toast.warning("Please verify your Resume for our AI analysis and job application process.", {
        autoClose: 2000,
        closeOnClick: false,
      });
      return;
    }
    addJobToWishList(jobId);
  };

  const addJobToWishList = async (jobId) => {
    if (user?.user_type === USER_TYPES.EMPLOYER) return;
    if (user?.id && jobId) {
      try {
        const formData = new FormData();
        formData.append("user_id", user?.id);
        formData.append("job_id", jobId);
        await API.post(`/update-whishlist`, formData)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304
            ) {
              let res = response?.data;

              if (res?.result === true) {
                if (res?.whishlist === "1") {
                  console.log("result");
                  setJobAddedToWishlist(true);
                } else if (res?.whishlist === "0") {
                  console.log("result2");
                  setJobAddedToWishlist(false);
                }
                dispatch(incrementFavoriteJobsCount(res?.count));
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  console.log(jobAddedToWishlist);

  if (withParentButton) {
    return (
      <button
        className="fav-j-btn icon-cursor"
        onClick={() => wishListHandler(jobId)}
      >
        <img
          src={
            jobAddedToWishlist
              ? "/image/favorite-filled.png"
              : "/image/favorite.png"
          }
          alt="favorite"
        />
        {jobAddedToWishlist ? "Job Added to Wishlist" : "Add Job To Wishlist"}
      </button>
    );
  }

  return (
    <div
      className="job-favorite icon-cursor"
      onClick={() => wishListHandler(jobId)}
    >
      <img
        src={
          jobAddedToWishlist
            ? "/image/favorite-filled.png"
            : "/image/favorite.png"
        }
        alt="favorite"
      />
      {!withoutTxt && (
        <p className="museo-font">
          {jobAddedToWishlist ? "Job Added to Wishlist" : "Add Job To Wishlist"}{" "}
        </p>
      )}
    </div>
  );
}

export default AddToWishlist;
