import React from 'react'

const PricingCard = ({ getSubscription, val, tabType, planSubscribed }) => {

  const checkUserLoggedin = (data) => {
    if (getSubscription) {
      if (tabType === 'monthly') {
        getSubscription(data?.stripe_price_id_monthly)
      } else {
        getSubscription(data?.stripe_price_id_yearly)
      }
    }
  }
  return (
    <div className='price-card'>
      {
        <div className='price-card-header'>
          <p className={`${val?.name !== "Startup" && "p-0"}`}>{val?.name === "Startup" && "Most popular"}</p>
          <h4>{`${tabType === 'yearly' && val?.plan_pricing[0].price !== null ? "$" + val?.plan_pricing[0].price : ""}`}</h4>
        </div>
      }
      <div className='price-card-body'>
        <div>
          <h3>{val?.name}</h3>
          <div className='card-para'>
            <p>{val?.description}</p>
          </div>
        </div>
        <h2>CA ${tabType === 'yearly' ? val?.plan_pricing[0].yrly_per_month || '0.00' : val?.plan_pricing[0].price || 0}
          <small className='ms-1'>per month</small></h2>
        <button className='btn btn-primary' onClick={() => checkUserLoggedin(val?.plan_pricing[0])} disabled={val.id == planSubscribed?.plan_id}>
          {val?.name === "Free" && planSubscribed?.plan_id !== 1 ? "Free Plan" :
            val?.name === "Startup" && planSubscribed?.plan_id !== 2 ? "Choose Startup" :
              val?.name === "Team" && planSubscribed?.plan_id !== 3 ? "Choose Team" :
                val?.name === "Enterprise" && planSubscribed?.plan_id !== 4 ? "Choose Enterprise" :
                  "Current Plan"}
        </button>
        <p>This includes:</p>
        <ul>
          {val?.plan_features.map((list, index) => {
            return (
              <li key={index} > {list}</li>
            )
          })}
        </ul>
      </div>
    </div >
  )
}

export default PricingCard