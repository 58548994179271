import React from "react";
import PolicyContent from "../../components/PrivacyPolicy/PolicyContent";
import WorldConnect from "../../components/Common/WorldConnect/WorldConnect";
import "./index.css";

const PrivacyPolicy = () => {
  return (
    <>
      <PolicyContent />
      <WorldConnect />
    </>
  );
};

export default PrivacyPolicy;
