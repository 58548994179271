import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  getCreatePromotionDataAsync,
  getCreatePromotionPagesForAdsAsync,
  getPromotionJobsAsync,
  getWeeksPromotionDataAsync,
  setSelectedJob,
  setSelectedPage,
  setSelectedPromotionDate,
  setSelectedPromotionType,
  setSelectedTimeFrame,
} from "../../../../redux/slices/companyPromotionSlice";
import { FormDropdown } from "../../../../components/Admin/Promotion/CreatePromotion/FormDropdown";

const AdminCreatePromotionForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedPromotionType, selectedTimeFrame, selectedPromotionDate } = useSelector(
    (state) => state.companyPromotion
  );

  /*Dropdown Value*/
  const [promotionTypeD, setPromotionTypeD] = useState([]);
  const [promotionTypeDLoading, setPromotionTypeDLoading] = useState(false);
  const [timeFrameD, setTimeFramesD] = useState([]);
  const [promotionPages, setPromotionPages] = useState([]);
  const [promotionPagesLoading, setPromotionPagesLoading] = useState(false);
  const [promotionDate, setPromotionDate] = useState([]);
  const [promotionDateLoading, setPromotionDateLoading] = useState(false);
  const [promotionJobs, setPromotionJobs] = useState([]);
  const [promotionJobsLoading, setPromotionJobsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      promotion: "",
      promotionId: null,
      timeFrame: "",
      timeFrameId: null,
      page: "",
      pageId: null,
      promotionDate: "",
      promotionDateId: null,
      job: "",
      jobId: null,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      promotion: Yup.string().required("Please enter promotion type"),
      timeFrame: Yup.string().required("Please enter promotion time frame"),
      job: Yup.string().when("promotion", {
        is: (value) => value === "Job Ads",
        then: () => Yup.string().required("Please select job to promote"),
        otherwise: () => Yup.string(),
      }),
      promotionDate: Yup.string().required("Please enter promotion date"),
    }),
    onSubmit: async (data, { resetForm }) => {
      navigate("/admin-promotion-slot");
    },
  });

  const onBackClick = () => {
    dispatch(setSelectedPromotionType({ id: null, name: "" }));
    dispatch(setSelectedTimeFrame({ id: null, name: "" }));
    navigate("/admin-all-promotion");
  };

  const onproClick = async (id, name) => {
    await formik.setFieldValue("promotionId", id);
    await formik.setFieldValue("promotion", name);
    dispatch(setSelectedPromotionType({ id: id, name: name }));
    const needDataForJobPromotion = formik?.values?.timeFrameId && id === 2;
    needDataForJobPromotion &&
      getPromotionPagesData(formik?.values?.timeFrameId);
  };

  const onTimeFrameClick = async (id, name) => {
    await formik.setFieldValue("timeFrameId", id);
    await formik.setFieldValue("timeFrame", name);
    dispatch(setSelectedTimeFrame({ id: id, name: name }));
    const needDataForJobPromotion =
      formik?.values?.promotionId === 2 && promotionPages.length === 0;
    needDataForJobPromotion && getPromotionPagesData(id);
    getPromotionDate(id);
  };

  const hasNoJobsForSelectedPromotionDate =
    promotionJobs.length === 0 && formik?.values?.promotionDate;

  const onJobSelect = async (id, name) => {
    if (!hasNoJobsForSelectedPromotionDate) {
      await formik.setFieldValue("jobId", id);
      await formik.setFieldValue("job", name);
      dispatch(setSelectedJob({ id: id, name: name }));
    }
  };

  const onPromotionDateSelection = async (id, name) => {
    await formik.setFieldValue("promotionDate", name);
    await formik.setFieldValue("promotionDateId", id);
    await formik.setFieldValue("job", "");
    await formik.setFieldValue("jobId", null);
    dispatch(setSelectedJob(null));
    dispatch(
      setSelectedPromotionDate({ id: id, name: name, ...promotionDate[id] })
    );
    getPromotionJobs({ ...promotionDate[id] });
  };

  const onJobPageSelection = async (id, name, item) => {
    await formik.setFieldValue("page", name);
    await formik.setFieldValue("pageId", id);
    dispatch(
      setSelectedPage({ id: id, name: name, section: item.sections[0] })
    );
  };

  const getPromotionListingData = async () => {
    setPromotionTypeDLoading(true);
    dispatch(getCreatePromotionDataAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setPromotionTypeD(res?.data?.promotionTypes);
          setTimeFramesD(res?.data?.promotionTimeFrames);
        }
        setPromotionTypeDLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPromotionTypeDLoading(false);
      });
  };

  const getPromotionPagesData = async (time_frame_id) => {
    setPromotionPagesLoading(true);
    dispatch(getCreatePromotionPagesForAdsAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setPromotionPages(res?.data);
        }
        setPromotionPagesLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPromotionPagesLoading(false);
      });
    getPromotionDate(time_frame_id);
  };

  const getPromotionJobs = async ({ start_date, end_date }) => {
    setPromotionJobsLoading(true);
    dispatch(getPromotionJobsAsync({ start_date, end_date }))
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setPromotionJobs(res?.data);
        }
        setPromotionJobsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPromotionJobsLoading(false);
      });
  };

  const getPromotionDate = async (time_frame_id) => {
    if (time_frame_id !== undefined) {
      setPromotionDateLoading(true);
      dispatch(getWeeksPromotionDataAsync({ timeFrame: time_frame_id }))
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            setPromotionDate(
              res?.data.map((date, index) => ({
                id: index,
                name: `${date?.week_start_day} - ${date?.week_end_day}`,
                ...date,
              }))
            );
          }
          setPromotionDateLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setPromotionDateLoading(false);
        });
    }
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  useEffect(() => {
    async function getSelectedProValue() {
      await formik.setFieldValue("promotionId", selectedPromotionType?.id);
      await formik.setFieldValue("promotion", selectedPromotionType?.name);
      await formik.setFieldValue("timeFrameId", selectedTimeFrame?.id);
      await formik.setFieldValue("timeFrame", selectedTimeFrame?.name);
      await formik.setFieldValue("promotionDateId", selectedPromotionDate?.id);
      await formik.setFieldValue("promotionDate", selectedPromotionDate?.name);
    }
    getSelectedProValue();
    getPromotionListingData();
    if(selectedTimeFrame?.id !== null)
    getPromotionDate(selectedTimeFrame?.id);
  }, []);

  return (
    <div className="department-role-space bg-clr-admin">
      <div className="form-heading-prom">
        <img
          src="/image/form-back-arrow.png"
          alt=""
          onClick={() => onBackClick()}
        />
        <h2>Create promotion</h2>
      </div>
      <div className="department-form-box">
        <form onSubmit={formik.handleSubmit}>
          <div className="admin-from-step">
            <FormDropdown
              label="Promotion type"
              placeholder="Promotion type"
              required
              value={formik?.values?.promotion}
              loading={promotionTypeDLoading}
              isInvalid={isFormFieldValid("promotion")}
              error={getFormErrorMessage("promotion")}
              options={promotionTypeD}
              onSelect={onproClick}
              formik={formik}
            />

            <FormDropdown
              label="Promotion time frame"
              placeholder="Promotion time frame"
              required
              value={formik?.values?.timeFrame}
              loading={promotionTypeDLoading}
              isInvalid={isFormFieldValid("timeFrame")}
              error={getFormErrorMessage("timeFrame")}
              options={timeFrameD}
              onSelect={onTimeFrameClick}
              formik={formik}
            />
            <FormDropdown
              label="Promotion date"
              placeholder="Promotion date"
              required
              value={formik?.values?.promotionDate}
              loading={promotionDateLoading}
              isInvalid={isFormFieldValid("promotionDate")}
              error={getFormErrorMessage("promotionDate")}
              options={promotionDate}
              onSelect={onPromotionDateSelection}
              formik={formik}
            />

            {formik?.values?.promotionId === 2 &&
              formik?.values?.timeFrameId && (
                <>
                  <FormDropdown
                    label="Job ad position"
                    placeholder="Job ad position"
                    required
                    value={formik?.values?.page}
                    loading={promotionPagesLoading}
                    isInvalid={isFormFieldValid("page")}
                    error={getFormErrorMessage("page")}
                    options={promotionPages}
                    onSelect={onJobPageSelection}
                    formik={formik}
                  />
                  <FormDropdown
                    label="Promote a job"
                    placeholder="Promote a job"
                    required
                    value={formik?.values?.job}
                    loading={promotionJobsLoading}
                    isInvalid={isFormFieldValid("job")}
                    error={getFormErrorMessage("job")}
                    options={
                      hasNoJobsForSelectedPromotionDate
                        ? [{ id: 0, name: "No jobs for selected date" }]
                        : promotionJobs
                    }
                    onSelect={onJobSelect}
                    formik={formik}
                  />
                </>
              )}          
            <div className="f-admin feild-f-admin"></div>
          </div>
          <div className="admin-form-btn">
            <button
              className="cancel-admin"
              onClick={() => navigate("/admin-all-promotion")}
            >
              Cancel
            </button>
            <button className="next-admin" type="submit">
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminCreatePromotionForm;
