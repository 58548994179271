import "./CanProgressBar.css";

const CanProgressBar = ({ signUpActive, resumeVerifyActive }) => {
  return (
    <div className="step-progressbar-container">
      <ul className="progressbar-steps-res">
        <li
          className={`step-circle ${
            signUpActive === true && "active active-circle"
          }`}
        >
          Sign up
        </li>
        <li
          className={`step-circle ${
            resumeVerifyActive === true && "active active-circle"
          }`}
        >
          Resume verification
        </li>
      </ul>
    </div>
  );
};

export default CanProgressBar;
