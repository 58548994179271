import { useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import API from "../../../api";
import "./ShareListModel.css";

const ShareListModel = ({
  loader,
  modelHandler,
  shareListHandler,
  title,
  label,
  showTitleIcon = true,
  returnFullUser,
  bodyClassName,
  footerClassName,
}) => {
  const { user } = useSelector((state) => state.auth);

  const [allCompanyUser, setAllCompanyUser] = useState([]);
  const [selectCompanyUser, setSelectCompanyUser] = useState([]);
  const [allCompanyUserHide, setCompanyUserHide] = useState(true);

  const formik = useFormik({
    initialValues: {
      campanyUser: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      campanyUser: Yup.mixed().test(
        "campanyUser",
        "Please select the people to access the list",
        (value) =>
          !!(Array.isArray(selectCompanyUser) && selectCompanyUser?.length >= 1)
      ),
    }),
    onSubmit: async (data, { resetForm }) => {
      if (selectCompanyUser?.length < 1) return;
      const selectCompanyUsersId = selectCompanyUser.map((item) => item.id);

      shareListHandler(
        returnFullUser ? selectCompanyUser : selectCompanyUsersId
      );
    },
  });

  const onCompanyUserChange = async (value) => {
    await formik.setFieldValue("campanyUser", value);
    getSearchCompanyUser(value);
  };

  const onCompanyUserClick = async (curentCompanyUser) => {
    const isIdExists = selectCompanyUser?.some(
      (item) => item.id === curentCompanyUser?.id
    );
    if (isIdExists) return;
    setSelectCompanyUser([...selectCompanyUser, curentCompanyUser]);
    setCompanyUserHide(true);
    await formik.setFieldValue("campanyUser", "");
  };

  const onCompanyUserCancel = async (curentCompanyUser) => {
    const updatedItems = selectCompanyUser.filter(
      (item) => item.id !== curentCompanyUser?.id
    );
    setSelectCompanyUser(updatedItems);
  };

  const isCompanyUserFieldValid = () =>
    !!(
      formik.touched["campanyUser"] &&
      formik.errors["campanyUser"] &&
      selectCompanyUser?.length < 1
    );

  const getCompanyUserErrorMessage = () => {
    return (
      isCompanyUserFieldValid() && (
        <p className="error-msg">{formik.errors["campanyUser"]}</p>
      )
    );
  };

  const getSearchCompanyUser = async (searchValue) => {
    try {
      const formData = new FormData();
      user !== null && formData.append("my_id", user?.id);
      user !== null && formData.append("company_id", user?.company_id);
      // user !== null && formData.append("my_id", 5);
      // user !== null && formData.append("company_id", 1);
      formData.append("search_word", searchValue);

      await API.post(`/job-board/auto-search-company-user`, formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            let res = response?.data;

            if (res?.company_users?.length > 0) {
              setAllCompanyUser(res?.company_users);
              setCompanyUserHide(false);
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={`modal-body ${bodyClassName || ""}`}>
          <h3 className="mt-0">
            {title || "Share List"}{" "}
            {showTitleIcon && <img src="/image/share-list-b.png" alt=" " />}
          </h3>
          <div className="crea-list-m text-start w-100">
            <div className="label-field">
              <label for="">
                {label || "Invite people to access the list"}
              </label>
              <div className="share-list-feild">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1 custom-tag-input"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <input
                      type="text "
                      className="form-font-list-share"
                      name="campanyUser"
                      value={formik.values.campanyUser}
                      onChange={(e) => {
                        onCompanyUserChange(e.currentTarget.value);
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      placeholder="Invite people"
                    />

                    <img
                      src="/image/admin-d.png"
                      alt=""
                      className="hide-tick"
                    />
                  </div>
                </div>

                {allCompanyUser?.length > 0 && (
                  <div
                    className={`admin-status-dropdown-frm ${allCompanyUserHide && "job-dropdown-hide"
                      }`}
                  >
                    <ul className="list-share-drop">
                      {allCompanyUser.map((j, i) => (
                        <li
                          key={i}
                          onClick={() => {
                            onCompanyUserClick(j);
                          }}
                        >
                          {" "}
                          {`${j?.first_name} ${j?.last_name}`}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getCompanyUserErrorMessage()}

              {selectCompanyUser?.length > 0 && (
                <div className="tags-share">
                  <ul className="tags-share-list">
                    {selectCompanyUser.map((j, i) => (
                      <li key={i}>
                        {`${j?.first_name} ${j?.last_name}`}
                        <img
                          src="/image/cross.png"
                          alt=""
                          onClick={() => onCompanyUserCancel(j)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="tags-share">
                <ul className="tags-share-list"></ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`modal-footer ${footerClassName || ""}`}>
          <button
            className="btn btn-black "
            type="button "
            onClick={() => modelHandler(false)}
          >
            Cancel
          </button>
          <button className="btn btn-outline-black " type="submit ">
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Done"
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default ShareListModel;
