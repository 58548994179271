import { useState, Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCandidateListingDropdown } from "../../../redux/slices/candidateListingSlice";
import getCurrentFormattedDate from "../../../utils/getCurrentFormattedDate";
import { EMP_CAND_LIST, EMP_LIST } from "../../../constants";
import "./ListingTable.css";

const ListingTable = ({
  item,
  deleteListModelHandler,
  selectedEditListModelHandler,
  shareListModelHandler,
  selectedSaveAsListModelHandler,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeListingTab } = useSelector((state) => state.listing);
  const [showMenu, setShowMenu] = useState(false);

  const viewClickHandler = () => {
    if (activeListingTab !== EMP_LIST.SHARED_WITH_ME) {
      dispatch(
        setCandidateListingDropdown({
          id: item?.id,
          name: item?.name,
          value: EMP_CAND_LIST.MY_LIST,
        })
      );
    } else {
      dispatch(
        setCandidateListingDropdown({
          id: item?.id,
          name: item?.name,
          value: EMP_CAND_LIST.SHARE_LIST,
        })
      );
    }
    navigate("/employer-candidate-list");
  };

  const showSubMenu = () => {
    if (showMenu) {
      setShowMenu(false)
    } else {
      setShowMenu(true)
    }
  }
  return (
    <Fragment key={item?.id}>
      <div className="lr list-name">
        {item?.name}
      </div>
      <div className="lr l-desc">
        {item?.description?.length >= 40 ? (
          <>{item?.description?.substr(0, 40) + "..."}</>
        ) : (
          <>{item?.description}</>
        )}
      </div>
      <div className="lr c-date">
        {getCurrentFormattedDate(item?.created_at)}
      </div>
      <div className="lr no-cand">
        <p className="d-flex align-items-center justify-content-center">
          <strong className="d-md-none mr-1"># of Candidates:</strong>
          {item?.candidates_count}
        </p>
        <p className="d-md-none d-flex align-items-center">
          <span className="d-md-none mr-1">Created Date: </span>
          {getCurrentFormattedDate(item?.created_at)}
        </p>
      </div>
      <div className="lr action">
        <button className="btn d-md-none d-inline" onClick={showSubMenu}>
          <img src='/image/ellipses.png' alt='éllipses' />
        </button>
        <div className={showMenu ? 'cv-cions active' : 'cv-cions'}>
          <div className="img-table-tooltip" onClick={() => {
            viewClickHandler(item);
          }}>
            <img
              src="/image/table-list.png"
              alt=""
              className="show-tooltip"
            />
            <div className="tooltip-table">View List</div>
          </div>

          {activeListingTab !== EMP_LIST.SHARED_WITH_ME ? (
            <>
              <div className="img-table-tooltip" onClick={() => {
                shareListModelHandler(true, item?.id);
              }}>
                <img
                  src="/image/share-list-b.png"
                  alt=""
                  className="show-tooltip"
                />
                <div className="tooltip-table">Share List</div>
              </div>
              <div className="img-table-tooltip" onClick={() => {
                selectedEditListModelHandler(
                  true,
                  item?.id,
                  item?.name,
                  item?.description
                );
              }}>
                <img
                  src="/image/role-edit.png"
                  alt=""
                  className="show-tooltip"
                />
                <div className="tooltip-table">Edit</div>
              </div>
              <div className="img-table-tooltip" onClick={() => {
                deleteListModelHandler(true, item?.id);
              }}>
                <img
                  src="/image/role-delet.png"
                  alt=""
                  className="show-tooltip"
                />
                <div className="tooltip-table">Delete</div>
              </div>
            </>
          ) : (
            <div className="img-table-tooltip">
              <img
                src="/image/save-list-b.png"
                alt=""
                className="show-tooltip"
                onClick={() =>
                  selectedSaveAsListModelHandler(
                    true,
                    item?.id,
                    item?.name,
                    item?.description
                  )
                }
              />
              <img
                src="/image/save-list-a.png"
                alt=""
                className="show-tooltip save-list-icon"
              />
              <div className="tooltip-table">Save As</div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ListingTable;
