import { createSlice } from '@reduxjs/toolkit'

const initialState = {  
  selectedJobId: null,
}
const jobDetailSlice = createSlice({
  name: 'jobDetail',
  initialState,
  reducers: {
    getSelectJobId: (state) => {  
      return state.selectedJobId;
    },
    setSelectJobId: (state, action) => {   
      state.selectedJobId = action.payload;
    },
   
  },
})

// Action creators are generated for each case reducer function
export const { getSelectJobId, setSelectJobId } = jobDetailSlice.actions

export default jobDetailSlice.reducer



