const JobNoRecordFound = () => {
  return (
    <>
      {" "}
      <div className="divider divide-desk"></div>
      <p class="error-wishlist">The search did not return any rows</p>
    </>
  );
};

export default JobNoRecordFound;