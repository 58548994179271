import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./ResumeSaveModel.css";

const ResumeSaveModel = ({
  modelHandler,
  onFileNameChangeHandler,
  onCustomResumeClick,
}) => {
  const formik = useFormik({
    initialValues: {
      resume_display_name: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      resume_display_name: Yup.string().required("Please Enter Resume Name"),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      modelHandler(false);
      onCustomResumeClick();
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const onCustomChangeHandler = async (e) => {
    const { name, value } = e?.target;
    await formik.setFieldValue(name, value);
    const newFormData = {
      ...formik?.values,
      [name]: value,
    };
    onFileNameChangeHandler(newFormData);
  };

  return (
    <>

      <form onSubmit={formik.handleSubmit}>
        <div className="modal-body modal-body-resume-save">
          <h3>Save resume <img src="/image/save-resume.png" alt="" /></h3>

          <div
            className={`label-field text-start ${isFormFieldValid("resume_display_name") && "error-input"
              }`}
          >
            <label for="lname">Resume name</label>
            <br />
            <input
              type="text"
              placeholder="Resume name"
              className="feild-stl-res form-font-f"
              name="resume_display_name"
              value={formik?.values?.resume_display_name}
              onChange={onCustomChangeHandler}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("resume_display_name")}
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="text"
            className="btn btn-black"
            onClick={() => modelHandler(false)}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-outline-black">
            Save as
          </button>
        </div>
      </form>
    </>
  );
};

export default ResumeSaveModel;
