import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CompanyDescription from "../../../components/Job/CompanyDetail/CompanyDescription";
import OpenPosstion from "../../../components/Job/CompanyDetail/OpenPosstion";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import LoginModel from "../../../components/Common/LoginModel/LoginModel";
import API from "../../../api";
import "./index.css";

const CompanyDetail = () => {
  const UrlParams = useParams();
  const { user } = useSelector((state) => state.auth);
  const { selectedCompanyId } = useSelector((state) => state.companyDetail);
  const [jobTitles, setJobTitles] = useState([]);
  const [datePosted, setDatePosted] = useState([]);
  const [expiryDate, setExpiryDate] = useState([]);
  const [cities, setcities] = useState([]);

  const [selectedJobTitile, setSelectedJobTitile] = useState("");
  const [search, setSearch] = useState("");
  const [selectedDatePostedLabel, setSelectedDatePostedLabel] = useState("");
  const [selectedDatePosted, setSelectedDatePosted] = useState("");
  const [selectedExpiryDateLabel, setSelectedExpiryDateLabel] = useState("");
  const [selectedExpiryDate, setSelectedExpiryDate] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityId, setSelectedCityId] = useState(null);

  const [OpenPosstionShow, setOpenPosstionShow] = useState(false);

  const [companyData, setCompanyData] = useState({
    name: "",
    description: "",
    logo: "",
  });
  const [jobs, setJobs] = useState([]);

  const onJobTitleClick = (value) => {
    setSelectedJobTitile(value);
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onJobTitleClean = () => {
    setSelectedJobTitile("");
  };


  const onDatePostedClick = (value, label) => {
    setSelectedDatePosted(value);
    setSelectedDatePostedLabel(label);
  };

  const onDatePostedClean = () => {
    setSelectedDatePosted("");
    setSelectedDatePostedLabel("");
  };

  const onExpiryClick = (value, label) => {
    setSelectedExpiryDate(value);
    setSelectedExpiryDateLabel(label);
  };

  const onExpiryClean = () => {
    setSelectedExpiryDate("");
    setSelectedExpiryDateLabel("");;
  };

  const onLocationClick = ({ id, name }) => {
    setSelectedCityId(id);
    setSelectedCity(name);
  };

  const onLocationClean = () => {
    setSelectedCityId(null);
    setSelectedCity("");
  };


  useEffect(() => {
    async function getCompanyDetail() {
      if (UrlParams?.id !== null) {
        let params = `?`;
        if (user !== null) {
          params += `user_id=${user?.id}&`;
        }
        if (selectedJobTitile !== "") {
          params += `title=${selectedJobTitile}&`;
        }
        if (search !== "") {
          params += `search=${search}&`;
        }
        if (selectedDatePosted !== "") {
          params += `date_posted=${selectedDatePosted}&`;
        }
        if (selectedExpiryDate !== "") {
          params += `expiry_date=${selectedExpiryDate}&`;
        }
        if (selectedCityId !== null) {
          params += `city_id=${selectedCityId}&`;
        }       

        try {
          await API.get(
            `/job-board/get-company-detail/${UrlParams?.id}${params}`
          )
            .then((response) => {
              if (response?.status === 200) {
                let res = response?.data?.data;
                let company = res?.company;
                setCompanyData({
                  name: company?.name,
                  description: company?.description,
                  logo: company?.logo,
                });
                setJobs(company?.jobs);
                if (
                  selectedJobTitile === "" &&
                  search === "" &&
                  selectedDatePosted === "" &&
                  selectedExpiryDate === "" &&
                  selectedCityId === null &&
                  company?.jobs?.length === 0
                ) {
                  setOpenPosstionShow(false);
                } else setOpenPosstionShow(true);
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    }

    getCompanyDetail();
  }, [
    UrlParams,
    selectedJobTitile,
    search,
    selectedDatePosted,
    selectedExpiryDate,
    selectedCityId,
  ]);

  useEffect(() => {
    async function getCompanyFilterValue() {
      if (UrlParams?.id !== null) {
        try {
          await API.get(
            `/job-board/get-company-detail-filters?company_id=${UrlParams?.id}`
          )
            .then((response) => {
              if (response?.status === 200) {
                let success = response?.data?.success;
                console.log('res of companyDetails is : ', response);
                if (success === true) {
                  let res = response?.data?.data;
                  
                  setJobTitles(res?.job_titles);
                  setDatePosted(res?.date_posted);
                  setExpiryDate(res?.expiry_date);
                  setcities(res?.cities);
                }
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    }

    getCompanyFilterValue();
  }, [selectedCompanyId]);

  return (
    <>
      <CompanyDescription companyData={companyData} />
      <OpenPosstion
        jobTitles={jobTitles}
        selectedJobTitile={selectedJobTitile}
        datePosted={datePosted}
        selectedDatePostedLabel={selectedDatePostedLabel}
        selectedDatePosted={selectedDatePosted}
        expiryDate={expiryDate}
        selectedExpiryDateLabel={selectedExpiryDateLabel}
        selectedExpiryDate={selectedExpiryDate}
        cities={cities}
        selectedCity={selectedCity}
        jobs={jobs}
        OpenPosstionShow={OpenPosstionShow}
        onJobTitleClick={onJobTitleClick}
        onSearchChange={onSearchChange}
        onJobTitleClean={onJobTitleClean}
        onDatePostedClick={onDatePostedClick}
        onDatePostedClean={onDatePostedClean}
        onExpiryClick={onExpiryClick}
        onExpiryClean={onExpiryClean}
        onLocationClick={onLocationClick}
        onLocationClean={onLocationClean}
      />
      <WorldConnect />
      <LoginModel />
    </>
  );
};

export default CompanyDetail;
