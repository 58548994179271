import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import API from "../../api";
import { RESUME_MODE } from "../../constants";

const initialState = {
  resumeFormMode: RESUME_MODE.VERIFICATION,
  retryCount: 0,
};
const verifyResumeSlice = createSlice({
  name: "verifyResume",
  initialState,
  reducers: {
    setResumeFormMode: (state, action) => {
      state.resumeFormMode = action.payload;
    },
    setResumeRetryCount: (state, action) => {
      state.retryCount = action.payload;
    },
    incrementRetryCount: (state) => {
      state.retryCount += 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setResumeFormMode, setResumeRetryCount, incrementRetryCount } = verifyResumeSlice.actions;

export default verifyResumeSlice.reducer;

// asynchronous function with createAsyncThunk

export const resumeSectionUpdateAsync = createAsyncThunk(
  "candidate/resumeSection",
  async ({ userId, resumeData, resumeId, resumeType, sessionName, action }) => {
    const rawData = {
      resume_data: resumeData,
      candidate_id: userId,
      section_name: sessionName,
      action: action,
      resume_id: resumeId,
      resume_type: resumeType,
    };

    console.log("rawData", rawData);
    try {
      const response = await API.post("/auth/update-resume-section", rawData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const regenerateResumeAIAsync = createAsyncThunk(
  "candidate/regenerateResumeAI",
  async ({ userId, sectionName, jobTitle, sectionValue, promptInput, aiGen }) => {
    let params = `?`;
    if (userId !== null) {
      params += `candidate_id=${userId}&`;
    }
    if (sectionName !== "") {
      params += `section_name=${sectionName}&`;
    }
    if (jobTitle !== null) {
      params += `job_title=${jobTitle}&`;
    }
    if (sectionValue !== "") {
      params += `section_value=${sectionValue}&`;
    }
    if (promptInput !== "") {
      params += `prompt_input=${promptInput}&`;
    }
    if (aiGen !== "") {
      params += `ai_gen=${aiGen}&`;
    }

    try {
      const response = await API.get(
        `/auth/update-section-wo-jd${params}`
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const regenerateResumeListAIAsync = createAsyncThunk(
  "candidate/regenerateResumeListAI",
  async ({ userId, sectionName, jobTitle, sectionValue, promptInput, aiGen }) => {
    let params = `?`;
    if (userId !== null) {
      params += `candidate_id=${userId}&`;
    }
    if (sectionName !== "") {
      params += `section_name=${sectionName}&`;
    }
    if (jobTitle !== null) {
      params += `job_title=${jobTitle}&`;
    }
    if (sectionValue !== "") {
      params += `section_value=${sectionValue}&`;
    }
    if (promptInput !== "") {
      params += `prompt_input=${promptInput}&`;
    }
    if (aiGen !== "") {
      params += `ai_gen=${aiGen}&`;
    }

    try {
      const response = await API.get(
        `/auth/update-section-wo-jd-list${params}`
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      const { response } = error;
      const { data } = response;

      if (typeof data?.message === 'string') {
        toast.error(data?.message, {
          autoClose: 2000,
          closeOnClick: false,
        });
      } else if (data?.message?.error) {
        toast.error(data?.message?.error, {
          autoClose: 2000,
          closeOnClick: false,
        });
      } 
      throw error;
    }
  }
);

export const verifyResumeAsync = createAsyncThunk(
  "candidate/verifyResume",
  async ({ userId, resumeData, resumeId, resumeType, resumeDisplayName }) => {
    const rawData = {
      resume_data: { ...resumeData, achievements: [], hobbies: [] },
      candidate_id: userId,
      resume_id: resumeId,
      resume_type: resumeType,
      resume_display_name: resumeDisplayName,
    };

    try {
      const response = await API.post("/auth/verify-update-resume", rawData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const customResumeAsync = createAsyncThunk(
  "candidate/customResumeAsync",
  async ({ userId, resume, JobURL, JobDescription }) => {
    const formData = new FormData();
    formData.append("candidate_id", userId);
    formData.append("resume", resume);
    formData.append("job_url", JobURL);
    formData.append("job_description", JobDescription);

    try {
      const response = await API.post(`/auth/customize-my-resume`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
