import React from 'react'
import APIPvtDB from "../../../../apiPvtD";

const PinnedChat = ({ data, loggedInID, getCommentsDetail }) => {

  const pinComment = async (pinData) => {
    try {
      // const formData = new FormData();
      let formData = {
        "id": pinData.id,
        "type": 2,
      }

      if (data.user_id === loggedInID) {
        formData.type = 1
      }
      await APIPvtDB.post(`/pin-unpin-comment-or-reply`, formData)
        .then((response) => {
          if (response.data.success) {
            getCommentsDetail()
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='s-chat'>
      <img src='/image/chat-pinned.png' className='pin-chat' onClick={() => pinComment(data)} />
      <div className='chat-h'>
        <div className='avatar'>
          <img src={data?.user.avatar_link || '/image/client-img.png'} />
        </div>
        <h3>{data?.user.first_name} {data?.user.last_name}</h3>
      </div>
      <div className='chat-b'>
        <p>
          {data?.comments}
        </p>
      </div>
      <div className='chat-f'>
        <p>
          {data?.pinned_at}
        </p>
      </div>
    </div>
  )
}

export default PinnedChat