const formatDate = (date) => {
  const newDate = new Date(date);

  if (!(newDate instanceof Date) || isNaN(newDate)) {
    return "Invalid Date";
  }

  const day = String(newDate.getDate()).padStart(2, "0");
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const year = newDate.getFullYear();
  return `${day}/${month}/${year}`;
};

export const formatRelativeDate = (date) => {
  let inputDate = new Date(date);

  // Get the current date
  const currentDate = new Date();

  // Get the year, month, and day for the input date and the current date
  const inputYear = inputDate.getFullYear();
  const inputMonth = inputDate.getMonth();
  const inputDay = inputDate.getDate();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  // Compare the input date with the current date
  if (
    inputYear === currentYear &&
    inputMonth === currentMonth &&
    inputDay === currentDay
  ) {
    return "Today";
  } else if (
    inputYear === currentYear &&
    inputMonth === currentMonth &&
    inputDay === currentDay - 1
  ) {
    return "Yesterday";
  } else {
    // Convert the month number to the month name
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthName = monthNames[inputMonth];

    // Format the date as "DD - Month"
    return `${inputDay.toString().padStart(2, "0")} - ${monthName}`;
  }
};

export const formatTimestamp = (timestamp) => {

  // Create a Date object from the input timestamp
  const date = new Date(timestamp);

  // Convert the UTC time to the local time zone
  const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  // Extract hours, minutes, and seconds
  const hours = localDate.getHours();
  const minutes = localDate.getMinutes();

  // Determine whether it's AM or PM
  const amOrPm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format and format with leading zero
  const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");

  // Format minutes with leading zero
  const formattedMinutes = minutes.toString().padStart(2, "0");

  // Create the formatted time string
  const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;

  // console.log(formattedTime); // Output: "01:56 PM"
  return formattedTime;
};

export const formatCurrDate = () => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = currentDate.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export default formatDate;
