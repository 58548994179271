import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import CandidateForm from "./CandidateForm/CandidateForm";
import ProgressBar from "./EmployerForm/ProgressBar";
import CanProgressBar from "./CandidateForm/CanProgressBar/CanProgressBar";
import SignUpForm from "./EmployerForm/SignUpForm";
import CreateProfileForm from "./EmployerForm/CreateProfileForm";
import API from "../../../api";
import ActionModal from "../../Common/ActionModal/ActionModal";
import {
  setActiveSubTab,
  setActiveTab,
  setEmailVerify,
  setLoginUser,
  setLoginUserName,
  setResumeVerified,
  setResumeDetail,
  setSignUpEmp,
  setLoginUserCountry,
  setUserResumes,
} from "../../../redux/slices/AuthSlice";
import { setResumeFormMode, setResumeRetryCount } from "../../../redux/slices/verifyResumeSlice";
import WelcomeModel from "../../Common/WelcomeModel/WelcomeModel";
import FileUploadModel from "../../Common/FileUploadModel/FileUploadModel";
import UploadImageModel from "../../Common/UploadImageModel/UploadImageModel";
import { clearJobDecision } from "../../../redux/slices/JobDecisionSlice";
import {
  setActiveSideBar,
  setdefaultPermissions,
} from "../../../redux/slices/AdminDashboardSlice";
import { ADMIN, CANDIDATE, EMPLOYER, RESUME_MODE } from "../../../constants";
import "./index.css";
import "./EmployerForm/EmployerForm.css";
import { getResumeForApplyingOnJob } from "../../../redux/slices/candidateDetailSlice";

const SignUpMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    user,
    mode,
    isEmailVerify,
    resumeVerified,
    resumeDetail,
    accessToken,
    signUpBarHide,
  } = useSelector((state) => state.auth);

  const { jobDecision } = useSelector((state) => state.jobDecision);
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");

  const { activeTab, activeSubTab, signUpEmp } = useSelector(
    (state) => state.auth
  );

  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [candidateResume, setCandidateResume] = useState(null);
  const [toggleModel, setToggleModel] = useState(false);
  const [toggleResumeModel, setToggleResumeModel] = useState(false);
  const [companyLogoModel, setCompanyLogoModel] = useState(false);
  const [employerForm, setEmployerForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    country: null,
    state_id: null,
    city_id: null,
    phoneNo: "",
    website_url: "",
    company_name: "",
    company_detail: "",
    legal_document: null,
    company_logo: null,
    linkedin: "",
  });

  const hideModel = (userType, resumeVerified, resumeId, resumeType) => {
    const timeId = setTimeout(() => {
      if (CANDIDATE.includes(user?.user_type) || CANDIDATE.includes(userType)) {
        dispatch(setResumeVerified(resumeVerified));
        dispatch(
          setResumeDetail({ resumeId: resumeId, resumeType: resumeType })
        );
        if (resumeVerified === 0) {
          dispatch(setResumeFormMode(RESUME_MODE.VERIFICATION));
          dispatch(setResumeRetryCount(0));
          navigate("/candidate-resume");
        } else if (resumeVerified !== 0) {
          dispatch(setResumeFormMode(RESUME_MODE.UPDATE));
          navigate("/candidate-dashboard");
        }
      } else if (
        EMPLOYER.includes(user?.user_type) ||
        EMPLOYER.includes(userType)
      ) {
        dispatch(setActiveSubTab("postJob"));
        navigate("/post-job");
      } else if (ADMIN.includes(user?.user_type) || ADMIN.includes(userType)) {
        dispatch(
          setActiveSideBar({
            id: "2",
            name: "Department",
            value: "Department",
          })
        );
        navigate("/admin-department");
      }

      setErrorMsg("");
      setToggleModel(false);
      dispatch(setEmailVerify(true));
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  };

  const modelHandler = (
    value,
    userType,
    resumeVerified,
    resumeId,
    resumeType
  ) => {
    setToggleModel(value);
    if (value === true)
      hideModel(userType, resumeVerified, resumeId, resumeType);
  };

  const errorMsgHandler = (msg) => {
    setErrorMsg(msg);
  };

  const resumeModelHandler = (value) => {
    setToggleResumeModel(value);
  };

  const candidateResumeHandler = (file) => {
    setCandidateResume(file);
  };

  const companyLogoModelHandler = (value) => {
    setCompanyLogoModel(value);
  };

  const companyLogoHandler = (file) => {
    setEmployerForm({
      ...employerForm,
      company_logo: file,
    });
  };

  const companyLegalDocumentHandler = (file) => {
    setEmployerForm({
      ...employerForm,
      legal_document: file,
    });
  };

  const CandidateSignUpHandler = async (
    data,
    selectJobTitlesId,
    selectSkillTagsId,
    selectWorkPreferenceId
  ) => {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      countryId,
      stateId,
      cityId,
      phoneNo,
      linkedinURL,
      salaryExpectation,
      files,
      currency,
      currencyId,
      salaryStart,
    } = data;
    try {
      const formData = new FormData();
      formData.append("first_name", firstName?.trim());
      formData.append("last_name", lastName?.trim());
      formData.append("email", email?.trim());
      formData.append("phone", phoneNo);
      formData.append("password", password);
      formData.append("password_confirmation", confirmPassword);
      formData.append("country", countryId);
      stateId !== null && formData.append("state_id", stateId);
      cityId !== null && formData.append("city_id", cityId);
      formData.append("linkedin", linkedinURL);
      formData.append("terms_of_services", 1);
      formData.append("expected_salary", salaryStart);
      formData.append("currency_id", currencyId);
      formData.append("salary_range", salaryExpectation);

      files !== null && formData.append("resume", files);

      selectJobTitlesId !== null &&
        Object.keys(selectJobTitlesId).forEach((key) => {
          formData.append("job_title[]", selectJobTitlesId[key]);
        });

      selectSkillTagsId !== null &&
        Object.keys(selectSkillTagsId).forEach((key) => {
          formData.append("tech_tags[]", selectSkillTagsId[key]);
        });

      selectWorkPreferenceId !== null &&
        Object.keys(selectWorkPreferenceId).forEach((key) => {
          formData.append("work_preferences[]", selectWorkPreferenceId[key]);
        });

      setLoader(true);
      setErrorMsg("");

      await API.post("/auth/signup-candidate", formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;
            const { user } = response?.data;
            const { country } = user;
            if (res?.result === true) {
              // dispatch(setLoginUser(res));
              // dispatch(setLoginUserName(user?.first_name));
              dispatch(
                setLoginUserCountry({
                  id: country?.id,
                  name: country?.name,
                })
              );
              localStorage.setItem("token", res?.access_token);
              toast.success(res?.message?.success, {
                autoClose: 2000,
                closeOnClick: false,
              });
            } else {
              toast.error(res?.message?.error, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
            setLoader(false);
          }
        })
        .then(() => {
          setSuccessMsg("Please check your email to verify your account.");
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            toast.error(data?.error, {
              autoClose: 2000,
              closeOnClick: false,
            });
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const signUpFormHandler = (data) => {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      countryId,
      stateId,
      cityId,
      phoneNo,
    } = data;

    setEmployerForm({
      ...employerForm,
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      password_confirmation: confirmPassword,
      country: countryId,
      state_id: stateId,
      city_id: cityId,
      phoneNo: phoneNo,
    });
    setErrorMsg("");
    dispatch(setSignUpEmp(data));
    dispatch(setActiveSubTab("createProfile"));
  };

  const createProfileHandler = async (data) => {
    const {
      websiteUrl,
      companyName,
      companyInfo,
      linkedinURL,
      files,
      legal_document,
    } = data;
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      countryId,
      stateId,
      cityId,
      phoneNo,
    } = signUpEmp;

    setEmployerForm({
      ...employerForm,
      website_url: websiteUrl,
      company_name: companyName,
      company_detail: companyInfo,
      company_logo: files,
      legal_document: legal_document ? legal_document : null,
      linkedin: linkedinURL,
    });

    try {
      const formData = new FormData();
      formData.append("first_name", firstName?.trim());
      formData.append("last_name", lastName?.trim());
      formData.append("email", email?.trim());
      formData.append("phone", phoneNo);
      formData.append("password", password);
      formData.append("password_confirmation", confirmPassword);
      formData.append("country", countryId);
      stateId !== null && formData.append("state_id", stateId);
      cityId !== null && formData.append("city_id", cityId);

      legal_document !== null &&
        formData.append("legal_document", legal_document);
      formData.append("linkedin", linkedinURL);
      formData.append(
        "website_url",
        websiteUrl.includes("http://") ? websiteUrl : "http://" + websiteUrl
      );
      formData.append("company_name", companyName);
      formData.append("company_detail", companyInfo);
      formData.append(" terms_of_services", 1);
      files !== null && formData.append("company_logo", files);

      setLoader(true);
      setErrorMsg("");
      await API.post("/auth/signup", formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;
            const { user } = response?.data;
            const { country } = user;

            if (res?.result === true) {
              // dispatch(setLoginUser(res));
              // dispatch(setLoginUserName(user?.first_name));
              dispatch(
                setLoginUserCountry({
                  id: country?.id,
                  name: country?.name,
                })
              );
              localStorage.setItem("token", res?.access_token);
              toast.success(res?.message?.success, {
                autoClose: 2000,
                closeOnClick: false,
              });
            } else {
              toast.error(res?.message?.error, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
            setLoader(false);
          }
        })
        .then(() => {
          setSuccessMsg("Please check your email to verify your account.");
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            toast.error(data?.error, {
              autoClose: 2000,
              closeOnClick: false,
            });
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const getResumes = async (userId) => {
    dispatch(
      getResumeForApplyingOnJob({
        userId,
      })
    )
      .then(async (response) => {
        const res = response?.payload?.data;
        dispatch(setUserResumes(res));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (code) {
      async function checkEmailVerify() {
        try {
          await API.get(`/auth/check-email-verified/${code}`)
            .then(async (response) => {
              if (response?.status === 200) {
                const res = response?.data;
                const { user } = response?.data;
                const { country } = user;
                if (res?.result === true) {
                  dispatch(
                    setLoginUser({ access_token: res?.token, user: user })
                  );
                  dispatch(setLoginUserName(user?.first_name));
                  dispatch(
                    setLoginUserCountry({
                      id: country?.id,
                      name: country?.name,
                    })
                  );
                  await getResumes(user?.id);
                  localStorage.setItem("token", res?.token);
                  if (res?.permissions)
                    dispatch(setdefaultPermissions(res?.permissions));

                  if (user?.resumeVerified === 0) {
                    dispatch(setResumeFormMode(RESUME_MODE.VERIFICATION));
                  } else if (user?.resumeVerified !== 0) {
                    dispatch(setResumeFormMode(RESUME_MODE.UPDATE));
                  }

                  setErrorMsg(res?.message?.success);
                  modelHandler(
                    true,
                    user?.user_type,
                    res?.resumeVerified,
                    res?.resume_id,
                    res?.resume_type
                  );
                }
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
      checkEmailVerify();
    }
  }, [code]);

  return (
    <>
      <section className="candiate-signup">
        <div className={"container container-site"}>
          <div className="candiate-signup-row">
            <div className="position-relative">
              {activeSubTab !== "postJob" && (
                <Button
                  variant="link"
                  className="back-to-home add resp-mob p-0"
                  onClick={() => {
                    if (
                      activeSubTab === "postJob" &&
                      mode === "add" &&
                      jobDecision !== null
                    ) {
                      dispatch(clearJobDecision());
                    } else {
                      navigate("/");
                    }
                  }}
                >
                  <img src="/image/form-back-arrow.png" alt="" />
                </Button>
              )}
              <div className="candidate-signup-form">
                <div className="candidate-signup">
                  <h2>
                    {activeTab === "candidate" ? (
                      <>Create account</>
                    ) : (
                      <>
                        {activeSubTab === "signUp" && (
                          <>Create employer account</>
                        )}
                        {activeSubTab === "createProfile" && (
                          <>Create Company profile</>
                        )}
                        {activeSubTab === "postJob" && <></>}
                      </>
                    )}
                  </h2>
                  {isEmailVerify ? (
                    <></>
                  ) : (
                    <div className="login-here">
                      <p>
                        Already have account?
                        <Link to="/login" className="text-pacific-blue">
                          <span> Log in</span>
                        </Link>
                      </p>
                    </div>
                  )}

                  {activeSubTab !== "postJob" && (
                    <>
                      <div className="candidate-employe-btn">
                        <Link
                          onClick={() => {
                            setSuccessMsg("");
                            dispatch(setActiveTab("candidate"))
                          }}
                        >
                          <button
                            className={
                              activeTab === "candidate"
                                ? "employee-btn toggle-btn-active"
                                : "employee-btn"
                            }
                          >
                            Candidate
                          </button>
                        </Link>
                        <Link
                          onClick={() => {
                            setSuccessMsg("");
                            dispatch(setActiveTab("employer"));
                            dispatch(setActiveSubTab("signUp"));
                            dispatch(setSignUpEmp({}));
                          }}
                        >
                          <button
                            className={
                              activeTab === "employer"
                                ? "employee-btn toggle-btn-active"
                                : "employee-btn"
                            }
                          >
                            Employer
                          </button>
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {activeTab === "candidate" ? (
                <>
                  <div className="candidate-signup-form">
                    <div className="or-passage d-none">
                      <p>Or</p>
                    </div>
                  </div>
                  <CanProgressBar
                    signUpActive={true}
                    resumeVerifyActive={false}
                  />

                  <CandidateForm
                    errorMsg={errorMsg}
                    successMsg={successMsg}
                    loader={loader}
                    candidateResume={candidateResume}
                    errorMsgHandler={errorMsgHandler}
                    resumeModelHandler={resumeModelHandler}
                    CandidateSignUpHandler={CandidateSignUpHandler}
                  />
                </>
              ) : (
                <>
                  {signUpBarHide === false && (
                    <div className="goole-login-border">
                      <ProgressBar currentTab={activeSubTab} />
                    </div>
                  )}
                  {activeSubTab === "signUp" && (
                    <SignUpForm signUpFormHandler={signUpFormHandler} />
                  )}
                  {activeSubTab === "createProfile" && (
                    <CreateProfileForm
                      errorMsg={errorMsg}
                      successMsg={successMsg}
                      loader={loader}
                      companyLogo={employerForm?.company_logo}
                      legaDocument={employerForm?.legal_document}
                      companyLegalDocumentHandler={companyLegalDocumentHandler}
                      companyLogoModelHandler={companyLogoModelHandler}
                      createProfileHandler={createProfileHandler}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* {toggleModel && <WelcomeModel modelHandler={modelHandler} />} */}
      {toggleModel && (
        <ActionModal
          heading="Success"
          content="Your Account has been created successfully"
          closeModal={() => modelHandler}
          modalArt="/image/success-modal.png"
        ></ActionModal>
      )}
      {companyLogoModel && (
        <UploadImageModel
          companyLogoHandler={companyLogoHandler}
          companyLogoModelHandler={companyLogoModelHandler}
        />
      )}{" "}
      {toggleResumeModel && (
        <FileUploadModel
          modelHandler={resumeModelHandler}
          resumeFileHandler={candidateResumeHandler}
        />
      )}
    </>
  );
};

export default SignUpMain;
