import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSideBar } from "../../../../redux/slices/AdminDashboardSlice";
import * as Yup from "yup";
import FormError from "../../../Common/Admin/FormError/FormError";
import PhoneNumber from "../../../Common/PhoneNumber/PhoneNumber";
import API from "../../../../api";
import APIPvt from "../../../../apiPvt";
import {
  getCitiesAsync,
  getEmpCountriesAsync,
  getStateAsync,
} from "../../../../redux/slices/AuthSlice";

const AdminCreateUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formMode, selectedDep, selectedRoleR, selectedCompanyUser } =
    useSelector((state) => state.adminDashboard);

  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);

  /*Select Value*/
  const [selectModule, setSelectModule] = useState([]);

  /*Dropdown Option*/
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [depOptions, setDepOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [userTypeOptions, setUserTypeOptions] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [statusDropDown, setStatusDropDown] = useState([]);

  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [maskedPlaceHolder, setMaskedPlaceHolder] = useState("");

  const [statesCount, setStatesCount] = useState(0);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      country: "",
      countryId: null,
      address: "",
      city: "",
      cityId: null,
      state: "",
      stateId: null,
      postalCode: "",
      phoneNo: "",
      email: "",

      dep: "",
      depId: null,
      role: "",
      roleId: null,
      userType: "",
      userTypeId: null,
      jobType: "",
      jobTypeId: null,
      module: [],

      userName: "",
      status: "",
      statusId: null,
      password: "",
      confirmPassword: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      country: Yup.string().required("Please Select Country"),
      city: Yup.string().required("Please Select City"),
      postalCode: Yup.string(),
      phoneNo: Yup.string().required("Please enter phone Number"),
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please Enter Valid Email Id"
        )
        .required("Please Enter Email Id"),

      dep: Yup.string().required("Please Enter Department"),
      role: Yup.string().required("Please Enter Role"),
      userType: Yup.string().required("Please Enter User Type"),
      jobType: Yup.string().required("Please Enter Job Type"),

      userName: Yup.string(),
      status: Yup.string().required("Please Enter Status"),
      password:
        formMode === "edit"
          ? Yup.string()
          : Yup.string().test((value) => {
              const errors = [];
              if (!value || value?.length < 8) {
                errors.push("Password must be at least 8 characters in length");
              }
              if (!/\d/.test(value)) {
                errors.push("Use a minimum of 1 numeric value");
              }
              if (!/[A-Z]/.test(value)) {
                errors.push("Use at least 1 upper case letter (ABCD)");
              }
              if (!/[a-z]/.test(value)) {
                errors.push("Use at least 1 lower case letter (abcd)");
              }
              if (!/[!#%$*]/.test(value)) {
                errors.push("Use at least one special character (!# % $ *)");
              }
              setPasswordErrors(errors);

              return errors.length === 0;
            }),
      confirmPassword:
        formMode === "edit"
          ? Yup.string().oneOf([Yup.ref("password")], "Password doesn’t match")
          : Yup.string()
              .required("Please re-enter confirm password")
              .oneOf([Yup.ref("password")], "Password doesn’t match"),
    }),
    onSubmit: async (data, { resetForm }) => {
      if (passwordErrors?.length > 0) return;
      onCreateUser(data);
    },
  });

  const onCountyClick = async (item) => {
    const { id, name, masked_phone_number, placeholder, states_count } = item;

    await formik.setFieldValue("country", name);
    await formik.setFieldValue("countryId", id);

    await formik.setFieldValue("state", "");
    await formik.setFieldValue("stateId", null);
    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);

    setMaskedPhoneNumber(masked_phone_number);
    setMaskedPlaceHolder(placeholder);
    setStateOptions([]);
    setCityOptions([]);

    if (states_count === 0) {
      getCitiesData(id, null);
    } else {
      getStatusData(id);
    }
  };

  const onStateClick = async (id, name) => {
    await formik.setFieldValue("state", name);
    await formik.setFieldValue("stateId", id);

    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);
    getCitiesData(null, id);
  };

  const onCityClick = async (id, name) => {
    await formik.setFieldValue("city", name);
    await formik.setFieldValue("cityId", id);
  };

  const onDepClick = async (id, name, roles) => {
    await formik.setFieldValue("dep", name);
    await formik.setFieldValue("depId", id);
    await formik.setFieldValue("role", "");
    await formik.setFieldValue("roleId", null);
    setRoleOptions(roles);
  };

  const onRoleClick = async (id, title) => {
    await formik.setFieldValue("role", title);
    await formik.setFieldValue("roleId", id);
    getRoleModule(id);
  };

  const onUserTypeClick = async (id, name) => {
    await formik.setFieldValue("userType", name);
    await formik.setFieldValue("userTypeId", id);
  };

  const onJobTypeClick = async (id, name) => {
    await formik.setFieldValue("jobType", name);
    await formik.setFieldValue("jobTypeId", id);
  };

  const onStatusClick = async (label, value) => {
    await formik.setFieldValue("status", label);
    await formik.setFieldValue("statusId", value);
  };

  const onCancelClick = () => {
    if (formMode === "add") {
      dispatch(
        setActiveSideBar({
          id: "2",
          name: "Roles",
          value: "Create Role",
        })
      );
    } else if (formMode === "edit") {
      dispatch(
        setActiveSideBar({
          id: "2",
          name: "Users",
          value: "Users",
        })
      );
      navigate("/admin-user");
    }
  };

  const isPasswordFieldValid = (passwordErrors) =>
    !!(formik.touched["password"] && passwordErrors?.length > 0);
  const getPasswordErrorMessage = (passwordErrors) => {
    if (isPasswordFieldValid(passwordErrors)) {
      return (
        <ul className="error-msg">
          {passwordErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const onCreateUser = async (data) => {
    try {
      const {
        firstName,
        lastName,
        email,
        address,
        cityId,
        stateId,
        postalCode,
        countryId,
        phoneNo,
        depId,
        roleId,
        userTypeId,
        jobTypeId,
        userName,
        statusId,
        password,
        confirmPassword,
      } = data;

      const formData = new FormData();
      formData.append("first_name", firstName?.trim());
      formData.append("last_name", lastName?.trim());
      formData.append("email", email?.trim());
      formData.append("address", address);
      formData.append("city_id", cityId);
      stateId !== null && formData.append("state_id", stateId);
      formData.append("postal_code", postalCode);
      formData.append("country_id", countryId);
      formData.append("phone", phoneNo);
      formData.append("department_id", depId);
      formData.append("role_id", roleId);
      formData.append("user_type_id", userTypeId);
      formData.append("job_type_id", jobTypeId);
      formData.append("username", email);
      formData.append("status", statusId);
      password !== "" && formData.append("password", password);
      confirmPassword !== "" &&
        formData.append("password_confirmation", confirmPassword);

      setLoader(true);
      let baseURL = ``;
      if (formMode === "add") baseURL = `/user`;
      else if (formMode === "edit") baseURL = `/user/${selectedCompanyUser}`;

      await APIPvt.post(baseURL, formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;

            if (res?.success === true) {
              dispatch(
                setActiveSideBar({
                  id: "2",
                  name: "Users",
                  value: "Users",
                })
              );
              navigate("/admin-user");
            } else {
              setErrorMsg(res?.message);
              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
            }
            setLoader(false);
          }
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            setErrorMsg(data?.message);
            setTimeout(() => {
              setErrorMsg("");
            }, 5000);
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const getEditUserData = async () => {
    if (selectedCompanyUser !== null) {
      try {
        await APIPvt.get(`/user/${selectedCompanyUser}`)
          .then(async (response) => {
            if (response?.status === 200 || response?.success === true) {
              const { user, userCountry } = response?.data?.data;
              const { masked_phone_number, placeholder, states_count } =
                userCountry;

              await formik.setFieldValue("firstName", user?.first_name);
              await formik.setFieldValue("lastName", user?.last_name);
              await formik.setFieldValue("country", user?.country?.name);
              await formik.setFieldValue("countryId", user?.country?.id);
              await formik.setFieldValue("address", user?.address);
              await formik.setFieldValue("city", user?.city?.name);
              await formik.setFieldValue("cityId", user?.city?.id);
              user?.state !== null &&
                (await formik.setFieldValue("state", user?.state?.name));
              user?.state !== null &&
                (await formik.setFieldValue("stateId", user?.state?.id));
              await formik.setFieldValue("postalCode", user?.postal_code);
              await formik.setFieldValue("phoneNo", user?.phone);
              await formik.setFieldValue("email", user?.email);

              await formik.setFieldValue("dep", user?.role?.department?.name);
              await formik.setFieldValue("depId", user?.role?.department?.id);
              await formik.setFieldValue("role", user?.role?.title);
              await formik.setFieldValue("roleId", user?.role?.id);
              await formik.setFieldValue(
                "userType",
                user?.user_type_admin?.name
              );
              await formik.setFieldValue(
                "userTypeId",
                user?.user_type_admin?.id
              );

              await formik.setFieldValue("jobType", user?.job_type?.name);
              await formik.setFieldValue("jobTypeId", user?.job_type?.id);
              await formik.setFieldValue("module", user?.modules);

              await formik.setFieldValue("userName", user?.username);
              await formik.setFieldValue(
                "status",
                user?.status === 1 ? "Active" : "Inactive"
              );
              await formik.setFieldValue("statusId", user?.status);

              if (user?.modules?.length > 0) setSelectModule(user?.modules);

              if (user?.country?.id) {
                if (states_count === 0) getCitiesData(user?.country?.id, null);
                else getStatusData(user?.country?.id);
                getCitiesData(null, user?.country?.id);
              }

              setMaskedPlaceHolder(placeholder);
              setMaskedPhoneNumber(masked_phone_number);
              setStatesCount(states_count);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getRoleModule = async (id) => {
    if (id !== null) {
      try {
        await APIPvt.get(`/get-role-modules?role_id=${id}`)
          .then((response) => {
            if (response?.status === 200) {
              let res = response?.data;
              if (res?.success === true) {
                setSelectModule(res?.data);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getStatusData = async (id) => {
    dispatch(
      getStateAsync({
        id: id,
      })
    )
      .then((response) => {
        const res = response?.payload;
        setStateOptions(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCitiesData = async (id1, id2) => {
    dispatch(
      getCitiesAsync({
        country_id: id1,
        state_id: id2,
      })
    )
      .then((response) => {
        const res = response?.payload;
        setCityOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCountries = async () => {
    try {
      dispatch(getEmpCountriesAsync({ country_id: null }))
        .then((response) => {
          const res = response?.payload;
          setCountryOptions(res);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getDataCreateUser = async () => {
    try {
      await APIPvt.get(`/get-data-create-user`)
        .then((response) => {
          if (response?.status === 200 || response?.success === true) {
            let res = response?.data;
            let option = response?.data?.data;
            if (res?.success === true) {
              setDepOptions(option?.departments);
              setUserTypeOptions(option?.user_types);
              setJobTypeOptions(option?.job_types);
              setStatusDropDown(option?.status);
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formMode === "edit") getEditUserData();
  }, [selectedCompanyUser]);

  useEffect(() => {
    getDataCreateUser();
    if (formMode === "add") {
      async function getSelectedDepRole() {
        await formik.setFieldValue("depId", selectedDep?.id);
        await formik.setFieldValue("dep", selectedDep?.name);
        await formik.setFieldValue("roleId", selectedRoleR?.id);
        await formik.setFieldValue("role", selectedRoleR?.name);
      }
      getSelectedDepRole();
      getCountries();
    }
  }, []);

  return (
    <>
      <div className="form-heading">
        <h2>{formMode === "edit" ? "Edit " : "Create new "}user</h2>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="department-form-box">
          <div className="first-part-f">
            <h3>Personal information</h3>
            <p>Add user personal details here</p>
          </div>
          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("firstName") && "error-input-admin"
              }`}
            >
              <label for="">First name *</label>
              <br />
              <input
                type="text"
                id="dept-f"
                placeholder="First name"
                className="form-font-admin"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("firstName")}
            </div>
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("lastName") && "error-input-admin"
              }`}
            >
              <label for="">Last name *</label>
              <br />
              <input
                type="text"
                id="dept-f"
                placeholder="Last name"
                className="form-font-admin"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("lastName")}
            </div>

            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("country") && "error-input-admin"
              }`}
            >
              <label for="">Country *</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.country !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.country === ""
                        ? "Country"
                        : formik?.values?.country}
                    </p>
                    {isFormFieldValid("country") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>

                {countryOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-country">
                      {countryOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onCountyClick(item);
                          }}
                        >
                          {/* <img src="/image/CA.png" alt="" /> */}
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("country")}
            </div>
          </div>
          <div className="admin-from-step space-b-f">
            <div className="f-admin feild-f-admin-addres">
              <label for="">Address</label>
              <br />
              <input
                type="text"
                id="dept-f"
                placeholder="Address"
                className="form-font-admin"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div
              className={`f-admin feild-f-admin ${
                stateOptions?.length === 0 && "disable-field"
              }`}
            >
              <label for="">State/Province</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.state !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.state === ""
                        ? "State/Province"
                        : formik?.values?.state}
                    </p>
                    {isFormFieldValid("state") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>
                {stateOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-country">
                      {stateOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onStateClick(item?.id, item?.name);
                          }}
                        >
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                (isFormFieldValid("city") && "error-input-admin") ||
                (cityOptions?.length === 0 && "disable-field")
              }`}
            >
              <label for="">City *</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.city !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.city === ""
                        ? "City"
                        : formik?.values?.city}
                    </p>
                    {isFormFieldValid("city") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>
                {cityOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-country">
                      {cityOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onCityClick(item?.id, item?.name);
                          }}
                        >
                          {/* <img src="/image/CA.png" alt="" /> */}
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("city")}
            </div>
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("postalCode") && "error-input-admin"
              }`}
            >
              <label for="">Postal code</label>
              <br />
              <input
                type="text"
                id="dept-f"
                placeholder="Postal code"
                className="form-font-admin"
                name="postalCode"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("postalCode")}
            </div>
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("phoneNo") && "error-input-admin"
              }`}
            >
              <label for="">Phone number *</label>
              <PhoneNumber
                id="dept-f"
                className="form-font-admin"
                type="tel"
                name="phoneNo"
                value={formik.values.phoneNo}
                maskedPlaceHolder={maskedPlaceHolder}
                maskedPhoneNumber={maskedPhoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("phoneNo")}
            </div>

            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("email") && "error-input-admin"
              }`}
            >
              <label for="">Email address *</label>
              <br />
              <input
                type="email"
                id="dept-f"
                placeholder="Email id"
                className="form-font-admin"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("email")}
            </div>
          </div>
        </div>

        <div className="department-form-box second-box-admin">
          <div className="first-part-f">
            <h3>User detail</h3>
            <p>Add user detail here</p>
          </div>

          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("dep") && "error-input-admin"
              }`}
            >
              <label for="">Department *</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.dep !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.dep === ""
                        ? "Department"
                        : formik?.values?.dep}
                    </p>
                    {isFormFieldValid("dep") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>
                {depOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-portal">
                      {depOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onDepClick(item?.id, item?.name, item?.roles);
                          }}
                        >
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("dep")}
            </div>

            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("role") && "error-input-admin"
              }`}
            >
              <label for="">Role *</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.role !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.role === ""
                        ? "Role"
                        : formik?.values?.role}
                    </p>
                    {isFormFieldValid("role") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>
                {roleOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-portal">
                      {roleOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onRoleClick(item?.id, item?.title);
                          }}
                        >
                          {item?.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("role")}
            </div>
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("userType") && "error-input-admin"
              }`}
            >
              <label for="">User type *</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.userType !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.userType === ""
                        ? "User type"
                        : formik?.values?.userType}
                    </p>
                    {isFormFieldValid("userType") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>
                {userTypeOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-portal">
                      {userTypeOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onUserTypeClick(item?.id, item?.name);
                          }}
                        >
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("userType")}
            </div>
          </div>
          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("jobType") && "error-input-admin"
              }`}
            >
              <label for="">Job type *</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.jobType !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.jobType === ""
                        ? "Job type"
                        : formik?.values?.jobType}
                    </p>
                    {isFormFieldValid("jobType") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>
                {jobTypeOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-portal">
                      {jobTypeOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onJobTypeClick(item?.id, item?.name);
                          }}
                        >
                          <img src="/image/li-search.png" alt="" />
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("jobType")}
            </div>
            {selectModule?.length > 0 && (
              <div className="f-admin feild-f-admin tags-admin tags-list-user-c">
                <div className="tags-admin list-role-c">
                  <ul className="tags-titles-list">
                    {selectModule?.map((item, index) => (
                      <li>{item?.name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <div className="f-admin feild-f-admin"></div>
          </div>
        </div>
        <div className="department-form-box">
          <div className="first-part-f">
            <h3>User credential</h3>
            <p>Add user credentail detail here</p>
          </div>
          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("userName") && "error-input-admin"
              }`}
            >
              <label for="">User name *</label>
              <br />
              <input
                type="text"
                id="dept-f"
                placeholder="User name"
                className="form-font-admin"
                name="userName"
                disabled={true}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("userName")}
            </div>

            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("status") && "error-input-admin"
              }`}
            >
              <label for="">Status *</label>
              <div className={`admin-status-filter-form`}>
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.status !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.status === ""
                        ? "Status"
                        : formik?.values?.status}
                    </p>
                    {isFormFieldValid("status") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>

                {statusDropDown?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-portal">
                      {statusDropDown?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onStatusClick(item?.label, item?.value);
                          }}
                        >
                          {item?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("status")}
            </div>

            <div className="f-admin feild-f-admin"></div>
          </div>
          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("password") && "error-input-admin"
              }`}
            >
              <label for="">Password * </label>
              <div className="admin-status-filter-form">
                <div className="password-admin-f">
                  <input
                    type={passwordShown ? "text" : "password"}
                    id="admin-pass-field"
                    placeholder="Password"
                    className="form-font-admin"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {isPasswordFieldValid(passwordErrors) ? (
                    <img src="/image/warning.png" alt="" />
                  ) : (
                    <>
                      <img
                        src="/image/pass-admin.png"
                        alt=""
                        className={passwordShown && "password-icon-togle"}
                        onClick={() => setPasswordShown(true)}
                      />
                      <img
                        src="/image/hide-pass-admin.png"
                        alt=""
                        className={!passwordShown && "password-icon-togle"}
                        onClick={() => setPasswordShown(false)}
                      />
                    </>
                  )}
                </div>
              </div>
              {getPasswordErrorMessage(passwordErrors)}
            </div>
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("confirmPassword") && "error-input-admin"
              }`}
            >
              <label for="">Confirm password *</label>
              <div className="admin-status-filter-form">
                <div className="password-admin-f">
                  <input
                    type={confirmPasswordShown ? "text" : "password"}
                    id="admin-pass-field"
                    placeholder="Confirm password"
                    className="form-font-admin"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  {isFormFieldValid("confirmPassword") ? (
                    <img src="/image/warning.png" alt="" />
                  ) : (
                    <>
                      <img
                        src="/image/pass-admin.png"
                        alt=""
                        className={
                          confirmPasswordShown && "password-icon-togle"
                        }
                        onClick={() => setConfirmPasswordShown(true)}
                      />
                      <img
                        src="/image/hide-pass-admin.png"
                        alt=""
                        className={
                          !confirmPasswordShown && "password-icon-togle"
                        }
                        onClick={() => setConfirmPasswordShown(false)}
                      />
                    </>
                  )}
                </div>
              </div>
              {getFormErrorMessage("confirmPassword")}
            </div>
            <div className="f-admin feild-f-admin"></div>
          </div>
        </div>

        <div className="admin-form-btn">
          {" "}
          <button className="cancel-admin" onClick={() => onCancelClick()}>
            Cancel
          </button>
          <button className="next-admin" type="submit">
            {" "}
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : formMode === "add" ? (
              "Save"
            ) : (
              "Edit & Save"
            )}
          </button>
        </div>
      </form>
      <FormError errorMsg={errorMsg} />
    </>
  );
};

export default AdminCreateUser;
