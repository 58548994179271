import "./TableError.css";

const TableError = ({ msg }) => {
  if (msg === "") return;

  return (
    <div className="table-error">
      <p>{msg}</p>
    </div>
  );
};

export default TableError;
