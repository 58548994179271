import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getCitiesAsync, getEmpCountriesAsync, getStateAsync, resendVerifyEmailAsync } from "../../../../redux/slices/AuthSlice";
import API from "../../../../api";
import { PublicDomains } from "../../../../constants";
import PhoneNumber from "../../../Common/PhoneNumber/PhoneNumber";

const SignUpForm = ({ signUpFormHandler }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);

  
  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [maskedPlaceHolder, setMaskedPlaceHolder] = useState("");
  
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      country: "",
      countryId: null,
      state: "",
      stateId: null,
      city: "",
      cityId: null,
      phoneNo: "",
      termServices: false,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z ]+$/, "First name should be an alphabet")
        .required("Please enter first name"),
      lastName: Yup.string()
        .matches(/^[A-Za-z ]+$/, "Last name should be an alphabet")
        .required("Please enter last name"),
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Please enter email")
        .test(
          "is-not-public-email",
          "Public email account are not allowed",
          (value) => {
            const domain = value.split("@")[1];
            console.log("domain");
            return !PublicDomains.includes(domain);
          }
        ),

      password: Yup.string().test((value) => {
        const errors = [];
        if (!value || value.length < 8) {
          errors.push("Password must be at least 8 characters in length");
        }
        if (!/\d/.test(value)) {
          errors.push("Use a minimum of 1 numeric value");
        }
        if (!/[A-Z]/.test(value)) {
          errors.push("Use at least 1 upper case letter (ABCD)");
        }
        if (!/[a-z]/.test(value)) {
          errors.push("Use at least 1 lower case letter (abcd)");
        }
        if (!/[!#%$*]/.test(value)) {
          errors.push("Use at least one special character (!# % $ *)");
        }
        setPasswordErrors(errors);

        return errors.length === 0;
      }),
      confirmPassword: Yup.string()
        .required("Please re-enter confirm password")
        .oneOf([Yup.ref("password")], "Your passwords do not match"),
      country: Yup.string().required("Please select country"),
      state:  Yup.string(),
      city:  Yup.string(),
      phoneNo: Yup.string().required("Please enter phone number"),
      termServices: Yup.boolean()
        .oneOf([true], "Please select the terms and conditions")
        .required("Please select the terms and conditions"),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      const { email, state, city } = data;
      if (passwordErrors?.length > 0) return;
      if(stateOptions?.length > 0 && state === "") {
        formik?.setFieldError("state", "Please select state");
        return;
      }
      if(citiesOptions?.length > 0 && city === "") {
        formik?.setFieldError("city", "Please select city");
        return;
      }
      try {
        setLoader(true);
        await API.post("/auth/check-email-unique", {
          email: email,
        })
          .then(async (response) => {
            if (response?.status === 200 || response?.status === 201) {
              let res = response?.data;
              if (response?.data?.result === true) {
                await signUpFormHandler(data);
              } else {
                let errorMsg = res?.message?.email[0];
                setErrors({ email: errorMsg });
              }
              setLoader(false);
            }
          })
          .catch((error) => {
            if (error) {
              const { response } = error;
              const { data } = response;
              setErrors({ email: data?.message?.error });
              console.log(error);
            }
            setLoader(false);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isPasswordFieldValid = (passwordErrors) =>
    !!(formik.touched["password"] && passwordErrors?.length > 0);
  const getPasswordErrorMessage = (passwordErrors) => {
    if (isPasswordFieldValid(passwordErrors)) {
      return (
        <ul className="error-msg">
          {passwordErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const countryHandler = async (item) => {
    const {id, name, masked_phone_number, placeholder, states_count} = item;

    await formik.setFieldValue("country", name);
    await formik.setFieldValue("countryId", id);

    await formik.setFieldValue("state", "");
    await formik.setFieldValue("stateId", null);

    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);

    setMaskedPhoneNumber(masked_phone_number);
    setMaskedPlaceHolder(placeholder);
    setStateOptions([]);
    setCitiesOptions([]);
    
    if(states_count === 0){
      getCitiesData(id, null);
    }
    else {
      getStatusData(id);
    }
  };


  const stateHandler = async (id, name) => {
    await formik.setFieldValue("state", name);
    await formik.setFieldValue("stateId", id);
    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);
    getCitiesData(null, id);
  };

  const cityHandler = async (id, name) => {
    await formik.setFieldValue("city", name);
    await formik.setFieldValue("cityId", id);
  };


  
  const getStatusData = async (id) => {
    dispatch(
      getStateAsync({
        id: id
      })
    )
      .then((response) => {
        const res = response?.payload;
        setStateOptions(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCitiesData = async (id1, id2) => {
    dispatch(
      getCitiesAsync({
        country_id: id1,
        state_id: id2
      })
    )
      .then((response) => {
        const res = response?.payload;
        setCitiesOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    async function getCountries() {
      try {
        dispatch(
          getEmpCountriesAsync({ country_id: null })
        )
          .then((response) => {
            const res = response?.payload;
              setCountryOptions(res);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
    getCountries();
  }, []);

  return (
    <>
      <div className="candidate-signup-form-feild ">
        <form onSubmit={formik.handleSubmit}>
          <div className="candidate-username-signup-form f-feild ">
            <div
              className={`first-name ${
                isFormFieldValid("firstName") && "error-input"
              }`}
            >
              <label for="fname">First name</label>
              <br />
              <input
                className="form-font-f"
                type="text"
                placeholder="First name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("firstName")}
            </div>

            <div
              className={`last-name ${
                isFormFieldValid("lastName") && "error-input"
              }`}
            >
              <label for="lname">Last name</label>
              <br />
              <input
                className="form-font-f"
                type="text"
                placeholder="Last name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("lastName")}
            </div>
          </div>

          <div
            className={`signup-email f-feild ${
              isFormFieldValid("email") && "error-input"
            }`}
          >
            <label for="femail">E-mail</label>
            <br />
            <input
              className="form-font-f"
              type="email"
              name="email"
              id=""
              placeholder="Enter valid email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("email")}
          </div>

          <div
            className={`signup-passowrd f-feild ${
              isPasswordFieldValid(passwordErrors) && "error-input"
            }`}
          >
            <label for="fpassword">Password</label>
            <br />
            <div class="password-icon bg-pass-f">
              <input
                className="form-font-f"
                id="password-field"
                type={passwordShown ? "text" : "password"}
                name="password"
                placeholder="Enter valid password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="icon-hide">
                {isPasswordFieldValid(passwordErrors) ? (
                  <img src="/image/warning.png" alt="" />
                ) : (
                  <>
                    <img
                      src="/image/hide-password.png"
                      alt=""
                      className={passwordShown && "password-icon-togle"}
                      onClick={() => setPasswordShown(true)}
                    />
                    <img
                      src="/image/password.png"
                      alt=""
                      className={!passwordShown && "password-icon-togle"}
                      onClick={() => setPasswordShown(false)}
                    />
                  </>
                )}
              </div>
            </div>
            {getPasswordErrorMessage(passwordErrors)}
          </div>
          <div
            className={`signup-passowrd f-feild ${
              isFormFieldValid("confirmPassword") && "error-input"
            }`}
          >
            <label for="fpassword">Confirm password</label>
            <br />
            <div class="password-icon bg-pass-f">
              <input
                className="form-font-f"
                id="password-field"
                type={confirmPasswordShown ? "text" : "password"}
                name="confirmPassword"
                placeholder="Re-type password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="icon-hide">
                {isFormFieldValid("confirmPassword") ? (
                  <img src="/image/warning.png" alt="" />
                ) : (
                  <>
                    <img
                      src="/image/hide-password.png"
                      alt=""
                      className={confirmPasswordShown && "password-icon-togle"}
                      onClick={() => setConfirmPasswordShown(true)}
                    />
                    <img
                      src="/image/password.png"
                      alt=""
                      className={!confirmPasswordShown && "password-icon-togle"}
                      onClick={() => setConfirmPasswordShown(false)}
                    />
                  </>
                )}
              </div>
            </div>
            {getFormErrorMessage("confirmPassword")}
          </div>

          <div
            className={`signup-country f-feild ${
              isFormFieldValid("country") && "error-input"
            }`}
          >
            <label for="fcountry">Country</label>
            <br />
            <div
              className={`industry-feild-frm-country bg-pass-f ${
                isFormFieldValid("country") && "error-input"
              }`}
            >
              <div
                name="country"
                id="dropdown-boxId-2"
                className="company-form-dropdownBox-f"
                data-toggle="dropdown"
                aria-haspopup="true"
              >
                <span 
                    className={`${formik?.values?.country === "" && "dropdown-opty"
                    }`}>
                      {formik?.values?.country === ""
                      ? "Choose country"
                      : formik?.values?.country}
                  </span>
                <div className="icon-hide">
                  {isFormFieldValid("country") ? (
                    <img src="/image/warning.png" alt="" />
                  ) : (
                    <img src="/image/form-d-f.png" alt="" />
                  )}
                </div>
            
              </div>
              {countryOptions?.length > 0 && (
                <div className="list-search-dropdown-company">
                  <ul className="list-job-title-country">
                    {countryOptions.map((j, i) => (
                      <li
                        className="icon-cursor-f"
                        onClick={() => countryHandler(j)}
                      >
                        {/* <img src="/image/US.png" alt="" /> */}
                        {j?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {getFormErrorMessage("country")}
          </div>
          <div className="candidate-username-signup-form f-feild">
        <div
          className={`signup-country first-name ${isFormFieldValid("state") && "error-input" ||
          stateOptions?.length === 0 && "disable-field"
            }`}
        >
          <label for="fcountry">Province/State</label>
          <br />
          <div
            className={`industry-feild-frm-country bg-pass-f ${isFormFieldValid("state") && "error-input" 
              }`}
          >
            <div
              name="state"
              id="dropdown-boxId-2"
              className="candidate-form-dropdownBox-f"
              data-toggle="dropdown"
              aria-haspopup="true"
            >
              {formik?.values?.state === ""
                ? "Province/State"
                : formik?.values?.state}
              <div className="icon-hide-2">
                {isFormFieldValid("state") ? (
                  <img src="/image/warning.png" alt="" />
                ) : (
                  <img src="/image/form-d-f.png" alt="" />
                )}
              </div>
            </div>
            {stateOptions?.length > 0 && (
              <div className="list-search-dropdown-company">
                <ul className="list-job-title-country">
                  {stateOptions?.map((j, i) => (
                    <li
                      className="icon-cursor-f"
                      onClick={() => stateHandler(j?.id, j?.name)}
                    >
                      {j?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {getFormErrorMessage("state")}
        </div>
        <div
          className={`signup-country first-name ${isFormFieldValid("city") && "error-input"  || 
          citiesOptions?.length === 0 && "disable-field"
            }`}
        >
          <label for="fcountry">City</label>
          <br />
          <div
            className={`industry-feild-frm-country bg-pass-f ${isFormFieldValid("city") && "error-input"            
           
              }`}
          >
            <div
              name="city"
              id="dropdown-boxId-2"
              className="candidate-form-dropdownBox-f"
              data-toggle="dropdown"
              aria-haspopup="true"
            >
              {formik?.values?.city === ""
                ? "City"
                : formik?.values?.city}
              <div className="icon-hide-2">
                {isFormFieldValid("city") ? (
                  <img src="/image/warning.png" alt="" />
                ) : (
                  <img src="/image/form-d-f.png" alt="" />
                )}
              </div>
            </div>
            {citiesOptions?.length > 0 && (
              <div className="list-search-dropdown-company">
                <ul className="list-job-title-country">
                  {citiesOptions?.map((j, i) => (
                    <li
                      className="icon-cursor-f"
                      onClick={() => cityHandler(j?.id, j?.name)}
                    >
                      {j?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {getFormErrorMessage("city")}
        </div>
        </div>
          <div
            className={`signup-phone f-feild ${
              isFormFieldValid("phoneNo") && "error-input"
            }`}
          >
            <label for="fphone">Phone</label>
            <br />
            <div
              className={`merge-feild  ${
                isFormFieldValid("phoneNo") && "error-input"
              }`}
            >
              <PhoneNumber
                id=""
                className="form-font-f"
                type="tel"
                name="phoneNo"
                value={formik.values.phoneNo}
                maskedPlaceHolder={maskedPlaceHolder}
                maskedPhoneNumber={maskedPhoneNumber} 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            {getFormErrorMessage("phoneNo")}
          </div>

          <div className="signup-check f-feild">
            {isFormFieldValid("termServices") && (
              <div className="attention-box">
                <div className="attendtion-heading">
                  <div className="icon-atten">
                    <img src="/image/attention.png" alt="" />
                  </div>
                  <div className="attention-text">
                    <p className="text-atten-box">
                      {formik?.errors?.termServices}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <input
              type="checkbox"
              name="termServices"
              checked={formik?.values?.termServices}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />{" "}
            &nbsp;Yes, I understand and agree to the terms of service
          </div>
          <div className="candidate-from-signup-create">
            <button type="submit" className="form-submit">
              {loader ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Get started"
              )}
              {"  "}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignUpForm;
