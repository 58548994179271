import "./WorldConnect.css";

const WorldConnect = () => {
  return (
    <>
      <section className="all-oversite-sec">
        <div className="site-text-poly-sec">Connecting the world to work</div>
      </section>

      {/* <section className="world-conect-sec">
        <div className="world-conect-row">
          <img src="/image/world.png" alt="world" className="earth-mbl" />
          <p className="sm-font">Connecting The World To Work</p>
        </div>
      </section> */}
    </>
  );
};

export default WorldConnect;
