import { Link } from "react-router-dom";
import TopMatchLeft from "./TopMatchLeft";
import TopMatchRight from "./TopMatchRight";
import Pagination from "../Pagination/Pagination";
import "./TopMatchContainer.css";
import JobRecordLeft from "../../../Common/JobRecord/JobRecordLeft";
import JobRecordRight from "../../../Common/JobRecord/JobRecordRight";
import JobCardMbl from "../../../Common/JobCardMbl/JobCardMbl";

const TopMatchContainer = ({
  startPage,
  currentPage,
  endPage,
  featuredJobs,
  topMatchJobs,
  onCurrentPageHandler,
}) => {
  return (
    <>
      <section className="job-posstion-job-content">
        <div className="job-heading">
          <h2>Top matches</h2>
        </div>
        <div className="container container-site">
          <div className="left-right-content">
            {/* <div className="col-left-j-space py-3"> */}
            <div className={`col-left-j-space ${featuredJobs?.length ? "mb-3 pt-md-3 pt-0" : "mt-3"}`}>
              {/* <div className="col-left-j-space" style={{ maxWidth: "100%", borderRadius: "16px", border: "0.5px solid rgba(20, 57, 117, 0.20)" }}> */}

              {featuredJobs?.length > 0 &&
                featuredJobs.map((prod) => (
                  <TopMatchLeft JobData={prod} selectedJobId={prod?.id} />
                ))
              }
              {/* </div> */}
            </div>

            {/* <div className="divider-left"></div> */}
            <div className="col-right-j-space">
              {topMatchJobs?.length > 0 &&
                topMatchJobs.map((prod, index) => (
                  <>

                    <div className="desktop-jobs w-100" style={{ paddingLeft: "20px" }}>
                      <div className="Job-Opportunities-row">
                        <JobRecordLeft JobData={prod} index={index} />
                        <JobRecordRight
                          date={prod?.date}
                          selectedJobId={prod?.id}
                          jobCountry={prod?.country}
                          isApplied={prod?.is_applied}
                          wishList={prod?.whishlist}
                        />
                      </div>

                      <div className="divider-two"></div>

                    </div>

                    <div className="mbl-jobs">

                      <JobCardMbl
                        JobData={prod}
                        index={index}
                        date={prod?.date}
                        selectedJobId={prod?.id}
                        jobCountry={prod?.country}
                        isApplied={prod?.is_applied}
                        wishList={prod?.whishlist}
                      />
                    </div>


                  </>

                ))}
            </div>
          </div>
        </div>
      </section>
      <Pagination
        currentPage={currentPage}
        totalPages={endPage}
        onCurrentPageHandler={onCurrentPageHandler}
      />
    </>
  );
};

export default TopMatchContainer;
