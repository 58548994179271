import { createSlice } from '@reduxjs/toolkit'

const initialState = {  
  selectedCompanyId: null,
}
const companyDetailSlice = createSlice({
  name: 'companyDetail',
  initialState,
  reducers: {
    getSelectCompanyId: (state) => {  
      return state.selectedCompanyId;
    },
    setSelectCompanyId: (state, action) => {   
      state.selectedCompanyId = action.payload;
    },
   
  },
})

// Action creators are generated for each case reducer function
export const { getSelectCompanyId, setSelectCompanyId } = companyDetailSlice.actions

export default companyDetailSlice.reducer



