import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSideBar,
  setDeleteAction,
  setFormMode,
  setSelectedDep,
  setSelectedRoleR,
} from "../../../redux/slices/AdminDashboardSlice";
import TablePagination from "../../../components/Common/Admin/TablePagination/TablePagination";
import TableError from "../../../components/Common/Admin/TabelError/TableError";
import APIPvt from "../../../apiPvt";
import "../Dashboard/index.css";

const AdminViewRole = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedDep } = useSelector((state) => state.adminDashboard);
  const [msg, setMsg] = useState("");
  const [roleListingData, setRoleListingData] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [role, setRole] = useState([]);

  const [selectedRole, setSelectedRole] = useState({
    id: null,
    title: "",
  });
  const [selectedStatus, setSelectedStatus] = useState({
    label: "",
    value: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onStatusClick = (label, value) => {
    setSelectedStatus({
      label: label,
      value: value,
    });
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onRoleClick = (id, name) => {
    setSelectedRole({ id: id, title: name });
  };

  const getRoleListingData = async () => {
    if (selectedDep?.id !== null) {
      try {
        let params = `?`;
        if (search !== "") {
          params += `search=${search}&`;
        }
        if (selectedRole?.id !== null) {
          params += `role_id=${selectedRole?.id}&`;
        }
        if (selectedStatus?.value !== null) {
          params += `status=${selectedStatus?.value}&`;
        }
        if (currentPage !== null) {
          params += `page=${currentPage}&`;
        }
        if (selectedDep?.id !== null) {
          params += `department_id=${selectedDep?.id}&`;
        }
        setMsg("");
        await APIPvt.get(`/get-department-roles${params}`)
          .then((response) => {
            if (response?.status === 200 || response?.success === true) {
              let res = response?.data;
              setRoleListingData(res?.data);
              setRole(res?.roles);
              setStatus(res?.status);
              setTotalPages(res?.last_page);
              if (
                res?.data?.length === 0 &&
                (search === "" ||
                  selectedRole?.id === null ||
                  selectedStatus?.value === null)
              )
                setMsg("Please Create a Role for this Department.");
              else if (
                res?.data?.length === 0 &&
                (search !== "" ||
                  selectedRole?.id !== null ||
                  selectedStatus?.value !== null)
              )
                setMsg("Please make another selection. No record found.");
                else {
                  if (res?.data?.length !== 0) setMsg("");
                }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getRoleListingData();
  }, [selectedDep, selectedRole, selectedStatus, search, currentPage]);

  return (
    <div className="page-space bg-clr-admin">
      <div className="page-title-heading">
        <h2>Marketing roles</h2>
      </div>
      <div className="filter-search-bar">
        <div className="two-drop-role">
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Roles</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedRole?.title !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedRole?.title === "" ? "Roles" : selectedRole?.title}
                  </p>
                  <img src="/image/admin-d.png" alt="" />
                </div>
              </div>

              {role?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {role?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onRoleClick(item?.id, item?.title);
                        }}
                      >
                        {item?.title}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Status</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedStatus?.label !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedStatus?.label === ""
                      ? "Status"
                      : selectedStatus?.label}
                  </p>
                  <img src="/image/admin-d.png" alt="" />
                </div>
              </div>

              {status?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {status?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onStatusClick(item?.label, item?.value);
                        }}
                      >
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-right-searcgadmin">
          <div className="search-admin">
            <img src="/image/job-search.png" alt="" />
            <input
              type="text"
              name="search"
              id="jon-search-box"
              placeholder="Search"
              className="form-font-f"
              onChange={(e) => {
                onSearchChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="department-table-imge">
        <div className="col-user-table">
          <div className="table-responsive">
            <table className="depart-table-left">
              <thead className="tb-heading-user">
                <tr>
                  <th scope="col">
                    <div className="th-admin-heading">Department</div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">Role name</div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">Modules access</div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">Status</div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">Role description</div>
                  </th>
                </tr>
              </thead>
              <tbody className="tb-user">
                {roleListingData?.length > 0 &&
                  roleListingData?.map((item, index) => (
                    <tr>
                      <td className="">
                        <div className="name-sr-circle">
                          {item?.department?.name}
                        </div>
                      </td>
                      <td>{item?.title}</td>
                      <td>
                        {item?.modules?.length > 0 &&
                          item?.modules?.map((module) => <>{module?.name}, </>)}
                      </td>
                      <td>
                        <div
                          className={`active-tb-data ${
                            item?.status === 0 && "inactive-tb-data"
                          }`}
                        >
                          {" "}
                          {item?.status === 0 ? "Inactive" : "Active"}
                        </div>
                      </td>
                      <td>
                        {item?.description !== "null" ? (
                          item?.description?.length >= 15 ? (
                            <p>{item?.description?.substr(0, 25) + "..."}</p>
                          ) : (
                            <>{item?.description}</>
                          )
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {roleListingData?.length > 0 && (
            <TablePagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          )}
          <TableError msg={msg} />
        </div>
      </div>
    </div>
  );
};

export default AdminViewRole;
