const CustomSelect = ({
  label,
  label2,
  logo,
  borderRed,
  errorMsg,
  value,
  data,
  onClickHandler,
}) => {
  return (
    <>
      <div
        className={`signup-jobs-option f-feild ${borderRed && "error-input"}`}
      >
        <label for="fjobtitles">{label}</label>
        <br />
        <div className="industry-feild-frm bg-pass-f">
          <div
            id="dropdown-boxId-2"
            className="company-form-dropdownBox-f"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {value === "" ? (
              <span className="company-form-dropdownBox-f-placeholder">
                {label2}
              </span>
            ) : (
              value
            )}

            <div className="icon-hide">
              {borderRed ? (
                <img src="/image/warning.png" alt="" />
              ) : (
                <img src="/image/form-d-f.png" alt="" />
              )}
            </div>
          </div>
          {data?.length > 0 && (
              <div
                id="company-dropList-t"
                className="dropdown-form-f company-drop-list-t" // Changed class to className
              >
                <ul>
                  {data.map((j, i) => (
                    <li
                      className="icon-cursor-f" // Changed class to className
                      onClick={() => onClickHandler(j?.id, j?.name)}
                      key={i} // Added key prop for each list item
                    >
                      <img src={logo} alt="" />
                      {j?.name}{" "}
                    </li>
                  ))}
                </ul>
              </div>
            )}
        </div>
        {errorMsg && <p className="error-msg">{errorMsg}</p>}
      </div>
    </>
  );
};

export default CustomSelect;
