import React, { useEffect } from "react";
import "./ActionModal.css";
import OutsideClickHandler from "react-outside-click-handler";

const ActionModal = ({ closeModal, mainHeading, heading, content, modalArt, children }) => {

  useEffect(() => {
    let timer;
    if (!children || children === 'undefined') {
      timer = setTimeout(() => {
        if (closeModal) {
          closeModal()
        }
      }, 10000)
    }
    return () => clearTimeout(timer)
  }, [])
  return (
    <section className="common-modal">
      <OutsideClickHandler
        onOutsideClick={() => {
          closeModal();
        }}
      >
        <div className="modal-content-info">
          <div className="modal-header">
            <div className="modal-logo">
              <img src="/image/polycareers-north-macedonia.png" alt="" className="img-fluid" />
            </div>
            <button className="btn btn-close" onClick={() => closeModal()}></button>
          </div>
          <div className="modal-body">
            {mainHeading && (
              <h3 className="mt-0">{mainHeading}</h3>
            )}
            {modalArt && (
              <img src={modalArt} alt='close' />
            )}
            {heading && (
              <h3>{heading}</h3>
            )}
            {content && (
              <p className="text-center">{content}</p>
            )}
          </div>
          <div className="modal-footer">
            {children}
          </div>
        </div>
      </OutsideClickHandler>
    </section>
  )
}

export default ActionModal;