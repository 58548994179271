import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  downloadPdfBulkResumeAsync,
  getBulkParsingResumeDataAsync,
  setResumeCandidateTab,
} from "../../../../redux/slices/bulkResumeCanDetailSlice";
import BulkAIAnaylsisHead from "../../../../components/Admin/BulkResume/BulkAIAnaylsisHead/BulkAIAnaylsisHead";
import CanDetailTab from "../../../../components/Employer/CandidateDetail/CanDetailTab/CanDetailTab";
import CanDetailResume from "../../../../components/Employer/CandidateDetail/CanDetailResume/CanDetailResume";
import CanDetailEval from "../../../../components/Employer/CandidateDetail/CanDetailEval/CanDetailEval";
import { BULK_RESUME_CAN_Detail_Tab } from "../../../../constants";
import {
  getEmpCandidatesDetailDData,
  getParsedBulkResumeDData,
} from "../../../../data/admin/bulkResume";
import API from "../../../../api";
import "../BulkResumeAnalysis/index.css";

const BulkResumeAIAnalysis = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { candidateTab, candidateId } = useSelector(
    (state) => state.bulkResumeCanDetail
  );

  /* Candidate Detail on Resume Session */

  const [resumeData, setResumeData] = useState({
    fullName: "",
    originalResumeURL: null,
  });
  const [contactDetailsR, setContactDetailsR] = useState({});
  const [experienceR, setExperienceR] = useState([]);
  const [projectsR, setProjectsR] = useState([]);
  const [educationR, setEducationR] = useState([]);
  const [certificationsR, setCertificationsR] = useState([]);
  const [keySkillsR, setKeySkillsR] = useState([]);

  /**************************************/

  /* Candidate Detail + Candidate Evaluation */
  const [loader, setLoader] = useState(false);

  const [jobDetailData, setJobDetailData] = useState({});
  const [candidateDetailData, setCandidateDetailData] = useState({});
  const [candidateData, setCandidateData] = useState({
    analysis_skills: {},
    avatarLink: null,
    isFavorite: 0,
    currentCompany: "",
    currentJobTitle: "",
    email: "",
    education: null,
    linkedinURL: "",
    workPreferences: [],
    techTag: [],
    address: "",
  });

  const navHandler = () => {
    navigate(-1);
  };

  /* Candidate Detail on first impression click */
  const onFirstImpressionClick = async (
    candidateId,
    jobId,
    impressionId,
    currentStatusId
  ) => {};

  /* Candidate Detail on Favourite click */
  const onFvtClick = async () => {};

  /* PDF Preview Handler */

  const handlePreviewResume = async () => {
    if (candidateId !== null) {
      dispatch(
        downloadPdfBulkResumeAsync({
          candidateId: candidateId,
        })
      )
        .then(async (response) => {
          const res = response?.payload;
          if(res?.success === true){
              /* Resume  */
              window.open(res?.url, "_blank");
          }       
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  /* Candidate Detail + Resume + Candidate Evaluation */

  const getParsedResumeAIAnalysisData = async () => {
    if (candidateId !== null) {
      setLoader(true);
      dispatch(
        getBulkParsingResumeDataAsync({
          candidateId: candidateId,
        })
      )
        .then(async (response) => {
          const res = response?.payload;
          /* Resume  */
          const { latest_resume, assessment_data, resumeLink } = res;

          const {
            contact_details,
            experience,
            overall_experience,
            projects,
            education,
            certifications,
            key_skills,
            analysis_skills,
          } = res?.resume_data;

          const latestExp = experience?.length > 0 ? experience[0] : [];
          const latesEdu = education?.length > 0 ? education[0] : [];

          setLoader(false);
          setContactDetailsR(contact_details);
          setExperienceR(experience);
          setProjectsR(projects);
          setEducationR(education);
          setCertificationsR(certifications);
          setKeySkillsR(key_skills);

          setResumeData({
            fullName: `${contact_details?.firstName} ${contact_details?.lastName}`,
            originalResumeURL: null,
          });

          /* Candidate Evaluation */

          setJobDetailData(assessment_data);
          setCandidateDetailData({
            ...assessment_data,
            first_name: contact_details?.firstName,
            last_name: contact_details?.lastName,
            overall_experience: overall_experience,
            last_degree: latesEdu?.degree ? latesEdu?.degree : "",
          });
          setCandidateData({
            ...candidateData,
            analysis_skills: analysis_skills,
            currentCompany: latestExp?.company_name
              ? latestExp?.company_name
              : "",
            currentJobTitle: latestExp?.job_title ? latestExp?.job_title : "",
            email: contact_details?.email ? contact_details?.email : "",
            education: latesEdu?.degree ? latesEdu?.degree : "",
            linkedinURL: contact_details?.linkedin_url
              ? contact_details?.linkedin_url
              : "",
            address: contact_details?.address ? contact_details?.address : "",
          });
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getParsedResumeAIAnalysisData();
  }, []);

  // Check if candidateDetail and jobDetail are not empty objects
  const isCandidateDetailNotEmpty =
    Object?.keys(candidateDetailData)?.length > 0 || loader === false;
  const isJobDetailNotEmpty = Object?.keys(jobDetailData)?.length > 0;

  return (
    <div classNameName="bg-clr-admin">
      <div className="page-space bg-clr-admin">
        <div className="form-heading-prom">
          <img
            src="/image/form-back-arrow.png"
            alt=""
            onClick={() => navHandler()}
          />
          <h2>AI analysis</h2>
        </div>
        {isCandidateDetailNotEmpty ? (
          <div className="two-card-admin-request">
            <section className="journey-sec">
              <div className="container">
                <div className="jour-cantainer">
                  <BulkAIAnaylsisHead
                    showEmoji={false}
                    showCandidateStatus={false}
                    showSalaryExpectation={false}
                    showWorkPreference={false}
                    showTechTag={false}
                    showSendMsg={false}
                    candidateData={candidateData}
                    candidateDetailData={candidateDetailData}
                    jobDetailData={jobDetailData}
                    onFvtClick={onFvtClick}
                    onFirstImpressionClick={onFirstImpressionClick}
                  />
                </div>

                <div className="table-user-permission">
                  <CanDetailTab
                    list={BULK_RESUME_CAN_Detail_Tab}
                    detailTab={candidateTab}
                    onTabClick={(tab) => dispatch(setResumeCandidateTab(tab))}
                  />

                  {candidateTab === "resume" && (
                    <CanDetailResume
                      resumeData={resumeData}
                      contactDetails={contactDetailsR}
                      experience={experienceR}
                      projects={projectsR}
                      education={educationR}
                      certifications={certificationsR}
                      keySkills={keySkillsR}
                      handlePreviewResume={handlePreviewResume}
                    />
                  )}
                  {candidateTab === "candidate-evaluation" && (
                    <CanDetailEval
                      showFeeddBack={false}
                      candidateData={candidateData}
                      candidateDetailData={candidateDetailData}
                      jobDetailData={jobDetailData}
                    />
                  )}
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div className="loader-div">
            <span
              className="spinner-border spinner-border"
              role="status"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BulkResumeAIAnalysis;
