import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setSelectJobId } from "../../../redux/slices/JobDetailSlice";
import { setLoginModel } from "../../../redux/slices/AuthSlice";
import {
  jobAppliedAsync,
  setJobAppliedId,
} from "../../../redux/slices/candidateDashboardSlice";
import getDays from "../../../utils/getDays";
import AddToWishlist from "./AddToWishlist";
import { ADMIN, USER_TYPES } from "../../../constants";
import "./JobRecord.css";
import { ApplyJobSteps } from "./ApplyJobSteps/Index";

const initialValuesForSubmitData = {
  id: null,
  profile: false,
  salary: false,
};

const JobRecordRight = ({
  selectedJobId,
  jobCountry,
  date,
  isApplied,
  wishList,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggleApplied, setToggleApplied] = useState(false);
  const [showApplyJobModal, setShowApplyJobModal] = useState(false);
  const { user, isEmailVerify, resumeVerified, userCountry } = useSelector(
    (state) => state.auth
  );
  const [applyingToJob, setApplyingToJob] = useState(false);

  const [submitData, setSubmitData] = useState(initialValuesForSubmitData);

  const onDetailJobHandler = () => {
    navigate(`/job-view-detail/${selectedJobId}`);
  };

  const applyNowHandler = () => {
    if (isApplied === 1) {
      return null;
    }
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else if (resumeVerified === 0) {
      toast.warning(
        "Please verify your Resume for our AI analysis and job application process.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
    } else {
      setShowApplyJobModal(true);
    }
  };

  const handleSubmit = (resume_id, include_profile, show_expected_salary) => {
    setApplyingToJob(true);
    dispatch(
      jobAppliedAsync({
        userId: user?.id,
        jobId: selectedJobId,
        resume_id,
        include_profile,
        show_expected_salary,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          dispatch(setJobAppliedId(selectedJobId));
          setToggleApplied(true);
          setShowApplyJobModal(false);
          setSubmitData(initialValuesForSubmitData);
          toast.success(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
        setApplyingToJob(false);
      })
      .catch((error) => {
        console.log(error);
        setApplyingToJob(false);
      });
  };

  return (
    <div>
    
      <div className="job-announcements">      
        <div
          style={{ width: "100%" }}
          className={
            isEmailVerify && ADMIN.includes(user?.user_type) ? "no-fav" : ""
          }
        >
          {isEmailVerify && ADMIN.includes(user?.user_type) ? (
            ""
          ) : (
            <div className="wish-cirle" style={{ marginLeft: "auto" }}>
              <AddToWishlist
                jobId={selectedJobId}
                wishlist={wishList}
                withoutTxt={true}
              />
            </div>
          )}
        </div>
      {showApplyJobModal && 
      <ApplyJobSteps
        setSubmitData={(data) => {
          setSubmitData(data);
        }}
        submitData={submitData}
        handleSubmit={handleSubmit}
        applyingToJob={applyingToJob}
        show={showApplyJobModal}
        onHide={() => setShowApplyJobModal(false)}
      />
      }

        <div className="btn-holder">
          <div className="btn-group-holder">
            <button class="view-job-btn" onClick={() => onDetailJobHandler()}>
              View job
            </button>

            {(isEmailVerify && ADMIN.includes(user?.user_type)) ||
            (isEmailVerify && userCountry?.id !== jobCountry?.id) ? (
              ""
            ) : (
              <div
                className="apply-btn mt-2"
                onClick={() => !toggleApplied && applyNowHandler()}
              >
                <img
                  className="apply-icon d-none"
                  alt="apply"
                  src="/image/apply.png"
                />{" "}
                <p className="apply-head">
                  {isApplied === 1
                    ? "Applied"
                    : toggleApplied
                    ? "Applied"
                    : "Apply now"}
                </p>
              </div>
            )}
          </div>

          <p
            className="museo-font"
            style={{
              textAlign: "right",
              fontSize: "16px",
              color: "rgba(43 43 43 / 70%)",
            }}
          >
            {date
              ? getDays(date) > 1
                ? getDays(date) + " Days ago"
                : getDays(date) > 0
                ? getDays(date) + " Day ago"
                : "Today"
              : "21 Days ago"}
          </p>
        </div>

        {/* <div className="job-announcements-col btn-size">



        <div className="job-time-wishlist">
          <div className="job-announcements-status">
            <p className="museo-font">
              {date
                ? getDays(date) > 1
                  ? getDays(date) + " Days ago"
                  : getDays(date) > 0
                    ? getDays(date) + " Day ago"
                    : "Today"
                : "21 Days ago"}
            </p>
          </div>
          {isEmailVerify && ADMIN.includes(user?.user_type) ? (
            ""
          ) : (
            <>
              <div
                className="job-apply icon-cursor"
                onClick={() => applyNowHandler()}
              >
                <img src="/image/apply.png" alt="apply" />
                <p className="museo-font">
                  {isApplied === 1
                    ? "Applied"
                    : toggleApplied
                      ? "Applied"
                      : "Apply now"}
                </p>
              </div>
              <AddToWishlist jobId={selectedJobId} wishlist={wishList} />
            </>
          )}
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default JobRecordRight;
