import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import NFormatter, { NJobFormatter } from "../../../utils/NFormatter";
import { setSelectJobId } from "../../../redux/slices/JobDetailSlice";
import { setSelectCompanyId } from "../../../redux/slices/companyDetailSlice";
import "./JobRecord.css";
import { formatSalary } from "../../../utils/formatSalary";

const JobRecordLeft = ({
  hideCompanyName,
  activeSwitch,
  radioBtn,
  jobCounter,
  index,
  JobData,
  onChangeJobStatus,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [switchBtn, setSwitchBtn] = useState(false);

  const onSwitchChange = () => {
    setSwitchBtn(!switchBtn);
    onChangeJobStatus(JobData?.id);
  };

  const onDetailJobHandler = (id) => {
    navigate(`/job-view-detail/${id}`);
  };

  const onDetailCompanyHandler = (id) => {
    navigate(`/company-detail/${id}`);
  };

  useEffect(() => {
    setSwitchBtn(radioBtn);
  }, []);


  return (
    <>
      <div className="job-requriments">
        <div className="job-details">
          <div
            className={`job-title justify-content-between align-items-start flex-nowrap`}
          >
            <div className="d-md-flex gap-2 align-items-center flex-row">
              <a
                className="title-job-link promoted-btn-not"
                onClick={() => onDetailJobHandler(JobData?.id)}
              >
                <h3>{JobData?.name}</h3>
              </a>
              {JobData?.is_promoted === 1 && (
                <button className=" promoted-job-btn">Promoted * </button>
              )}
            </div>
            {activeSwitch ? (
              <div
                className={
                  "loction-switch-col d-md-none d-block p-0" +
                  (JobData?.promoted ? "mt-1" : "")
                }
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={switchBtn}
                    onChange={() => onSwitchChange()}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            ) : (
              ""
            )}
          </div>

          {hideCompanyName ? (
            <></>
          ) : (
            <div className="job-services-anchor">
              <a onClick={() => onDetailCompanyHandler(JobData?.company?.id)}>
                <img
                  src={JobData?.company?.logo}
                  alt="fitenss"
                  className="d-md-block d-none"
                />
                <p className="job-companyName">{JobData?.company?.name}</p>
              </a>
            </div>
          )}
        </div>
        <div className="job-info">
          <div className="job-time d-md-flex d-none">
            <div className="d-flex">
              <p>
                {JobData?.salary_from && (
                  <span style={{ marginRight: "40px" }}>
                    {" "}
                    {JobData?.salary_from &&
                      JobData?.salary_from !== null &&
                      "Salary "}
                    {JobData?.salary_from && JobData?.salary_from !== null
                      ? JobData?.currency[0]?.title
                        ? ` ${JobData?.currency[0]?.title}`
                        : JobData?.currency?.title
                          ? JobData?.currency?.title
                          : JobData?.currency?.name
                            ? JobData?.currency?.name
                            : ""
                      : ""}
                    {JobData?.salary_from &&
                      ` ${JobData?.currency[0]?.title
                        ? formatSalary(
                          JobData?.salary_from,
                          JobData?.currency[0]?.title,
                          "",
                          false
                        )
                        : JobData?.currency?.title
                          ? formatSalary(
                            JobData?.salary_from,
                            JobData?.currency?.title,
                            "",
                            false
                          )
                          : formatSalary(
                            JobData?.salary_from,
                            JobData?.currency?.name,
                            "",
                            false
                          )
                      }`}{" "}
                    {JobData?.salary_to &&
                      ` - ${JobData?.currency[0]?.title
                        ? formatSalary(
                          JobData?.salary_to,
                          JobData?.currency[0]?.title,
                          JobData?.salary_range,
                          true
                        )
                        : JobData?.currency?.title
                          ? formatSalary(
                            JobData?.salary_to,
                            JobData?.currency?.title,
                            JobData?.salary_range,
                            true
                          )
                          : formatSalary(
                            JobData?.salary_to,
                            JobData?.currency?.title,
                            JobData?.salary_range,
                            true
                          )
                      }`}
                  </span>
                )}
              </p>

              <p style={{ maxWidth: "max-content" }}>
                {JobData?.jobType?.length > 0 && JobData?.jobType[0]?.name}
              </p>
            </div>
            {jobCounter && (
              <div className="application-dash">
                <a>
                  <img src="/image/application-dash.png" alt="fitenss" />
                  <p>
                  {JobData?.applicants_count > 0
                ? `${JobData?.applicants_count} Applicant${JobData?.applicants_count > 1 ? 's' : ''}`
                : '0 Applicant'}
                  </p>
                </a>
              </div>
            )}
          </div>
          <div
            className={`job-stats-container d-flex d-md-none ${JobData?.salary_from ? "gap-1" : ""
              }`}
          >
            {JobData?.salary_from ? (
              <>
                <button className="purple-tags">
                  {" "}
                  {JobData?.salary_from &&
                    JobData?.salary_from !== null &&
                    "Salary "}
                  {JobData?.salary_from && JobData?.salary_from !== null
                    ? JobData?.currency[0]?.title
                      ? ` ${JobData?.currency[0]?.title}`
                      : JobData?.currency?.title
                        ? JobData?.currency?.title
                        : JobData?.currency?.name
                          ? JobData?.currency?.name
                          : ""
                    : ""}
                  {JobData?.salary_from &&
                    ` ${JobData?.currency[0]?.title
                      ? formatSalary(
                        JobData?.salary_from,
                        JobData?.currency[0]?.title,
                        "",
                        false
                      )
                      : JobData?.currency?.title
                        ? formatSalary(
                          JobData?.salary_from,
                          JobData?.currency?.title,
                          "",
                          false
                        )
                        : formatSalary(
                          JobData?.salary_from,
                          JobData?.currency?.name,
                          "",
                          false
                        )
                    }`}{" "}
                  {JobData?.salary_to &&
                    ` - ${JobData?.currency[0]?.title
                      ? formatSalary(
                        JobData?.salary_to,
                        JobData?.currency[0]?.title,
                        JobData?.salary_range,
                        true
                      )
                      : JobData?.currency?.title
                        ? formatSalary(
                          JobData?.salary_to,
                          JobData?.currency?.title,
                          JobData?.salary_range,
                          true
                        )
                        : formatSalary(
                          JobData?.salary_to,
                          JobData?.currency?.title,
                          JobData?.salary_range,
                          true
                        )
                    }`}
                </button>{" "}
                <span className="w-100"></span>
              </>
            ) : (
              ""
            )}
            {JobData?.jobCities ? (
              <button className="purple-tags">
                <p className="location-head">Location</p>
                <img src="/image/location-blue.png" alt="Location" />
                <p>
                  {JobData?.jobCities?.length === 1
                    ? JobData?.jobCities[0]?.name
                    : "Multiple Cities"}
                </p>
              </button>
            ) : (
              ""
            )}
            {JobData?.jobType ? (
              <button className="purple-tags">
                {JobData?.jobType?.length > 0 && JobData?.jobType[0]?.name}
              </button>
            ) : (
              ""
            )}
          </div>

          <div className="job-discription job-description-mg">
            {" "}
            {JobData?.description?.length >= 250 ? (
              <>
                {ReactHtmlParser(JobData?.description?.substr(0, 250) + "...")}
              </>
            ) : (
              <>{ReactHtmlParser(JobData?.description)}</>
            )}{" "}
          </div>

          {jobCounter && (
            <div className="application-dash application-dash-hide">
              <a>
                <img src="/image/application-dash.png" alt="fitenss" />
                <p>
                {JobData?.applicants_count > 0
                ? `${JobData?.applicants_count} Applicant${JobData?.applicants_count > 1 ? 's' : ''}`
                : '0 Applicant'}
                </p>
              </a>
            </div>
          )}
        </div>
        <div className="job-skill-location">
          <div className="job-skills">
            {JobData?.tags?.length > 0 &&
              JobData?.tags?.map((t, i) => {
                if (i < 5) {
                  return <button key={i} className="museo-font tag">{t?.name}</button>;
                }
              })}
          </div>

          {/* <div className="job-location-new"> */}
          {activeSwitch ? (
            JobData?.jobCities?.length !== 0 ? (
              <div className="job-location-switch">
                <div className="location-job-table">
                  <p>Location</p>
                  <img src="/image/Location.png" alt="Location" />
                  <p className="job-city">
                    {JobData?.jobCities?.length === 1
                      ? JobData?.jobCities[0]?.name
                      : "Multiple Cities"}
                  </p>
                </div>

                <div className="loction-switch-col">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={switchBtn}
                      onChange={() => onSwitchChange()}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
            ) : (
              <div className="loction-switch-col">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={switchBtn}
                    onChange={() => onSwitchChange()}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            )
          ) : (
            JobData?.jobCities?.length !== 0 && (
              <div className="job-location">
                <p>Location</p>
                <img src="/image/Location.png" alt="Location" />
                <p className="job-city ">
                  {JobData?.jobCities?.length === 1
                    ? JobData?.jobCities[0]?.name
                    : "Multiple Cities"}
                </p>
              </div>
            )
          )}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default JobRecordLeft;
