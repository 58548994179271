import { createSlice } from "@reduxjs/toolkit";
import { JOB_POST_DECISION } from "../../constants";

const initialState = {
  jobDecision: null, // it can hold 'copy' for copy and paste, also it can hold 'zoe' to create via chatGpt and by default it will be null
  isJobDecisionModalOpen: false,
  prevJobDecisionState: null,
  progressModel: false,
};

const jobDecisionSlice = createSlice({
  name: "jobDecision",
  initialState,
  reducers: {
    setJobDecision: (state, action) => {
      state.jobDecision = action.payload;
    },
    clearJobDecision: (state) => {
      state.jobDecision = null;
    },
    getJobDecision: (state) => state.jobDecision,
    changeJobDecisionToSyetemGen: (state) => {
      state.prevJobDecisionState = state.jobDecision;
      state.jobDecision = JOB_POST_DECISION.SYSTEM_GEN;
    },
    changeJobDecisionToCopy: (state) => {
      state.prevJobDecisionState = state.jobDecision;
      state.jobDecision = JOB_POST_DECISION.COPY_PASTE;
    },
    changeJobDecisionToZoe: (state) => {
      state.prevJobDecisionState = state.jobDecision;
      state.jobDecision = JOB_POST_DECISION.ZOE;
    },
    openJobDecisionModal: (state) => {
      state.isJobDecisionModalOpen = true;
    },
    closeJobDecisionModal: (state) => {
      state.isJobDecisionModalOpen = false;
    },
    clearPrevJobDecisionState: (state) => {
      state.prevJobDecisionState = null;
    },
    setProgressModel: (state, action) => {
      state.progressModel = action.payload;
    },
  },
});

export const {
  setJobDecision,
  clearJobDecision,
  getJobDecision,
  changeJobDecisionToSyetemGen,
  changeJobDecisionToCopy,
  changeJobDecisionToZoe,
  openJobDecisionModal,
  closeJobDecisionModal,
  clearPrevJobDecisionState,
  setProgressModel
} = jobDecisionSlice.actions;

export default jobDecisionSlice.reducer;
