import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSubTab,
  setActiveTab,
  setLoginModel,
  setPostJobId,
  setPostJobMode,
  setSignUpBarHide,
} from "../../../redux/slices/AuthSlice";
import { toast } from "react-toastify";
import { ADMIN, CANDIDATE, COUNTRY_ISO_CODE } from "../../../constants";
import "./Banner.css";

const Banner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { countryISOCode } = useSelector((state) => state.home);

  const scroll = "search";
  const { isEmailVerify, user, resumeVerified } = useSelector(
    (state) => state.auth
  );

  const postJobClickHanlder = async () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else {
      dispatch(setActiveTab("employer"));
      dispatch(setActiveSubTab("postJob"));
      dispatch(setPostJobMode("add"));
      dispatch(setPostJobId(null));
      dispatch(setSignUpBarHide(true));
      navigate("/post-job");
    }
  };

  const onCustomResumeHandler = () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else if (resumeVerified === 0) {
      toast.warning(
        "Please verify your Resume for our AI analysis and job application process.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
    } else {
      navigate("/candidate-resume-decision");
    }
  };

  return (
    <>
      <section className="banner-top container-site">
        <div className="banner-row-poly">
          <div className="banner-heading-poly">
            <h1>
              Your search starts <span> here.</span>
            </h1>
            <div className="two-btn-poly">
              {!ADMIN.includes(user?.user_type) && (
                <Link
                  to={`/job-search/${scroll}`}
                  onClick={() => dispatch(setSignUpBarHide(true))}
                >
                  <button className="find-job-poly">Find a job</button>
                </Link>
              )}
              {!CANDIDATE.includes(user?.user_type) && (
                <button
                  className="post-job-poly"
                  onClick={() => postJobClickHanlder()}
                >
                  Post a job
                </button>
              )}
            </div>
            <div class="banner-img">
              <img         
                src={
                  countryISOCode === COUNTRY_ISO_CODE.CA
                    ? "/image/banner-poly-CA.png"
                    : "/image/banner-poly-MK.png"
                }
                lt=""
              />
            </div>
          </div>
        </div>

        {/* <div className="banner-row ">
          <div className="col-1-banner ">
            <img src="/image/banner-12.png " alt="boylaptop " />
          </div>
          <div className="col-2-banner">
            <h1 className="col-2-banner-mob banner-head-mbl toggle-desktop-banner text-center">
              Work <span>Where,</span>
              <br />
              You’re <span>Happy</span>
            </h1>
            <h1 className="col-2-banner-mob banner-head-mbl text-center toggle-mobile-banner">
              <span style={{ color: "#143975" }}>Work</span> <span>Where,</span>
              <br />
              <div className="mt-2">
                <span style={{ color: "#143975" }}>You're </span>
                <span>Happy</span>
              </div>
            </h1>
            <p
              className={`banner-sub-text text-center ${
                isEmailVerify === true && !"banner-text-mbl"
              }`}
            >
              Explore amazing opportunities with some of the world's leading
              brands right here
            </p>
            <p
              className={`banner-text text-center ${
                isEmailVerify === true && "banner-text-mbl"
              } toggleDesktopText`}
            >
              Explore amazing opportunities with some <br /> of the world's
              leading brands right here
            </p>

            <div className="align-banner-btn">
              {isEmailVerify === true && ADMIN.includes(user?.user_type) ? (
                <div className="button-stack button--postJob mb-3">
                  <Link
                    to="/signup"
                    onClick={() => postJobClickHanlder()}
                  >
                    <button>
                      <img
                        src="/image/postJobW.png"
                        className="postIcon"
                        alt="post-icon"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "10px",
                        }}
                      />
                      Post a Job
                    </button>
                  </Link>
                </div>
              ) : (
                <div className="button-stack button--postJob mb-3">
                  <Link
                    to={`/job-search/${scroll}`}
                    onClick={() => dispatch(setSignUpBarHide(true))}
                  >
                    <button>
                      <img
                        src="/image/findJobW.png"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "10px",
                        }}
                        className="postIcon"
                        alt="post-icon"
                      />
                      Find Jobs
                    </button>
                  </Link>
                </div>
              )}

              {isEmailVerify && ADMIN.includes(user?.user_type) ? (
                ""
              ) : (
                <div
                  className="button-stack-outlined button--postJob"
                  onClick={() => onCustomResumeHandler()}
                >
                  <button>
                    {" "}
                    <img
                      src="/image/resume.png"
                      alt="resume-icon"
                      style={{
                        width: "22px",
                        height: "24px",
                        marginRight: "5px",
                      }}
                    />
                    {isEmailVerify ? "Customize Resume" : "Build Your Resume"}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="arrow-picture ">
            <img className="vector " src="/image/Vector 1.png " alt=" " />
          </div>
          <div className="col-3-banner text-right">
            <img src="/image/banner-22.png " alt="girl with laptop" />
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Banner;
