import { SHOW_ROW_COUNT } from "../../../../constants";
import "./ShowRows.css";

const ShowRows = ({perPage, onChangePerPageHandler}) => {
  return (
    <div className="select-filter-resume">
      <label for="">Show </label>
      <div className="admin-status-filter-form">
        <div
          id="dropdown-boxId2"
          className="company-form-dropdownBox1"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="text-filter-admin-bulk">
            <p>{perPage}</p>
            <img src="/image/admin-bulk.png" alt="" />
          </div>
        </div>
        {SHOW_ROW_COUNT?.length > 0 && (
          <div className="admin-status-dropdown-frm">
            <ul className="list-admin-portal">
              {SHOW_ROW_COUNT?.map((item, index) => (
                <li onClick={() => onChangePerPageHandler(item)}>{item}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <label for="">Rows</label>
    </div>
  );
};

export default ShowRows;
