import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";

/* Import All Form */
import CanProgressBar from "../../../../components/Auth/SignUp/CandidateForm/CanProgressBar/CanProgressBar";
import CanResumeTemp from "../../../../components/Candidate/Resume/CanResumeTemp/CanResumeTemp";
import CanResumeExpForm from "../../../../components/Candidate/Resume/CanResumeExpForm/CanResumeExpForm";
import CanResumeEduForm from "../../../../components/Candidate/Resume/CanResumeEduForm/CanResumeEduForm";
import CanResumeProForm from "../../../../components/Candidate/Resume/CanResumeProForm/CanResumeProForm";
import CanResumeCredForm from "../../../../components/Candidate/Resume/CanResumeCredForm/CanResumeCredForm";
import CanResumeSkillForm from "../../../../components/Candidate/Resume/CanResumeSkillForm/CanResumeSkillForm";
import WorldConnect from "../../../../components/Common/WorldConnect/WorldConnect";
/*********/

/* Import All Model */
import ResumeExpModel from "../../../../components/Common/ResumeExpModel/ResumeExpModel";
import ResumeProModel from "../../../../components/Common/ResumeProModel/ResumeProModel";
import ResumeSaveModel from "../../../../components/Common/ResumeSaveModel/ResumeSaveModel";
import ActionModal from "../../../../components/Common/ActionModal/ActionModal";
import ContentModal from "../../../../components/Common/ContentModal/ContentModal";
import ResumeProgressModel from "../../../../components/Common/ResumeProgressModel/ResumeProgressModel";
/*********/

import {
  getCountriesAsync,
  setResumeVerified,
  setSelectedCustomResume,
  setUserResumes,
} from "../../../../redux/slices/AuthSlice";
import {
  getParsedResumeAsync,
  getResumeForApplyingOnJob,
} from "../../../../redux/slices/candidateDetailSlice";
import {
  incrementRetryCount,
  resumeSectionUpdateAsync,
  setResumeFormMode,
  verifyResumeAsync,
} from "../../../../redux/slices/verifyResumeSlice";
import PhoneNumber from "../../../../components/Common/PhoneNumber/PhoneNumber";
import {
  PARSING_STATUS,
  PublicDomains,
  RESUME_ITEM,
  RESUME_MODE,
} from "../../../../constants";
import API from "../../../../api";
import "./index.css";

const CanResume = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [maskedPlaceHolder, setMaskedPlaceHolder] = useState("");
  const [parsingStatus, setParsingStatus] = useState("");
  const [parsingMessage, setParsingMessage] = useState("");

  const { user, userCountry, resumeDetail, selectedCustomResume } = useSelector(
    (state) => state.auth
  );
  const { resumeFormMode, retryCount } = useSelector(
    (state) => state.verifyResume
  );

  const selectedResume =
    resumeFormMode === RESUME_MODE.CUSTOMIZED
      ? selectedCustomResume
      : resumeDetail;

  const headRef = useRef(null);
  const headExpRef = useRef(null);

  const [spinner, setSpinner] = useState(false);
  const [verifyLoader, setVerifyLoader] = useState(false);

  const [activeTab, setActiveTab] = useState("edit");
  const [originalData, setOriginalData] = useState({
    fullName: "",
    originalResumeURL: null,
  });

  const [experience, setExperience] = useState([]);
  const [projects, setProjects] = useState([]);
  const [education, setEducation] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [keySkills, setKeySkills] = useState([]);

  /* Drop Down Value*/
  const [monthDropDown, setMonthDropDown] = useState([]);
  const [yearDropDown, setYearDropDown] = useState([]);

  /*Toggle Model*/
  const [toggleDeleteItemModel, setToggleDeleteItemModel] = useState(false);
  const [toggleExpModel, setToggleExpModel] = useState(false);
  const [toggleProModel, setToggleProModel] = useState(false);
  const [toggleSaveModel, setToggleSaveModel] = useState(false);
  const [loaderAI, setLoaderAI] = useState(false);
  const [toggleFormatModel, setToggleFormatModel] = useState(false);

  /*Selected Object*/
  const [selectedExp, setSelectedExp] = useState({});
  const [selectedExpId, setSelectedExpId] = useState(null);

  const [selectedPro, setSelectedPro] = useState({});
  const [selectedProId, setSelectedProId] = useState(null);

  /*Selected Delete Item Object*/
  const [selectedDeleteItemIndex, setSelectedDeleteItemIndex] = useState(null);
  const [selectedDeleteItemSection, setSelectedDeleteItemSection] =
    useState("");

  /*Resume File Name*/
  const [resumeDisplayName, setResumeDisplayName] = useState("");

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      address: "",
      email: "",
      phone_number: "",
      github_url: "",
      linkedin_url: "",
      candidate_summary: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      first_name: Yup.string()
        .matches(/^[A-Za-z ]+$/, "First name should be an alphabet")
        .required("Please enter first name"),
      last_name: Yup.string()
        .matches(/^[A-Za-z ]+$/, "Last name should be an alphabet")
        .required("Please enter last name"),
      address: Yup.string(),
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Please enter email"),

      phone_number: Yup.string().required("Please enter phone number"),
      candidate_summary: Yup.string().required("Please enter summary"),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {},
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const scrollToHead = () => {
    if (headRef.current) {
      headRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToHeadExp = () => {
    if (headExpRef.current) {
      headExpRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  /* Session Experience Event Handler*/

  const onExpChangeHandler = (updatedObject, indexToUpdate) => {
    const updatedExperience = [...experience];
    updatedExperience[indexToUpdate] = updatedObject;
    setExperience(updatedExperience);
  };

  const onExpAddHandler = () => {
    const newExp = {
      company_name: "",
      job_title: "",
      city: "",
      country: "",
      start_date: {
        month: "",
        year: "",
      },
      end_date: {
        month: "",
        year: "",
      },
      roles_n_responsibilities: "",
      years_of_experience: "",
      is_present: false,
      is_correct: false,
      is_remote: false,
      achievements: [],
    };

    setExperience([newExp, ...experience]);
  };

  const onExpDeleteHandler = (indexToRemove) => {
    const updatedExperience = experience?.filter(
      (item, index) => index !== indexToRemove
    );
    setExperience(updatedExperience);

    resumeSectionUpdate({
      userId: user?.id,
      resumeData: {
        contact_details: formik.values,
        experience: updatedExperience,
      },
      sessionName: "experience",
      action: "delete",
    });
  };

  const onExpSaveHandler = async (newFormData = undefined, index = undefined) => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();

    if (Object.keys(isFormValid).length === 0) {
      const toggleForm = newFormData !== undefined && index !== undefined;
      const updatedExperience = [...experience];
      if (toggleForm) {
        updatedExperience[index] = newFormData;
        setExperience(updatedExperience);
      }

      resumeSectionUpdate({
        userId: user?.id,
        resumeData: {
          contact_details: formik.values,
          experience:
          toggleForm ? updatedExperience : experience,
        },
        sessionName: "experience",
        action: "update",
      });
      try {
      } catch (error) {}
    } else {
      scrollToHead();
    }
  };

  /***********************/

  /* Session Project Event Handler*/

  const onProChangeHandler = (updatedObject, indexToUpdate) => {
    const updatedProject = [...projects];
    updatedProject[indexToUpdate] = updatedObject;
    setProjects(updatedProject);
  };

  const onProAddHandler = () => {
    const newPro = {
      name: "",
      url: "",
      description: "",
    };
    setProjects([newPro, ...projects]);
  };

  const onProDeleteHandler = (indexToRemove) => {
    const updatedProject = projects?.filter(
      (item, index) => index !== indexToRemove
    );
    setProjects(updatedProject);

    resumeSectionUpdate({
      userId: user?.id,
      resumeData: {
        contact_details: formik.values,
        projects: updatedProject,
      },
      sessionName: "projects",
      action: "delete",
    });
  };

  const onProSaveHandler = async () => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();
    if (Object.keys(isFormValid).length === 0) {
      resumeSectionUpdate({
        userId: user?.id,
        resumeData: {
          contact_details: formik.values,
          projects: projects,
        },
        sessionName: "projects",
        action: "update",
      });
      try {
      } catch (error) {}
    } else {
      scrollToHead();
    }
  };

  /***********************/

  /* Session Education Event Handler*/

  const onEduChangeHandler = (updatedObject, indexToUpdate) => {
    const updatedEdu = [...education];
    updatedEdu[indexToUpdate] = updatedObject;
    setEducation(updatedEdu);
  };

  const onEduAddHandler = () => {
    const newEdu = {
      degree: "",
      institute: "",
      city: "",
      country: "",
      start_date: {
        month: "",
        year: "",
      },
      end_date: {
        month: "",
        year: "",
      },
      is_present: false,
      is_correct: false,
    };

    setEducation([newEdu, ...education]);
  };

  const onEduDeleteHandler = (indexToRemove) => {
    const updatedEdu = education?.filter(
      (item, index) => index !== indexToRemove
    );
    setEducation(updatedEdu);

    resumeSectionUpdate({
      userId: user?.id,
      resumeData: {
        contact_details: formik.values,
        education: updatedEdu,
      },
      sessionName: "education",
      action: "delete",
    });
  };

  const onEduSaveHandler = async () => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();
    if (Object.keys(isFormValid).length === 0) {
      try {
        resumeSectionUpdate({
          userId: user?.id,
          resumeData: {
            contact_details: formik.values,
            education: education,
          },
          sessionName: "education",
          action: "update",
        });
      } catch (error) {}
    } else {
      scrollToHead();
    }
  };

  /***********************/

  /* Session Certifications Event Handler*/

  const onCredChangeHandler = (updatedObject, indexToUpdate) => {
    const updatedCred = [...certifications];
    updatedCred[indexToUpdate] = updatedObject;
    setCertifications(updatedCred);
  };

  const onCredAddHandler = () => {
    const newCred = {
      name: "",
      start_date: {
        month: "",
        year: "",
      },
      end_date: {
        month: "",
        year: "",
      },
      is_correct: false,
    };

    setCertifications([newCred, ...certifications]);
  };

  const onCredDeleteHandler = (indexToRemove) => {
    const updatedCred = certifications?.filter(
      (item, index) => index !== indexToRemove
    );
    setCertifications(updatedCred);

    resumeSectionUpdate({
      userId: user?.id,
      resumeData: {
        contact_details: formik.values,
        certifications: updatedCred,
      },
      sessionName: "certifications",
      action: "delete",
    });
  };

  const onCredSaveHandler = async () => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();
    if (Object.keys(isFormValid).length === 0) {
      try {
        resumeSectionUpdate({
          userId: user?.id,
          resumeData: {
            contact_details: formik.values,
            certifications: certifications,
          },
          sessionName: "certifications",
          action: "update",
        });
      } catch (error) {}
    } else {
      scrollToHead();
    }
  };

  /***********************/

  /* Session Skill Event Handler*/

  const onSkillAddHandler = (curentSkill) => {
    setKeySkills([curentSkill, ...keySkills]);
  };

  const onSkillDeleteHandler = (indexToRemove) => {
    const updatedSkill = keySkills?.filter(
      (item, index) => index !== indexToRemove
    );
    setKeySkills(updatedSkill);
  };

  const onSkillSaveHandler = async () => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();

    if (Object.keys(isFormValid).length === 0) {
      try {
        resumeSectionUpdate({
          userId: user?.id,
          resumeData: {
            contact_details: formik.values,
            key_skills: keySkills,
          },
          sessionName: "key_skills",
          action: "update",
        });
      } catch (error) {}
    } else {
      scrollToHead();
    }
  };

  /***********************/

  /*******Resume File Name Event Handler*********/

  const onFileNameChangeHandler = (updatedObject) => {
    const { resume_display_name } = updatedObject;
    setResumeDisplayName(resume_display_name);
  };

  /***********************/

  /*******Model Event Handler*********/

  const deleteItemModelHandler = (v) => setToggleDeleteItemModel(v);

  const expModelHandler = (v) => setToggleExpModel(v);

  const proModelHandler = (v) => setToggleProModel(v);

  const saveModelHandler = (v) => setToggleSaveModel(v);

  const loaderAIHandler = (toggleModel) => {
    setLoaderAI(toggleModel);
  };

  /***********************/

  /*******Selected Event Handler*********/

  const selectedExpHandler = (selectedExp, indexToUpdate) => {
    setSelectedExp(selectedExp);
    setSelectedExpId(indexToUpdate);
  };

  const selectedProHandler = (selectedPro, indexToUpdate) => {
    setSelectedPro(selectedPro);
    setSelectedProId(indexToUpdate);
  };

  /***********************/

  /*******Replace Event Handler*********/

  const updateExpByIdHandler = (updatedObject) => {
    const updatedExperience = [...experience];
    updatedExperience[selectedExpId] = updatedObject;
    setExperience(updatedExperience);
    setSelectedExp({});
    setSelectedExpId(null);
  };

  const updateProByIdHandler = (updatedObject) => {
    const updatedProject = [...projects];
    updatedProject[selectedProId] = updatedObject;
    setProjects(updatedProject);
    setSelectedPro({});
    setSelectedProId(null);
  };

  /***********************/

  /**********Delete Item Model************/

  const openDeleteItemHandler = (indexToUpdate, session, toggleModel) => {
    setSelectedDeleteItemIndex(indexToUpdate);
    setSelectedDeleteItemSection(session);
    deleteItemModelHandler(toggleModel);
  };

  const closeDeleteItemHandler = () => {
    setSelectedDeleteItemIndex(null);
    setSelectedDeleteItemSection("");
    deleteItemModelHandler(false);
  };

  const closeAIModelHandler = () => {
    setLoaderAI(false);
    setToggleFormatModel(false);
  };

  const selectedDeleteItemHandler = () => {
    if (selectedDeleteItemSection === RESUME_ITEM.EXPERIENCE) {
      onExpDeleteHandler(selectedDeleteItemIndex);
    } else if (selectedDeleteItemSection === RESUME_ITEM.PROJECT) {
      onProDeleteHandler(selectedDeleteItemIndex);
    } else if (selectedDeleteItemSection === RESUME_ITEM.EDUCATION) {
      onEduDeleteHandler(selectedDeleteItemIndex);
    } else if (selectedDeleteItemSection === RESUME_ITEM.CERTIFICATION) {
      onCredDeleteHandler(selectedDeleteItemIndex);
    }
  };

  /***********************/

  const getResumes = async (userId) => {
    if (userId !== null) {
      dispatch(
        getResumeForApplyingOnJob({
          userId,
        })
      )
        .then(async (response) => {
          const res = response?.payload?.data;
          dispatch(setUserResumes(res));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onVerifyResumeClick = async () => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();

    if (Object.keys(isFormValid).length === 0) {
      const hasExpError = experience.some((item) => item?.is_correct === false);

      if (hasExpError) {
        toast.warning(
          "Please complete the work experience dates in the highlighted section",
          {
            autoClose: 2000,
            closeOnClick: false,
          }
        );
        scrollToHeadExp();
      } else {
        setVerifyLoader(true);
        dispatch(
          verifyResumeAsync({
            userId: user?.id,
            resumeData: {
              contact_details: formik.values,
              experience: experience,
              projects: projects,
              education: education,
              certifications: certifications,
              key_skills: keySkills,
            },
            resumeId: selectedResume?.resumeId,
            resumeType: selectedResume?.resumeType,
            resumeDisplayName: resumeDisplayName,
          })
        )
          .then((response) => {
            const res = response?.payload;
            if (res?.result === true) {
              setVerifyLoader(false);
              toast.success(res?.message?.success, {
                autoClose: 2000,
                closeOnClick: false,
              });

              if (resumeFormMode === RESUME_MODE.VERIFICATION) {
                dispatch(setResumeVerified(1));
                dispatch(setResumeFormMode(RESUME_MODE.UPDATE));
                navigate("/candidate-dashboard");
              }
              if (resumeFormMode === RESUME_MODE.UPDATE) {
                scrollToHead();
              }
              if (resumeFormMode === RESUME_MODE.CUSTOMIZED) {
                dispatch(
                  setSelectedCustomResume({
                    resumeId: null,
                    resumeType: 2,
                  })
                );
                navigate("/candidate-profile");
              }
              getResumes(user?.id);
            } else {
              setVerifyLoader(false);

              if (resumeFormMode === RESUME_MODE.VERIFICATION) {
                dispatch(setResumeVerified(0));
                dispatch(setResumeFormMode(RESUME_MODE.VERIFICATION));
              }

              toast.error(res?.message?.error, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
          })
          .catch((error) => {
            setVerifyLoader(false);
            console.log(error);
          });
      }
    } else {
      scrollToHead();
    }
  };

  const onCustomResumeFileNameClick = async () => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();

    if (Object.keys(isFormValid).length === 0) {
      const hasExpError = experience.some((item) => item?.is_correct === false);

      if (hasExpError) {
        toast.warning(
          "Please complete the work experience dates in the highlighted section",
          {
            autoClose: 2000,
            closeOnClick: false,
          }
        );
        scrollToHeadExp();
      } else {
        saveModelHandler(true);
      }
    } else {
      scrollToHead();
    }
  };

  const onCustomResumeClick = async () => {
    onVerifyResumeClick();
  };

  const resumeSectionUpdate = async (rawData) => {
    const newRawData = {
      ...rawData,
      resumeId: selectedResume?.resumeId,
      resumeType: selectedResume?.resumeType,
    };
    dispatch(resumeSectionUpdateAsync(newRawData))
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          toast.success(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
        } else {
          toast.error(res?.message?.error, {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
        closeDeleteItemHandler();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getParsedResumeData = async () => {
    if (user?.id !== null) {
      setSpinner(true);
      dispatch(
        getParsedResumeAsync({
          userId: user?.id,
          resumeId: selectedResume?.resumeId,
        })
      )
        .then(async (response) => {
          setSpinner(false);
          const res = response?.payload;
          const { latest_resume } = res;
          const {
            contact_details,
            experience,
            projects,
            education,
            certifications,
            key_skills,
            parsing_status,
            parsing_message,
          } = res?.resume_data;

          if (parsing_status === PARSING_STATUS.SUCCESS) {
            await formik.setFieldValue(
              "first_name",
              contact_details?.firstName !== null
                ? contact_details?.firstName
                : ""
            );
            await formik.setFieldValue(
              "last_name",
              contact_details?.lastName != null ? contact_details?.lastName : ""
            );
            await formik.setFieldValue(
              "address",
              contact_details?.address !== null ? contact_details?.address : ""
            );
            await formik.setFieldValue(
              "email",
              contact_details?.email !== null ? contact_details?.email : ""
            );
            await formik.setFieldValue(
              "phone_number",
              contact_details?.phone_number !== null
                ? contact_details?.phone_number
                : ""
            );
            await formik.setFieldValue(
              "github_url",
              contact_details?.github_url !== null
                ? contact_details?.github_url
                : ""
            );
            await formik.setFieldValue(
              "linkedin_url",
              contact_details?.linkedin_url !== null
                ? contact_details?.linkedin_url
                : ""
            );
            await formik.setFieldValue(
              "candidate_summary",
              contact_details?.candidate_summary !== null
                ? contact_details?.candidate_summary
                : ""
            );

            setExperience(experience);
            setProjects(projects);
            setEducation(education);
            setCertifications(certifications);
            setKeySkills(key_skills);

            setLoaderAI(false);
            setToggleFormatModel(false);
          } else if (
            parsing_status === PARSING_STATUS.PENDIND ||
            parsing_status === PARSING_STATUS.PROCESS
          ) {
            if (retryCount < 2) {
              setLoaderAI(true);
              setToggleFormatModel(false);

              setTimeout(() => {
                dispatch(incrementRetryCount());
                setLoaderAI(false);
              }, 30000);
            } else {
              setLoaderAI(false);
              setToggleFormatModel(true);
            }
          } else {
            setLoaderAI(false);
            setToggleFormatModel(true);
          }
          setOriginalData({
            fullName: `${contact_details?.firstName} ${contact_details?.lastName}`,
            originalResumeURL: latest_resume?.resume_link,
          });

          setResumeDisplayName(
            res?.resume_display_name !== null ? res?.resume_display_name : ""
          );

          setMonthDropDown(res?.month_names);
          setYearDropDown(res?.yearsList);
          scrollToHead();
        })
        .catch((error) => {
          setSpinner(false);
          setLoaderAI(false);
          setToggleFormatModel(false);
          console.log(error);
        });
    }
  };

  const getCountries = () => {
    if (userCountry?.id !== null) {
      try {
        dispatch(
          getCountriesAsync({
            country_id: userCountry?.id,
          })
        )
          .then((response) => {
            const res = response?.payload;
            const { masked_phone_number, placeholder } = res[0];
            setMaskedPhoneNumber(masked_phone_number);
            setMaskedPlaceHolder(placeholder);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    scrollToHead();
    getCountries();
    getParsedResumeData();
  }, [retryCount]);

  return (
    <>
      {spinner ? (
        <div className="loader-div">
          <span
            className="spinner-border spinner-border"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <>
          <div className="container container-site py-md-2 py-1">
            <Button
              variant="link"
              className="back-to-home add position-relative top-0"
              onClick={() => navigate(-1)}
            >
              <img src="/image/form-back-arrow.png" alt="" />
            </Button>
          </div>
          <section className="journey-sec pt-0" ref={headRef}>
            <div className="position-relative">
              <div className="job-heading">
                <div className="container container-site position-relative">
                  <h2>
                    {" "}
                    {resumeFormMode === RESUME_MODE.VERIFICATION
                      ? "Resume verification"
                      : resumeFormMode === RESUME_MODE.UPDATE
                      ? "Update resume"
                      : resumeFormMode === RESUME_MODE.CUSTOMIZED
                      ? "Customize resume"
                      : ""}
                  </h2>
                </div>
              </div>
              <div className="container container-site cv-toggler">
                <div className="jour-div-e">
                  <div className="jres-verification-row">
                    <div className="toogle-btn-res">
                      <button
                        onClick={() => setActiveTab("edit")}
                        className={
                          activeTab === "edit"
                            ? "template-btn active-toggle-btn-ver"
                            : "template-btn"
                        }
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => setActiveTab("preview")}
                        className={
                          activeTab === "preview"
                            ? "template-btn active-toggle-btn-ver"
                            : "template-btn"
                        }
                      >
                        Preview
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container container-site">
              {resumeFormMode === RESUME_MODE.VERIFICATION && (
                <CanProgressBar signUpActive={true} resumeVerifyActive={true} />
              )}
              {activeTab === "edit" ? (
                <div className="resume-verivication-form">
                  <div className="resume-form-info">
                    <div className="personal-info-right">
                      Personal Information
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="resume-personal-form f-feild-res">
                        <div
                          className={`first-name-res ${
                            isFormFieldValid("first_name") && "error-input"
                          }`}
                        >
                          <label for="fname">First Name</label>
                          <br />
                          <input
                            className="feild-stl-res form-font-f"
                            type="text"
                            placeholder="First Name"
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {getFormErrorMessage("first_name")}
                        </div>
                        <div
                          className={`last-name-res ${
                            isFormFieldValid("last_name") && "error-input"
                          }`}
                        >
                          <label for="lname">Last Name</label>
                          <br />
                          <input
                            className="feild-stl-res form-font-f"
                            type="text"
                            placeholder="Last Name"
                            name="last_name"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {getFormErrorMessage("last_name")}
                        </div>
                      </div>
                      <div
                        className={`address-res f-feild-res ${
                          isFormFieldValid("address") && "error-input"
                        }`}
                      >
                        <label for="lname">Address</label>
                        <br />
                        <input
                          className="feild-stl-res form-font-f"
                          type="text"
                          placeholder="Address"
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("address")}
                      </div>
                      <div className="resume-personal-form f-feild-res">
                        <div
                          className={`first-name-res f-feild-res ${
                            isFormFieldValid("email") && "error-input"
                          }`}
                        >
                          <label for="lname">E-Mail</label>
                          <br />
                          <input
                            className="feild-stl-res form-font-f"
                            type="email"
                            name="email"
                            id=""
                            placeholder="Enter valid email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {getFormErrorMessage("email")}
                        </div>
                        <div
                          className={`first-name-res f-feild-res ${
                            isFormFieldValid("phone_number") && "error-input"
                          }`}
                        >
                          <label for="lname">Phone </label>
                          <br />
                          <PhoneNumber
                            id=""
                            className="feild-stl-res form-font-f"
                            type="tel"
                            name="phone_number"
                            value={formik.values.phone_number}
                            maskedPlaceHolder={maskedPlaceHolder}
                            maskedPhoneNumber={maskedPhoneNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {getFormErrorMessage("phone_number")}
                        </div>
                      </div>
                      <div
                        className={`address-res f-feild-res ${
                          isFormFieldValid("github_url") && "error-input"
                        }`}
                      >
                        <label for="lname">Github/Portfolio Link</label>
                        <br />
                        <input
                          className="feild-stl-res form-font-f"
                          type="url"
                          name="github_url"
                          id=""
                          placeholder="Enter Your Github/Portfolio Profile Here"
                          value={formik.values.github_url}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("github_url")}
                      </div>
                      <div
                        className={`address-res f-feild-res ${
                          isFormFieldValid("linkedin_url") && "error-input"
                        }`}
                      >
                        <label for="lname">LinkedIn</label>
                        <br />
                        <input
                          className="feild-stl-res form-font-f"
                          type="text"
                          id=""
                          placeholder="Enter Your LinkedIn Profile Here"
                          name="linkedin_url"
                          value={formik.values.linkedin_url}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("linkedin_url")}
                      </div>
                      <div
                        className={`address-res f-feild-res ${
                          isFormFieldValid("candidate_summary") && "error-input"
                        }`}
                      >
                        <label for="lname">Summary</label>
                        <br />
                        <textarea
                          className="feild-stl-res form-font-f"
                          cols="30"
                          rows="7"
                          name="candidate_summary"
                          value={formik.values.candidate_summary}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("candidate_summary")}
                      </div>
                    </form>

                    <div className="experince-resume-right" ref={headExpRef}>
                      <div className="experience-info-right">
                        Experience{" "}
                        <img
                          className="icon-cursor"
                          src="/image/plus-res.png"
                          alt=""
                          onClick={() => onExpAddHandler()}
                        />
                      </div>
                      {experience?.length > 0 &&
                        experience?.map((prod, index) => (
                          <CanResumeExpForm
                            prod={prod}
                            index={index}
                            monthDropDown={monthDropDown}
                            yearDropDown={yearDropDown}
                            onExpChangeHandler={onExpChangeHandler}
                            onExpSaveHandler={onExpSaveHandler}
                            expModelHandler={expModelHandler}
                            selectedExpHandler={selectedExpHandler}
                            openDeleteItemHandler={openDeleteItemHandler}
                          />
                        ))}
                    </div>

                    <div className="experince-resume-right">
                      <div className="experience-info-right">
                        Projects{" "}
                        <img
                          className="icon-cursor"
                          src="/image/plus-res.png"
                          alt=""
                          onClick={() => onProAddHandler()}
                        />
                      </div>

                      {projects?.length > 0 &&
                        projects?.map((prod, index) => (
                          <CanResumeProForm
                            prod={prod}
                            index={index}
                            onProChangeHandler={onProChangeHandler}
                            onProSaveHandler={onProSaveHandler}
                            proModelHandler={proModelHandler}
                            selectedProHandler={selectedProHandler}
                            openDeleteItemHandler={openDeleteItemHandler}
                          />
                        ))}
                    </div>
                    <div className="experince-resume-right">
                      <div className="experience-info-right">
                        Education{" "}
                        <img
                          className="icon-cursor"
                          src="/image/plus-res.png"
                          alt=""
                          onClick={() => onEduAddHandler()}
                        />
                      </div>

                      {education?.length > 0 &&
                        education?.map((prod, index) => (
                          <CanResumeEduForm
                            prod={prod}
                            index={index}
                            monthDropDown={monthDropDown}
                            yearDropDown={yearDropDown}
                            onEduChangeHandler={onEduChangeHandler}
                            onEduSaveHandler={onEduSaveHandler}
                            openDeleteItemHandler={openDeleteItemHandler}
                          />
                        ))}
                    </div>
                    <div className="experince-resume-right">
                      <div className="experience-info-right">
                        Certifications{" "}
                        <img
                          className="icon-cursor"
                          src="/image/plus-res.png"
                          alt=""
                          onClick={() => onCredAddHandler()}
                        />
                      </div>
                      {certifications?.length > 0 &&
                        certifications?.map((prod, index) => (
                          <CanResumeCredForm
                            prod={prod}
                            index={index}
                            monthDropDown={monthDropDown}
                            yearDropDown={yearDropDown}
                            onCredChangeHandler={onCredChangeHandler}
                            onCredSaveHandler={onCredSaveHandler}
                            openDeleteItemHandler={openDeleteItemHandler}
                          />
                        ))}
                    </div>
                    <div className="experince-resume-right">
                      <div className="experience-info-right">Skills </div>
                      <CanResumeSkillForm
                        keySkills={keySkills}
                        onSkillAddHandler={onSkillAddHandler}
                        onSkillSaveHandler={onSkillSaveHandler}
                        onSkillDeleteHandler={onSkillDeleteHandler}
                      />
                    </div>
                    {/* <div className="experince-resume-right">
                      <div className="experience-info-right">
                        Reference{" "}
                        <img
                          className="icon-cursor"
                          src="/image/plus-res.png"
                          alt=""
                        />
                      </div>
                      <CanResumeRefForm />
                    </div> */}

                    <div className="address-res f-feild-res">
                      <div className="two-can-sav-btn-last">
                        <button
                          type="submit"
                          className="can-res-btn-last"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </button>
                        {resumeFormMode === RESUME_MODE.VERIFICATION ? (
                          <button
                            type="submit"
                            className="save-res-btn-last"
                            onClick={() => onVerifyResumeClick()}
                          >
                            {verifyLoader ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Verify"
                            )}
                          </button>
                        ) : resumeFormMode === RESUME_MODE.UPDATE ? (
                          <button
                            type="submit"
                            className="save-res-btn-last"
                            onClick={() => onVerifyResumeClick()}
                          >
                            {verifyLoader ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        ) : resumeFormMode === RESUME_MODE.CUSTOMIZED ? (
                          <button
                            type="submit"
                            className="save-res-btn-last"
                            onClick={() => onCustomResumeFileNameClick()}
                          >
                            {verifyLoader ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <CanResumeTemp
                    originalData={originalData}
                    contactDetails={formik.values}
                    experience={experience}
                    projects={projects}
                    education={education}
                    certifications={certifications}
                    keySkills={keySkills}
                  />
                </>
              )}
            </div>
          </section>
          <WorldConnect />

          {toggleExpModel && (
            <ResumeExpModel
              selectedExp={selectedExp}
              modelHandler={expModelHandler}
              updateExpByIdHandler={updateExpByIdHandler}
            />
          )}
          {toggleProModel && (
            <ResumeProModel
              selectedPro={selectedPro}
              modelHandler={proModelHandler}
              updateProByIdHandler={updateProByIdHandler}
            />
          )}
          {toggleSaveModel && (
            <ContentModal closeModal={saveModelHandler}>
              <ResumeSaveModel
                modelHandler={saveModelHandler}
                onFileNameChangeHandler={onFileNameChangeHandler}
                onCustomResumeClick={onCustomResumeClick}
              />
            </ContentModal>
          )}

          {toggleDeleteItemModel && (
            <ActionModal
              closeModal={closeDeleteItemHandler}
              modalArt="/image/warning-wishlist.png"
              heading="Double-Check"
              content={`Are you sure you want to delete this ${selectedDeleteItemSection}?`}
            >
              <button
                className="btn btn-black"
                onClick={() => closeDeleteItemHandler()}
              >
                No
              </button>
              <button
                className="btn btn-outline-black"
                onClick={() => selectedDeleteItemHandler()}
              >
                Yes
              </button>
            </ActionModal>
          )}
          {loaderAI && (
            <ContentModal size="lg" closeModal={() => loaderAIHandler()}>
              <ResumeProgressModel
                heading="Just a moment please, while we are parsing your resume."
                content="A quick dance while you wait for PolycareersAI is parsing your resume."
              />
            </ContentModal>
          )}

          {toggleFormatModel && (
            <ActionModal
              heading="Verify resume"
              content={`PolyCareersAI was unable to read your resume. Please complete this form to continue.`}
              closeModal={() => closeAIModelHandler()}
              modalArt="/image/warning-wishlist.png"
            ></ActionModal>
          )}
        </>
      )}
    </>
  );
};

export default CanResume;
