import { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";
import JobListingRight from "../../Common/JobRecord/JobListingRight";
import JobNoRecordFound from "../../Common/JobNoRecordFound/JobNoRecordFound";
import "./JobListing.css";
import OutsideClickHandler from "react-outside-click-handler";

const JobListing = ({
  jobTitles,
  setSearch,
  selectedJobTitile,
  onJobTitleClean,
  datePosted,
  selectedDatePostedLabel,
  selectedDatePosted,
  expiryDate,
  selectedExpiryDateLabel,
  selectedExpiryDate,
  cities,
  selectedCity,
  jobStatus,
  selectedJobStatus,
  showJobListing,
  jobListingsCount,
  publishedJobs,
  onJobTitleClick,
  onSearchChange,
  onDatePostedClick,
  onDatePostedClean,
  onExpiryClick,
  onExpiryClean,
  onLocationClick,
  onLocationClean,
  onJobStatusClick,
  onJobStatusClean,
  onChangeJobStatus,
}) => {
  const [count, setCount] = useState(10);
  const [openSearch, setOpenSearch] = useState(false);
  const [searchingInput, setSearchingInput] = useState("");
  const [locationList, setLocationList] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {

  }, [jobTitles]);

  const countHandler = () => {
    if (count < jobListingsCount) setCount((prevCount) => prevCount + 10);
  };

  if (showJobListing === false && publishedJobs?.length === 0) return null;

  const filteredItem = cities.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))

  const onLocationDropDownHideHandler = (v) => {
    setLocationList(v);
  };

  const searchJobLocation = (e) => {
    setSearchTerm(e.target.value);
  }
  const toggleLocation = () => {
    setLocationList(!locationList)
  }

  const onJobLocationClickHandler = (data) => {
    setSearchTerm(data.name)
    onLocationClick(data)
    toggleLocation()
  };
  return (
    <>
      <section className="job-heading" id="job_listing">
        <div className="container container-site">
          <div className="job-search-box-row">
            <div className="job-heading-home smilar-job-heading job-heading-local w-100">
              <h2>Job listing</h2>
              <div
                className={`job-search-div m-0 ${openSearch ? "job-searchBtn-open" : "job-search-div-mbl"
                  }`}
                onClick={() => {
                  setOpenSearch(true);
                }}
              >
                <img
                  className="job-searchIcon job-searchIcon-mbl"
                  src="/image/job-searchIcon.png"
                  alt="job-searchBar"
                />
              </div>
              <div
                className={`job-search-div m-0 ${openSearch ? "job-search-div-abs" : "job-search-open"
                  }`}
              >
                <img
                  className="job-searchIcon"
                  src="/image/job-searchIcon.png"
                  alt="job-searchBar"
                  onClick={() => {
                    setOpenSearch(false);
                  }}
                />
                <input
                  placeholder="Search jobs"
                  className="job-search-input"
                  onChange={(e) => {
                    onSearchChange(e?.target?.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="open-postion">
        <div className="container container-site">
          <div className="divider"></div>
          <div className="j-fillter-only-dashbord">
            <div className="job-fillter-company">
              <div className="j-company-fillter job-listing-mbl">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {" "}
                      {selectedJobTitile?.jobTitle === ""
                        ? "Job title"
                        : selectedJobTitile?.jobTitle?.length >= 20
                          ? selectedJobTitile?.jobTitle?.substr(0, 20) + " ..."
                          : selectedJobTitile?.jobTitle}
                    </p>
                    {selectedJobTitile?.jobTitle === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onJobTitleClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {jobTitles?.length > 0 && (
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {jobTitles?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor job-title-size"
                          onClick={() => {
                            onJobTitleClick(j?.job_id, j?.job_title);
                          }}
                        >
                          {j?.job_title}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="job-fillter-company">
              <div className="j-company-fillter job-listing-mbl">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {" "}
                      {selectedDatePostedLabel === ""
                        ? "Date posted"
                        : selectedDatePostedLabel}
                    </p>
                    {selectedDatePostedLabel === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onDatePostedClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {datePosted?.length > 0 && (
                  <div className="list-search-dropdown-status">
                    <ul className="dorpdown-list-date-company">
                      {datePosted?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onDatePostedClick(j?.value, j?.label);
                          }}
                        >
                          <img src="/image/calander-d.png" alt="" />
                          {j?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="job-fillter-company">
              <div className="j-company-fillter job-listing-mbl">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {" "}
                      {selectedExpiryDateLabel === ""
                        ? "Expiry date"
                        : selectedExpiryDateLabel}
                    </p>
                    {selectedExpiryDateLabel === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onExpiryClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {expiryDate?.length > 0 && (
                  <div className="list-search-dropdown-status">
                    <ul className="dorpdown-list-date-company">
                      {expiryDate?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onExpiryClick(j?.value, j?.label);
                          }}
                        >
                          <img src="/image/calander-d.png" alt="" />
                          {j?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className='job-fillter-company'>
              <OutsideClickHandler
                onOutsideClick={() => {
                  onLocationDropDownHideHandler("true");
                }}
                className="j-company-fillter job-listing-mbl"
              >
                <div className="j-company-fillter job-listing-mbl">
                  <input
                    type="text"
                    name="searchLocation"
                    value={searchTerm}
                    onChange={(e) =>
                      searchJobLocation(e)
                    }
                    id="job-search-field"
                    placeholder="Job Location"
                    onFocus={toggleLocation}
                    autoComplete="off"
                  />
                  {
                    searchTerm ? <img
                      src="/image/cross-b.png"
                      alt=""
                      onClick={() => { onLocationClean(); setSearchTerm('') }}
                    /> : ''
                  }
                  <div
                    className={`job-location-dropdown ${locationList ? "job-dropdown-showhide" : 'open-dd'
                      }`}
                  >
                    {(cities?.length > 0 && !filteredItem.length) && (
                      <>
                        <ul className="dorpdown-list-location">
                          {cities.map((j, i) => (
                            <li
                              className="icon-cursor"
                              onClick={() =>
                                onJobLocationClickHandler(j)
                              }
                            >
                              <img src="/image/location-j-place.png" alt="" />
                              {j?.name}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                    {(filteredItem?.length > 0) && (
                      <>
                        <ul className="dorpdown-list-location">
                          {filteredItem.map((j, i) => (
                            <li
                              className="icon-cursor"
                              onClick={() =>
                                onJobLocationClickHandler(j)
                              }
                            >
                              <img src="/image/location-j-place.png" alt="" />
                              {j?.name}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
            <div className="job-fillter-company d-none">
              <div className="j-company-fillter job-listing-mbl">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p> {selectedCity === "" ? "Location" : selectedCity}</p>
                    {selectedCity === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onLocationClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {cities?.length > 0 && (
                  <div className="list-search-dropdown-company">
                    <ul className="dorpdown-list-date-company">
                      {cities?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onLocationClick(j);
                          }}
                        >
                          <img src="/image/location-j-place.png" alt="" />
                          {j?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="job-fillter-company">
              <div className="j-company-fillter job-listing-mbl">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {" "}
                      {selectedJobStatus?.label === ""
                        ? "Active"
                        : selectedJobStatus?.label}
                    </p>
                    {selectedJobStatus?.label === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onJobStatusClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {jobStatus?.length > 0 && (
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {jobStatus?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onJobStatusClick(j?.label, j?.value);
                          }}
                        >
                          {j?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="company-job-sec">
        <div className="space-inner-row">
          <div className="container container-site">
            {publishedJobs?.length > 0 ? (
              publishedJobs?.slice(0, count)?.map((prod, index) => (
                <Fragment key={prod?.id}>
                  <div className="divider mb-md-0 mb-4"></div>
                  <div className="Job-Opportunities-row">
                    <JobRecordLeft
                      hideCompanyName={true}
                      jobCounter={true}
                      activeSwitch={true}
                      radioBtn={prod?.is_active === 1 ? true : false}
                      JobData={prod}
                      onChangeJobStatus={onChangeJobStatus}
                    />
                    <JobListingRight
                      isActive={prod?.is_active}
                      date={prod?.date}
                      expireDate={prod?.expire_date}
                      selectedJobId={prod?.id}
                      myLists={prod?.myLists}
                      listSharedWithMe={prod?.listSharedWithMe}
                    />
                  </div>
                </Fragment>
              ))
            ) : (
              <JobNoRecordFound />
            )}
            <div className="divider"></div>
          </div>
        </div>
        {publishedJobs?.length > 10 && (
          <div className="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>See more</button>
            </Link>
          </div>
        )}
      </section>
    </>
  );
};

export default JobListing;
