import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectCompanyId } from "../../../redux/slices/companyDetailSlice";
import API from "../../../api";
import "./CompanyCard.css";
import "../CompanySection/CompanySection.css";

const CompanyCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const onDetailCompanyHandler = (id) => {
    navigate(`/company-detail/${id}`);
  };

  useEffect(() => {
    async function getTopCompanies() {
      try {
        await API.get(`/job-board/top_companies`)
          .then((response) => {
            if (response?.status === 200) {
              let res = response?.data;
              if (Array?.isArray(response?.data) && response?.data?.length > 0) {
                setData(res);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
    getTopCompanies();
  }, []);

  if (data?.length === 0) return null;

  return (
    <>
      <section className="logo-dev-poly">
        <div className="container container-site">
          <div className="two-heading-row-poly">
            <h2>Find your dream job.</h2>
            <p className="poly-row-test-job">
              Check out these opportunities with some amazing companies.
            </p>
          </div>
        </div>
      </section>
      <section className="featured-sec">
        <div className="container container-site">
          <div className="featured-row ">
            {data?.length > 0 &&
              data?.slice(0, 15)?.map((prod) => (
                <div className="featured-col ">
                  <div
                    className="featured-col-content companyCard-cursor"
                    onClick={() => onDetailCompanyHandler(prod?.id)}
                  >
                    <div className="card-body">
                      <div className="image-heading ">
                        <img src={prod?.logo} alt="fitenss " />
                        <div className="j-home-btn-round">
                          {prod?.jobs_count}{" "}
                          {prod?.jobs_count > 1 ? "Jobs" : "Job"}
                        </div>
                      </div>
                      <div className="card-headeing">
                        <span>
                          <h3>{prod?.name}</h3>
                        </span>
                      </div>
                      <div className="card-text">
                        <p>
                          {prod?.description?.length >= 80 ? (
                            <p>{prod?.description?.substr(0, 80) + "..."}</p>
                          ) : (
                            <p>{prod?.description}</p>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="card-footer">
                      <Link to="/">
                        <button className="e-btn e-btn-rounded">
                          E-Commerce
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyCard;
