import axios from "axios";
import { BASE_API, BASE_API_DYNAMIC } from "./config";

// Check if the project is running on localhost
const isLocalhost = window.location.hostname === 'localhost';

const baseURL = isLocalhost ? BASE_API : BASE_API_DYNAMIC;

const getAccessToken = () => {
  return localStorage.getItem("token");
};

const APIPvtDB = axios.create({
  baseURL: baseURL,
});

APIPvtDB.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default APIPvtDB;
