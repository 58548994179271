import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  candidateDetailTab: "candidate-evaluation",
  candidateDetailId: null,
  candidateDetailResumeId: null,
  jobDetailId: null,
};
const candidateDetailSlice = createSlice({
  name: "candidateDetail",
  initialState,
  reducers: {
    setCandidateDetailTab: (state, action) => {
      state.candidateDetailTab = action.payload;
    },
    setCandidateDetailId: (state, action) => {
      state.candidateDetailId = action.payload;
    },
    setCandidateDetailResumeId: (state, action) => {
      state.candidateDetailResumeId = action.payload;
    },
    setjobDetailId: (state, action) => {
      state.jobDetailId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCandidateDetailTab, setCandidateDetailId, setCandidateDetailResumeId, setjobDetailId } =
  candidateDetailSlice.actions;

export default candidateDetailSlice.reducer;

// asynchronous function with createAsyncThunk

export const getCandidateDetailAsync = createAsyncThunk(
  "public/getCandidateDetail",
  async ({ userId, candidateId, jobId }) => {
    let params = `?`;
    if (userId !== null) {
      params += `user_id=${userId}&`;
    }
    if (candidateId !== null) {
      params += `candidate_id=${candidateId}&`;
    }
    if (jobId !== null) {
      params += `job_id=${jobId}&`;
    }

    try {
      const response = await API.get(`/get-candidate-detail${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getParsedResumeAsync = createAsyncThunk(
  "public/getParsedResume",
  async ({ userId, resumeId, resumeType }) => {
    let params = `?`;
    if (userId !== null) {
      params += `candidate_id=${userId}&`;
    }
    if (resumeId !== null) {
      params += `resume_id=${resumeId}&`;
    }
    try {
      const response = await API.get(`/auth/get-parsed-resume${params}`);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getResumeForApplyingOnJob = createAsyncThunk(
  "public/getUserResumes",
  async ({ userId, resumeId, resumeType }) => {
    let params = `?`;
    if (userId !== null) {
      params += `user_id=${userId}`;
    }
    // if (resumeId !== null) {
    //   params += `resume_id=${resumeId}&`;
    // }
    // if (resumeType !== null) {
    //   params += `resume_type=${resumeType}`;
    // }
    try {
      const response = await API.get(`/get-user-resumes${params}`);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
