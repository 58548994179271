import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { resendVerifyEmailAsync, setActiveSubTab } from "../../../../redux/slices/AuthSlice";
import Message from "../EmployerForm/Message";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { COUNTRY_ISO_CODE } from "../../../../constants";

const CreateProfileForm = ({
  errorMsg,
  successMsg,
  loader,
  companyLogo,
  companyLogoModelHandler,
  createProfileHandler,
}) => {
  const dispatch = useDispatch();
  const { countryISOCode } = useSelector((state) => state.home);
  const { signUpEmpCompanyLogoFile, signUpEmp } = useSelector((state) => state.auth);

  const [selectedImageName, setSelectedImageName] = useState("");

  const [selectedFileName, setSelectedFileName] = useState("");

  const formik = useFormik({
    initialValues: {
      websiteUrl: "",
      companyName: "",
      companyInfo: "",
      linkedinURL: "",
      legal_document: "",
      files: null,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      websiteUrl: Yup.string().required("Please enter valid website URL"),
      companyName: Yup.string().required("Please enter company Name"),
      companyInfo: Yup.string().required("Please enter company Information"),
      legal_document: Yup.mixed()
        .test(
          "fileType",
          "Please upload a file in PDF format",
          (value) => !value || ["application/pdf"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 2 MB",
          (value) => !value || (value?.size / 1000) <= 2048
        ),
      files: Yup.mixed().required("Please upload a logo file")
        .test(
          "fileType",
          "File can be a JPEG or PNG document",
          (value) => !value || ["image/png", "image/jpeg"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 10 MB",
          (value) => !value || value?.size <= 10 * 1024 * 1024
        ),
    }),
    onSubmit: async (data, { resetForm }) => {
      // console.log(data);
      createProfileHandler(data);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const handleImageChange = async (event) => {
    companyLogoModelHandler(true);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Extracting file name and extension
        const fileName = file?.name;
        const fileExtension = fileName?.substring(
          fileName?.lastIndexOf(".") + 1
        );

        // Truncate the file name to 20 characters (excluding extension)
        const truncatedFileName =
          fileName?.substring(0, 20 - (fileExtension?.length + 1));

        setSelectedFileName(fileName);
        formik.setFieldValue("legal_document", file);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFileName("");
      formik.setFieldValue("legal_document", '');
    }
  };

  const resendVerifyEmailHandler = async () => {
    if (signUpEmp?.email === "") {
      return;
    }
    else {
      dispatch(
        resendVerifyEmailAsync({
          email: signUpEmp?.email, resendVerifyEmailHandler
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
          if (res?.success === true) {
            console.log("success");
          }
          else {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
          console.log(error);
        });
    }
  };


  useEffect(() => {
    if (companyLogo !== null) {
      formik.setFieldValue("files", companyLogo);
      setSelectedImageName(companyLogo?.name);
    }
  }, [companyLogo]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])

  return (
    <>
      <div className="candidate-signup-form-feild">
        <form onSubmit={formik.handleSubmit}>
          <div
            className={`signup-website-url f-feild ${isFormFieldValid("websiteUrl") && "error-input"
              }`}
          >
            <label for="flink">Website URL</label>
            <input
              className="form-font-f"
              type="text"
              id=""
              placeholder="Website URL"
              name="websiteUrl"
              value={formik.values.websiteUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("websiteUrl")}
          </div>

          <div
            className={`signup-company-name f-feild ${isFormFieldValid("companyName") && "error-input"
              }`}
          >
            <label for="flink">Company name</label>
            <input
              className="form-font-f"
              type="text"
              id=""
              placeholder="Company name"
              name="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("companyName")}
          </div>

          {countryISOCode !== COUNTRY_ISO_CODE.CA &&
            <div
              className={`signup-resume f-feild ${isFormFieldValid("companyDocs") && "error-input"
                }`}
            >
              <div className="flex-center-between label-text">
                <OverlayTrigger
                  placement='top'
                  overlay={
                    // delay={{ show: 250, hide: 4000000 }}
                    <Tooltip id='tooltip-top'>
                      тековна состојба
                    </Tooltip>
                  }
                >
                  <label for="flink">Company status document</label>
                </OverlayTrigger>
                <OverlayTrigger
                  placement='top'
                  overlay={
                    // delay={{ show: 250, hide: 4000000 }}
                    <Tooltip id='tooltip-top' className="large-tooltip">
                      The company status document is required to ensure that you are an authorized representative.
                    </Tooltip>
                  }
                >
                  <p className="helper-text">(Why do we need this?)</p>
                </OverlayTrigger>
              </div>
              <div className="position-relative upload-icon">
                <div className="resume-choose-label">
                  {selectedFileName !== ""
                    ? selectedFileName
                    : "Upload document"}
                </div>
                <input
                  type="file"
                  className="bg-pass-f"
                  placeholder="Choose file"
                  name="legal_document"
                  onChange={handleFileChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {getFormErrorMessage("legal_document")}
            </div>}

          <div
            className={`signup-company-information f-feild ${isFormFieldValid("companyInfo") && "error-input"
              }`}
          >
            <label for="flink">Company information</label>
            <br />
            <textarea
              className="form-font-f text-area-size"
              rows="10"
              cols="100"
              name="companyInfo"
              value={formik.values.companyInfo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p className="msg-show-blowe-textarea">Max limit (100) words</p>
            {getFormErrorMessage("companyInfo")}
          </div>
          <div className={`signup-linkedIn-link f-feild`}>
            <label for="flink">LinkedIn link</label>
            <input
              className="form-font-f"
              type="text"
              id=""
              placeholder="Enter your LinkedIn profile here"
              name="linkedinURL"
              value={formik.values.linkedinURL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="signup-file-company-logo f-feild">
            <label for="file-input">
              <strong>
                <img src="/image/custom-icon.png" alt="pic" />
              </strong>
              <input
                // type="file"
                id="file-input"
                name="files"
                placeholder="Choose file"
                onClick={handleImageChange}
              />

              <p className="company-logo-file">
                {selectedImageName !== ""
                  ? selectedImageName
                  : "Upload Company Logo"}
              </p>
              <span>(Upload file in .Jpeg .Png)</span>
              <p className="file-size">Max size 10MB</p>
            </label>
            {getFormErrorMessage("files")}
          </div>
          <div className="candidate-from-signup-create btn-next-pre">
            <button
              className="btn-next-previous btn-next-pre-toggle"
              onClick={() => {
                dispatch(setActiveSubTab("signUp"));
              }}
            >
              Previous
            </button>
            <button
              className="btn-next-previous btn-next-pre-toggle"
              type="submit"
            >
              {loader ? (
                <>
                  {/* {"Your account is being created "} */}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </>
              ) : (
                "Next"
              )}
            </button>
          </div>
          <div className="signup-resendmail">
            <p>
              Haven't received the confirmation email yet?{" "}
              <Link href="#" onClick={() => resendVerifyEmailHandler()}>
                <span>Resend Email</span>
              </Link>
            </p>
          </div>
          <br />
          <Message errorMsg={successMsg} />
        </form>
      </div>
    </>
  );
};

export default CreateProfileForm;
