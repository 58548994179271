import React from "react";
import TermContent from "../../components/TermCondition/TermContent";
import WorldConnect from "../../components/Common/WorldConnect/WorldConnect";
import "../PrivacyPolicy/index.css";

const TermCondition = () => {
  return (
    <>
      <TermContent />
      <WorldConnect />
    </>
  );
};

export default TermCondition;
