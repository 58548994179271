import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  roleDropdownAsync,
  setActiveSideBar,
  setPermissionModel,
  setSelectedDep,
} from "../../../../redux/slices/AdminDashboardSlice";
import * as Yup from "yup";
import FormError from "../../../Common/Admin/FormError/FormError";
import PermissionForm from "./PermissionForm";
import APIPvt from "../../../../apiPvt";

const AdminCreateRole = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formMode, selectedDep, selectedRoleR } = useSelector(
    (state) => state.adminDashboard
  );

  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  /*Dropdown Option*/
  const [depDropDown, setDepDropDown] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [statusDropDown, setStatusDropDown] = useState([]);

  /*Select Value*/
  const [selectModule, setSelectModule] = useState([]);
  const [moduleError, setModuleError] = useState(false);

  /*Permission*/
  const [selectPermission, setSelectPermission] = useState([]);
  const [selectedSubModules, setselectedSubModules] = useState([]);
  const [selectedSubModulesSlug, setselectedSubModulesSlug] = useState([]);

  const [activePerTab, setActivePerTab] = useState("");

  const formik = useFormik({
    initialValues: {
      role: "",
      dep: "",
      depId: null,
      status: "",
      statusId: null,
      module: "",
      description: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      role: Yup.string().required("Please Enter Role"),
      dep: Yup.string().required("Please Enter Department"),
    }),
    onSubmit: async (data, { resetForm }) => {
      if (isModuleFieldValid()) {
        setModuleError(true);
        return;
      } else if (selectedSubModulesSlug?.length === 0) {
        dispatch(setPermissionModel(true));
        return;
      }

      onCreateRole(data);
    },
  });

  const onDepClick = async (id, name) => {
    await formik.setFieldValue("depId", id);
    await formik.setFieldValue("dep", name);
  };

  const onStatusClick = async (label, value) => {
    await formik.setFieldValue("status", label);
    await formik.setFieldValue("statusId", value);
  };

  const onCancelClick = () => {
    if (formMode === "add") {
      dispatch(
        setActiveSideBar({
          id: "2",
          name: "Department",
          value: "Create Department",
        })
      );
    } else if (formMode === "edit") {
      dispatch(
        setActiveSideBar({
          id: "2",
          name: "Roles",
          value: "Roles",
        })
      );
      navigate("/admin-role");
    }
  };

  /* Access Module Event Handler*/

  const onModuleClick = async (curentModule) => {
    const isIdExists = selectModule?.some(
      (item) => item.id === curentModule?.id
    );
    if (isIdExists) return;
    const newModule = [...selectModule, curentModule];
    setSelectModule(newModule);
    setselectedSubModulesSlug([]);
    if (newModule?.length > 0) setModuleError(false);
    getModulePermission(newModule);
  };

  const onModuleCancel = async (curentModule) => {
    const updatedItems = selectModule.filter(
      (item) => item.id !== curentModule?.id
    );
    setSelectModule(updatedItems);
    setselectedSubModulesSlug([]);
    if (updatedItems?.length === 0) setModuleError(true);
    getModulePermission(updatedItems);
  };

  const onActivePerTabClick = (item) => {
    setActivePerTab(item?.name);
    setselectedSubModules(item?.sub_modules);
  };

  const onSlugClick = (slugName) => {
    if (selectedSubModulesSlug.includes(slugName)) {
      setselectedSubModulesSlug((prevState) =>
        prevState.filter((name) => name !== slugName)
      );
    } else {
      setselectedSubModulesSlug((prevState) => [...prevState, slugName]);
    }
  };

  const onAddAllSlugClick = () => {
    if (Array.isArray(selectPermission) && selectPermission?.length > 0) {
      const permissionNames = selectPermission?.reduce((acc, module) => {
        if (module?.sub_modules && Array.isArray(module?.sub_modules)) {
          const subModulePermissionNames = module?.sub_modules.reduce(
            (subAcc, subModule) => {
              if (
                subModule?.permissions &&
                Array.isArray(subModule?.permissions)
              ) {
                const subModuleNames = subModule?.permissions.map(
                  (permission) => permission?.name
                );
                return [...subAcc, ...subModuleNames];
              }
              return subAcc;
            },
            []
          );
          return [...acc, ...subModulePermissionNames];
        }
        return acc;
      }, []);

      setselectedSubModulesSlug(permissionNames);
    }
  };

  const onRemoveAllSlugClick = () => {
    setselectedSubModulesSlug([]);
  };

  const onCreateRole = async (data) => {
    try {
      const { role, depId, statusId, description } = data;
      let selectModuleId = [];
      selectModuleId = selectModule?.map((item) => item?.id);

      const formData = new FormData();
      formData.append("department_id", depId);
      formData.append("title", role?.trim());
      statusId !== null && formData.append("status", statusId);
      description !== "" && formData.append("description", description);
      selectModuleId?.length > 0 &&
        Object.keys(selectModuleId).forEach((key) => {
          formData.append("module_ids[]", selectModuleId[key]);
        });

      selectedSubModulesSlug?.length > 0
        ? Object.keys(selectedSubModulesSlug).forEach((key) => {
            formData.append("permissions[]", selectedSubModulesSlug[key]);
          })
        : formData.append("permissions[]", []);

      setLoader(true);

      let baseURL = ``;
      if (formMode === "add") baseURL = `/role`;
      else if (formMode === "edit") baseURL = `/role/${selectedRoleR?.id}`;

      await APIPvt.post(baseURL, formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;

            if (res?.success === true) {
              dispatch(setSelectedDep({ id: null, name: "" }));

              if (formMode === "add") {
                dispatch(
                  setActiveSideBar({
                    id: "2",
                    name: "Users",
                    value: "Create User",
                  })
                );
              } else if (formMode === "edit") {
                dispatch(
                  setActiveSideBar({
                    id: "2",
                    name: "Roles",
                    value: "Roles",
                  })
                );
                navigate("/admin-role");
              }
            } else {
              setErrorMsg(res?.message);
              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
            }
            setLoader(false);
          }
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            setErrorMsg(data?.message);
            setTimeout(() => {
              setErrorMsg("");
            }, 5000);
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const getEditRoleData = async () => {
    try {
      await APIPvt.get(`/role/${selectedRoleR?.id}`)
        .then(async (response) => {
          if (response?.status === 200 || response?.success === true) {
            let res = response?.data?.data;
            let role = response?.data?.data?.role;
            await formik.setFieldValue("role", role?.title);
            await formik.setFieldValue("dep", role?.department?.name);
            await formik.setFieldValue("depId", role?.department?.id);
            await formik.setFieldValue(
              "status",
              role?.status === 1 ? "Active" : "Inactive"
            );
            await formik.setFieldValue("statusId", role?.status);
            role?.description !== "null" &&
              (await formik.setFieldValue("description", role?.description));

            if (res?.role_modules?.length > 0)
              setSelectModule(res?.role_modules);
            /*Permission*/
            setSelectPermission(res?.modules);
            setselectedSubModules(res?.modules[0]?.sub_modules);
            setselectedSubModulesSlug(res?.permissions);

            setActivePerTab(res?.modules[0]?.name);
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getModulePermission = async (selectModule) => {
    try {
      const selectModuleId = selectModule?.map((item) => item.id);

      const formData = new FormData();
      selectModuleId !== null &&
        Object.keys(selectModuleId).forEach((key) => {
          formData.append("module_ids[]", selectModuleId[key]);
        });

      await APIPvt.post(`/get-module-submodule-permissions`, formData)
        .then((response) => {
          if (response?.status === 200 || response?.success === true) {
            let res = response?.data;
            let option = response?.data?.data;
            if (res?.success === true && res?.data?.length > 0) {
              setSelectPermission(option);
              setselectedSubModules(option[0]?.sub_modules);
              setActivePerTab(option[0]?.name);
            }
          }
        })
        .catch((error) => {
          if (error) {
            setselectedSubModules([]);
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const roleDropdownHandler = () => {
    dispatch(roleDropdownAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setDepDropDown(res?.data?.departments);
          setStatusDropDown(res?.data?.statuses);
          setModuleOptions(res?.data?.modules);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isModuleFieldValid = () => !!(selectModule?.length <= 0);

  const getModuleErrorMessage = () => {
    return (
      isModuleFieldValid() && (
        <p className="error-msg">{formik.errors["module"]}</p>
      )
    );
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  useEffect(() => {
    if (formMode === "edit") getEditRoleData();
  }, [selectedRoleR]);

  useEffect(() => {
    roleDropdownHandler();
    async function getSelectedDep() {
      await formik.setFieldValue("depId", selectedDep?.id);
      await formik.setFieldValue("dep", selectedDep?.name);
    }
    getSelectedDep();
  }, []);

  return (
    <>
      <div className="form-heading">
        <h2>{formMode === "edit" ? "Edit " : "Create"} roles</h2>
        {/* <button className="add-role-admin">Add Role +</button> */}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="department-form-box">
          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("dep") && "error-input-admin"
              }`}
            >
              <label for="">Department *</label>
              <div className={`admin-status-filter-form`}>
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.dep !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.dep === ""
                        ? "Department"
                        : formik?.values?.dep}
                    </p>

                    {isFormFieldValid("dep") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>
                {depDropDown?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-job-title-company">
                      {depDropDown?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onDepClick(item?.id, item?.name);
                          }}
                        >
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("dep")}
            </div>
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("role") && "error-input-admin"
              }`}
            >
              <label for="">Role name *</label>
              <br />
              <input
                type="text"
                name="role"
                id="dept-f"
                placeholder="Role name"
                className="form-font-admin"
                value={formik.values.role}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("role")}
            </div>

            <div className={`f-admin feild-f-admin`}>
              <label for="">Status</label>
              <div className={`admin-status-filter-form`}>
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.status !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.status === ""
                        ? "Status"
                        : formik?.values?.status}
                    </p>
                    <img src="/image/admin-d.png" alt="" />
                  </div>
                </div>

                {statusDropDown?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-job-title-company">
                      {statusDropDown?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onStatusClick(item?.label, item?.value);
                          }}
                        >
                          {item?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="tag-input-f">
              <div
                className={`f-admin feild-f-admin ${
                  moduleError && "error-input-admin"
                }`}
              >
                <label for="">Module access *</label>
                <div className="admin-status-filter-form">
                  <div
                    id="dropdown-boxId2"
                    className="company-form-dropdownBox1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-filter-admin">
                      <p>Admin module access</p>
                      {moduleError ? (
                        <img src="/image/warning.png" alt="" />
                      ) : (
                        <img src="/image/admin-d.png" alt="" />
                      )}
                    </div>
                  </div>

                  {moduleOptions?.length > 0 && (
                    <div className="admin-status-dropdown-frm">
                      <ul className="list-admin-portal">
                        {moduleOptions?.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              onModuleClick(item);
                            }}
                          >
                            <img src="/image/modules.svg" alt="" />
                            {item?.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {moduleError && (
                  <p className="error-msg">Please Enter Admin Module Access</p>
                )}
              </div>

              {selectModule?.length > 0 && (
                <div class="tags-admin list-role-c">
                  <ul className="tags-titles-list">
                    {selectModule.map((j, i) => (
                      <li key={i}>
                        {j?.name}
                        <span onClick={() => onModuleCancel(j)}>
                          <img src="/image/cross.png" alt="" />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="f-admin feild-f-text-are">
            <label for="">Role description</label>
            <br />
            <textarea
              name="description"
              id="text-admin"
              cols="30"
              rows="5"
              placeholder="Type here"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
        {selectPermission?.length > 0 && (
          <PermissionForm
            activePerTab={activePerTab}
            selectPermission={selectPermission}
            selectedSubModules={selectedSubModules}
            selectedSubModulesSlug={selectedSubModulesSlug}
            onActivePerTabClick={onActivePerTabClick}
            onSlugClick={onSlugClick}
            onAddAllSlugClick={onAddAllSlugClick}
            onRemoveAllSlugClick={onRemoveAllSlugClick}
          />
        )}

        <div className="admin-form-btn">
          {" "}
          <button className="cancel-admin" onClick={() => onCancelClick()}>
            Cancel
          </button>{" "}
          <button className="next-admin" type="submit">
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : formMode === "add" ? (
              "Next"
            ) : (
              "Edit & Save"
            )}
          </button>
        </div>
      </form>
      <FormError errorMsg={errorMsg} />
    </>
  );
};

export default AdminCreateRole;
