import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getCurrentFormattedDate from "../../../utils/getCurrentFormattedDate";
import "./ChooseResumeModel.css";
import API from "../../../api";
import {
  handlePDFResume,
  handleWordResume,
} from "../../../utils/downloadResumes";

const ChooseResumeModel = ({
  customResume,
  modelHandler,
  onDeleteCustomResume,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [deleteMsg, setDeleteMsg] = useState("");
  const [customResumeData, setCustomResumeData] = useState([]);

  const handlePreviewResume = (resumeURL) => {
    if (resumeURL !== null) {
      const pdfUrl = resumeURL;
      window.open(pdfUrl, "_blank");
    }
  };

  const deleteResumeHandler = async (id) => {
    onDeleteCustomResume(id);
    setDeleteMsg("Removed");
    const timer = setTimeout(() => {
      setDeleteMsg("");
    }, 2000);

    return () => clearTimeout(timer);
  };

  const getCustomResumeData = async () => {
    setCustomResumeData(customResume);
  };

  useEffect(() => {
    getCustomResumeData();
  }, [customResume]);

  return (
    <>
      <div className="modal-body">
        <h3 className="mt-0">My custom resumes</h3>
        {deleteMsg !== "" && (
          <div className="alert alert-danger" role="alert">
            {deleteMsg}
          </div>
        )}

        <div className="scroll-resume-mdl-job text-start">
          {customResumeData?.length > 0 &&
            customResumeData?.map((prod, index) => (
              <div className="choose-res-row me-0">
                {/* <div
                  className="pdf-res-icon icon-cursor"
                  onClick={() => handlePreviewResume(prod?.parsed_resume)}
                >
                  <img src="/image/res-PDF.png" alt="" />
                </div> */}
                <div className="choose-res-all-content">
                  <div className="res-name-choose">
                    {prod?.resume_display_name !== "" && (
                      <>
                        <p className="name-file-choose">
                          {prod?.resume_display_name}
                        </p>
                      </>
                    )}
                    {prod?.job_url !== null && (
                      <>
                        <a
                          href={prod?.job_url}
                          className="job-link-modl"
                          target="_blank"
                        >
                          {prod?.job_url?.length >= 25
                            ? `${prod?.job_url?.substr(0, 25)}...`
                            : prod?.job_url}
                        </a>
                      </>
                    )}
                    {prod?.updated_at !== "" && (
                      <p className="date-res-choose">
                        Last Updated {getCurrentFormattedDate(prod?.updated_at)}
                      </p>
                    )}{" "}
                  </div>
                  <div className="two-icon-choose-res">
                    <img
                      src="/image/res-PDF.png"
                      alt=""
                      className="pdf-icon-res icon-cursor"
                      onClick={() => handlePDFResume(prod?.id, user?.id)}
                    />
                    <img
                      src="/image/word-icon.png"
                      alt=""
                      className="dowld-res icon-cursor"
                      onClick={() => handleWordResume(prod?.id, user?.id)}
                    />

                    <img
                      className="icon-cursor"
                      src="../image/delet-chos-res.png"
                      alt=""
                      onClick={() => deleteResumeHandler(prod?.id)}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
        {/* <div className="show-less">
              Show Less <img src="/image/arrow-up.png" alt="" />
            </div> */}
      </div>
    </>
    // <section className="model-popup model-popup-2 ">
    //   <div className="choose-job-model-popup-bg">
    //     <OutsideClickHandler
    //       onOutsideClick={() => {
    //         modelHandler(false);
    //       }}
    //     >
    //       <div className="popup-resume-content-choose">
    //         <div
    //           className="close-resmodle-file"
    //           onClick={() => modelHandler(false)}
    //         >
    //           <img src="/image/cut-sm.png " alt=" " />
    //         </div>
    //         <div className="send-correct-resume">
    //           <img src="/image/WORKBRIDGE.png" alt=" " />
    //         </div>
    //         <div className="choose-upload-heading">Choose Resume</div>
    //         {deleteMsg !== "" && (
    //           <div className="choose-remove-row">
    //             <div className="remove-f-mdl">{deleteMsg}</div>
    //           </div>
    //         )}

    //         <div className="scroll-resume-mdl-job">
    //           {customResumeData?.length > 0 &&
    //             customResumeData?.map((prod, index) => (
    //               <div className="choose-res-row">
    //                 <div
    //                   className="pdf-res-icon icon-cursor"
    //                   onClick={() => handlePreviewResume(prod?.parsed_resume)}
    //                 >
    //                   <img src="/image/res-PDF.png" alt="" />
    //                 </div>
    //                 <div className="choose-res-all-content">
    //                   <div className="res-name-choose">
    //                     {prod?.resume_display_name !== "" && (
    //                       <>
    //                         <p className="name-file-choose">
    //                           {prod?.resume_display_name}
    //                         </p>
    //                       </>
    //                     )}
    //                     {prod?.job_url !== null && (
    //                       <>
    //                         <a
    //                           href={prod?.job_url}
    //                           className="job-link-modl"
    //                           target="_blank"
    //                         >
    //                           {prod?.job_url?.length >= 30
    //                             ? `${prod?.job_url?.substr(0, 30)}...`
    //                             : prod?.job_url}
    //                         </a>
    //                       </>
    //                     )}
    //                     {prod?.updated_at !== "" && (
    //                       <p className="date-res-choose">
    //                         Last Updated{" "}
    //                         {getCurrentFormattedDate(prod?.updated_at)}
    //                       </p>
    //                     )}{" "}
    //                   </div>
    //                   <div className="two-icon-choose-res">
    //                     <img
    //                       className="icon-cursor"
    //                       src="../image/delet-chos-res.png"
    //                       alt=""
    //                       onClick={() => deleteResumeHandler(prod?.id)}
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //             ))}
    //         </div>
    //         {/* <div className="show-less">
    //           Show Less <img src="/image/arrow-up.png" alt="" />
    //         </div> */}
    //       </div>
    //     </OutsideClickHandler>
    //   </div>
    // </section>
  );
};

export default ChooseResumeModel;
