export const BASE_API = `https://dev.polycareers.com.mk/backend/public/api/v1`;

export const BASE_API_ADMIN = `https://dev.polycareers.com.mk/backend/public/api/v1/admin/v1`;


export const BASE_API_DYNAMIC = `https://${window.location.host}/backend/public/api/v1`;

export const BASE_API_ADMIN_DYNAMIC = `https://${window.location.host}/backend/public/api/v1/admin/v1`;





// export const BASE_API = `https://demo.workbridgetalent.ca/backend-files/public/api/v1`;

// export const BASE_API_ADMIN = `https://demo.workbridgetalent.ca/backend-files/public/api/v1/admin/v1`;


