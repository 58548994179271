import { useSelector, useDispatch } from "react-redux";
import ActionModal from "../../ActionModal/ActionModal";
import OutsideClickHandler from "react-outside-click-handler";
import { setDeleteAction } from "../../../../redux/slices/AdminDashboardSlice";
import "./ComfrimModel.css";

const ComfrimModel = () => {
  const dispatch = useDispatch();
  const { deleteAction } = useSelector((state) => state.adminDashboard);
  return (
    <>
      <ActionModal
        heading="Double-Check"
        closeModal={() => dispatch(setDeleteAction({ open: false, msg: "" }))}
        modalArt="/image/warning-wishlist.png"
        content={deleteAction?.msg !== "" && deleteAction?.msg}
      ></ActionModal>

      {/* <section className="model-popup-post-job">
        <div className="popup-admin-portal">
          <OutsideClickHandler
            onOutsideClick={() => {
              dispatch(setDeleteAction({ open: false, msg: "" }));
            }}
          >
            <div className="popup-content-whishlist">
              <div
                className="close-img icon-cursor"
                onClick={() => {
                  dispatch(setDeleteAction({ open: false, msg: "" }));
                }}
              >
                <img src="/image/chat-cut.png" alt=" " />
              </div>
              <div className="uper-part-admin">
                <div className="wishlist-logo">
                  <img src="/image/polycareers-north-macedonia.png" alt=" " />
                </div>
                <div className="admin-logo-model">
                  <img src="/image/model-admin.png" alt=" " />
                </div>
                <p className="text-con-model">Confirm</p>
              </div>
              <div className="lower-part-admin">
                <p className="text-admin-two">
                  {deleteAction?.msg !== "" && deleteAction?.msg}
                </p>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </section> */}
    </>
  );
};

export default ComfrimModel;
