import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";

const JobDetailSec = ({ label, description }) => {
  // const [newDes, setNewDes] = useState([""]);

  // useEffect(() => {
  //   const currentArray = JSON?.parse(description);
  //   const convertArray = currentArray?.map((item) => {
  //     return item;
  //   });
  //   setNewDes(convertArray);
  // }, []);

  return (
    <>
      {description && (
        <div className="job-heading-detail-sec">
          <h5>{label}</h5>
          {ReactHtmlParser(description)}

          {/* <ul className="job-details-list">
            {newDes?.map((i) => (
              <li>{`${i}`}</li>
            ))}
          </ul> */}
        </div>
      )}
    </>
  );
};

export default JobDetailSec;
