import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import API from "../../../api";
import {
  setActiveSubTab,
  setActiveTab,
  setEmailVerify,
  setLoginModel,
  setLoginUser,
  setLoginUserName,
  setSignUpBarHide,
  setSignUpEmp,
} from "../../../redux/slices/AuthSlice";
import {
  setActiveSideBar,
  setdefaultPermissions,
} from "../../../redux/slices/AdminDashboardSlice";
import "./ContactusModal.css";
import {
  ADMIN,
  CANDIDATE,
  COUNTRY_ISO_CODE,
  EMPLOYER,
} from "../../../constants";

const ContactUsModal = ({ setContactModal, showSubmitModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { countryISOCode } = useSelector((state) => state.home);
  const { openLoginModel } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Please enter email"),
      firstName: Yup.string().required("Please enter your first name"),
    }),
    onSubmit: async (data, { resetForm }) => {
      console.log(data);
      setContactModal(false);
      if (showSubmitModal) {
        showSubmitModal()
      }
      const { email, password } = data;
      // try {
      //   setLoader(true);
      //   await API.post(`/auth/login`, {
      //     email,
      //     password,
      //   })
      //     .then((response) => {
      //       if (response?.status === 200 || response?.status === 201) {
      //         const res = response?.data;
      //         const user = response?.data?.user;

      //         if (res?.result === true) {
      //           dispatch(setLoginUser(res));
      //           dispatch(setLoginUserName(user?.first_name));
      //           dispatch(setEmailVerify(true));
      //           dispatch(setLoginModel(false));
      //           dispatch(setSignUpBarHide(true));
      //           if (res?.permissions)
      //             dispatch(setdefaultPermissions(res?.permissions));
      //           localStorage.setItem("token", res?.access_token);

      //           if (
      //             res?.user?.user_type &&
      //             CANDIDATE.includes(res?.user?.user_type)
      //           )
      //             navigate("/candidate-dashboard");
      //           else if (
      //             res?.user?.user_type &&
      //             EMPLOYER.includes(res?.user?.user_type)
      //           )
      //             navigate("/employer-dashboard");
      //           else if (
      //             res?.user?.user_type &&
      //             ADMIN.includes(res?.user?.user_type)
      //           ) {
      //             dispatch(
      //               setActiveSideBar({
      //                 id: "2",
      //                 name: "Department",
      //                 value: "Department",
      //               })
      //             );
      //             navigate("/admin-department");
      //           }
      //         }
      //         setLoader(false);
      //       }
      //     })
      //     .catch((error) => {
      //       if (error) {
      //         const { response } = error;
      //         const { data } = response;
      //         setErrorMsg(data?.message);
      //         setTimeout(() => {
      //           setErrorMsg("");
      //         }, 5000);
      //         console.log(error);
      //       }
      //       setLoader(false);
      //     });
      // } catch (error) {
      //   setLoader(false);
      //   console.log(error);
      // }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const onSignUpHandler = () => {
    dispatch(setActiveTab("candidate"));
    dispatch(setActiveSubTab("signUp"));
    dispatch(setSignUpEmp({}));
    dispatch(setLoginModel(false));
  };

  return (
    <section className={`model-comment-popup `}>
      <div className="popup-bg-job-cont">
        <OutsideClickHandler
          onOutsideClick={() => {
            dispatch(setLoginModel(false));
            setContactModal(false);
          }}
        >
          <div className="popup-job-sec">
            <div className="model-left-right-job">
              <div className="col-left-model-cont"></div>
              <div className="col-right-model-cont">
                <div
                  className="close-img-resume"
                  onClick={() => {
                    setContactModal(false);
                  }}
                >
                  <img
                    src="/image/cross-btn.png"
                    alt=""
                    className="cross-btn"
                  />
                </div>
                <div className="model-logo-job-sec">
                  <img
                    className="logo-img contact-logo"
                    src="/image/polycareers-model.png"
                    alt=""
                  />
                </div>
                <div className="divider divider-mg divider-mble"></div>
                <div className="content-f-cont">
                  <h5>Contact us</h5>
                </div>
                <div className="model-signin-cont">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="candidate-username-signup-form ">
                      <div
                        className={`first-name first-full-width ${isFormFieldValid("firstName") && "error-input"
                          }`}
                      >
                        <br />
                        <input
                          className="form-font-f"
                          type="text"
                          placeholder="Name"
                          name="firstName"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("firstName")}
                      </div>
                    </div>
                    <div
                      className={`signup-email f-feild ${isFormFieldValid("email") && "error-input"
                        }`}
                    >
                      <br />
                      <input
                        className="form-font-f"
                        type="email"
                        name="email"
                        id=""
                        placeholder="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {getFormErrorMessage("email")}
                    </div>
                    <div className={`signup-phone message-field`}>
                      <div
                        className={`merge-feild  ${isFormFieldValid("phoneNo") && "error-input"
                          }`}
                      >
                        <textarea
                          id=""
                          className="form-font-f contact-message"
                          placeholder="Message"
                          name="phoneNo"
                          value={formik.values.phoneNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          rows={6} // You can adjust the number of visible rows
                          cols={50} // You can adjust the number of visible columns
                        />
                      </div>
                    </div>
                    {/* <div className="contact-send-btn" onClick={() => { setIsSent(true) }}>
                        <p className="signIn-btn-head">Send</p>
                      </div> */}
                    <div className="text-end">
                      <button
                        type="text"
                        className="btn btn-black mt-3 px-5 py-3"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                  <div className="footer-div">
                    <div className="address-sec">
                      <img
                        src="/image/location-icon.png"
                        alt=""
                        className="cross-btn"
                      />
                      <div>
                        <p className="font-museo">
                          <span className="bluish-clr addr-head">
                            Address
                          </span>{" "}
                          <br />
                          {countryISOCode === COUNTRY_ISO_CODE.CA ? (
                            <>
                              1920 Yonge Street, <br />
                              Toronto, ON M5E 1S7
                            </>
                          ) : (
                            <>
                              202/1 Orce Nikolov, First Floor <br /> Skopje,
                              Macedonia
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="email-sec">
                      <img
                        src="/image/email-icon.png"
                        alt=""
                        className="cross-btn"
                      />
                      <p className="font-museo bluish-clr">
                        EMAIL
                        <br />
                        <a
                          href={
                            countryISOCode === COUNTRY_ISO_CODE.CA
                              ? "mailto:info@polycareers.com"
                              : "mailto:info@polycareers.com.mk"
                          }
                        >
                          {countryISOCode === COUNTRY_ISO_CODE.CA
                            ? "info@polycareers.com"
                            : "info@polycareers.com.mk"}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </section>
  );
};

export default ContactUsModal;
