const JobDetailRightSec = ({ label, value }) => {
  return (
    <>      
      <div className="j-com-dev-info">
        <p className="company-j-info-l">{label}</p>
        <p className="j-l-text">{value[0]?.name?.trim()}</p>
      </div>
      {/* <div className="company-right-col"></div> */}
    </>
  );
};

export default JobDetailRightSec;
