export const ModuleData = [
  {
    name: "Job Portal Management",
  },
  {
    name: "HR Management",
  },
  {
    name: "Time Sheet Management",
  },
  {
    name: "Asset Management",
  },
  {
    name: "Expense Management",
  },
  {
    name: "Client Management",
  },
];
