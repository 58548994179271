import { useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { closeZoeModal, openDivModal } from "../../../redux/slices/JobPostZoeModalSlice";
import {
  changeJobDecisionToZoe,
  clearJobDecision,
} from "../../../redux/slices/JobDecisionSlice";
import login from "../../../pages/Auth/Login";
import "./PostJobZoeModel.css";

const PostJobZoeModel = ({ handleNoButton, handleYesButtonClick }) => {
  const dispatch = useDispatch();

  const handleNoButtonClicked = async () => {
    await handleNoButton();
  };

  const handleYesButtonClicked = async () => {
    handleYesButtonClick(true);
  };

  return (
    <div className="modal-body">

      <div className="generating-decs text-start pt-0 px-md-5 px-3 pb-md-4 pb-3">
        <h4>PolyCareersAI feature</h4>
        <p className="job-decs-sub-heading mb-3">Would you like our AI to provide suggestions on how to make your job posting more inclusive?</p>
        <p>Cultivating an inclusive job posting fosters diversity, equity,a and a welcoming workplace culture, leading to a more innovative and engaged team.</p>
        <div className="zeo-btn mt-md-4 mt-3">
          <button
            className="btn btn-black"
            type="submit"
            onClick={async () => {
              dispatch(closeZoeModal());
              await handleNoButtonClicked();
            }}
          >
            No, thank you
          </button>
          <button
            className="btn btn-outline-black"
            type="submit"
            onClick={async () => {
              dispatch(closeZoeModal());
              await handleYesButtonClicked();
            }}
          >
            Yes, please
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostJobZoeModel;
